import { Divider } from "antd";

export default function PrivacyPolicy() {

    return (
        <div
            style={{
                padding: '32px 48px',
                color: '#212121',
                background: '#FFFFFF',
                minHeight: '100vh',
            }}
        >
            <h1
                style={{
                    fontWeight: 'bolder',
                }}
            >
                Nota Município - Política de Privacidade
            </h1>

            <section>
                <h2
                    id="introducao"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    1 - INTRODUÇÃO
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        1.1 A GOVFÁCIL GESTÃO & TECNOLOGIA LTDA, doravante denominada simplesmente "GOVFÁCIL", reconhece a importância de zelar pela privacidade das informações dos usuários do aplicativo para dispositivos móveis Nota Município, doravante denominado simplesmente "APLICATIVO”.
                    </p>

                    <p>
                        1.2 Este documento, denominado Política de Privacidade, tem por objetivo estabelecer as regras sobre a obtenção, uso e armazenamento dos dados e informações coletadas dos usuários e visitantes, além dos registros de suas atividades na GOVFÁCIL.
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="definicoes"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    2 - DEFINIÇÕES
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        2.1 Esta política de privacidade descreve as práticas da GOVFÁCIL em relação a dados coletados por meio do nosso APLICATIVO. Esta política se aplica aos dados que identificam nossos usuários individualmente e demais dados fornecidos pelos usuários ou coletados durante a utilização dos serviços.
                    </p>

                    <p>
                        2.2 Em todos os casos, nós cumpriremos com toda legislação aplicável à proteção de dados nas jurisdições em que operamos. Esta política de privacidade se sujeita a lei brasileira de proteção de dados. Quando você usa nossos serviços, está confiando a nós suas informações. Entendemos que isso é uma grande responsabilidade e trabalhamos para proteger essas informações. Criamos uma gama de serviços que ajudam diariamente nossos clientes a descobrir e interagir com os nossos produtos e serviços.
                    </p>

                    <p>
                        2.3 Para fins desta Política de Privacidade aplicam-se as seguintes definições:

                        <ul>
                            <li>
                                <strong>Aplicativo:</strong> abrange o aplicativo “Nota Município” e sistemas de apoio ao seu funcionamento;
                            </li>
                            <li>
                                <strong>Usuário:</strong> qualquer pessoa que acessa determinada área restrita do APLICATIVO mediante cadastro;
                            </li>
                        </ul>
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="informacoes-que-coletamos"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    3 - INFORMAÇÕES QUE COLETAMOS
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        3.1 Ao utilizar o APLICATIVO, o usuário se submeterá automaticamente às regras e condições desta Política de Privacidade.
                    </p>

                    <p>
                        3.2 Dependendo dos serviços utilizados, a GOVFÁCIL pode coletar informações dos usuários e visitantes, bem como de seus dispositivos usados no acesso, conforme descrição abaixo:

                        <ul>
                            <li>
                                APLICATIVO:

                                <ul>
                                    <li>
                                        Informações fornecidas pelo usuário: informações solicitadas diretamente por meio de formulários de cadastro ou de coleta de dados necessários para identificação e registro;
                                    </li>
                                    <li>
                                        Ações executadas no sistema e outros registros de utilização.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <p>
                        3.3 Se pedimos por suas informações pessoais por uma razão secundária, como marketing, vamos lhe pedir diretamente por seu consentimento, ou lhe fornecer a oportunidade de dizer não.
                    </p>
                    <p style={{ color: '#FC5050' }}>
                        3.4 Caso o usuário desejar excluir sua conta do APLICATIVO, ele poderá acessar o menu “Meu Perfil” no APLICATIVO, depois escolher o ícone de “Configurações” e por fim escolher a opção “Deletar conta” fornecendo o seu consentimento para que essa ação possa ser concluída.
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="responsabilidades-da-govfacil"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    4 - RESPONSABILIDADES DA GOVFÁCIL
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        4.1 As informações coletadas e processadas no APLICATIVO e WEBSITE atendem a diversas finalidades ligadas diretamente às atividades institucionais da GOVFÁCIL. A GOVFÁCIL não faz uso comercial das informações armazenadas em seus sistemas. A GOVFÁCIL poderá, sem prévio aviso, bloquear e cancelar o acesso aos serviços, quando verificar que o usuário praticou algum ato ou mantenha conduta que:

                        <ul>
                            <li>
                                Viole as leis e regulamentos federais, estaduais e/ou municipais;
                            </li>
                            <li>
                                Contrarie as regras desta Política de Privacidade ou dos Termos de Uso;
                            </li>
                            <li>
                                Viole os princípios da moral e dos bons costumes.
                            </li>
                        </ul>
                    </p>

                    <p>
                        4.2 A GOVFÁCIL se reserva o direito de incluir, excluir ou alterar os conteúdos e funcionalidades do APLICATIVO, bem como suspendê-lo temporariamente ou cancelá-lo, a qualquer momento, independentemente de aviso-prévio ao usuário.
                    </p>

                    <p>
                        4.3 Da mesma forma, poderá modificar esta Política de Privacidade, cuja versão mais recente estará sempre disponível para consulta através do menu “Meu Perfil” no APLICATIVO.
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="responsabilidades-do-cliente"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    5 - RESPONSABILIDADES DO CLIENTE
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        5.1 Toda e qualquer ação executada ou conteúdo publicado pelo usuário durante o uso do aplicativo será de sua exclusiva e integral responsabilidade, devendo isentar e indenizar a GOVFÁCIL de quaisquer reclamações, prejuízos, perdas e danos causados à GOVFÁCIL, em decorrência de tais ações ou manifestações.
                    </p>

                    <p>
                        5.2 O usuário autoriza a GOVFÁCIL a utilizar as informações fornecidas, no ato da utilização do serviço, para fins estatísticos e envio de material publicitário, newsletters, informes e etc.
                    </p>

                    <p>
                        5.3 O usuário é exclusivamente responsável por todo e qualquer conteúdo por ele informado. Ao enviar o conteúdo, o usuário garante que ele não viola quaisquer direitos de terceiros ou leis vigentes e concorda em manter a GOVFÁCIL isenta de quaisquer reclamações judiciais ou extrajudiciais de terceiros.
                    </p>

                    <p>
                        5.4 As informações solicitadas ao usuário, no momento de utilização dos serviços, serão utilizadas pela GOVFÁCIL somente para os fins previstos nesta Política de Privacidade em conformidade com a LGPD – Lei Geral de Proteção de Dados de número 13.709/2018.
                    </p>
                </div>
            </section>

            <section>
                <h2
                    id="quando-se-aplica"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    6 - QUANDO A POLÍTICA DE PRIVACIDADE SE APLICA
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        6.1 Esta Política de Privacidade aplica-se ao APLICATIVO. Outros serviços podem ter Políticas de Privacidade e Termos de Uso complementares, incluindo outras formas de coleta e tratamento de dados, instalação de cookies com finalidades diversas e, ainda, solicitar novas informações dos usuários. Cabe ao usuário do respectivo serviço tomar ciência dessas condições.
                    </p>
                </div>
            </section>

            <section>
                <h2
                    id="protecao-das-informacoes"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    7 - PROTEÇÃO DAS INFORMAÇÕES
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        7.1 A GOVFÁCIL atende à Resolução SF Nº 20, de 14-03-2012​, que dispõe sobre o acesso a informações protegidas por sigilo fiscal constantes de sistemas informatizados.
                    </p>

                    <p>
                        7.2 As informações de caráter pessoal e aquelas sujeitas a outras hipóteses de obrigatoriedade de sigilo também dispõem de mecanismos de proteção que garantem a confidencialidade e integridade, sendo acessadas por servidores devidamente cadastrados e com permissão de utilização para desempenho de suas atribuições.
                    </p>
                </div>
            </section>

            <section>
                <h2
                    id="acesso-menores"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    8 - ACESSO PARA MENORES DE 18 ANOS
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        8.1 No caso de menores de 18 anos ou outras pessoas que necessitem de representação na forma da lei, a coleta de informações deverá ser realizada com a assistência dos pais ou dos representantes legais.
                    </p>
                </div>
            </section>

            <section>
                <h2
                    id="disposicoes-gerais"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    9 - DISPOSIÇÕES GERAIS
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        9.1 As disposições desta Política de Privacidade poderão ser atualizadas ou modificadas a qualquer momento, cabendo aos usuários e visitantes verificarem sempre que efetuarem o acesso ao APLICATIVO.
                    </p>
                </div>
            </section>
        </div>
    )
}