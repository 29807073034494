import { Radio, RadioProps } from "antd";
import { Ref, forwardRef, useContext } from "react";
import { ThemeContext } from "../../../../contexts/ThemeContext";

const RadioIsInactive = forwardRef(({ value, ...rest }: RadioProps, ref: Ref<HTMLDivElement> | undefined) => {
    const { theme } = useContext(ThemeContext);

    const dangerBorderColor = theme === "light" ? "#D9D9D9" : "#424242"

    return (
        <Radio.Group
            value={value}
            ref={ref}
            {...rest}
        >
            <Radio.Button
                value={false}
            >
                Ativa
            </Radio.Button>
            <Radio.Button
                value={true}
                style={{
                    background: value ? '#FC5050' : undefined,
                    color: value ? '#FFFFFF' : undefined,
                    borderColor: value ? dangerBorderColor : undefined,
                }}
            >
                Inativa
            </Radio.Button>
        </Radio.Group>
    )
})

export default RadioIsInactive;