import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UploadFile } from "antd";

import { IAwardType } from "../../../../interfaces";

import { getBase64Image } from "../../../../utils/getBase64Image";
import { awardImagesUrl } from "../../../../services/urls";

const ACCEPTED_IMAGE_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
]

interface IChangeFileProps {
    onChangeHookForm: (event: any) => void,
    file: UploadFile,
}

interface IInputs {
    name: string;
    default_image: UploadFile;
}

export default function useAwardTypeForm(
    onSubmit: (data: FormData) => Promise<void>,
    awardType: IAwardType | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [defaultImageUrl, setDefaultImageUrl] = useState<string | ArrayBuffer | null>();

    const {
        handleSubmit,
        formState: { errors },
        control,
        setError,
        clearErrors,
    } = useForm<IInputs>({
        defaultValues: {
            name: awardType?.name || '',
        },
        shouldFocusError: false,
    });

    const handleSubmitForm: SubmitHandler<IInputs> = async (data) => {
        setIsSubmiting(true);

        const formData = new FormData();

        if (data?.name) {
            formData.append('name', data.name);
        }

        if (data?.default_image?.originFileObj) {
            formData.append('image', data.default_image.originFileObj);
        }

        await onSubmit(formData);

        setIsSubmiting(false);
    };

    const dummyRequest = useCallback(({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const handleChangeDefaultImage = useCallback(({ onChangeHookForm, file }: IChangeFileProps) => {
        if (file?.type && !ACCEPTED_IMAGE_TYPES.includes(file.type.toLowerCase())) {
            setError('default_image', { message: 'Formato de imagem inválido! Os formatos aceitos são: PNG, JPG, JPEG e WEBP.' });
            return;
        }

        clearErrors('default_image');
        onChangeHookForm(file);
        getBase64Image(file.originFileObj, (imageUrl) => {
            setDefaultImageUrl(imageUrl);
        });
    }, [setError, clearErrors]);

    useEffect(() => {
        if (awardType?.default_image) {
            setDefaultImageUrl(`${awardImagesUrl}/${awardType.default_image}`);
        }
    }, [awardType]);

    return {
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors,
        dummyRequest,
        handleChangeDefaultImage,
        defaultImageUrl,
    }
}