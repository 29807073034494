import { IReward } from '../../../interfaces';

class RewardMapper {
    toPersistence(domainReward: IReward): any {
        return {
            id: domainReward.id,
            name: domainReward.name,
            description: domainReward.description,
            points: domainReward.points,
            stock: domainReward.stock,
            category_id: domainReward.category_id,
            county_id: domainReward.city_id,
            image: domainReward.image,
            vouchers_issued: domainReward.vouchers_issued,
            is_inactive: domainReward.is_inactive,
        }
    }

    toDomain(persistenceReward: any): IReward {
        return {
            id: persistenceReward.id,
            name: persistenceReward.name,
            description: persistenceReward.description,
            points: persistenceReward.points,
            stock: persistenceReward.stock,
            category_id: persistenceReward.category_id,
            city_id: persistenceReward.county_id,
            image: persistenceReward?.photos && persistenceReward?.photos[0].img,
            vouchers_issued: persistenceReward.vouchers_issued,
            is_inactive: persistenceReward.is_inactive,
            created_at: persistenceReward.created_at,
            updated_at: persistenceReward.updated_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RewardMapper();
