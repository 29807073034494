import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { ICategory } from "../../../interfaces";

import CategoriesService from "../../../services/http/services/CategoriesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useEditCategory() {
    const { id } = useParams();
    const [category, setCategory] = useState<ICategory>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadCategory() {
            try {
                setIsLoading(true);
                const categoryData = await CategoriesService.getCategoryById(Number(id), controller.signal);

                setCategory(categoryData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {

                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editRole_sdopasdasd');

                navigate('/categorias');
            }
        }

        loadCategory();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(categoryData: ICategory) {
        try {
            await CategoriesService.updateCategory(Number(id), categoryData);

            EmitSuccessAlert('Categoria atualizada com sucesso!');

            navigate('/categorias');
        } catch (err) {
            EmitError(err, true, 'editCategory_aspaiopsdhjaq');
        }
    }

    return {
        isLoading,
        handleSubmit,
        category,
    }
}