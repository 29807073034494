import { Controller } from "react-hook-form";
import { Form, Input } from "antd";

import { IAward } from "../../../../../interfaces";
import { ITableData } from "../../useGiveawayWinners";

import SimpleModal from "../../../../../components/SimpleModal";
import { convertMoedaBRDoJSComExtamente2CasasDecimais } from "../../../../../utils/Conversores";
import useConfirmValidadeAwardModal from "./useConfirmValidadeAwardModal";

interface IProps {
    open: boolean;
    award: ITableData | undefined;
    invalidating: boolean;
    onValidateFinished: (award: IAward) => void;
    onCancel: () => void;
}

export default function ConfirmValidateAwardModal({
    open,
    award,
    invalidating,
    onValidateFinished,
    onCancel,
}: IProps) {
    const {
        control,
        errors,
        handleSubmit,
        handleSubmitForm,
        isSubmiting,
    } = useConfirmValidadeAwardModal(invalidating, onValidateFinished, award);

    return (
        <SimpleModal
            open={open}
            onOk={() => handleSubmit(handleSubmitForm)}
            okButtonProps={{
                disabled: isSubmiting,
                loading: isSubmiting,
                htmlType: 'submit',
                form: 'hook-form-validate-award',
            }}
            cancelButtonProps={{
                disabled: isSubmiting
            }}
            okType={invalidating ? 'danger' : 'primary'}
            okText={invalidating ? 'Invalidar' : 'Validar'}
            onCancel={onCancel}
            title={`Deseja realmente ${invalidating ? 'invalidar' : 'validar'} o prêmio "${award?.description}" do usuário "${award?.app_user_name}" no valor de R$ ${convertMoedaBRDoJSComExtamente2CasasDecimais(award?.value)}?`}
        >
            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
                id="hook-form-validate-award"
            >
                <Form.Item
                    label="Observação"
                    required={invalidating}
                    validateStatus={errors.observation ? 'error' : 'success'}
                    help={errors.observation?.message}
                >
                    <Controller
                        name="observation"
                        control={control}
                        rules={{ required: { value: invalidating, message: 'Por favor, digite a observação!' }, }}
                        render={({ field }) => <Input {...field} placeholder='Observação' />}
                    />
                </Form.Item>
            </Form>
        </SimpleModal>
    )
}