import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputRef, Space, Tag } from "antd";
import { AxiosError } from "axios";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from '@ant-design/icons';

import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { IAppUser, IVoucher } from "../../../interfaces";

import { EmitError } from "../../../utils/EmitError";
import AppUsersService from "../../../services/http/services/AppUsersService";
import VouchersService from "../../../services/http/services/VouchersService";
import useAuth from "../../../hooks/useAuth";
import { removeAcento } from "../../../utils/RemoveAcentos";
import { formatDateStringToDateAndTime } from "../../../utils/Conversores";

interface ITableData extends IVoucher {
    key: number | undefined;
    reward_name: string;
}

export default function useViewAppUserVouchers() {
    const [appUser, setAppUser] = useState<IAppUser>();
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);
    
    const { id } = useParams();
    const { activeCity } = useAuth();

    const navigate = useNavigate();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents) : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Recompensa',
            dataIndex: 'reward_name',
            key: 'reward_name',
            sorter: (a: ITableData, b: ITableData) => a?.reward?.name.localeCompare(b?.reward?.name!)!,
            showSorterTooltip: false,
            ...getColumnSearchProps('reward_name', 'Recompensa'),
            width: '30%',
            ellipsis: true,
        },
        {
            title: 'Código',
            dataIndex: 'redemption_code',
            key: 'redemption_code',
            ...getColumnSearchProps('redemption_code', 'Nome'),
        },
        {
            title: 'Pontos Usados',
            dataIndex: 'points_used',
            key: 'points_used',
            render: (record: number) => record ? record.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0,
            sorter: (a: ITableData, b: ITableData) => (a?.points_used || 0) - (b?.points_used || 0),
            showSorterTooltip: false,
        },
        {
            title: 'Data de Resgate',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a: ITableData, b: ITableData) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('created_at', 'Data de resgate', true, formatDateStringToDateAndTime),
            defaultSortOrder: 'descend' as keyof SortOrder,
        },
        {
            title: 'Expira',
            dataIndex: 'validity',
            key: 'validity',
            sorter: (a: ITableData, b: ITableData) => new Date(a.validity).getTime() - new Date(b.validity).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('validity', 'Data de expiração', true, formatDateStringToDateAndTime),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: ITableData, b: ITableData) => a.status.localeCompare(b.status)!,
            filters: [
                {
                    text: 'Emitido',
                    value: 'Emitido',
                },
                {
                    text: 'Resgatado',
                    value: 'Resgatado',
                },
                {
                    text: 'Expirado',
                    value: 'Expirado',
                },
            ],
            onFilter: (value: any, record: IVoucher) => {
                return record.status === value;
            },
            render: (_: any, request: IVoucher) => (
                <Tag
                    key={request.id}
                    color={request.status === "Resgatado" ? "success" : request.status === "Expirado" ? "error" : "default"}
                >
                    {request.status}
                </Tag>
            ),
        }
    ]

    const setTableDataFromRequest = useCallback((data: Array<IVoucher>) => {
        const newData = data.map((voucher) => ({
            ...voucher,
            key: voucher.id,
            reward_name: voucher?.reward?.name!,
        }));
        setTableData(newData);
    }, []);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleClickViewVoucher = useCallback(() => {
        const voucher = tableData.find((voucher) => voucher.id === selectedIds[0]);
        
        if (!voucher) {
            return;
        }

        navigate(`/vouchers/${voucher.redemption_code}`);
    }, [navigate, selectedIds, tableData]);

    useEffect(() => {
        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoading(true);

                if (!id || !activeCity) {
                    return;
                }

                const [vouchersList, appUserData] = await Promise.all([
                    VouchersService.listVouchersByAppUserAndCity(Number(id), activeCity?.id!, controller.signal),
                    AppUsersService.getAppUserById(Number(id), controller.signal),
                ]);

                setTableDataFromRequest(vouchersList);
                setAppUser(appUserData);
                setIsLoading(false);

            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'viewAppUserTickets_asodkaskldasd');
                setIsLoading(false);
                navigate('/usuarios-aplicativo');
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [id, activeCity, navigate, setTableDataFromRequest]);

    const handleClickGoBack = useCallback(() => {
        navigate('/usuarios-aplicativo');
    }, [navigate]);

    return {
        appUser,
        tableData,
        isLoading,
        handleClickGoBack,
        handleClickViewVoucher,
        columns,
        rowSelection,
        onClickTableRow,
        selectedIds,
    }
}