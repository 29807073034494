import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IGiveaway, IPanelUser } from "../../../interfaces";

import { EmitError } from "../../../utils/EmitError";
import GiveawaysService from "../../../services/http/services/GiveawaysService";
import PanelUsersService from "../../../services/http/services/PanelUsersService";

export default function useHoldGiveaway() {
    const [giveaway, setGiveaway] = useState<IGiveaway | undefined>();
    const [isLoadingGiveaway, setIsLoadingGiveaway] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [panelUserGeneratedParcitipantsFile, setPanelUserGeneratedParticipantsFile] = useState<IPanelUser | undefined>();
    const [panelUserUploadedWinnersFile, setPanelUserUploadedWinnersFile] = useState<IPanelUser | undefined>();

    const { id } = useParams();

    const alreadyGeneratedTicketsFile = Boolean(giveaway?.file_with_participants && giveaway.hash_with_participants);

    const handleClickExportStep = useCallback(() => {
        setCurrentStep(0);
    }, []);

    const handleClickImportStep = useCallback(() => {
        setCurrentStep(1);
    }, []);

    const handleClickNextStep = useCallback(() => {
        setCurrentStep((prevState) => prevState < 1 ? prevState + 1 : prevState);
    }, []);

    const handleClickPreviousStep = useCallback(() => {
        setCurrentStep((prevState) => prevState > 0 ? prevState - 1 : prevState);
    }, []);

    const handleTicketsFileGenerated = useCallback((hash: string, file: string) => {
        setGiveaway((prevState) => {
            if (!prevState) {
                return;
            }

            return {
                ...prevState,
                hash_with_participants: hash,
                file_with_participants: file,
            }
        })
    }, []);

    const handleWinnersFileImported = useCallback((hash: string, file: string) => {
        setGiveaway((prevState) => {
            if (!prevState) {
                return;
            }

            return {
                ...prevState,
                hash_with_winners: hash,
                file_with_winners: file,
            }
        })
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }

        const controller = new AbortController();

        async function loadGiveaway() {
            try {
                setIsLoadingGiveaway(true);

                const giveawayData = await GiveawaysService.getGiveawayById(Number(id), controller.signal);

                setGiveaway(giveawayData);
                setIsLoadingGiveaway(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                setIsLoadingGiveaway(false);

                EmitError(err);
            }
        }

        loadGiveaway();

        return () => {
            controller.abort();
        }
    }, [id]);

    useEffect(() => {
        if (!giveaway || (!giveaway?.panel_user_generated_participants_file_id && !giveaway.panel_user_uploaded_winners_file_id)) {
            return;
        }

        const controller = new AbortController();

        async function loadPanelUsersData() {
            try {
                const userGeneratedParticipants = await PanelUsersService.getPanelUserById(giveaway?.panel_user_generated_participants_file_id!, controller.signal);

                setPanelUserGeneratedParticipantsFile(userGeneratedParticipants);

                if (giveaway?.panel_user_uploaded_winners_file_id) {
                    const userUploadedWinners = await PanelUsersService.getPanelUserById(giveaway.panel_user_uploaded_winners_file_id, controller.signal);

                    setPanelUserUploadedWinnersFile(userUploadedWinners);
                }
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                EmitError(err);
            }
        }

        loadPanelUsersData();

        return () => {
            controller.abort();
        }
    }, [giveaway]);

    return {
        giveaway,
        isLoadingGiveaway,
        currentStep,
        handleClickExportStep,
        handleClickImportStep,
        handleClickNextStep,
        handleClickPreviousStep,
        handleTicketsFileGenerated,
        handleWinnersFileImported,
        alreadyGeneratedTicketsFile,
        panelUserGeneratedParcitipantsFile,
        panelUserUploadedWinnersFile,
    }
}