import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IMessage } from "../../../interfaces";

import MessagesService from "../../../services/http/services/MessagesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useEditMessage() {
    const { id } = useParams();
    const [message, setMessage] = useState<IMessage>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const type = searchParams.get('type');

    const isGeneral = useMemo(() => {
        return type?.toLowerCase() === "general";
    }, [type]);

    useEffect(() => {
        const controller = new AbortController();

        async function loadMessage() {
            try {
                setIsLoading(true);
                const messageData = await MessagesService.getMessageById(Number(id), controller.signal);

                setMessage(messageData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editMessage_opasdasjpdasd');

                navigate(`/mensagens${isGeneral ? '?type=general' : ''}`);
            }
        }

        loadMessage();

        return () => {
            controller.abort();
        }
    }, [id, navigate, isGeneral]);

    async function handleSubmit(messageData: FormData) {
        try {
            await MessagesService.updateMessage(Number(id), messageData);

            EmitSuccessAlert('Mensagem atualizada com sucesso!');

            navigate(`/mensagens${isGeneral ? '?type=general' : ''}`);
        } catch (err) {
            EmitError(err, true, 'editMessage_asiopdasodasd');
        }
    }

    return {
        isLoading,
        message,
        handleSubmit
    }
}