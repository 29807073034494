import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, InputRef, Space } from "antd";
import Highlighter from "react-highlight-words";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { SearchOutlined } from '@ant-design/icons';

import { IMonthData } from "../../interfaces";
import { IAppUser } from "../../../../interfaces";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";

import { removeAcento } from "../../../../utils/RemoveAcentos";
import InvoicesService from "../../../../services/http/services/InvoicesService";
import AppUsersService from "../../../../services/http/services/AppUsersService";
import useAuth from "../../../../hooks/useAuth";
import { EmitError } from "../../../../utils/EmitError";
import { formatDateStringToMonthAndYear } from "../../../../utils/Conversores";
import { formatCurrency, formatPoints } from "../../../../utils/Masks";
import useModules from "../../../../hooks/useModules";

interface ITableData extends IMonthData {
    key: string;
    period: string;
}

export default function useInvoicesGroupedByMonth() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [appUser, setAppUser] = useState<IAppUser>();
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    const [totalProcessingInvoices, setTotalProcessingInvoices] = useState<number>(0);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const { activeCity } = useAuth();
    const { hasRewardModule } = useModules();
    const navigate = useNavigate();
    const { id } = useParams();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents).toLowerCase() : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ?
                            formatter ? formatter(text.toString())
                                : text.toString()
                            : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Mês',
            dataIndex: 'period',
            key: 'period',
            sorter: (a: ITableData, b: ITableData) => new Date(a.period).getTime() - new Date(b.period).getTime(),
            showSorterTooltip: false,
            defaultSortOrder: 'descend' as keyof SortOrder,
            ...getColumnSearchProps('period', 'Mês', true, formatDateStringToMonthAndYear),
        },
        {
            title: 'Total de Notas',
            dataIndex: 'total_invoices',
            key: 'total_invoices',
            render: (record: number) => record.toLocaleString('pt-BR'),
            sorter: (a: ITableData, b: ITableData) => a.total_invoices - b.total_invoices,
            showSorterTooltip: false,
        },
        {
            title: 'Valor Total',
            dataIndex: 'total_value',
            key: 'total_value',
            render: (record: number) => record ? `R$ ${formatCurrency(record)}` : 'R$ 0,00',
            sorter: (a: ITableData, b: ITableData) => a.total_value - b.total_value,
            showSorterTooltip: false,
        },
        {
            title: 'Total de Pontos',
            dataIndex: 'reward_points',
            key: 'reward_points',
            render: (record: number) => formatPoints(record),
            sorter: (a: ITableData, b: ITableData) => a.reward_points - b.reward_points,
            showSorterTooltip: false,
            hidden: !hasRewardModule,
        },
    ].filter((column) => !column?.hidden);

    const setTableDataFromRequest = useCallback((data: Array<IMonthData>) => {
        const newData = data.filter((monthData) => monthData.month !== null).map((monthData) => ({
            ...monthData,
            key: `${monthData.month}-${monthData.year}`,
            period: new Date(monthData.year, monthData.month).toISOString(),
        }));

        setTableData(newData);
    }, []);

    const onClickTableRow = useCallback((record: ITableData, index: any) => {
        if (selectedKeys.includes(record.key)) {
            setSelectedKeys((prevState) => prevState.filter((key) => key !== record.key));
        } else {
            setSelectedKeys((prevState) => [...prevState, record.key]);
        }
    }, [selectedKeys]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedKeys.length) {
            if (newSelectedRowKeys.length - 1 === selectedKeys.length) {
                const newKey = newSelectedRowKeys.pop();
                return setSelectedKeys((prevState) => [...prevState, newKey]);
            }

            if (newSelectedRowKeys.length - 1 > selectedKeys.length) {
                return setSelectedKeys(newSelectedRowKeys);
            }
        }

        setSelectedKeys(newSelectedRowKeys);
    }, [selectedKeys]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedKeys,
        onChange: onSelectChange,
    }), [selectedKeys, onSelectChange]);

    const handleClickViewInvoices = useCallback(() => {
        navigate(`/usuarios-aplicativo/${id}/notas/${selectedKeys[0]}`);
    }, [id, navigate, selectedKeys]);

    const handleClickGoBack = useCallback(() => {
        navigate('/usuarios-aplicativo');
    }, [navigate]);

    useEffect(() => {
        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoading(true);

                const [invoicesList, appUserData] = await Promise.all([
                    InvoicesService.listMonthsData(Number(id), activeCity?.id!, controller.signal),
                    AppUsersService.getAppUserById(Number(id), controller.signal),
                ]);

                setTableDataFromRequest(invoicesList);
                setAppUser(appUserData);

                const invoicesWithNoData = invoicesList.find((i: IMonthData) => i.month === null);
                setTotalProcessingInvoices(invoicesWithNoData?.total_invoices || 0);

                setIsLoading(false);

            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'viewAppUserInvoices_paosdasdkmsdasd');
                setIsLoading(false);
                navigate('/usuarios-aplicativo');
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [id, navigate, activeCity, setTableDataFromRequest]);

    return {
        columns,
        isLoading,
        tableData,
        appUser,
        onClickTableRow,
        rowSelection,
        totalProcessingInvoices,
        selectedKeys,
        handleClickViewInvoices,
        handleClickGoBack,
    }
}