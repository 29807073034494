import { IPermission } from '../../../interfaces';

class PermissionMapper {
    toPersistence(domainPermission: IPermission): any {
        return {
            id: domainPermission.id,
            name: domainPermission.name,
            description: domainPermission.description,
        }
    }

    toDomain(persistencePermission: any): IPermission {
        return {
            id: persistencePermission.id,
            name: persistencePermission.name,
            description: persistencePermission.description,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PermissionMapper();
