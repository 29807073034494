import { Spin } from "antd";

interface IProps {
    loading?: boolean,
    children?: React.ReactNode,
}

export default function Loading({ loading = false, children }: IProps) {
    return (
        <>
            {children ?
                <Spin
                    style={{
                        zIndex: 1051,
                    }}
                    size="large"
                    spinning={loading}
                >
                    {children}
                </Spin>
                :
                loading &&
                <Spin
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        zIndex: 1051,
                        background: 'rgba(0,0,0,0.15)'
                    }}
                    size="large"
                    spinning={true}
                />
            }
        </>
    )
}