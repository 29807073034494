import { MutableRefObject } from "react";
import { Controller } from "react-hook-form"
import { Button, Col, Form, Input, Row } from "antd"

import { IGiveaway } from "../../../../../../interfaces";

import DatePicker from "../../../../../../components/DatePicker"
import { colInputs } from "./GridStyle"
import useGiveawayDataStep from "./useGiveawayDataStep";
import { DateFns } from "../../../../../../utils/dateFns";

interface IProps {
    giveaway?: IGiveaway;
    onSubmit: (user: IGiveaway) => Promise<void>;
    onSubmitGiveawayDataStepFinished: () => void;
    refIsEditing: MutableRefObject<boolean>;
    canEdit: boolean;
}

export default function GiveawayDataStep({
    onSubmit,
    giveaway,
    onSubmitGiveawayDataStepFinished,
    refIsEditing,
    canEdit,
}: IProps) {
    const {
        isSubmiting,
        handleSubmit,
        handleSubmitForm,
        control,
        errors,
        handleChangeDateRange,
        handleClickUpdateAndLeave,
    } = useGiveawayDataStep({ onSubmit, giveaway, onSubmitGiveawayDataStepFinished });

    return (
        <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row>
                    <Col
                        span={24}
                    >
                        <Form.Item
                            label="Descrição"
                            required
                            validateStatus={errors.description ? 'error' : 'success'}
                            help={errors.description?.message}
                        >
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, digite a descrição do sorteio!' }, }}
                                render={({ field }) => 
                                    <Input 
                                        {...field}
                                        placeholder='Descrição'
                                        disabled={refIsEditing.current && !canEdit}
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Período"
                            required
                            validateStatus={errors.period_beginning || errors.period_ending ? 'error' : 'success'}
                            help={[
                                <p
                                    key="giveaway_period_beginning_error_message"
                                    style={{
                                        marginBottom: 0,
                                    }}
                                >
                                    {errors.period_beginning?.message}</p>,
                                <p
                                    key="giveaway_period_ending_error_message"
                                >
                                    {errors.period_ending?.message}
                                </p>
                            ]}
                        >
                            <Controller
                                name={"period_beginning"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Por favor, informe o período do sorteio!"
                                    },
                                }}
                                render={({ field: beginningField }) => (
                                    <Controller
                                        name="period_ending"
                                        control={control}
                                        render={({ field: endingField }) => (
                                                <DatePicker.RangePicker
                                                    format={(date) => date.toLocaleDateString('PT-BR')}
                                                    value={[beginningField.value ? new Date(beginningField.value) : null, endingField.value ? new Date(endingField.value) : null]}
                                                    onChange={handleChangeDateRange}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    disabled={refIsEditing.current}
                                                    allowClear
                                                />
                                            )
                                        }
                                    />
                                )}
                            />

                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Data do sorteio"
                            required
                            validateStatus={errors.date ? 'error' : 'success'}
                            help={errors.date?.message}
                        >
                            <Controller
                                name="date"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Por favor, informe a data do sorteio!"
                                    }
                                }}
                                render={({ field }) => (
                                    <DatePicker
                                        format={(date) => date.toLocaleDateString('PT-BR', { hour: '2-digit', minute: '2-digit' })}
                                        value={field.value ? new Date(field.value) : undefined}
                                        onChange={field.onChange}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={refIsEditing.current && !canEdit}
                                        showTime={{
                                            minuteStep: 30,
                                            format: 'HH:mm',
                                            showSecond: false,
                                            defaultValue: DateFns.setTimeToSixPM(new Date()),
                                        }}
                                    />
                                )}
                            />

                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Data limite para importação"
                            required
                            validateStatus={errors.import_deadline ? 'error' : 'success'}
                            help={errors.import_deadline?.message}
                        >
                            <Controller
                                name="import_deadline"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Por favor, informe a data limite para importação das notas!"
                                    }
                                }}
                                render={({ field }) => (
                                    <DatePicker
                                        format={(date) => date.toLocaleDateString('PT-BR', { hour: '2-digit', minute: '2-digit' })}
                                        value={field.value ? new Date(field.value) : undefined}
                                        onChange={field.onChange}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={refIsEditing.current && !canEdit}
                                        showTime={{
                                            minuteStep: 30,
                                            format: 'HH:mm',
                                            showSecond: false,
                                            defaultValue: DateFns.setTimeToSixPM(new Date()),
                                        }}
                                    />
                                )}
                            />

                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    
                    {
                        giveaway && (
                            <Button
                                type='primary'
                                htmlType='button'
                                disabled={isSubmiting || !canEdit}
                                onClick={handleSubmit(handleClickUpdateAndLeave)}
                                style={{
                                    marginRight: 8
                                }}
                            >
                                Atualizar e sair
                            </Button>
                        )
                    }
                    <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmiting || (giveaway && !canEdit)}
                    >
                        Próximo
                    </Button>
                </Row>
            </Form>
    )
}