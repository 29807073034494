import privateHttpClient from '../privateHttpClient';
import CityTaxMapper from '../mappers/CityTaxMapper';
import { urlHttpGov } from '../../urls';
import { ICityTax } from '../../../interfaces';

class CityTaxesService {
    private baseURL: string = `${urlHttpGov}/panel/taxes`;

    async listCityTaxes(cityId: number, signal: AbortSignal): Promise<Array<ICityTax>> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/county/${cityId}`, { signal });

        return data.map((tax: any) => CityTaxMapper.toDomain(tax));
    }

    approveCityTax(tax: ICityTax, observation: string) {

        return privateHttpClient.patch(`${this.baseURL}/analyze/${tax.id}`, {
            is_approved: true,
            observation: observation || undefined,
        })
    }

    refuseCityTax(tax: ICityTax, observation: string) {
        
        return privateHttpClient.patch(`${this.baseURL}/analyze/${tax.id}`, {
            is_approved: false,
            observation: observation,
        })
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CityTaxesService();
