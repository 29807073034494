import Text from 'antd/es/typography/Text';
import { ICityTaxFile } from '../ViewCityTax/useViewCityTax';
import { Button } from 'antd';
import { useCallback, useContext, useMemo } from 'react';
import privateHttpClient from '../../../services/http/privateHttpClient';
import { AxiosError } from 'axios';
import { EmitError } from '../../../utils/EmitError';
import { ThemeContext } from '../../../contexts/ThemeContext';

interface IProps {
    document: ICityTaxFile,
}

export default function PDFItem({ document }: IProps) {
    const { theme } = useContext(ThemeContext);

    const filename = useMemo(() => {
        return document.url.split('/').slice(-1)[0];
    }, [document]);

    const viewPDF = useCallback(async () => {
        const controller = new AbortController();

        try {
            const response = await privateHttpClient.get(document.url, {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
                signal: controller.signal,
            });

            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }
            EmitError(err, true, 'viewRecoverAccess_ajksdkasdjasjdasd')
        }

        return () => {
            controller.abort();
        }
    }, [document]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: 200,
                minHeight: 250,
                margin: 8,
                padding: 8,
                backgroundColor: theme === 'light' ? '#FFFFFF' : '#212121',
                border: `1px solid ${theme === 'light' ? '#F5F5F5' : '#262626'}`,
                borderRadius: 4,
            }}
        >
            <div
                style={{
                    padding: '8px 12px',
                    backgroundColor: '#FC5050',
                    borderRadius: 4,
                }}
            >
                <Text
                    style={{
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                    }}
                >
                    PDF
                </Text>
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Text
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {filename}
                </Text>
                <Button
                    type='link'
                    onClick={viewPDF}
                >
                    Visualizar
                </Button>
            </div>
        </div>
    )
}