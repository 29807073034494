import { useContext } from "react";
import useAuth from "../../../../hooks/useAuth"
import { urlHttpGov } from "../../../../services/urls";
import { ThemeContext } from "../../../../contexts/ThemeContext";

interface IProps {
    collapsed: boolean,
}

export default function CityImage({ collapsed }: IProps) {
    const { activeCity } = useAuth();
    const { theme } = useContext(ThemeContext);

    if (!activeCity) {
        return null;
    }

    return (
        collapsed ?
            <img
                style={{
                    width: 50,
                    margin: 10,
                    marginLeft: 12

                }}
                src={`${urlHttpGov}/logo_county/${theme === "light" ? activeCity?.logo_light : activeCity?.logo_dark}`}
                alt='Ícone GovFácil'
            />
            :
            <img
                style={{
                    margin: 15,
                    maxWidth: 220,
                    maxHeight: 125,
                }}
                src={`${urlHttpGov}/logo_county/${theme === "light" ? activeCity?.logo_light : activeCity?.logo_dark}`}
                alt='Logo GovFácil'
            />
    )
}