import { createContext, useCallback, useContext, useState } from "react";
import { ICityTax } from "../interfaces";
import { AxiosError } from "axios";
import { EmitError } from "../utils/EmitError";
import useAuth from "../hooks/useAuth";
import { Outlet } from "react-router-dom";
import CityTaxesService from "../services/http/services/CityTaxesService";

interface ICityTaxesContext {
    cityTaxes: Array<ICityTax>;
    loadCityTaxes: (controller?: AbortController) => Promise<any>;
}
export const CityTaxesContext = createContext({} as ICityTaxesContext);

export function CityTaxesProvider() {
    const [cityTaxes, setCityTaxes] = useState<Array<ICityTax>>([]);

    const { activeCity } = useAuth();

    const loadCityTaxes = useCallback(async (abortController?: AbortController) => {
        const controller = abortController || new AbortController();

        try {
            const cityTaxesList = await CityTaxesService.listCityTaxes(activeCity?.id!, controller.signal);

            setCityTaxes(cityTaxesList);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }

            EmitError(err, true, 'cityTaxes_asdhashuidd');
        }

        return () => {
            controller.abort();
        }
    }, [activeCity]);

    return (
        <CityTaxesContext.Provider value={{
            cityTaxes, loadCityTaxes
        }}>
            <Outlet />
        </CityTaxesContext.Provider>
    )
}

export function useCityTaxesContext() {
    return useContext(CityTaxesContext);
}
