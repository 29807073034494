import { Col, Form, Input, Row, Select } from "antd";
import { Controller, UseFormClearErrors, UseFormSetValue } from "react-hook-form";

import { ICityFormStepProps, ICityInputs } from "../../../../interfaces";
import { ICityData } from "../../../../../../interfaces";

import useRegisterStep from "./useRegisterStep";

interface IProps extends ICityFormStepProps {
    colInputs: any;
    setValue: UseFormSetValue<ICityInputs>;
    clearErrors: UseFormClearErrors<ICityInputs>;
    selectedCity: {
        uf: string | undefined;
        city: string | undefined;
    }
    cities: Array<ICityData>;
    isLoadingCities: boolean;
    isEditing: boolean;
}

export default function RegisterStep({
    colInputs,
    errors,
    control,
    setValue,
    cities,
    isLoadingCities,
    selectedCity,
    clearErrors,
    isEditing,
}: IProps) {
    const {
        filterOption,
        STATES,
    } = useRegisterStep();

    return (
        <>
            <Row
                gutter={16}
            >
                <Col
                    {...colInputs}
                >
                    <Form.Item
                        label="Estado"
                        required
                        validateStatus={errors.uf ? 'error' : 'success'}
                        help={errors.uf?.message}
                    >
                        <Controller
                            name="uf"
                            control={control}
                            render={({ field: { onChange, ...field } }) => (
                                <Select
                                    {...field}
                                    options={STATES.map((state) => ({
                                        value: state.uf,
                                        label: `${state.name} - ${state.uf}`
                                    }))}
                                    onChange={(e) => {
                                        onChange(e);
                                        setValue('city', '');
                                        setValue('cnpj', '');
                                        setValue('name', '');
                                    }}
                                    placeholder='Selecione o Estado'
                                    showSearch
                                    filterOption={filterOption}
                                    disabled={isEditing}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col
                    {...colInputs}
                >
                    <Form.Item
                        label="Município"
                        required
                        validateStatus={errors.city ? 'error' : 'success'}
                        help={errors.city?.message}
                    >
                        <Controller
                            name="city"
                            control={control}
                            render={({ field: { onChange, ...field } }) => (
                                <Select
                                    {...field}
                                    onChange={(e) => {
                                        const cityId = Number(e);

                                        const selectedCity = cities.find((city) => city.id_ibge === cityId);

                                        if (!selectedCity) {
                                            return;
                                        }

                                        onChange(selectedCity.name);
                                        setValue('cnpj', selectedCity.cnpj);
                                        setValue('name', `Prefeitura Municipal de ${selectedCity.name}`);
                                        clearErrors(['cnpj', 'name']);
                                    }}
                                    options={cities.map((city) => ({ value: city.id_ibge, label: city.name }))}
                                    placeholder='Selecione o Município'
                                    showSearch
                                    filterOption={filterOption}
                                    loading={isLoadingCities}
                                    disabled={!selectedCity.uf || isLoadingCities || isEditing}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row
                gutter={16}
            >
                <Col
                    {...colInputs}
                >
                    <Form.Item
                        label="CNPJ"
                        required
                        validateStatus={errors.cnpj ? 'error' : 'success'}
                        help={errors.cnpj?.message}
                    >
                        <Controller
                            name="cnpj"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder='CNPJ'
                                    disabled
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col
                    {...colInputs}
                >
                    <Form.Item
                        label="Nome do Orgão"
                        required
                        validateStatus={errors.name ? 'error' : 'success'}
                        help={errors.name?.message}
                    >
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder='Nome do Orgão'
                                    disabled={!selectedCity.city}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}