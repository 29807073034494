
export const urlHttpGov = process.env.REACT_APP_API_URL || 'http://localhost:3333'; //local

export const awardImagesUrl = `${urlHttpGov}/image_award`;
export const messageImageUrl = `${urlHttpGov}/panel/message/image`;
export const urlMunicipios = urlHttpGov+'/municipios';
export const urlUsuarioSession = urlHttpGov+'/auth';
export const urlUsuariosSessionRefreshToken = urlHttpGov+'/session/refresh';
export const urlTermsOfUse = urlHttpGov+'/app/counties/terms_of_use';
export const urlTermsOfUseByFileName = urlHttpGov+'/terms_of_use';
