import { Button, Divider } from "antd";
import Title from 'antd/es/typography/Title';

import {
    LeftOutlined,
} from '@ant-design/icons';

import useViewAppUserVouchers from "./useViewAppUserVouchers";
import Table from "../../../components/Table";

export default function ViewAppUserVouchers() {
    const {
        appUser,
        handleClickGoBack,
        handleClickViewVoucher,
        isLoading,
        tableData,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
    } = useViewAppUserVouchers();

    return (

        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                    alignItems: 'center',
                }}
            >
                <Button
                    type="link"
                    onClick={handleClickGoBack}
                >
                    <LeftOutlined />
                </Button>
                <Title
                    level={3}
                    style={{
                        margin: 0,
                    }}
                >
                    {appUser?.name && `${appUser?.name} - Vouchers`}
                </Title>


                <Divider type='vertical' style={{ width: 3, height: 16 }} />

                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={handleClickViewVoucher}
                    disabled={selectedIds.length !== 1}
                >
                    Visualizar
                </Button>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
            />
        </>

    )
}