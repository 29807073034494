import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputRef, Space } from "antd";
import Highlighter from "react-highlight-words";
import { AxiosError } from "axios";

import { SearchOutlined } from '@ant-design/icons';

import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { IAppUser, IGiveawayWithDetails } from "../../../../interfaces";

import useAuth from "../../../../hooks/useAuth";
import { removeAcento } from "../../../../utils/RemoveAcentos";
import { formatDateStringToDate } from "../../../../utils/Conversores";
import GiveawaysService from "../../../../services/http/services/GiveawaysService";
import AppUsersService from "../../../../services/http/services/AppUsersService";
import { EmitError } from "../../../../utils/EmitError";
import { formatCurrency } from "../../../../utils/Masks";
import useModules from "../../../../hooks/useModules";

interface ITableData extends IGiveawayWithDetails {
    key: number | undefined;
    total: number,
}

export default function useTicketsGroupedByGiveaway() {
    const [appUser, setAppUser] = useState<IAppUser>();
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { activeCity } = useAuth();
    const { hasAtLeastOneInvoiceModule, hasTaxesModule } = useModules();
    const { id } = useParams();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents) : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ?
                            formatter ? formatter(text.toString())
                                : text.toString()
                            : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
            sorter: (a: ITableData, b: ITableData) => a.description.localeCompare(b.description),
            showSorterTooltip: false,
            width: '25%',
            ellipsis: true,
            ...getColumnSearchProps('description', 'Descrição'),
        },
        {
            title: 'Início',
            dataIndex: 'period_beginning',
            key: 'period_beginning',
            sorter: (a: ITableData, b: ITableData) => new Date(a.period_beginning).getTime() - new Date(b.period_beginning).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('period_beginning', 'Início', true, formatDateStringToDate),
        },
        {
            title: 'Fim',
            dataIndex: 'period_ending',
            key: 'period_ending',
            sorter: (a: ITableData, b: ITableData) => new Date(a.period_ending).getTime() - new Date(b.period_ending).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('period_ending', 'Fim', true, formatDateStringToDate),
        },
        {
            title: 'Data do sorteio',
            dataIndex: 'date',
            key: 'date',
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: ITableData, b: ITableData) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('date', 'Início', true, formatDateStringToDate),
        },
        {
            title: 'Notas',
            dataIndex: 'total_invoices_balance',
            key: 'total_invoices_balance',
            render: (record: number) => record ? `R$ ${formatCurrency(record)}` : 'R$ 0,00',
            sorter: (a: ITableData, b: ITableData) => a.total_invoices_balance - b.total_invoices_balance,
            showSorterTooltip: false,
            hidden: !hasAtLeastOneInvoiceModule,
        },
        {
            title: 'Impostos',
            dataIndex: 'total_taxes_balance',
            key: 'total_taxes_balance',
            render: (record: number) => record ? `R$ ${formatCurrency(record)}` : 'R$ 0,00',
            sorter: (a: ITableData, b: ITableData) => a.total_taxes_balance - b.total_taxes_balance,
            showSorterTooltip: false,
            hidden: !hasTaxesModule,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (record: number) => record ? `R$ ${formatCurrency(record)}` : 'R$ 0,00',
            sorter: (a: ITableData, b: ITableData) => a.total - b.total,
            showSorterTooltip: false,
            hidden: hasAtLeastOneInvoiceModule !== hasTaxesModule || (!hasAtLeastOneInvoiceModule && !hasTaxesModule)
        },
        {
            title: 'Bilhetes',
            dataIndex: 'total_tickets',
            key: 'total_tickets',
            render: (record: number) => record.toLocaleString('pt-BR'),
            sorter: (a: ITableData, b: ITableData) => a.total_tickets - b.total_tickets,
            showSorterTooltip: false,
        },
    ].filter((column) => !column?.hidden);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newKey = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newKey]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const setTableDataFromRequest = useCallback((data: Array<IGiveawayWithDetails>) => {
        const newData = data.map((giveaway) => ({
            ...giveaway,
            key: giveaway.id,
            total: giveaway.total_invoices_balance + giveaway.total_taxes_balance,
        }));
        setTableData(newData);
    }, []);

    const handleClickGoBack = useCallback(() => {
        navigate('/usuarios-aplicativo');
    }, [navigate]);

    const handleClickViewTickets = useCallback(() => {
        navigate(`/usuarios-aplicativo/${id}/bilhetes/sorteio/${selectedIds[0]}`);
    }, [navigate, id, selectedIds]);

    useEffect(() => {

        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoading(true);

                const [giveawaysList, appUserData] = await Promise.all([
                    GiveawaysService.listGiveawaysWithDetails(activeCity?.id!, Number(id), controller.signal),
                    AppUsersService.getAppUserById(Number(id), controller.signal),
                ]);

                setTableDataFromRequest(giveawaysList);
                setAppUser(appUserData);
                setIsLoading(false);

            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'viewAppUserTickets_asodkaskldasd');
                setIsLoading(false);
                navigate('/usuarios-aplicativo');
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [id, activeCity, navigate, setTableDataFromRequest]);

    return {
        columns,
        tableData,
        appUser,
        handleClickGoBack,
        isLoading,
        rowSelection,
        onClickTableRow,
        selectedIds,
        handleClickViewTickets,
    }
}