export const colInputs = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 12, offset: 0 },
};

export const colInputsEffectiveDate = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 10, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const colInputsConfigDescriptionExpanded = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
};

export const colInputsConfigDescription = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 14, offset: 0 },
    xxl: { span: 16, offset: 0 },
};

export const colInputsTermsOfUseExpanded = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
};

export const colInputsTermsOfUseReduced = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 12, offset: 0 },
};

export const colInputsConfig = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
};

export const colCardsConfig = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 12, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 12, offset: 0 },
};

export const colCarouselControls = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 2, offset: 0 },
    lg: { span: 2, offset: 0 },
    xl: { span: 2, offset: 0 },
    xxl: { span: 2, offset: 0 },
}

export const colCarousel = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 20, offset: 0 },
    lg: { span: 20, offset: 0 },
    xl: { span: 20, offset: 0 },
    xxl: { span: 20, offset: 0 },
}

export const colCarouselFull = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
}
