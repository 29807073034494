import MessageForm from '../components/MessageForm';
import useNewMessage from './useNewMessage';

export default function NewMessage() {
    const { handleSubmit } = useNewMessage();

    return (
        <MessageForm action='Adicionar' onSubmit={handleSubmit} />
    )
}
