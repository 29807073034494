import { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { IAward } from "../../../../../interfaces";
import { ITableData } from "../../useGiveawayWinners";

import AwardsService from "../../../../../services/http/services/AwardsService";
import { EmitError } from "../../../../../utils/EmitError";
import { EmitSuccessAlert } from "../../../../../utils/EmitSuccessAlert";

interface IInputs {
    observation: string;
}

export default function useConfirmValidadeAwardModal(
    invalidating: boolean,
    onValidateFinished: (award: IAward) => void,
    award: ITableData | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IInputs>();


    const handleSubmitForm: SubmitHandler<IInputs> = useCallback(async (data) => {
        try {
            if (!award) {
                return;
            }

            setIsSubmiting(true);

            const awardData = await AwardsService.validateAward(award?.id!, !invalidating, data.observation);

            onValidateFinished(awardData);
            EmitSuccessAlert(`Prêmio ${invalidating ? 'invalidado' : 'validado'} com sucesso!`);
        } catch (err) {
            EmitError(err);
        } finally {
            setIsSubmiting(false);
        }
    }, [invalidating, award, onValidateFinished]);

    return {
        isSubmiting,
        control,
        handleSubmit,
        errors,
        handleSubmitForm,
    }
}