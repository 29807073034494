import { useCallback, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormInstance } from "antd";

import PanelUsersService from "../../../../../services/http/services/PanelUsersService";
import { EmitSuccessAlert } from "../../../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../../../utils/EmitError";

interface IInputs {
    password: string;
    confirmPassword: string;
}

export default function useUpdatePasswordFormModal(
    onFormSubmited: () => void,
    userId: number,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const refForm = useRef<FormInstance>(null);

    const {
        handleSubmit,
        formState: { errors },
        control,
        setError
    } = useForm<IInputs>();

    const handleSubmitForm: SubmitHandler<IInputs> = async (data) => {
        if (data.password !== data.confirmPassword) {
            setError('confirmPassword', { message: 'Senhas não coincidem' });
            return;
        }

        try {
            setIsSubmiting(true);

            await PanelUsersService.updatePanelUserPassword(userId, data.password);

            onFormSubmited();

            EmitSuccessAlert('Senha alterada com sucesso!');

            setIsSubmiting(false);
        } catch (err) {
            EmitError(err, true, 'UpdatePanelUserPasswordForm_ajshdasdasod');
            setIsSubmiting(false);
        }
    };

    const handleConfirmUpdatePassword = useCallback(() => {
        refForm.current?.submit();
    }, []);

    return {
        isSubmiting,
        handleSubmit,
        handleSubmitForm,
        control,
        errors,
        handleConfirmUpdatePassword,
        refForm,
    }
}