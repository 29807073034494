import { Row, StepProps, Steps } from "antd";
import { FieldErrors } from "react-hook-form";

import { ICityInputs } from "../../interfaces";
import { Ref } from "react";

interface IItem extends StepProps {
    fields: Array<keyof ICityInputs>,
}

interface IProps {
    currentStep: number,
    items: Array<IItem>,
    errors: FieldErrors<ICityInputs>,
    refSteps: Ref<HTMLDivElement>,
}

export default function FormStepsController({
    currentStep,
    items,
    errors,
    refSteps,
}: IProps) {
    return (
        <Row
            ref={refSteps}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 32,
            }}
        >
            <div
                style={{
                    width: '65%'
                }}
            >
                <Steps
                    current={currentStep}
                    onChange={() => { }} // função vazia apenas para habilitar click
                    items={items.map((item) => ({
                        ...item,
                        status: item.fields.some((field) => errors[field]) ? "error" : undefined
                    }))}
                />
            </div>
        </Row>
    )
}