import PanelUserForm from '../components/PanelUserForm';
import Loading from '../../../components/Loading';
import useEditPanelUser from './useEditPanelUser';

export default function EditPanelUser() {
    const {
        isLoading,
        user,
        handleSubmit
    } = useEditPanelUser();

    return (
        <Loading loading={isLoading} >
            <PanelUserForm action='Editar' user={user} key={user?.id} onSubmit={handleSubmit} />
        </Loading>
    )
}
