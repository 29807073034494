import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { ICity, IAppUser } from "../../../../interfaces";

import CitiesService from "../../../../services/http/services/CitiesService";
import { formatCep, formatCpf, formatPhone } from "../../../../utils/Masks";

export default function useAppUserForm(
    onSubmit: (user: IAppUser) => Promise<void>,
    user: IAppUser | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [isLoadingCities, setIsLoadingCities] = useState<boolean>(true);
    const [cities, setCities] = useState<Array<ICity>>([]);

    const handleSubmitForm: SubmitHandler<IAppUser> = async (data) => {
        setIsSubmiting(true);

        await onSubmit(data);

        setIsSubmiting(false);
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<IAppUser>({
        defaultValues: {
            name: user?.name || '',
            cpf: user?.cpf ? formatCpf(user.cpf) : '',
            phone: user?.phone ? formatPhone(user.phone) : '',
            email: user?.email || '',
            street: user?.street || '',
            neighborhood: user?.neighborhood || '',
            number: user?.number || '',
            cep: user?.cep ? formatCep(user.cep) : '',
            cities: user?.cities,
            city_ids: user?.cities?.map((permission) => permission.id) || [],
        }
    });

    useEffect(() => {
        const controller = new AbortController();

        async function loadCities() {
            try {
                setIsLoadingCities(true);
                const citiesList = await CitiesService.listCities(controller.signal);

                setCities(citiesList);
                setIsLoadingCities(false);
            } catch (err) {}
        }

        loadCities();

        return () => {
            controller.abort();
        }
    }, []);

    return {
        isLoadingCities,
        cities,
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors,
    }
}