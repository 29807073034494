import { useNavigate } from "react-router-dom";

import { IPanelUser } from "../../../interfaces";

import PanelUsersService from "../../../services/http/services/PanelUsersService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useNewPanelUser() {
    const navigate = useNavigate();

    async function handleSubmit(user: IPanelUser) {
        try {
            await PanelUsersService.createPanelUser(user);

            EmitSuccessAlert('Usuário cadastrado com sucesso!');

            navigate('/usuarios-painel');
        } catch (err) {
            EmitError(err, true, 'newPanelUser_ijapsdjasdp');
        }
    }

    return {
        handleSubmit,
    }
}