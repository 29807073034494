import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Breadcrumb, Button, Col, Divider, Form, Image, Input, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { PercentageOutlined } from '@ant-design/icons';

import { colInputs, colInputsThreePerRow, inputCpfAndPhone, inputName } from './GridStyle';
import useViewCityTax from './useViewCityTax';
import Loading from '../../../components/Loading';
import PDFItem from '../components/PDFItem';
import { convertMoedaBRDoJSComExtamente2CasasDecimais } from '../../../utils/Conversores';
import StatusOnView from '../components/StatusOnView';
import SimpleModal from '../../../components/SimpleModal';

export default function ViewCityTax() {
    const {
        isLoading,
        isSubmiting,
        control,
        files,
        handleApproveCityTax,
        handleRefuseCityTax,
        errors,
        cityTax,
        isConfirmApproveTaxModalVisible,
        isConfirmRefuseTaxModalVisible,
        handleConfirmApproveCityTax,
        handleConfirmRefuseCityTax,
        handleCancel,
    } = useViewCityTax();

    return (
        <Loading loading={isLoading}>
            <Row>
                <Col>
                    <Title level={3}><PercentageOutlined style={{ fontSize: 35, marginRight: 10 }} />Visualizar Imposto</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/impostos'>Impostos</Link>,
                            },
                            {
                                title: 'Visualizar Imposto',
                            }
                        ]}
                    />
                </Col>
                <Divider />
            </Row>

            {
                cityTax && (
                    <StatusOnView cityTax={cityTax} />
                )
            }
            
            <Form
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col
                        {...inputName}
                    >
                        <Form.Item
                            label="Nome do Usuário"
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...inputCpfAndPhone}
                    >
                        <Form.Item
                            label="CPF"
                        >
                            <Controller
                                name="cpf"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='CPF' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...inputCpfAndPhone}
                    >
                        <Form.Item
                            label="Telefone"
                        >
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Telefone' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Valor"
                        >
                            <Controller
                                name="value"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Valor' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Data de vencimento"
                        >
                            <Controller
                                name="due_date"
                                control={control}
                                render={({ field }) => <Input {...field} value={new Date(field.value).toLocaleDateString('pt-BR')} disabled placeholder='Data de vencimento' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputsThreePerRow}
                    >
                        <Form.Item
                            label="Tipo do Imposto"
                        >
                            <Controller
                                name="type"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Tipo' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputsThreePerRow}
                    >
                        <Form.Item
                            label="Modalidade de pagamento"
                        >
                            <Controller
                                name="payment_modality"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Modalidade' />}
                            />
                        </Form.Item>
                    </Col>
                    {
                        cityTax?.installment && (
                            <Col
                                {...colInputsThreePerRow}
                            >
                                <Form.Item
                                    label="Número da parcela"
                                >
                                    <Controller
                                        name="installment"
                                        control={control}
                                        render={({ field }) => <Input {...field} disabled placeholder='Parcela' />}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>

                <Title level={5}>Documentos</Title>

                <Row>
                    {
                        !isLoading ? (
                            files.length > 0 ? (
                                <Image.PreviewGroup>
                                    {
                                        files?.filter((file) => file.type !== 'application/pdf')?.map((file, index) => (
                                            <Image width={200} src={file.url} key={index} />
                                        ))
                                    }
                                    {
                                        files?.filter((file) => file.type === 'application/pdf')?.map((file, index) => (
                                            <PDFItem document={file} key={index} />
                                        ))
                                    }
                                </Image.PreviewGroup>
                            )
                                : (
                                    <Text>
                                        Não há documentos disponíveis.
                                    </Text>
                                )
                        )
                            : null
                    }
                </Row>

                {
                    cityTax?.status === "Em análise" && (
                        <Row
                            style={{
                                marginTop: 16
                            }}
                        >
                            <Col
                                xs={24}
                            >
                                <Form.Item
                                    label="Observação"
                                    validateStatus={errors.observation ? 'error' : 'success'}
                                    help={errors.observation?.message}
                                >
                                    <Controller
                                        name="observation"
                                        control={control}
                                        render={({ field }) => <Input {...field} placeholder='Observação' />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }


                {
                    cityTax?.status === "Em análise" && (
                        <Row
                            style={{
                                marginTop: 16
                            }}
                            justify="end"
                        >
                            <Button
                                type='primary'
                                danger
                                htmlType='button'
                                disabled={isSubmiting}
                                loading={isSubmiting}
                                style={{
                                    marginRight: 8,
                                }}
                                onClick={handleRefuseCityTax}
                            >
                                Recusar
                            </Button>

                            <Button
                                type='primary'
                                htmlType='button'
                                disabled={isSubmiting}
                                loading={isSubmiting}
                                onClick={handleApproveCityTax}
                            >
                                Aprovar
                            </Button>
                        </Row>
                    )
                }
            </Form>

            <SimpleModal
                open={isConfirmApproveTaxModalVisible}
                onOk={handleConfirmApproveCityTax}
                okButtonProps={{
                    disabled: isSubmiting,
                    loading: isSubmiting
                }}
                cancelButtonProps={{
                    disabled: isSubmiting
                }}
                okType='primary'
                okText='Aprovar'
                onCancel={handleCancel}
                title={`Deseja realmente aprovar o imposto do usuário "${cityTax?.app_user.name}" no valor de R$ ${convertMoedaBRDoJSComExtamente2CasasDecimais(cityTax?.value)}?`}
            />

            <SimpleModal
                open={isConfirmRefuseTaxModalVisible}
                onOk={handleConfirmRefuseCityTax}
                okButtonProps={{
                    disabled: isSubmiting,
                    loading: isSubmiting
                }}
                cancelButtonProps={{
                    disabled: isSubmiting
                }}
                okText='Recusar'
                onCancel={handleCancel}
                title={`Deseja realmente recusar o imposto do usuário "${cityTax?.app_user.name}"?`}
            />
        </Loading>
    )
}
