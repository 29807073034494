import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IAppUser } from "../../../interfaces";

import AppUsersService from "../../../services/http/services/AppUsersService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useViewAppUser() {
    const { id } = useParams();
    const [user, setUser] = useState<IAppUser>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadAppUser() {
            try {
                setIsLoading(true);
                const userData = await AppUsersService.getAppUserById(Number(id), controller.signal);

                setUser(userData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editAppUser_opasdasjpdasd');

                navigate('/usuarios-aplicativo');
            }
        }

        loadAppUser();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(userData: IAppUser) {
        try {
            await AppUsersService.updateAppUser(Number(id), userData);

            EmitSuccessAlert('Usuário atualizado com sucesso!');

            navigate('/usuarios-aplicativo');
        } catch (err) {
            EmitError(err, true, 'editAppUser_asiopdasodasd');
        }
    }

    return {
        isLoading,
        user,
        handleSubmit
    }
}