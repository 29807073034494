import { IVoucher } from '../../../interfaces';
import RewardMapper from './RewardMapper';

class VoucherMapper {
    toPersistence(domainVoucher: IVoucher): any {
        return {
            id: domainVoucher.id,
            app_user_id: domainVoucher.app_user_id,
            reward_id: domainVoucher.reward_id,
            reward: domainVoucher.reward,
            county_id: domainVoucher.county_id,
            date_redeemed: domainVoucher.date_redeemed,
            points_used: domainVoucher.points_used,
            redemption_code: domainVoucher.redemption_code,
            status: domainVoucher.status,
            validity: domainVoucher.validity,
            created_at: domainVoucher.created_at,
            updated_at: domainVoucher.updated_at,
        }
    }

    toDomain(persistenceVoucher: any): IVoucher {
        return {
            id: persistenceVoucher.id,
            app_user_id: persistenceVoucher.app_user_id,
            app_user: persistenceVoucher.app_user,
            reward_id: persistenceVoucher.reward_id,
            reward: persistenceVoucher.reward ? RewardMapper.toDomain(persistenceVoucher.reward) : undefined,
            county_id: persistenceVoucher.county_id,
            date_redeemed: persistenceVoucher.date_redeemed,
            points_used: persistenceVoucher.points_used || persistenceVoucher.points_userd,
            redemption_code: persistenceVoucher.redemption_code,
            status: persistenceVoucher.status,
            validity: persistenceVoucher.validity,
            panel_user: persistenceVoucher.panel_user,
            created_at: persistenceVoucher.created_at,
            updated_at: persistenceVoucher.updated_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new VoucherMapper();
