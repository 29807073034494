import { Row, Tag } from "antd";
import Highlighter from "react-highlight-words";

import { IMessageUser } from "../../../interfaces";

import { formatCpf } from "../../../../../utils/Masks";

interface IProps {
    appUser: IMessageUser,
    filters: {
        name: string,
        cpf: string,
    }
}

export default function AppUserRow({ appUser, filters }: IProps) {
    return (
        <Row
            style={{
                width: '100%',
                justifyContent: 'space-between',
            }}
        >
            <Highlighter
                highlightStyle={{
                    backgroundColor: '#ffc069',
                    padding: 0,
                }}
                searchWords={[filters.name, formatCpf(filters.cpf)]}
                autoEscape
                textToHighlight={`${formatCpf(appUser.cpf)} • ${appUser.name}`}
            />
            <Tag
                color={appUser.read ? "success" : "default"}
            >{appUser.read ? "Lido" : "Não lido"}</Tag>
        </Row>
    )
}