import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import Alert from "antd/es/alert/Alert";

import { ICity, ICityConfig, ICitySystem, ICityUpcomingConfigAndTerms } from "../../../../../../interfaces";
import { ICityFormStepProps } from "../../../../interfaces";

import FieldsGroupTitle from "../../../FieldsDivider";
import DatePicker from "../../../../../../components/DatePicker";
import useConfigStep from "./useConfigStep";
import { colInputs, colInputsConfig, colInputsEffectiveDate } from "../../GridStyle";
import UpcomingConfigAlert from "../../../UpcomingConfigAlert";
import { formatCurrencyInput } from "../../../../../../utils/Masks";
import FieldsCard from "../../../FieldsCard";
import { EModule } from "../../../../../../enums";
import useAuth from "../../../../../../hooks/useAuth";
import { MODULES } from "../../useCityForm";
import UpcomingConfigCarousel from "../../UpcomingConfigCarousel";

interface IProps extends ICityFormStepProps {
    needsToInformPointsConfigEffectiveDate: boolean;
    needsToInformTicketsConfigEffectiveDate: boolean;
    upcomingConfig: ICityUpcomingConfigAndTerms | undefined;
    systems: ICitySystem[];
    alreadyIncludedModules: EModule[],
    formHasInvoiceNFCModule: boolean,
    formHasInvoiceNFSModule: boolean,
    formHasRewardModule: boolean,
    formHasTaxesModule: boolean,
    city?: ICity,
}

export default function ConfigStep({
    control,
    errors,
    needsToInformPointsConfigEffectiveDate,
    needsToInformTicketsConfigEffectiveDate,
    upcomingConfig,
    systems,
    alreadyIncludedModules,
    formHasInvoiceNFCModule,
    formHasInvoiceNFSModule,
    formHasRewardModule,
    formHasTaxesModule,
    city
}: IProps) {
    const {
        colInputsPointsConfigDescription,
        colInputsTicketsConfigDescription,
    } = useConfigStep(needsToInformPointsConfigEffectiveDate, needsToInformTicketsConfigEffectiveDate);

    const { user } = useAuth();

    const isAdmin = useMemo(() => {
        return user?.role?.id === 1;
    }, [user]);

    const showNFSConfig = useMemo(() => {
        return formHasInvoiceNFSModule;
    }, [formHasInvoiceNFSModule]);

    const showNFCConfig = useMemo(() => {
        return formHasInvoiceNFCModule;
    }, [formHasInvoiceNFCModule]);

    const showTaxesConfig = useMemo(() => {
        return formHasTaxesModule;
    }, [formHasTaxesModule]);

    const showRewardConfig = useMemo(() => {
        return formHasRewardModule;
    }, [formHasRewardModule]);

    const hasAtLeastOnePointsGenerationModule = useMemo(() => {
        return formHasInvoiceNFSModule || formHasInvoiceNFCModule || formHasTaxesModule;
    }, [formHasInvoiceNFSModule, formHasInvoiceNFCModule, formHasTaxesModule]);

    const hasAtLeastOneInvoiceModule = useMemo(() => {
        return formHasInvoiceNFSModule || formHasInvoiceNFCModule;
    }, [formHasInvoiceNFSModule, formHasInvoiceNFCModule]);

    const disableInputPoints = useCallback((
        upcomingConfigField: keyof ICityConfig,
        cityField: keyof ICity
    ) => {
        return !!upcomingConfig?.points_config?.find((cfg) => cfg[upcomingConfigField]) && city && !city[cityField];
    }, [upcomingConfig, city]);


    const disableInputTickets = useCallback((
        upcomingConfigField: keyof ICityConfig,
        cityField: keyof ICity
    ) => {
        return !!upcomingConfig?.tickets_config?.find((cfg) => cfg[upcomingConfigField]) && city && !city[cityField];
    }, [upcomingConfig, city]);

    return (
        <>
            {
                isAdmin && (
                    <>
                        <FieldsGroupTitle
                            title="Aplicativo"
                        />

                        <Row
                            gutter={16}
                        >
                            <Col
                                {...colInputs}
                            >
                                <Form.Item
                                    label="Módulos"
                                    required
                                    validateStatus={errors.modules ? 'error' : 'success'}
                                    help={errors.modules?.message}
                                >
                                    <Controller
                                        name="modules"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Selecione os módulos'
                                                mode="multiple"
                                                allowClear={alreadyIncludedModules.length === 0}
                                            >
                                                {
                                                    MODULES.map((module) => (
                                                        <Select.Option
                                                            key={module.name}
                                                            value={module.name}
                                                            disabled={alreadyIncludedModules.includes(module.name)}
                                                        >
                                                            {module.label}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )
            }

            {
                systems.length > 0 && showNFSConfig && (
                    <>
                        <FieldsGroupTitle
                            title="Sistema Fiscal"
                            showDivider={isAdmin}
                        />

                        <Row
                            gutter={16}
                        >
                            <Col
                                {...colInputs}
                            >
                                <Form.Item
                                    label="Sistema"
                                    required
                                    validateStatus={errors.system_id ? 'error' : 'success'}
                                    help={errors.system_id?.message}
                                >
                                    <Controller
                                        name="system_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={systems.map((system) => ({ value: system.id, label: system.name }))}
                                                placeholder='Selecione o sistema'
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )
            }

            {
                showRewardConfig && (
                    <>
                        <FieldsGroupTitle
                            title="Pontos"
                            showDivider={systems.length > 0 || isAdmin}
                        />

                        {
                            hasAtLeastOnePointsGenerationModule ? (
                                <>
                                    <Row
                                        gutter={16}
                                    >
                                        <FieldsCard
                                            title="Divisores"
                                        >
                                            {
                                                showNFSConfig && (

                                                    <Col
                                                        {...colInputsConfig}
                                                    >
                                                        <Form.Item
                                                            label="NFS"
                                                            required
                                                            validateStatus={errors.nfs_points_divider ? 'error' : 'success'}
                                                            help={errors.nfs_points_divider?.message}
                                                            tooltip="A cada X valor em NFSs, o usuário ganhará 1 ponto."
                                                        >
                                                            <Controller
                                                                name="nfs_points_divider"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <InputNumber
                                                                        {...field}
                                                                        type='number'
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        disabled={disableInputPoints('nfs_divider', 'nfs_points_divider')}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            {
                                                showNFCConfig && (

                                                    <Col
                                                        {...colInputsConfig}
                                                    >
                                                        <Form.Item
                                                            label="NFC"
                                                            required
                                                            validateStatus={errors.nfc_points_divider ? 'error' : 'success'}
                                                            help={errors.nfc_points_divider?.message}
                                                            tooltip="A cada X valor em NFCs, o usuário ganhará 1 ponto."
                                                        >
                                                            <Controller
                                                                name="nfc_points_divider"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <InputNumber
                                                                        {...field}
                                                                        type='number'
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        disabled={disableInputPoints('nfc_divider', 'nfc_points_divider')}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            {
                                                showTaxesConfig && (
                                                    <Col
                                                        {...colInputsConfig}
                                                    >
                                                        <Form.Item
                                                            label="Impostos"
                                                            required
                                                            validateStatus={errors.taxe_points_divider ? 'error' : 'success'}
                                                            help={errors.taxe_points_divider?.message}
                                                            tooltip="A cada X valor em Impostos, o usuário ganhará 1 ponto."
                                                        >
                                                            <Controller
                                                                name="taxe_points_divider"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <InputNumber
                                                                        {...field}
                                                                        type='number'
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        disabled={disableInputPoints('taxe_divider', 'taxe_points_divider')}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </FieldsCard>

                                        <FieldsCard
                                            title="Valor máximo para gerar pontos"
                                        >
                                            {
                                                showNFSConfig && (
                                                    <Col
                                                        {...colInputsConfig}
                                                    >
                                                        <Form.Item
                                                            label="NFS"
                                                            required
                                                            validateStatus={errors.nfs_max_amount_points ? 'error' : 'success'}
                                                            help={errors.nfs_max_amount_points?.message}
                                                            tooltip="A partir de X valor em NFSs, não é contabilizado mais pontos."
                                                        >
                                                            <Controller
                                                                name="nfs_max_amount_points"
                                                                control={control}
                                                                render={({ field: { onChange, ...field } }) => (
                                                                    <Input
                                                                        {...field}
                                                                        onChange={(e) => onChange(formatCurrencyInput(e.target.value))}
                                                                        addonBefore={`R$`}
                                                                        placeholder='Valor'
                                                                        disabled={disableInputPoints('nfs_max_amount', 'nfs_max_amount_points')}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            {
                                                showNFCConfig && (

                                                    <Col
                                                        {...colInputsConfig}
                                                    >
                                                        <Form.Item
                                                            label="NFC"
                                                            required
                                                            validateStatus={errors.nfc_max_amount_points ? 'error' : 'success'}
                                                            help={errors.nfc_max_amount_points?.message}
                                                            tooltip="A partir de X valor em NFCs, não é contabilizado mais pontos."
                                                        >
                                                            <Controller
                                                                name="nfc_max_amount_points"
                                                                control={control}
                                                                render={({ field: { onChange, ...field } }) => (
                                                                    <Input
                                                                        {...field}
                                                                        onChange={(e) => onChange(formatCurrencyInput(e.target.value))}
                                                                        addonBefore={`R$`}
                                                                        placeholder='Valor'
                                                                        disabled={disableInputPoints('nfc_max_amount', 'nfc_max_amount_points')}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            {
                                                showTaxesConfig && (
                                                    <Col
                                                        {...colInputsConfig}
                                                    >
                                                        <Form.Item
                                                            label="Impostos"
                                                            required
                                                            validateStatus={errors.taxe_max_amount_points ? 'error' : 'success'}
                                                            help={errors.taxe_max_amount_points?.message}
                                                            tooltip="A partir de X valor em Impostos, não é contabilizado mais pontos."
                                                        >
                                                            <Controller
                                                                name="taxe_max_amount_points"
                                                                control={control}
                                                                render={({ field: { onChange, ...field } }) => (
                                                                    <Input
                                                                        {...field}
                                                                        onChange={(e) => onChange(formatCurrencyInput(e.target.value))}
                                                                        addonBefore={`R$`}
                                                                        placeholder='Valor'
                                                                        disabled={disableInputPoints('taxe_max_amount', 'taxe_max_amount_points')}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </FieldsCard>
                                    </Row>

                                    <Row
                                        gutter={16}
                                        style={{
                                            marginTop: 16,
                                            marginBottom: 32,
                                        }}
                                    >
                                        {
                                            hasAtLeastOneInvoiceModule && (
                                                <FieldsCard
                                                    title="Quantidade mensal limite por estabelecimento"
                                                >
                                                    {
                                                        showNFSConfig && (
                                                            <Col
                                                                {...colInputsConfig}
                                                            >
                                                                <Form.Item
                                                                    label="NFS"
                                                                    required
                                                                    validateStatus={errors.nfs_max_month_quantity_by_cnpj_points ? 'error' : 'success'}
                                                                    help={errors.nfs_max_month_quantity_by_cnpj_points?.message}
                                                                    tooltip="Quantidade limite de NFSs em um mesmo CNPJ por mês para geração de pontos."
                                                                >
                                                                    <Controller
                                                                        name="nfs_max_month_quantity_by_cnpj_points"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <InputNumber
                                                                                {...field}
                                                                                type='number'
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                                disabled={disableInputPoints('nfs_max_month_quantity_by_cnpj', 'nfs_max_month_quantity_by_cnpj_points')}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        )
                                                    }

                                                    {
                                                        showNFCConfig && (
                                                            <Col
                                                                {...colInputsConfig}
                                                            >
                                                                <Form.Item
                                                                    label="NFC"
                                                                    required
                                                                    validateStatus={errors.nfc_max_month_quantity_by_cnpj_points ? 'error' : 'success'}
                                                                    help={errors.nfc_max_month_quantity_by_cnpj_points?.message}
                                                                    tooltip="Quantidade limite de NFCs em um mesmo CNPJ por mês para geração de pontos."
                                                                >
                                                                    <Controller
                                                                        name="nfc_max_month_quantity_by_cnpj_points"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <InputNumber
                                                                                {...field}
                                                                                type='number'
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                                disabled={disableInputPoints('nfc_max_month_quantity_by_cnpj', 'nfc_max_month_quantity_by_cnpj_points')}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        )
                                                    }
                                                </FieldsCard>
                                            )
                                        }

                                        <FieldsCard
                                            title="Adicionais"
                                        >
                                            {
                                                needsToInformPointsConfigEffectiveDate && (
                                                    <Col
                                                        {...colInputsEffectiveDate}
                                                    >
                                                        <Form.Item
                                                            label="Data de vigência"
                                                            required
                                                            validateStatus={errors.points_config_effective_date ? 'error' : 'success'}
                                                            help={errors.points_config_effective_date?.message}
                                                            tooltip="Data que as novas configurações dos pontos passarão a ser válidas."
                                                        >
                                                            <Controller
                                                                name="points_config_effective_date"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        format={(date) => date.toLocaleDateString('PT-BR')}
                                                                        value={field.value ? new Date(field.value) : undefined}
                                                                        onChange={field.onChange}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }

                                            <Col
                                                {...colInputsPointsConfigDescription}
                                            >
                                                <Form.Item
                                                    label="Descrição das configurações"
                                                    validateStatus={errors.points_config_description ? 'error' : 'success'}
                                                    help={errors.points_config_description?.message}
                                                >
                                                    <Controller
                                                        name="points_config_description"
                                                        control={control}
                                                        render={({ field }) => <Input {...field} placeholder='Descrição das configurações dos pontos' />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </FieldsCard>
                                    </Row>

                                    {
                                        upcomingConfig && upcomingConfig.points_config && upcomingConfig.points_config.length > 0 && (
                                            <UpcomingConfigCarousel dataLength={upcomingConfig.points_config.length} >
                                                {
                                                    upcomingConfig.points_config.map((config) => (
                                                        <UpcomingConfigAlert
                                                            title="Próximas configurações de pontos a entrar em vigência"
                                                            config={config}
                                                        />
                                                    ))
                                                }
                                            </UpcomingConfigCarousel>
                                        )
                                    }
                                </>
                            ) : (
                                <Alert
                                    type={'error'}
                                    message="Selecione ao menos um módulo de geração de pontos (Notas Fiscais ou Impostos) para liberar a configuração de pontos."
                                    style={{
                                        width: '100%',
                                        marginBottom: 16,
                                    }}
                                />
                            )
                        }
                    </>
                )
            }

            <FieldsGroupTitle
                title="Bilhetes"
                showDivider
            />

            {
                hasAtLeastOnePointsGenerationModule ? (
                    <>

                        <Row
                            gutter={16}
                        >
                            <FieldsCard
                                title="Divisores"
                            >
                                {
                                    showNFSConfig && (
                                        <Col
                                            {...colInputsConfig}
                                        >
                                            <Form.Item
                                                label="NFS"
                                                required
                                                validateStatus={errors.nfs_tickets_divider ? 'error' : 'success'}
                                                help={errors.nfs_tickets_divider?.message}
                                                tooltip="A cada X valor em NFSs, o usuário ganhará 1 bilhete."
                                            >
                                                <Controller
                                                    name="nfs_tickets_divider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <InputNumber
                                                            {...field}
                                                            type='number'
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            disabled={disableInputTickets('nfs_divider', 'nfs_tickets_divider')}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {
                                    showNFCConfig && (
                                        <Col
                                            {...colInputsConfig}
                                        >
                                            <Form.Item
                                                label="NFC"
                                                required
                                                validateStatus={errors.nfc_tickets_divider ? 'error' : 'success'}
                                                help={errors.nfc_tickets_divider?.message}
                                                tooltip="A cada X valor em NFCs, o usuário ganhará 1 bilhete."
                                            >
                                                <Controller
                                                    name="nfc_tickets_divider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <InputNumber
                                                            {...field}
                                                            type='number'
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            disabled={disableInputTickets('nfc_divider', 'nfc_tickets_divider')}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {
                                    showTaxesConfig && (
                                        <Col
                                            {...colInputsConfig}
                                        >
                                            <Form.Item
                                                label="Impostos"
                                                required
                                                validateStatus={errors.taxe_tickets_divider ? 'error' : 'success'}
                                                help={errors.taxe_tickets_divider?.message}
                                                tooltip="A cada X valor em Impostos, o usuário ganhará 1 bilhete."
                                            >
                                                <Controller
                                                    name="taxe_tickets_divider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <InputNumber
                                                            {...field}
                                                            type='number'
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            disabled={disableInputTickets('taxe_divider', 'taxe_tickets_divider')}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                            </FieldsCard>

                            <FieldsCard
                                title="Valor máximo para gerar bilhetes"
                            >
                                {
                                    showNFSConfig && (
                                        <Col
                                            {...colInputsConfig}
                                        >
                                            <Form.Item
                                                label="NFS"
                                                required
                                                validateStatus={errors.nfs_max_amount_tickets ? 'error' : 'success'}
                                                help={errors.nfs_max_amount_tickets?.message}
                                                tooltip="A partir de X valor em NFSs, não é contabilizado mais bilhetes."
                                            >
                                                <Controller
                                                    name="nfs_max_amount_tickets"
                                                    control={control}
                                                    render={({ field: { onChange, ...field } }) => (
                                                        <Input
                                                            {...field}
                                                            onChange={(e) => onChange(formatCurrencyInput(e.target.value))}
                                                            addonBefore={`R$`}
                                                            placeholder='Valor'
                                                            disabled={disableInputTickets('nfs_max_amount', 'nfs_max_amount_tickets')}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {
                                    showNFCConfig && (
                                        <Col

                                            {...colInputsConfig}
                                        >
                                            <Form.Item
                                                label="NFC"
                                                required
                                                validateStatus={errors.nfc_max_amount_tickets ? 'error' : 'success'}
                                                help={errors.nfc_max_amount_tickets?.message}
                                                tooltip="A partir de X valor em NFCs, não é contabilizado mais bilhetes."
                                            >
                                                <Controller
                                                    name="nfc_max_amount_tickets"
                                                    control={control}
                                                    render={({ field: { onChange, ...field } }) => (
                                                        <Input
                                                            {...field}
                                                            onChange={(e) => onChange(formatCurrencyInput(e.target.value))}
                                                            addonBefore={`R$`}
                                                            placeholder='Valor'
                                                            disabled={disableInputTickets('nfc_max_amount', 'nfc_max_amount_tickets')}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {
                                    showTaxesConfig && (
                                        <Col

                                            {...colInputsConfig}
                                        >
                                            <Form.Item
                                                label="Impostos"
                                                required
                                                validateStatus={errors.taxe_max_amount_tickets ? 'error' : 'success'}
                                                help={errors.taxe_max_amount_tickets?.message}
                                                tooltip="A partir de X valor em Impostos, não é contabilizado mais bilhetes."
                                            >
                                                <Controller
                                                    name="taxe_max_amount_tickets"
                                                    control={control}
                                                    render={({ field: { onChange, ...field } }) => (
                                                        <Input
                                                            {...field}
                                                            onChange={(e) => onChange(formatCurrencyInput(e.target.value))}
                                                            addonBefore={`R$`}
                                                            placeholder='Valor'
                                                            disabled={disableInputTickets('taxe_max_amount', 'taxe_max_amount_tickets')}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                            </FieldsCard>
                        </Row>

                        <Row
                            gutter={16}
                            style={{
                                marginTop: 16,
                                marginBottom: 32,
                            }}
                        >
                            {
                                hasAtLeastOneInvoiceModule && (
                                    <FieldsCard
                                        title="Quantidade mensal limite por estabelecimento"
                                    >
                                        {
                                            showNFSConfig && (
                                                <Col

                                                    {...colInputsConfig}
                                                >
                                                    <Form.Item
                                                        label="NFS"
                                                        required
                                                        validateStatus={errors.nfs_max_month_quantity_by_cnpj_tickets ? 'error' : 'success'}
                                                        help={errors.nfs_max_month_quantity_by_cnpj_tickets?.message}
                                                        tooltip="Quantidade limite de NFSs em um mesmo CNPJ por mês para geração de bilhetes."
                                                    >
                                                        <Controller
                                                            name="nfs_max_month_quantity_by_cnpj_tickets"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <InputNumber
                                                                    {...field}
                                                                    type='number'
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    disabled={disableInputTickets('nfs_max_month_quantity_by_cnpj', 'nfs_max_month_quantity_by_cnpj_tickets')}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )
                                        }

                                        {
                                            showNFCConfig && (
                                                <Col

                                                    {...colInputsConfig}
                                                >
                                                    <Form.Item
                                                        label="NFC"
                                                        required
                                                        validateStatus={errors.nfc_max_month_quantity_by_cnpj_tickets ? 'error' : 'success'}
                                                        help={errors.nfc_max_month_quantity_by_cnpj_tickets?.message}
                                                        tooltip="Quantidade limite de NFCs em um mesmo CNPJ por mês para geração de bilhetes."
                                                    >
                                                        <Controller
                                                            name="nfc_max_month_quantity_by_cnpj_tickets"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <InputNumber
                                                                    {...field}
                                                                    type='number'
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    disabled={disableInputTickets('nfc_max_month_quantity_by_cnpj', 'nfc_max_month_quantity_by_cnpj_tickets')}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )
                                        }
                                    </FieldsCard>
                                )
                            }

                            <FieldsCard
                                title="Adicionais"
                            >
                                {
                                    needsToInformTicketsConfigEffectiveDate && (
                                        <Col
                                            {...colInputsEffectiveDate}
                                        >
                                            <Form.Item
                                                label="Data de vigência"
                                                required
                                                validateStatus={errors.tickets_config_effective_date ? 'error' : 'success'}
                                                help={errors.tickets_config_effective_date?.message}
                                                tooltip="Data que as novas configurações dos bilhetes passarão a ser válidas."
                                            >
                                                <Controller
                                                    name="tickets_config_effective_date"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            format={(date) => date.toLocaleDateString('PT-BR')}
                                                            value={field.value ? new Date(field.value) : undefined}
                                                            onChange={field.onChange}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                <Col
                                    {...colInputsTicketsConfigDescription}
                                >
                                    <Form.Item
                                        label="Descrição das configurações"
                                        validateStatus={errors.tickets_config_description ? 'error' : 'success'}
                                        help={errors.tickets_config_description?.message}
                                    >
                                        <Controller
                                            name="tickets_config_description"
                                            control={control}
                                            render={({ field }) => <Input {...field} placeholder='Descrição das configurações dos bilhetes' />}
                                        />
                                    </Form.Item>
                                </Col>
                            </FieldsCard>
                        </Row>

                        {
                            upcomingConfig && upcomingConfig.tickets_config && upcomingConfig.tickets_config.length > 0 && (
                                <UpcomingConfigCarousel dataLength={upcomingConfig.tickets_config.length} >
                                    {
                                        upcomingConfig.tickets_config.map((config) => (
                                            <UpcomingConfigAlert
                                                title="Próximas configurações de bilhetes a entrar em vigência"
                                                config={config}
                                            />
                                        ))
                                    }
                                </UpcomingConfigCarousel>
                            )
                        }
                    </>
                ) : (
                    <Alert
                        type={'error'}
                        message="Selecione ao menos um módulo de geração de bilhetes (Notas Fiscais ou Impostos) para liberar a configuração dos bilhetes."
                        style={{
                            width: '100%',
                            marginBottom: 16,
                        }}
                    />
                )
            }

            {
                showRewardConfig && (
                    <>
                        <FieldsGroupTitle
                            title="Validades"
                            showDivider
                        />

                        <Row
                            gutter={16}
                        >
                            <Col>
                                <Form.Item
                                    label="Tempo de validade dos pontos (em dias)"
                                    required
                                    validateStatus={errors.time_expire_points ? 'error' : 'success'}
                                    help={errors.time_expire_points?.message}
                                >
                                    <Controller
                                        name="time_expire_points"
                                        control={control}
                                        defaultValue={365}
                                        render={({ field }) => <InputNumber {...field} type='number' />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label="Tempo de validade dos Vouchers (em dias)"
                                    required
                                    validateStatus={errors.time_expire_vouchers ? 'error' : 'success'}
                                    help={errors.time_expire_vouchers?.message}
                                >
                                    <Controller
                                        name="time_expire_vouchers"
                                        control={control}
                                        defaultValue={30}
                                        render={({ field }) => <InputNumber {...field} type='number' />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )
            }
        </>
    )
}