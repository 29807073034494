import { MutableRefObject } from "react";
import { Controller } from "react-hook-form";
import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import Text from "antd/es/typography/Text";

import { IGiveaway } from "../../../../../../interfaces";

import { colInputs } from "./GridStyle";
import useAwardsStep from "./useAwardsStep";
import UploadButton from "./components/UploadButton";
import AwardsList from "./components/AwardsList";
import { awardImagesUrl } from "../../../../../../services/urls";
import { formatCurrencyInput } from "../../../../../../utils/Masks";
import SimpleModal from "../../../../../../components/SimpleModal";
import { removeAcento } from "../../../../../../utils/RemoveAcentos";

interface IProps {
    giveaway?: IGiveaway;
    onAwardsListLengthChange?: (awardsListLength: number) => void,
    refIsEditing: MutableRefObject<boolean>;
    canEdit: boolean;
}

export default function AwardsStep({
    giveaway,
    onAwardsListLengthChange,
    canEdit,
    refIsEditing
}: IProps) {
    const {
        isSubmiting,
        handleSubmit,
        handleSubmitForm,
        errors,
        control,
        dummyRequest,
        fileList,
        handleFileListChange,
        awards,
        orderedAwardTypes,
        isLoadingAwardTypes,
        selectedTypeDefaultImageUrl,
        awardBeingEditedImageIsDefault,
        handleClickEditAward,
        awardBeingEdited,
        handleCancelEditAward,
        isDeleteModalVisible,
        handleClickDeleteAward,
        handleConfirmDeleteAward,
        handleCancelDeleteAward,
        awardBeingDeleted,
        isLoadingDelete,
        isConfirmOverwriteFieldsToEditModalVisible,
        isConfirmFinishModalVisible,
        handleCancelOverwriteFields,
        handleConfirmOverwriteFields,
        handleClickFinish,
        handleCancelFinish,
        handleConfirmFinish,
        handleUpdateAwardsOrder,
        isOrderUnsaved,
        handleOrderChanged,
        ACCEPTED_IMAGE_TYPES,
    } = useAwardsStep(giveaway, onAwardsListLengthChange);

    const giveawayEnded = refIsEditing.current && !canEdit;

    if (!giveaway) {
        return null;
    }

    return (

        <Form
            onFinish={handleSubmit(handleSubmitForm)}
            autoComplete='off'
            layout='vertical'
        >
            <Row
                gutter={16}
            >
                <Col
                    {...colInputs}
                >
                    <Row>
                        <Col
                            span={24}
                        >
                            <Form.Item
                                label="Descrição"
                                required
                                validateStatus={errors.description ? 'error' : 'success'}
                                help={errors.description?.message}
                            >
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{ required: { value: true, message: 'Por favor, digite a descrição do prêmio!' }, }}
                                    render={({ field }) => <Input {...field} placeholder='Descrição' disabled={giveawayEnded} />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            span={24}
                        >
                            <Form.Item
                                label="Tipo"
                                required
                                validateStatus={errors.type_id ? 'error' : 'success'}
                                help={errors.type_id?.message}
                            >
                                <Controller
                                    name="type_id"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: 'Por favor, informe o tipo do prêmio!' },
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={orderedAwardTypes.map((type) => ({ value: type.id, label: type.name }))}
                                            placeholder='Selecione o tipo'
                                            disabled={isSubmiting || giveawayEnded || isLoadingAwardTypes}
                                            loading={isLoadingAwardTypes}
                                            showSearch
                                            filterOption={(input, option) => (removeAcento(option?.label ?? '')).includes(removeAcento(input))}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col
                            span={24}
                        >
                            <Form.Item
                                label="Valor"
                                required
                                validateStatus={errors.value ? 'error' : 'success'}
                                help={errors.value?.message}
                            >
                                <Controller
                                    name="value"
                                    control={control}
                                    rules={{ required: { value: true, message: 'Por favor, informe o valor do prêmio!' }, }}
                                    render={({ field: { onChange, ...field } }) => (
                                        <Input
                                            {...field}
                                            onChange={(e) => onChange(formatCurrencyInput(e.target.value))}
                                            addonBefore={`R$`}
                                            placeholder='Valor'
                                            style={{
                                                width: '100%',
                                            }}
                                            disabled={giveawayEnded}
                                        />
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            span={24}
                        >
                            <Form.Item
                                label="Imagem"
                                validateStatus={errors.images ? 'error' : 'success'}
                                help={errors.images?.message}
                            >
                                <Controller
                                    name="images"
                                    control={control}
                                    render={({ field: { value, onChange, ...field } }) => (
                                        <Upload
                                            {...field}
                                            listType="picture-card"
                                            fileList={fileList}
                                            customRequest={dummyRequest}
                                            onChange={handleFileListChange}
                                            accept={ACCEPTED_IMAGE_TYPES.toString()}
                                            disabled={giveawayEnded}
                                        >
                                            {fileList && fileList.length >= 1 ? null
                                                : awardBeingEdited?.images && awardBeingEdited.images.length > 0 && !awardBeingEditedImageIsDefault && !isConfirmOverwriteFieldsToEditModalVisible ? (
                                                    <img
                                                        src={`${awardImagesUrl}/${awardBeingEdited?.images[0].img}`}
                                                        alt="award"
                                                        width="100%"
                                                    />
                                                ) 
                                                : selectedTypeDefaultImageUrl ? (
                                                    <img
                                                        src={selectedTypeDefaultImageUrl}
                                                        alt="award"
                                                        width="100%"
                                                    />
                                                ) : <UploadButton />}
                                        </Upload>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginBottom: 16
                        }}
                    >
                        {
                            awardBeingEdited && !isConfirmOverwriteFieldsToEditModalVisible && (
                                <Button
                                    htmlType='button'
                                    onClick={handleCancelEditAward}
                                    disabled={isSubmiting}
                                    style={{
                                        marginRight: 8,
                                    }}
                                >
                                    Cancelar edição
                                </Button>
                            )
                        }
                        <Button
                            type='primary'
                            htmlType='submit'
                            disabled={isSubmiting || isOrderUnsaved || giveawayEnded}
                        >
                            {
                                awardBeingEdited && !isConfirmOverwriteFieldsToEditModalVisible ? "Atualizar prêmio" : "Adicionar prêmio"
                            }
                        </Button>
                        {
                            isOrderUnsaved && (
                                <p
                                    style={{
                                        color: '#FC5050',
                                        textAlign: 'center',
                                        marginTop: 8,
                                    }}
                                >
                                    Para adicionar novos prêmios ou editar um prêmio existente, primeiro salve ou cancele a ordenação no rodapé da tabela ao lado
                                </p>
                            )
                        }
                    </Row>
                </Col>
                <Col
                    {...colInputs}
                >
                    <AwardsList
                        awards={awards}
                        onClickEditAward={handleClickEditAward}
                        onClickDeleteAward={handleClickDeleteAward}
                        onUpdateAwardsOrder={handleUpdateAwardsOrder}
                        onAwardsOrderChanged={handleOrderChanged}
                        giveawayEnded={giveawayEnded}
                    />
                </Col>

            </Row>
            <Row
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: 16,
                }}
            >
                <Button
                    type='primary'
                    htmlType='button'
                    disabled={isSubmiting}
                    onClick={handleClickFinish}
                >
                    Finalizar
                </Button>
            </Row>
            <SimpleModal
                open={isDeleteModalVisible}
                onOk={handleConfirmDeleteAward}
                okButtonProps={{
                    disabled: isLoadingDelete,
                    loading: isLoadingDelete,
                }}
                cancelButtonProps={{
                    disabled: isLoadingDelete
                }}
                okText='Deletar'
                onCancel={handleCancelDeleteAward}
                title={`Deseja realmente deletar "${awardBeingDeleted?.description}"?`}
            />
            <SimpleModal
                open={isConfirmOverwriteFieldsToEditModalVisible}
                onOk={handleConfirmOverwriteFields}
                onCancel={handleCancelOverwriteFields}
                title={"O seu formulário possui um ou mais campos preenchidos!"}
            >
                <Text>
                    Caso realmente queira sobrescreve-los, clique em "Confirmar".
                </Text>
            </SimpleModal>
            <SimpleModal
                open={isConfirmFinishModalVisible}
                onOk={handleConfirmFinish}
                onCancel={handleCancelFinish}
                title={"O seu formulário possui um ou mais campos preenchidos!"}
            >
                <Text>
                    Clique no botão "Adicionar prêmio" para cadastra-lo. Caso realmente queira finalizar <strong style={{ color: '#FF4D4F' }}>sem salvar os dados do formulário</strong>, clique em "Confirmar".
                </Text>
            </SimpleModal>
        </Form>
    )
}