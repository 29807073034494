import { Controller } from 'react-hook-form';
import { Form, Input, Button, Row, Col, Alert, theme as themeAntd } from 'antd';

// // @ts-ignore
import ImagemLogo from './../../assets/images/logo.png';

import './style.css';

import Loading from '../../components/Loading';
import useLogin from './useLogin';

const Login = () => {
    const {
        user,
        handleSubmit,
        onSubmit,
        isSubmiting,
        isLoadingUser,
        msgEmail,
        formRef,
        errors,
        control,
        // onClickForgotPassword,
    } = useLogin();

    const { token: {
        colorBgContainer
    } } = themeAntd.useToken();

    if (isLoadingUser) {
        return <Loading loading={isLoadingUser} />
    }

    return (
        !user ?
            <Row
                gutter={24}
                style={{
                    maxHeight: "100vh",
                    margin: 0,
                    backgroundColor: colorBgContainer,
                }}
                align="middle"
            >
                <Col
                    className='fotoFundoLogin'
                    flex={3}
                />
                <Col 
                    flex={1}
                    style={{
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: '563px'
                    }}
                >
                    <Loading loading={isSubmiting} >
                        <Form
                            name="formLogin"
                            ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={handleSubmit(onSubmit)}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <img
                                    src={ImagemLogo}
                                    alt="Logo Nota Município"
                                    style={{
                                        width: '290px',
                                        // margin: '20px'
                                    }}
                                />

                                {
                                    msgEmail &&
                                    <div
                                        style={{
                                            float: 'left',
                                            marginLeft: 30,
                                            marginRight: 30
                                        }}
                                    >

                                        <Alert
                                            message="Verifique seu email."
                                            type="success"
                                            style={{
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                paddingTop: 2,
                                                paddingBottom: 2,
                                                marginTop: 10,
                                                marginBottom: -15,
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: 30
                            }}>

                                <Form.Item
                                    style={{
                                        marginBottom: 15,
                                    }}
                                    validateStatus={errors.email ? 'error' : 'success'}
                                    help={errors.email?.message}
                                >
                                    <Controller
                                        name='email'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Por favor, digite o email!',
                                            },
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Email inválido',
                                            }
                                        }}
                                        render={({ field }) => <Input {...field} placeholder='Email' />}
                                    />
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        marginBottom: 15,
                                    }}
                                    validateStatus={errors.password ? 'error' : 'success'}
                                    help={errors.password?.message}
                                >
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{
                                            required: { value: true, message: 'Por favor, digite a senha!' },
                                        }}
                                        render={({ field }) => <Input.Password {...field} placeholder='Senha' />}
                                    />
                                </Form.Item>

                                {/* <div>
                                    <Button
                                        style={{
                                            marginBottom: 20,
                                            padding: 0
                                        }}
                                        type="link"
                                        onClick={onClickForgotPassword}
                                    >
                                        Esqueceu a senha?
                                    </Button>
                                </div> */}

                                <Button
                                    type="primary"
                                    style={{ width: "100%" }}
                                    htmlType='submit'
                                    disabled={isSubmiting}
                                >
                                    Entrar
                                </Button>
                            </div>
                        </Form>
                    </Loading>
                </Col>
            </Row>
        : null
    );
};

export default Login;
