import { useContext } from "react";
import { Table as TableAntd } from "antd";
import Text from "antd/es/typography/Text";

import { ThemeContext } from "../../contexts/ThemeContext";
import { TableProps } from "antd/es/table";

interface IProps extends TableProps<any> {
    tableData: Array<any>,
}

export default function Table({
    columns,
    tableData,
    rowSelection,
    loading,
    onRow,
    expandable,
    style,
}: IProps) {
    const { theme } = useContext(ThemeContext);

    return (
        <TableAntd
            columns={columns}
            dataSource={tableData}
            rowSelection={rowSelection}
            loading={loading}
            onRow={onRow}
            style={{
                marginTop: 50,
                ...style,
            }}
            pagination={{
                showSizeChanger: true,
                position: ['topRight', 'bottomRight'],
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100]
            }}
            title={(data) => (
                <div
                    style={{
                        backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                        padding: 10,
                        margin: -16,
                        marginBottom: 0.5,
                    }}
                >
                    <Text>Exibindo {data.length} de um total de {tableData.length} {tableData.length === 1 ? 'registro' : 'registros'}.</Text>
                </div>
            )}
            footer={(data) => (
                <div
                    style={{
                        textAlign: 'end'
                    }}
                >
                    <Text>Exibindo {data.length} de um total de {tableData.length} {tableData.length === 1 ? 'registro' : 'registros'}.</Text>
                </div>
            )}
            expandable={expandable}
        >

        </TableAntd>
    )
}