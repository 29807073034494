import { addDays, addMinutes, addMonths, fromUnixTime, isAfter, isEqual, isFirstDayOfMonth, isLastDayOfMonth, isPast, parse, parseISO, subDays, subYears, setHours, setMinutes, setSeconds, setMilliseconds, isBefore, differenceInMinutes, isToday, isYesterday, isSameWeek } from "date-fns";
import { ptBR } from "date-fns/locale";

interface IDateProvider {
	dateNow(): Date;
	dateStringToDateTime(date: string): Date
	dateMonthStringToDate(month: string): Date
	dateMonthNumberToDate(month: number, year: number): Date
	dateNowPlusDays(days: number): Date;
	datePlusMinutes(date: Date, minutes: number): Date
	datePlusMonths(date: Date, months: number): Date
	dateNowLessDays(days: number): Date;
	dateHasExpired(expireIn: Date): boolean;
	dateHasExpiredInUnix(date: number): boolean;
	dateNowSubYears(year: number): Date;
	dateIsAtLeastTenMinutesBefore(dateNow: Date, dateAfter: Date): boolean;
	dateIsAfter(dateNow: Date, dateAfter: Date): boolean;
	dateLessDays(date: Date, days: number): Date
	dateDayIsAfterOrEqual(date1: Date, date2: Date): boolean
	dateIsFirstDayOfMonth(date: Date): boolean;
	dateIsLastDayOfMonth(date: Date): boolean;
	setTimeToMidnight(date: Date): Date;
	setTimeToOneMillisecondBeforeMidnight(date: Date): Date;
	setTimeToSixPM(date: Date): Date;
	setSecondsAndMillisecondsToZero(date: Date): Date;
	isToday(date: Date): boolean;
	isYesterday(date: Date): boolean; 
	isCurrentWeek(date: Date): boolean;
}

class DateFnsProvider implements IDateProvider {
	dateNow(): Date {
		const dateNow = new Date()
		return dateNow;
	}
	dateStringToDateTime(date: string): Date {
		const newDate = parseISO(date)
		return newDate
	}
	dateMonthStringToDate(month: string): Date {
		const newDate = parse(month, "MMMM-yyyy", new Date(), { locale: ptBR });
		return newDate
	}
	dateMonthNumberToDate(month: number, year: number): Date {
		const date = parse(`${year}-${month + 1}`, "yyyy-MM", new Date());
		return date
	}
	dateNowPlusDays(days: number): Date {
		const newDatePlusDays = addDays(new Date(), days)
		return newDatePlusDays
	}
	datePlusMinutes(date: Date, minutes: number): Date {
		const newDate = addMinutes(date, minutes)
		return newDate
	}
	datePlusMonths(date: Date, months: number): Date {
		const newDate = addMonths(date, months)
		return newDate
	}
	dateNowLessDays(days: number): Date {
		const newDate = subDays(new Date(), days)
		return newDate
	}
	dateHasExpired(expireIn: Date): boolean {
		const isExpired = isPast(expireIn)
		return isExpired
	}
	dateHasExpiredInUnix(date: number): boolean {
		const isExpired = isPast(fromUnixTime(date));
		return isExpired
	}
	dateNowSubYears(year: number): Date {
		const newDate = subYears(new Date(), year)
		return newDate
	}
	dateIsAtLeastTenMinutesBefore(dateNow: Date, dateAfter: Date): boolean {
		const dateIsBefore = isBefore(dateNow, dateAfter);

		if (!dateIsBefore) {
			return false;
		}

		const minutesDiff = differenceInMinutes(dateNow, dateAfter);
		if (minutesDiff > -10) {
			return false;
		}

		return true;
	}
	dateIsAfter(dateNow: Date, dateAfter: Date): boolean {
		const date = isAfter(dateNow, dateAfter)
		return date
	}
	dateLessDays(date: Date, days: number): Date {
		const dateLess = subDays(date, days);
		return dateLess
	}
	dateDayIsAfterOrEqual(date1: Date, date2: Date): boolean {
		const date1NoHours = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
		const date2NoHours = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

		if (isAfter(date1NoHours, date2NoHours)) {
			return true
		}
		if (isEqual(date1NoHours, date2NoHours)) {
			return true
		}

		return false
	}

	dateIsFirstDayOfMonth(date: Date): boolean {
		const dateIsFirst = isFirstDayOfMonth(date)
		return dateIsFirst
	}

	dateIsLastDayOfMonth(date: Date): boolean {
		const dateIsLat = isLastDayOfMonth(date);
		return dateIsLat
	}

	setTimeToMidnight(date: Date): Date {
		const withHours = setHours(date, 0);
		const withMinutes = setMinutes(withHours, 0);
		const withSeconds = setSeconds(withMinutes, 0);
		const withMilliseconds = setMilliseconds(withSeconds, 0);
		return withMilliseconds;
	}

	setTimeToOneMillisecondBeforeMidnight(date: Date): Date {
		const withHours = setHours(date, 23);
		const withMinutes = setMinutes(withHours, 59);
		const withSeconds = setSeconds(withMinutes, 59);
		const withMilliseconds = setMilliseconds(withSeconds, 999);
		return withMilliseconds;
	}

	setTimeToSixPM(date: Date): Date {
		const withHours = setHours(date, 18);
		const withMinutes = setMinutes(withHours, 0);
		const withSeconds = setSeconds(withMinutes, 0);
		const withMilliseconds = setMilliseconds(withSeconds, 0);
		return withMilliseconds;
	}

	setSecondsAndMillisecondsToZero(date: Date): Date {
		const withSeconds = setSeconds(date, 0);
		const withMilliseconds = setMilliseconds(withSeconds, 0);
		return withMilliseconds;
	}

	isToday(date: Date): boolean {
		return isToday(date);
	}

	isYesterday(date: Date): boolean {
		return isYesterday(date);
	}

	isCurrentWeek(date: Date): boolean {
		return isSameWeek(new Date(), date);
	}
}

export const DateFns = new DateFnsProvider();