import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import MessagesService from "../../../services/http/services/MessagesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useNewMessage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const type = searchParams.get('type');

    const isGeneral = useMemo(() => {
        return type?.toLowerCase() === "general";
    }, [type]);

    async function handleSubmit(message: FormData) {
        try {
            await MessagesService.createMessage(message);

            EmitSuccessAlert('Mensagem cadastrada com sucesso!');

            navigate(`/mensagens${isGeneral ? '?type=general' : ''}`);
        } catch (err) {
            EmitError(err, true, 'newMessage_ijapsdjasdp');
        }
    }

    return {
        handleSubmit,
    }
}