import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Button, Input, InputRef, Space } from "antd";

import { SearchOutlined } from '@ant-design/icons';

import Highlighter from 'react-highlight-words';

import { IAppUser } from "../../interfaces";

import AppUsersService from "../../services/http/services/AppUsersService";
import { EmitError } from "../../utils/EmitError";
import { formatCpf, formatPhone } from "../../utils/Masks";
import { formatDateStringToDateAndTime } from "../../utils/Conversores";
import useAuth from "../../hooks/useAuth";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { removeAcento } from "../../utils/RemoveAcentos";

interface ITableData extends IAppUser {
    key: number | undefined;
}

export default function useAppUsers() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { activeCity } = useAuth();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ITableData, inputColumnName: string, formatter?: (text: string) => string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) =>
            removeAcento(record[dataIndex]!.toString()).toLowerCase().includes(removeAcento(value).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: IAppUser, b: IAppUser) => a.name.localeCompare(b.name),
            showSorterTooltip: false,
            ...getColumnSearchProps('name', 'Nome'),
        },
        {
            title: 'CPF',
            dataIndex: 'cpf',
            key: 'cpf',
            ...getColumnSearchProps('cpf', 'CPF', formatCpf),
        },
        {
            title: 'Telefone',
            dataIndex: 'phone',
            key: 'phone',
            ...getColumnSearchProps('phone', 'Telefone', formatPhone),
        },
        {
            title: 'Data de Cadastro',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_: any, { created_at }: IAppUser) => formatDateStringToDateAndTime(created_at),
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: IAppUser, b: IAppUser) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            showSorterTooltip: false,
        },
    ]

    const setTableDataFromRequest = useCallback((data: Array<IAppUser>) => {
        const newData = data.map((user) => ({ ...user, key: user.id }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        if (!activeCity) {
            return;
        }

        const controller = new AbortController();

        async function loadAppUsers() {
            try {
                setIsLoading(true);

                const appUsersList = await AppUsersService.listAppUsers(activeCity?.id!, controller.signal);

                setTableDataFromRequest(appUsersList);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'appUsers_aosdpasdpasd');
                setIsLoading(false);
            }
        }

        loadAppUsers();

        return () => {
            controller.abort();
        }
    }, [activeCity, setTableDataFromRequest]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleClickCreateNewAppUser = useCallback(() => {
        navigate('/usuarios-aplicativo/criar');
    }, [navigate]);

    const handleClickEditAppUser = useCallback(() => {
        navigate(`/usuarios-aplicativo/editar/${selectedIds[0]}`);
    }, [navigate, selectedIds]);

    const handleClickViewAppUserInvoices = useCallback(() => {
        navigate(`/usuarios-aplicativo/${selectedIds[0]}/notas`);
    }, [navigate, selectedIds]);

    const handleClickViewAppUser = useCallback(() => {
        navigate(`/usuarios-aplicativo/visualizar/${selectedIds[0]}`);
    }, [navigate, selectedIds]);

    const handleClickViewAppUserTickets = useCallback(() => {
        navigate(`/usuarios-aplicativo/${selectedIds[0]}/bilhetes`);
    }, [navigate, selectedIds]);

    const handleClickViewAppUserVouchers = useCallback(() => {
        navigate(`/usuarios-aplicativo/${selectedIds[0]}/vouchers`);
    }, [navigate, selectedIds]);

    return {
        isLoading,
        handleClickCreateNewAppUser,
        handleClickEditAppUser,
        handleClickViewAppUser,
        handleClickViewAppUserInvoices,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        handleClickViewAppUserTickets,
        handleClickViewAppUserVouchers,
    }
}