import { DateFns } from "../dateFns";

export const normalizeCnpjNumber = (value: String | undefined) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export const removeCnpjMask = (value: String) => {
    if (!value) return '';

    return value.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
}

export function formatPhone(phoneNumber: string) {
    if (!phoneNumber) {
        return '';
    }
    
    return phoneNumber
        .replace(/\D/g, '')
        .replace(/^(\d{2})\B/, '($1) ')
        .replace(/(\d{1})?(\d{4})(\d{4})/, '$1$2-$3');
}

export function formatCpf(cpf: string) {
    if (!cpf) {
        return '';
    }

    return cpf
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export const formatCep = (cep: string) => {
    if (!cep) {
        return "";
    }

    return cep
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2');
}

export function onlyNumbers(value: string | undefined | null) {
    if (!value) {
        return "";
    }

    return value.replace(/\D/g, '');
}

export function formatCurrency(value: string | number) {
    if (!value || value === 'NaN') {
        return '';
    }

    try {
        return Number(value).toLocaleString("PT-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } catch (e) {
        return '';
    }
}

export function formatPoints(value: string | number) {
    if (!value || value === 'NaN') {
        return '0,00';
    }

    try {
        return Number(value).toLocaleString("PT-BR", { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } catch (e) {
        return '0,00';
    }
}

export function formatCurrencyInput(value: string) {
    if (!value) {
        return (0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'code'
        }).replace('BRL', '').trim();
    }
    // Remove qualquer caractere que não seja dígito numérico
    const numeroLimpo = value.replace(/\D/g, '');

    if (!numeroLimpo) {
        return (0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'code'
        }).replace('BRL', '').trim();
    }

    // Converte para número
    const numero = parseFloat(numeroLimpo) / 100;

    // Formata para reais com duas casas decimais e separador de milhar
    const numeroFormatado = numero.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'code'
    }).replace('BRL', '').trim();

    // Define o valor formatado de volta no campo de entrada
    return numeroFormatado;
}

export function formatMessageDate(date: Date): string {
    const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
    ]

    const days = [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
    ]

    if (DateFns.isToday(date)) {
        return `Hoje ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    }

    if (DateFns.isYesterday(date)) {
        return `Ontem ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    }

    if (DateFns.isCurrentWeek(date)) {
        return `${days[date.getMonth()]} ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    }

    const today = new Date();

    if (today.getFullYear() === date.getFullYear()) {
        return `${String(date.getDate()).padStart(2, "0")} ${months[date.getMonth()]}`;
    }

    return `${String(date.getDate()).padStart(2, "0")} ${months[date.getMonth()]} ${date.getFullYear()}`;
}


