import { Divider, Row } from "antd";
import Title from "antd/es/typography/Title";

interface IProps {
    title: string,
    showDivider?: boolean,
}

export default function FieldsGroupTitle({ title, showDivider = false }: IProps) {
    return (
        <Row>
            {
                showDivider && (
                    <Divider
                        style={{
                            marginTop: 0,
                            borderWidth: 3,
                        }}
                    />
                )
            }
            <Title
                level={2}
            >
                {title}
            </Title>
        </Row>
    )
}