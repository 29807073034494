import { IGiveaway } from '../../../interfaces';

class GiveawayMapper {
    toPersistence(domainGiveaway: IGiveaway): any {
        return {
            id: domainGiveaway.id,
            description: domainGiveaway.description,
            start_period: domainGiveaway.period_beginning,
            end_period: domainGiveaway.period_ending,
            date_of_raffle: domainGiveaway.date,
            dead_line_importing: domainGiveaway.import_deadline,
            county_id: domainGiveaway.city_id,
            is_valid: domainGiveaway.is_valid,
        }
    }

    toDomain(persistenceGiveaway: any): IGiveaway {
        return {
            id: persistenceGiveaway.id,
            description: persistenceGiveaway.description,
            period_beginning: persistenceGiveaway.start_period,
            period_ending: persistenceGiveaway.end_period,
            date: persistenceGiveaway.date_of_raffle,
            import_deadline: persistenceGiveaway.dead_line_importing,
            city: persistenceGiveaway.county,
            is_valid: persistenceGiveaway.is_valid,
            awards: persistenceGiveaway.awards,
            file_with_participants: persistenceGiveaway.file_with_participants,
            file_with_winners: persistenceGiveaway.file_with_winners,
            hash_with_participants: persistenceGiveaway.hash_with_participants,
            hash_with_winners: persistenceGiveaway.hash_with_winners,
            status: persistenceGiveaway.status,
            participants_file_generated_at: persistenceGiveaway.date_emitted_file_with_participants,
            winners_file_uploaded_at: persistenceGiveaway.date_uploded_file_with_winners,
            panel_user_generated_participants_file_id: persistenceGiveaway.panel_user_emitted_participants_id,
            panel_user_uploaded_winners_file_id: persistenceGiveaway.panel_user_uploded_winners_id,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GiveawayMapper();
