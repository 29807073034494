import { Button, Col, Form, Row } from "antd";
import { Controller } from "react-hook-form";
import Dragger from "antd/es/upload/Dragger";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import Text from "antd/es/typography/Text";

import { InboxOutlined } from '@ant-design/icons';

import { ICity, ICityTerms } from "../../../../../../interfaces";
import { ICityFormStepProps, IChangeFileProps } from "../../../../interfaces";

import useFilesStep from "./useFilesStep";
import FieldsGroupTitle from "../../../FieldsDivider";
import DatePicker from "../../../../../../components/DatePicker";
import UpcomingTermsAlert from "../../../UpcomingTermsAlert";

interface IProps extends ICityFormStepProps {
    colInputs: any;
    acceptedImageTypes: Array<string>;
    acceptedFileTypes: Array<string>;
    lightLogoImageUrl: string | ArrayBuffer | null | undefined;
    darkLogoImageUrl: string | ArrayBuffer | null | undefined;
    termsOfUseFileName: string | undefined;
    onChangeLightLogoImage: (props: IChangeFileProps) => void;
    onChangeDarkLogoImage: (props: IChangeFileProps) => void;
    onChangeTermsOfUseFile: (props: IChangeFileProps) => void;
    hasTermsOfUse: boolean;
    city: ICity | undefined;
    needsToInformTermsOfUseEffectiveDate: boolean;
    upcomingTerms: ICityTerms | null | undefined;
}

export default function FilesStep({
    colInputs,
    errors,
    control,
    acceptedImageTypes,
    acceptedFileTypes,
    lightLogoImageUrl,
    darkLogoImageUrl,
    termsOfUseFileName,
    hasTermsOfUse,
    onChangeLightLogoImage,
    onChangeDarkLogoImage,
    onChangeTermsOfUseFile,
    city,
    needsToInformTermsOfUseEffectiveDate,
    upcomingTerms,
}: IProps) {
    const {
        handleClickViewTermsOfUse,
        dummyRequest,
        colInputsTermsOfUse,
    } = useFilesStep(city, needsToInformTermsOfUseEffectiveDate);

    return (
        <>
            <FieldsGroupTitle
                title="Regulamento"
            />

            <Row
                gutter={16}
            >
                <Col
                    {...colInputs}
                >
                    <Row
                        gutter={8}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Col
                            {...colInputsTermsOfUse}
                        >
                            <Form.Item
                                label="Termos de Uso"
                                validateStatus={errors.terms_of_use ? 'error' : 'success'}
                                help={errors.terms_of_use?.message}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Controller
                                    name="terms_of_use"
                                    control={control}
                                    render={({ field: { value, onChange, ...field } }) => (
                                        <Dragger
                                            {...field}
                                            showUploadList={false}
                                            customRequest={dummyRequest}
                                            onChange={(e: UploadChangeParam<UploadFile>) => onChangeTermsOfUseFile({ onChangeHookForm: onChange, file: e.file })}
                                            accept={acceptedFileTypes.toString()}
                                        >
                                            {
                                                termsOfUseFileName && typeof termsOfUseFileName === 'string' ? (
                                                    <Text
                                                        style={{
                                                            margin: '0 8px',
                                                        }}
                                                        className="ant-upload-text"
                                                    >
                                                        {termsOfUseFileName}
                                                    </Text>
                                                )
                                                    : (
                                                        <>
                                                            <p
                                                                className="ant-upload-drag-icon"
                                                            >
                                                                <InboxOutlined />
                                                            </p>
                                                            <Text
                                                                style={{
                                                                    margin: '0 8px',
                                                                }}
                                                                className="ant-upload-text"
                                                            >
                                                                Clique ou arraste o arquivo para fazer o upload
                                                            </Text>
                                                        </>
                                                    )
                                            }
                                        </Dragger>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        
                        {
                            needsToInformTermsOfUseEffectiveDate && (
                                <Col
                                    {...colInputsTermsOfUse}
                                >
                                    <Form.Item
                                        label="Data de vigência"
                                        required
                                        validateStatus={errors.terms_of_use_effective_date ? 'error' : 'success'}
                                        help={errors.terms_of_use_effective_date?.message}
                                        tooltip="Data que o novos Termos de Uso passarão a ser válidos."
                                    >
                                        <Controller
                                            name="terms_of_use_effective_date"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    format={(date) => date.toLocaleDateString('PT-BR')}
                                                    value={field.value ? new Date(field.value) : undefined}
                                                    onChange={field.onChange}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                    </Row>
                    {
                        hasTermsOfUse && (
                            <Row
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    marginBottom: 16,
                                }}
                            >
                                <Button
                                    type="primary"
                                    onClick={handleClickViewTermsOfUse}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    Visualizar Termos de Uso atual
                                </Button>
                            </Row>
                        )
                    }

                    {
                        upcomingTerms && (
                            <UpcomingTermsAlert
                                title="Próximos termos de uso a entrar em vigência"
                                terms={upcomingTerms}
                            />
                        )
                    }
                </Col>
            </Row>

            <FieldsGroupTitle
                title="Logos"
                showDivider
            />

            <Row
                gutter={16}
            >
                <Col
                    {...colInputs}
                >
                    <Form.Item
                        label="Logo (modo claro)"
                        validateStatus={errors.logo_light ? 'error' : 'success'}
                        help={errors.logo_light?.message}
                    >
                        <Controller
                            name="logo_light"
                            control={control}
                            render={({ field: { value, onChange, ...field } }) => (
                                <Dragger
                                    {...field}
                                    showUploadList={false}
                                    customRequest={dummyRequest}
                                    onChange={(e: UploadChangeParam<UploadFile>) => onChangeLightLogoImage({ onChangeHookForm: onChange, file: e.file })}
                                    accept={acceptedImageTypes.toString()}
                                >
                                    {
                                        lightLogoImageUrl && typeof lightLogoImageUrl === 'string' ? (
                                            <img
                                                src={lightLogoImageUrl}
                                                alt="logo modo claro"
                                                style={{
                                                    width: '50%',
                                                }}
                                            />
                                        )
                                            : (
                                                <>
                                                    <p
                                                        className="ant-upload-drag-icon"
                                                    >
                                                        <InboxOutlined />
                                                    </p>
                                                    <Text
                                                        style={{
                                                            margin: '0 8px',
                                                        }}
                                                        className="ant-upload-text"
                                                    >
                                                        Clique ou arraste o arquivo para fazer o upload
                                                    </Text>
                                                </>
                                            )
                                    }
                                </Dragger>
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col
                    {...colInputs}
                >
                    <Form.Item
                        label="Logo (modo escuro)"
                        validateStatus={errors.logo_dark ? 'error' : 'success'}
                        help={errors.logo_dark?.message}
                    >
                        <Controller
                            name="logo_dark"
                            control={control}
                            render={({ field: { value, onChange, ...field } }) => (
                                <Dragger
                                    {...field}
                                    showUploadList={false}
                                    customRequest={dummyRequest}
                                    onChange={(e: UploadChangeParam<UploadFile>) => onChangeDarkLogoImage({ onChangeHookForm: onChange, file: e.file })}
                                    accept="image/png, image/jpg, image/jpeg, image/webp"
                                >
                                    {
                                        darkLogoImageUrl && typeof darkLogoImageUrl === 'string' ? (
                                            <img
                                                src={darkLogoImageUrl}
                                                alt="logo modo escuro"
                                                style={{
                                                    width: '50%',
                                                }}
                                            />
                                        )
                                            : (
                                                <>
                                                    <p
                                                        className="ant-upload-drag-icon"
                                                    >
                                                        <InboxOutlined />
                                                    </p>
                                                    <Text
                                                        style={{
                                                            margin: '0 8px',
                                                        }}
                                                        className="ant-upload-text"
                                                    >
                                                        Clique ou arraste o arquivo para fazer o upload
                                                    </Text>
                                                </>
                                            )
                                    }

                                </Dragger>
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}