import { Alert, Button, Divider, Row } from "antd";

import { LeftOutlined } from '@ant-design/icons';

import Title from 'antd/es/typography/Title';

import Table from "../../../../components/Table";
import useInvoicesGroupedByMonth from "./useInvoicesGroupedByMonth";

export default function InvoicesGroupedByMonth() {
    const {
        columns,
        appUser,
        isLoading,
        tableData,
        onClickTableRow,
        rowSelection,
        totalProcessingInvoices,
        selectedKeys,
        handleClickViewInvoices,
        handleClickGoBack,
    } = useInvoicesGroupedByMonth();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                    alignItems: 'center',
                }}
            >
                <Button
                    type="link"
                    onClick={handleClickGoBack}
                    style={{
                        marginRight: 8,
                    }}
                >
                    <LeftOutlined />
                </Button>

                <Title
                    level={3}
                    style={{
                        margin: 0,
                    }}
                >
                    {appUser?.name}
                </Title>

                <Divider type='vertical' style={{ width: 3, height: 16 }} />

                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickViewInvoices}
                    disabled={selectedKeys.length !== 1}
                >
                    Ver notas
                </Button>
            </div>

            {
                totalProcessingInvoices !== 0 ? (
                    <Row
                        style={{
                            marginTop: 50,
                            marginBottom: 12,
                        }}
                    >
                        <Alert
                            type={'info'}
                            message={`Total de notas em processamento: ${totalProcessingInvoices}`}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Row>
                ) : null
            }

            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
                style={{
                    marginTop: totalProcessingInvoices === 0 ? 50 : 0,
                }}
            />
        </>
    )
}