import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { ICity } from "../../../interfaces";

import CitiesService from "../../../services/http/services/CitiesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";
import useAuth from "../../../hooks/useAuth";

export default function useEditCity() {
    const { id } = useParams();
    const [city, setCity] = useState<ICity>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { activeCity, setUserActiveCity, updateUserCityOnStorage } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadCity() {
            try {
                setIsLoading(true);
                const cityData = await CitiesService.getCityById(Number(id), controller.signal);

                setCity(cityData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editCity_asdashiasdas');

                navigate('/municipios');
            }
        }

        loadCity();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(city: FormData) {
        try {
            const updatedCity = await CitiesService.updateCity(Number(id), city);

            // se usuário editou cidade que está logado, atualiza os dados
            if (Number(id) === activeCity?.id && updatedCity) {
                setUserActiveCity(updatedCity);
            }

            // Se usuário estiver vinculado a cidade editada, atualiza os dados dela no local storage
            updateUserCityOnStorage(updatedCity);

            EmitSuccessAlert('Município atualizado com sucesso!');

            navigate('/municipios');
        } catch (err) {
            EmitError(err, true, 'editCity_iashdausda');
        }
    }

    return {
        isLoading,
        city,
        handleSubmit
    }
}