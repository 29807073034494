export default function RegainAccessDocuments() {
    return (
        <div
            style={{
                padding: '32px 48px',
                color: '#212121',
                background: '#FFFFFF',
                minHeight: '100vh',
            }}
        >
            <h1
                style={{
                    fontWeight: 'bolder',
                }}
            >
                Nota Município - Recuperação de acesso
            </h1>

            <section>
                <h2
                    id="introducao"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    1 - Documetos
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        1.1 Para iniciar o processo de recuperação de acesso, o usuário deverá fornecer o seu número de telefone anterior e enviar imagens de um documento de identidade válido.
                    </p>

                    <p>
                        1.2 As imagens enviadas terão a finalidade exclusiva de assegurar a autenticidade da solicitação, garantindo que a pessoa que está requisitando a recuperação de acesso seja, de fato, o usuário associado ao número de telefone fornecido. A privacidade e a segurança das informações serão estritamente mantidas durante todo o processo.
                    </p>
                </div>
            </section>
        </div>
    )
}