import { IPanelUser } from '../../../interfaces';

class PanelUserMapper {
    toPersistence(domainPanelUser: IPanelUser): any {
        return {
            id: domainPanelUser.id,
            name: domainPanelUser.name,
            email: domainPanelUser.email,
            password: domainPanelUser.password,
            role_id: domainPanelUser.role_id,
            county_ids: domainPanelUser.city_ids
        }
    }

    toDomain(persistencePanelUser: any): IPanelUser {
        return {
            id: persistencePanelUser.id,
            name: persistencePanelUser.name,
            email: persistencePanelUser.email,
            password: persistencePanelUser.password,
            role: persistencePanelUser.role,
            cities: persistencePanelUser.counties,
            first_access: persistencePanelUser.first_access,
            last_access: persistencePanelUser.last_access,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PanelUserMapper();
