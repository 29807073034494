import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { ICategory } from "../../../../interfaces";

export default function useCategoryForm(
    onSubmit: (category: ICategory) => Promise<void>,
    category: ICategory | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const handleSubmitForm: SubmitHandler<ICategory> = async (data) => {
        setIsSubmiting(true);

        await onSubmit(data);

        setIsSubmiting(false);
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<ICategory>({
        defaultValues: {
            name: category?.name || '',
            description: category?.description || '',
        }
    });

    return {
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors
    }
}