import { Controller } from 'react-hook-form';
import { Button, Divider, Form, Input } from 'antd';

import useVouchers from './useVouchers';
import Table from '../../components/Table';

export default function Vouchers() {
    const {
        handleSubmitForm,
        handleSubmit,
        errors,
        control,
        columns,
        isLoading,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        handleClickViewVoucher,
    } = useVouchers();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                }}
            >
                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={handleClickViewVoucher}
                    disabled={selectedIds.length !== 1}
                >
                    Visualizar
                </Button>

                <Form
                    onFinish={handleSubmit(handleSubmitForm)}
                    autoComplete='off'
                    layout='horizontal'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: 2,
                    }}
                >

                    <Divider type='vertical' style={{ width: 3, height: '60%', }} />
                    <Form.Item
                        validateStatus={errors.redemption_code ? 'error' : 'success'}
                        help={errors.redemption_code?.message}
                    >
                        <Controller
                            name="redemption_code"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Por favor, digite o código do Voucher!' },
                                minLength: { value: 6, message: 'Por favor, digite um código válido!' },
                                maxLength: { value: 6, message: 'Por favor, digite um código válido!' },
                            }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder='Buscar Voucher pelo código'
                                    onChange={(e) => field.onChange(e.target.value.replace(/\D/g, ''))}
                                    maxLength={6}
                                />
                            )}
                        />
                    </Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        style={{
                            marginLeft: 8,
                        }}
                    >
                        Buscar
                    </Button>
                </Form>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
            >
            </Table>
        </>
    )
}
