import { Button } from 'antd';

import { LeftOutlined } from '@ant-design/icons';

import useGiveawayWinners from './useGiveawayWinners';
import Table from '../../../components/Table';
import ConfirmValidateAwardModal from './components/ConfirmValidateAwardModal';

export default function GiveawayWinners() {
    const {
        columns,
        isLoading,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        handleClickViewAppUser,
        handleClickGoBack,
        handleClickValidateAward,
        invalidating,
        isConfirmValidateAwardModalVisible,
        handleValidateFinished,
        handleCancelValidateAward,
        selectedAward,
        canValidadeOrInvalidateAward,
    } = useGiveawayWinners();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                }}
            >
                <Button
                    type="link"
                    onClick={handleClickGoBack}
                    style={{
                        marginRight: 8,
                    }}
                >
                    <LeftOutlined />
                </Button>

                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={() => handleClickValidateAward(true)}
                    disabled={!canValidadeOrInvalidateAward}
                >
                    Validar prêmio
                </Button>
                <Button
                    type="primary"
                    danger
                    style={{ margin: 2 }}
                    onClick={() => handleClickValidateAward(false)}
                    disabled={!canValidadeOrInvalidateAward}
                >
                    Invalidar prêmio
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickViewAppUser}
                    disabled={selectedIds.length !== 1}
                >
                    Ver usuário
                </Button>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
            />

            {
                isConfirmValidateAwardModalVisible && (
                    <ConfirmValidateAwardModal
                        open={true}
                        award={selectedAward}
                        invalidating={invalidating}
                        onValidateFinished={handleValidateFinished}
                        onCancel={handleCancelValidateAward}
                    />
                )
            }
        </>
    )
}
