import { IRole } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import RoleMapper from '../mappers/RoleMapper';
import { urlHttpGov } from '../../urls';

class RolesService {
    private baseURL: string = `${urlHttpGov}/panel/user/roles`;

    async listRoles(signal: AbortSignal) {
        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        return data.map((role: any) => RoleMapper.toDomain(role));
    }

    createRole(role: IRole) {
        const persistenceRole = RoleMapper.toPersistence(role);

        return privateHttpClient.post(this.baseURL, persistenceRole);
    }

    async getRoleById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return RoleMapper.toDomain(data);
    }

    updateRole(id: number, role: IRole) {
        const persistenceRole = RoleMapper.toPersistence(role);

        return privateHttpClient.patch(`${this.baseURL}/${id}`, persistenceRole);
    }

    deleteRole(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RolesService();
