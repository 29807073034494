import RoleForm from '../components/RoleForm';
import Loading from '../../../components/Loading';
import useEditRole from './useEditRole';

export default function EditRole() {
    const {
        isLoading,
        handleSubmit,
        role
    } = useEditRole();

    return (
        <Loading loading={isLoading} >
            <RoleForm action='Editar' role={role} key={role?.id} onSubmit={handleSubmit} />
        </Loading>
    )
}
