
export enum ECityTaxStatus {
    processing = "Em análise",
    approved = "Aprovado",
    warning = "Alerta",
    error = "Erro",
}

export enum EInvoiceStatus {
    processing = "Em processamento",
    completed = "Concluído",
    warning = "Alerta",
    error = "Erro",
}

export enum EAwardStatus {
    waiting = "A sortear",
    underAnalysis = "Em análise",
    valid = "Válido",
    invalid = "Inválido",
}

export enum EGiveawayStatus {
    ON_HOLD = "Em espera", // before period beginning
    FORCE = "Vigente", // is under period
    IMPORT_PERIOD = "Periodo de importação", // before deadline importing
    WAITING_RAFFLE = "Aguardando sorteio", // after deadline importing
    UNDER_VERIFICATION = "Em verificação", // checking awards
    FINISHED = "Finalizado",
}

export enum EModule {
    REWARD = "reward",
    TAXES = "taxes",
    INVOICE_NFC = "invoice_NFC",
    INVOICE_NFS = "invoice_NFS",
}