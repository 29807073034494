import { Controller } from "react-hook-form";
import { Col, Form, Modal, Select } from "antd";

import useAuth from "../../hooks/useAuth";

import useSelectCityModal from "./useSelectCityModal";

export default function SelectCityModal() {
    const {
        control,
        errors,
        handleSubmit,
        handleSubmitForm,
        cities,
        handleConfirmSelectCity,
        isSubmiting,
        refForm,
        isValid,
        filterOption,
    } = useSelectCityModal();

    const { activeCity, handleCloseSelectCityModal } = useAuth();

    const { isSelectCityModalOpen } = useAuth();

    return (
        <Modal
            open={isSelectCityModalOpen}
            okText="Confirmar"
            cancelText="Cancelar"
            onOk={handleConfirmSelectCity}
            onCancel={handleCloseSelectCityModal}
            maskClosable={false}
            closable={false}
            okButtonProps={{
                disabled: isSubmiting || !isValid,
                loading: isSubmiting
            }}
            cancelButtonProps={{
                hidden: !activeCity,
                disabled: isSubmiting,
            }}
        >
            <Form
                layout='vertical'
                onFinish={handleSubmit(handleSubmitForm)}
                ref={refForm}
            >
                <Col
                    span={24}
                >
                    <Form.Item
                        label="Município"
                        required
                        validateStatus={errors.cityId ? 'error' : 'success'}
                        help={errors.cityId?.message}
                    >
                        <Controller
                            name="cityId"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Por favor, selecione o Município que deseja acessar!' },
                            }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    disabled={!cities}
                                    loading={!cities}
                                    options={cities?.map((city) => ({ value: city.id, label: `${city.city} - ${city.uf}`, key: city.id }))}
                                    placeholder='Selecione o Município que deseja acessar'
                                    filterOption={filterOption}
                                    showSearch
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Form>
        </Modal>
    )
}