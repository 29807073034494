import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps } from "antd";
import {
    FileTextOutlined,
    LogoutOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
    EnvironmentOutlined,
    BulbOutlined,
    GiftOutlined,
    BarsOutlined,
    SolutionOutlined,
    UnlockOutlined,
    TagOutlined,
    PercentageOutlined,
    MessageOutlined,
} from '@ant-design/icons';

import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ThemeContext } from "../../contexts/ThemeContext";
import useModules from "../../hooks/useModules";
import { EModule } from "../../enums";

type MenuItem = Required<MenuProps>['items'][number];

export default function useLayoutAdmin() {
    const navigate = useNavigate();
    const { signOut } = useAuth();
    const { getUser, getUserId } = useLocalStorage();

    const { updateTheme } = useContext(ThemeContext);
    const { handleOpenSelectCityModal, user } = useAuth();
    const location = useLocation();
    const { cityModules } = useModules();

    const [collapsed, setCollapsed] = useState(false);
    const [visibleDrawer, setvisibleDrawer] = useState(false);
    const [visibleDrawerColor, setVisibleDrawerColor] = useState(false);
    const [posicaoMenu, setPosicaoMenu] = useState('');
    const [abrirMenuLateral, setAbrirMenuLateral] = useState<Array<string>>(['']);
    const [itemsMenuPainelState, setItemsMenuPainelState] = useState<MenuItem[]>([]);
    const [collapsedWidth, setCollapsedWidth] = useState<number>(77);
    const [itemsMenuConfiguracaoState, setItemsMenuConfiguracaoState] = useState<MenuItem[]>([]);
    const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');

    const getItem = useCallback((
        permissao: boolean,
        classNamePopUpDrawer: string,
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem => {
        let popupClassName = classNamePopUpDrawer;
        return {
            key,
            popupClassName,
            icon,
            children,
            label,
            type,
            permissao: permissao.toString()
        } as MenuItem;
    }, []);

    const onCloseDrawer = useCallback((): void => {
        setvisibleDrawer(false);
    }, []);

    const hasPermissionAndModule = useCallback((permissions: Array<string>, module?: EModule) => {
        if (module && !cityModules.includes(module)) {
            return false;
        }
        return !!user?.permissions?.find((p) => permissions.includes(p.name));
    }, [user, cityModules]);

    const clickNavigateMenuLateral = useCallback((rota: string): void => {
        navigate(rota);
        setvisibleDrawer(false);
    }, [navigate]);

    const onCollapse = useCallback((collapsedVal: boolean): void => {
        if (!collapsed) {
            setCollapsed(collapsedVal);
            setCollapsedWidth(77);
        } else {
            setCollapsed(collapsedVal);
            setCollapsedWidth(0);
        }
    }, [collapsed]);

    const onAbrirDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(true);
    }, []);

    const onCloseDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(false);
    }, []);

    const toggle = useCallback((): void => {
        if (collapsedWidth === 0) {
            setvisibleDrawer(!visibleDrawer);
        } else {
            setCollapsed(!collapsed);
        }
    }, [collapsedWidth, visibleDrawer, collapsed]);

    const clickMenuDireitoUsuarios = useCallback((): void => {
        navigate("/usuarios");
    }, [navigate]);

    const clickMenuPerfilUsuario = useCallback((): void => {
        let id = getUserId();
        navigate("/perfil/" + id);
    }, [getUserId, navigate]);

    const clickMenuDireitoChangeCity = useCallback((): void => {
        handleOpenSelectCityModal();
    }, [handleOpenSelectCityModal]);

    const clickMenuDireitoSair = useCallback((): void => {
        signOut();
    }, [signOut]);

    const onClickMenuConfiguracoes = useCallback((e: any): void => {
        switch (e.key) {
            case 'perfil1':
                clickMenuPerfilUsuario();
                break;
            case 'usuarios1':
                clickMenuDireitoUsuarios();
                break;
            case 'tema1':
                onAbrirDrawerColor();
                break;
            case 'municipio1':
                clickMenuDireitoChangeCity();
                break;
            case 'sair1':
                clickMenuDireitoSair();
                break;
            default:
                break;
        }

    }, [clickMenuDireitoSair, clickMenuDireitoUsuarios, clickMenuPerfilUsuario, clickMenuDireitoChangeCity, onAbrirDrawerColor]);

    const onchangeRadioTheme = useCallback((value: any): void => {
        updateTheme(value.target.value)
    }, [updateTheme]);

    const clickMenuPrincipal = useCallback(({ key }: any): void => {

        setPosicaoMenu(key)

        switch (key) {
            case 'municipios':
                clickNavigateMenuLateral("/municipios");
                break;
            case 'sorteios':
                clickNavigateMenuLateral("/sorteios");
                break;
            case 'recompensas':
                clickNavigateMenuLateral("/recompensas");
                break;
            case 'categorias':
                clickNavigateMenuLateral("/categorias");
                break;
            case 'impostos':
                clickNavigateMenuLateral("/impostos");
                break;
            case 'vouchers':
                clickNavigateMenuLateral("/vouchers");
                break;
            case 'mensagens':
                clickNavigateMenuLateral('/mensagens');
                break;
            case 'perfis':
                clickNavigateMenuLateral('/perfis');
                break;
            case 'usuarios-painel':
                clickNavigateMenuLateral('/usuarios-painel');
                break;
            case 'tipos-de-premios':
                clickNavigateMenuLateral('/tipos-de-premios');
                break;
            case 'usuarios-aplicativo':
                clickNavigateMenuLateral('/usuarios-aplicativo');
                break;
            case 'mensagens-gerais':
                clickNavigateMenuLateral('/mensagens?type=general');
                break;
            case 'recuperacao-acesso':
                clickNavigateMenuLateral('/recuperacao-acesso');
                break;
            default:
                break;
        }
    }, [clickNavigateMenuLateral]);

    useEffect(() => {
        let dataUsuario = getUser();

        if (dataUsuario) {
            let arrayUrl = dataUsuario.name.split(" ")
            setNomeUsuarioLogado(arrayUrl[0])
        }

        let isDrawerOpned = '';

        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                isDrawerOpned = 'dspclassMenuPopUpDraweer';
                setCollapsedWidth(0);
            }
        }

        const hasAccessToMoreThanOneCity = Boolean(user?.cities && user.cities.length > 1);

        const itemsMenuConfiguracao: MenuItem[] = [
            getItem(true, isDrawerOpned, 'Tema', 'tema1', <FileTextOutlined />),
            getItem(hasAccessToMoreThanOneCity, isDrawerOpned, 'Alterar Município', 'municipio1', <EnvironmentOutlined />),
            getItem(true, isDrawerOpned, 'Sair', 'sair1', <LogoutOutlined />),
        ];

        const itemsMenuPainel: MenuItem[] = [
            getItem(hasPermissionAndModule(['manage_user_app']), isDrawerOpned, 'Usuários Aplicativo', 'usuarios-aplicativo', <UserOutlined />),
            getItem(hasPermissionAndModule(['manage_raffle']), isDrawerOpned, 'Sorteios', 'sorteios', <BulbOutlined />),
            getItem(hasPermissionAndModule(['manage_user_app'], EModule.TAXES), isDrawerOpned, 'Impostos', 'impostos', <PercentageOutlined />),
            getItem(hasPermissionAndModule(['manage_reward'], EModule.REWARD), isDrawerOpned, 'Vouchers', 'vouchers', <TagOutlined />),
            getItem(hasPermissionAndModule(['manage_reward'], EModule.REWARD), isDrawerOpned, 'Recompensas', 'recompensas1', <GiftOutlined />, [
                getItem(hasPermissionAndModule(['manage_reward'], EModule.REWARD), isDrawerOpned, 'Listagem', 'recompensas', <GiftOutlined />),
                getItem(hasPermissionAndModule(['manage_reward'], EModule.REWARD), isDrawerOpned, 'Categorias', 'categorias', <BarsOutlined />),
            ]),
            getItem(hasPermissionAndModule(['manage_user_app']), isDrawerOpned, 'Mensagens', 'mensagens', <MessageOutlined />),
            getItem(hasPermissionAndModule(['manage_county']), isDrawerOpned, 'Municípios', 'municipios', <EnvironmentOutlined />),
            getItem(hasPermissionAndModule(['manage_user_app']), isDrawerOpned, 'Solicitações Rec. Acesso', 'recuperacao-acesso', <UnlockOutlined />),
            getItem(hasPermissionAndModule(['manage_config']), isDrawerOpned, 'Configurações', 'configuracoes1', <SettingOutlined />, [
                getItem(hasPermissionAndModule(['manage_config']), isDrawerOpned, 'Perfis', 'perfis', <SolutionOutlined />),
                getItem(hasPermissionAndModule(['manage_config']), isDrawerOpned, 'Usuários Painel', 'usuarios-painel', <TeamOutlined />),
                getItem(hasPermissionAndModule(['manage_config']), isDrawerOpned, 'Mensagens Gerais', 'mensagens-gerais', <MessageOutlined />),
                getItem(hasPermissionAndModule(['manage_config']), isDrawerOpned, 'Tipos de Prêmios', 'tipos-de-premios', <BarsOutlined />),
            ]),
        ];

        const menuConfig = itemsMenuConfiguracao.filter((element: any) => element?.permissao === 'true');
        setItemsMenuConfiguracaoState(menuConfig);

        const menuPainel = itemsMenuPainel.filter((element: any) => element?.permissao === 'true');
        setItemsMenuPainelState(menuPainel);

    }, [getUser, getItem, user, hasPermissionAndModule]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let arrayUrl = window.location.pathname.split("/");

            switch (arrayUrl[1]) {
                case "municipios":
                    setPosicaoMenu('municipios')
                    setAbrirMenuLateral([''])
                    break;
                case "sorteios":
                    setPosicaoMenu('sorteios')
                    setAbrirMenuLateral([''])
                    break;
                case "impostos":
                    setPosicaoMenu('impostos')
                    setAbrirMenuLateral([''])
                    break;
                case "vouchers":
                    setPosicaoMenu('vouchers')
                    setAbrirMenuLateral([''])
                    break;
                case "recompensas":
                    setPosicaoMenu('recompensas')
                    setAbrirMenuLateral(['recompensas1'])
                    break;
                case "mensagens":
                    const params = new URL(document.location.toString()).searchParams;
                    const type = params.get('type');

                    if (type?.toLowerCase() === "general") {
                        setPosicaoMenu('mensagens-gerais')
                        setAbrirMenuLateral(['configuracoes1'])
                        break;
                    }

                    setPosicaoMenu('mensagens')
                    setAbrirMenuLateral([''])
                    break;
                case "categorias":
                    setPosicaoMenu('categorias')
                    setAbrirMenuLateral(['recompensas1'])
                    break;
                case "usuarios-aplicativo":
                    setPosicaoMenu('usuarios-aplicativo')
                    setAbrirMenuLateral([''])
                    break;
                case "recuperacao-acesso":
                    setPosicaoMenu('recuperacao-acesso')
                    setAbrirMenuLateral([''])
                    break;
                case "perfis":
                    setPosicaoMenu('perfis')
                    setAbrirMenuLateral(['configuracoes1'])
                    break;
                case "usuarios-painel":
                    setPosicaoMenu('usuarios-painel')
                    setAbrirMenuLateral(['configuracoes1'])
                    break;
                case "tipos-de-premios":
                    setPosicaoMenu('tipos-de-premios')
                    setAbrirMenuLateral(['configuracoes1'])
                    break;
                default:
                    setPosicaoMenu('')
                    setAbrirMenuLateral([''])
                    break;
            }
        }
    }, [location.pathname]);

    return {
        onCloseDrawer,
        visibleDrawer,
        clickMenuPrincipal,
        posicaoMenu,
        itemsMenuPainelState,
        collapsedWidth,
        onCollapse,
        collapsed,
        setAbrirMenuLateral,
        abrirMenuLateral,
        onClickMenuConfiguracoes,
        toggle,
        itemsMenuConfiguracaoState,
        nomeUsuarioLogado,
        onCloseDrawerColor,
        visibleDrawerColor,
        onchangeRadioTheme,
    }
}
