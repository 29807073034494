import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IAwardType } from "../../../interfaces";

import AwardTypesService from "../../../services/http/services/AwardTypesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useEditAwardType() {
    const { id } = useParams();
    const [awardType, setAwardType] = useState<IAwardType>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadAwardType() {
            try {
                setIsLoading(true);
                const awardTypeData = await AwardTypesService.getAwardTypeById(Number(id), controller.signal);

                setAwardType(awardTypeData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {

                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editRole_sdopasdasd');

                navigate('/tipos-de-premios');
            }
        }

        loadAwardType();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(awardTypeData: FormData) {
        try {
            await AwardTypesService.updateAwardType(Number(id), awardTypeData);

            EmitSuccessAlert('Tipo de Prêmio atualizado com sucesso!');

            navigate('/tipos-de-premios');
        } catch (err) {
            EmitError(err, true, 'editAwardType_aspaiopsdhjaq');
        }
    }

    return {
        isLoading,
        handleSubmit,
        awardType,
    }
}