import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { Button, Input, InputRef, Space } from "antd";
import Highlighter from 'react-highlight-words';

import { SearchOutlined } from '@ant-design/icons';

import { IMessage } from "../../interfaces";

import { EmitError } from "../../utils/EmitError";
import { formatDateStringToDateAndTime } from "../../utils/Conversores";
import useAuth from "../../hooks/useAuth";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { removeAcento } from "../../utils/RemoveAcentos";
import MessagesService from "../../services/http/services/MessagesService";

interface ITableData extends IMessage {
    key: number | undefined;
    panel_user_name: string;
}

export default function useMessages() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchParams] = useSearchParams();

    const type = searchParams.get('type');

    const isGeneral = useMemo(() => {
        return type?.toLowerCase() === "general";
    }, [type]);

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { activeCity } = useAuth();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ITableData, inputColumnName: string, formatter?: (text: string) => string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) =>
            removeAcento(record[dataIndex]!.toString()).toLowerCase().includes(removeAcento(value).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ?
                            formatter ? formatter(text.toString())
                                : text.toString()
                            : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Título',
            dataIndex: 'title',
            key: 'title',
            sorter: (a: ITableData, b: ITableData) => a.title.localeCompare(b.title),
            showSorterTooltip: false,
            ...getColumnSearchProps('title', 'Título'),
        },
        {
            title: 'Cadastrada por',
            dataIndex: 'panel_user_name',
            key: 'panel_user_name',
            sorter: (a: ITableData, b: ITableData) => a.panel_user_name.localeCompare(b.panel_user_name),
            showSorterTooltip: false,
            ...getColumnSearchProps('panel_user_name', 'Usuário'),
            width: '25%',
            ellipsis: true,
        },
        {
            title: 'Data de Cadastro',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_: any, { created_at }: ITableData) => formatDateStringToDateAndTime(created_at),
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: ITableData, b: ITableData) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            showSorterTooltip: false,
        },
    ]

    const setTableDataFromRequest = useCallback((data: Array<IMessage>) => {
        const newData = data.map((message) => ({
            ...message,
            key: message.id,
            panel_user_name: message.panel_user?.name || '',
        }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        if (!activeCity) {
            return;
        }

        const controller = new AbortController();

        async function loadMessages() {
            try {
                setIsLoading(true);

                if (isGeneral) {
                    const generalMessagesList = await MessagesService.listGeneralMessages(controller.signal);

                    setTableDataFromRequest(generalMessagesList);
                } else {
                    const messagesList = await MessagesService.listCityMessages(activeCity?.id!, controller.signal);

                    setTableDataFromRequest(messagesList);
                }

                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'messages_aosdpasdpasd');
                setIsLoading(false);
            }
        }

        loadMessages();

        return () => {
            controller.abort();
        }
    }, [activeCity, setTableDataFromRequest, isGeneral]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleClickCreateNewMessage = useCallback(() => {
        navigate(`/mensagens/criar${isGeneral ? '?type=general' : ''}`);
    }, [navigate, isGeneral]);

    const handleClickEditMessage = useCallback(() => {
        navigate(`/mensagens/editar/${selectedIds[0]}${isGeneral ? '?type=general' : ''}`);
    }, [navigate, selectedIds, isGeneral]);

    return {
        isLoading,
        handleClickCreateNewMessage,
        handleClickEditMessage,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
    }
}