import RewardForm from '../components/RewardForm';
import Loading from '../../../components/Loading';
import useEditReward from './useEditReward';

export default function EditReward() {
    const {
        isLoading,
        reward,
        handleSubmit
    } = useEditReward();

    return (
        <Loading loading={isLoading} >
            <RewardForm action='Editar' reward={reward} key={reward?.id} onSubmit={handleSubmit} />
        </Loading>
    )
}
