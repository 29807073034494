import { ICityTax } from '../../../interfaces';

class CityTaxMapper {
    toPersistence(domainCityTax: ICityTax): any {
        return {
            id: domainCityTax.id,
            app_user: domainCityTax.app_user,
            county_id: domainCityTax.city_id,
            expiration_date: domainCityTax.due_date,
            files: domainCityTax.files,
            reward_points: domainCityTax.reward_points,
            observation: domainCityTax.observation,
            installment_number: domainCityTax.installment,
            payment_method: domainCityTax.payment_modality,
            points_expired: domainCityTax.points_expired,
            status: domainCityTax.status,
            type: domainCityTax.type,
            value: domainCityTax.value,
            created_at: domainCityTax.created_at,
            updated_at: domainCityTax.updated_at,
        }
    }

    toDomain(persistenceCityTax: any): ICityTax {
        return {
            id: persistenceCityTax.id,
            app_user: persistenceCityTax.app_user,
            city_id: persistenceCityTax.county_id,
            due_date: persistenceCityTax.expiration_date,
            files: persistenceCityTax.files,
            reward_points: persistenceCityTax.reward_points,
            observation: persistenceCityTax.observation,
            installment: persistenceCityTax.installment_number,
            payment_modality: persistenceCityTax.payment_method,
            points_expired: persistenceCityTax.points_expired,
            panel_user: persistenceCityTax.panel_user,
            date_response: persistenceCityTax.date_response,
            status: persistenceCityTax.status,
            message: persistenceCityTax.message,
            type: persistenceCityTax.type,
            value: persistenceCityTax.value,
            created_at: persistenceCityTax.created_at,
            updated_at: persistenceCityTax.updated_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CityTaxMapper();
