import { RcFile } from "antd/es/upload";

export const getBase64Image = (img: RcFile | undefined, callback: (result: string | ArrayBuffer | null) => void) => {
    if (!img) {
        return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};