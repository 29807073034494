import { useCallback } from "react";

import { ICity } from "../../../../../../interfaces";

import { urlTermsOfUse } from "../../../../../../services/urls";
import { colInputsTermsOfUseExpanded, colInputsTermsOfUseReduced } from "../../GridStyle";

export default function useFilesStep(city: ICity | undefined, needsToInformTermsOfUseEffectiveDate: boolean) {
    const handleClickViewTermsOfUse = useCallback(() => {
        if (!city) {
            return;
        }

        window.open(`${urlTermsOfUse}/${city.id}`)
    }, [city]);

    const dummyRequest = useCallback(({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const colInputsTermsOfUse = needsToInformTermsOfUseEffectiveDate ? colInputsTermsOfUseReduced : colInputsTermsOfUseExpanded;

    return {
        dummyRequest,
        handleClickViewTermsOfUse,
        colInputsTermsOfUse,
    }
}