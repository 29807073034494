import { ICity, ICityData, ICitySystem, ICityUpcomingConfigAndTerms } from '../../../interfaces';

import privateHttpClient from '../privateHttpClient';
import CityMapper from '../mappers/CityMapper';
import { urlHttpGov } from '../../urls';

class CitiesService {
    private baseURL: string = `${urlHttpGov}/panel/counties`;

    async listCities(signal: AbortSignal): Promise<ICity[]> {
        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        return data.map((city: any) => CityMapper.toDomain(city));
    }

    createCity(city: FormData) {
        return privateHttpClient.post(this.baseURL, city, { headers: { 'content-type': 'multipart/form-data' } });
    }

    async getCityById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return CityMapper.toDomain(data);
    }

    async updateCity(id: number, city: FormData) {
        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, city, { headers: { 'content-type': 'multipart/form-data' } });

        return CityMapper.toDomain(data);
    }

    deleteCity(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }

    async findCityUpcomingConfig(cityId: number, signal: AbortSignal): Promise<ICityUpcomingConfigAndTerms> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${cityId}/next_configs_and_terms`, { signal });

        return CityMapper.upcomingConfigToDomain(data);
    }

    async getSystems(signal: AbortSignal): Promise<Array<ICitySystem>> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/systems`, { signal });
        
        return data;
    }

    async findAllBrazilCitiesByUf(uf: string, signal: AbortSignal): Promise<Array<ICityData>> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/state/${uf}`, { signal });
        
        return data;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CitiesService();
