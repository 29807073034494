import { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { IGiveaway } from "../../../../../../interfaces";

import useAuth from "../../../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { DateFns } from "../../../../../../utils/dateFns";
import { EmitError } from "../../../../../../utils/EmitError";

interface IValidateDatesProps {
    giveawayPeriodBeginning: Date;
    giveawayPeriodEnding: Date;
    giveawayDate: Date;
    giveawayImportDeadline: Date;
}

interface IProps {
    onSubmit: (giveaway: IGiveaway) => Promise<void>;
    giveaway: IGiveaway | undefined;
    onSubmitGiveawayDataStepFinished: () => void;
}

export default function useGiveawayDataStep({ giveaway, onSubmit, onSubmitGiveawayDataStepFinished }: IProps) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const { activeCity } = useAuth();
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError
    } = useForm<IGiveaway>({
        defaultValues: {
            description: giveaway?.description || '',
            period_beginning: giveaway?.period_beginning ? giveaway.period_beginning : '',
            period_ending: giveaway?.period_ending ? giveaway.period_ending : '',
            date: giveaway?.date ? giveaway.date : '',
            import_deadline: giveaway?.import_deadline ? giveaway.import_deadline : '',
            city_id: giveaway?.city?.id ? giveaway.city.id
                : activeCity ? activeCity.id
                    : undefined,
        }
    });

    const validateDates = useCallback(({
        giveawayPeriodBeginning,
        giveawayPeriodEnding,
        giveawayDate,
        giveawayImportDeadline,
    }: IValidateDatesProps): boolean => {
        let hasDateValidationError = false;

        //validar se a data do inicio do periodo e igual ou maior que a data atual
        if (!DateFns.dateIsAfter(giveawayPeriodBeginning, new Date())) {
            setError("period_beginning", { message: "Início do período do sorteio deve ser uma data posterior à data atual!" });
            hasDateValidationError = true;
        }

        //validar se a data do sorteio é depois da data de fim do periodo
        if (!DateFns.dateIsAfter(giveawayDate, giveawayPeriodEnding)) {
            setError("date", { message: "Sorteio não pode ocorrer antes ou no mesmo dia do fim do período das notas!" });
            hasDateValidationError = true;
        }

        //validar se a data de limite de lançamento é depois da data de fim do periodo
        if (!DateFns.dateIsAfter(giveawayImportDeadline, giveawayPeriodEnding)) {
            setError("import_deadline", { message: "Data limite de lançamento não pode ocorrer antes ou no mesmo dia do fim do período das notas!" });
            hasDateValidationError = true;
        }

        // validar se a data limite de importacao é pelo menos 10min antes da data/horario do sorteio
        if (!DateFns.dateIsAtLeastTenMinutesBefore(giveawayImportDeadline, giveawayDate)) {
            setError("import_deadline", { message: "Data limite de importação precisa ser pelo menos 10 minutos antes da data do sorteio!" });
            hasDateValidationError = true;
        }

        return hasDateValidationError;
    }, [setError]);

    const handleSubmitForm: SubmitHandler<IGiveaway> = useCallback(async (data): Promise<boolean> => {
        try {
            setIsSubmiting(true);

            const giveawayPeriodBeginning = DateFns.dateStringToDateTime(DateFns.setTimeToMidnight(new Date(data.period_beginning)).toISOString());
            const giveawayPeriodEnding = DateFns.dateStringToDateTime(DateFns.setTimeToOneMillisecondBeforeMidnight(new Date(data.period_ending)).toISOString());
            const giveawayDate = DateFns.dateStringToDateTime(DateFns.setSecondsAndMillisecondsToZero(new Date(data.date)).toISOString());
            const giveawayImportDeadline = DateFns.dateStringToDateTime(DateFns.setSecondsAndMillisecondsToZero(new Date(data.import_deadline)).toISOString());

            const hasDateValidationError = validateDates({
                giveawayPeriodBeginning,
                giveawayPeriodEnding,
                giveawayDate,
                giveawayImportDeadline
            });

            if (hasDateValidationError) {
                setIsSubmiting(false);
                return false;
            }

            await onSubmit({
                ...data,
                period_beginning: giveawayPeriodBeginning.toISOString(),
                period_ending: giveawayPeriodEnding.toISOString(),
                date: giveawayDate.toISOString(),
                import_deadline: giveawayImportDeadline.toISOString(),
            });

            onSubmitGiveawayDataStepFinished();
            return true;
        } catch (err) {
            EmitError(err);
            return false;
        } finally {
            setIsSubmiting(false);
        }
    }, [onSubmit, onSubmitGiveawayDataStepFinished, validateDates]);

    const handleClickUpdateAndLeave: SubmitHandler<IGiveaway> = useCallback(async (data) => {
        const success = await handleSubmitForm(data);

        if (success) {
            navigate('/sorteios');
        }
    }, [handleSubmitForm, navigate]);

    const handleChangeDateRange = useCallback((values: any) => {
        if (!values) {
            setValue('period_beginning', '');
            setValue('period_ending', '');
            return;
        }

        const period_beginning = values[0];
        const period_ending = values[1];

        setValue('period_beginning', period_beginning);
        setValue('period_ending', period_ending);
        clearErrors('period_beginning');
        clearErrors('period_ending');
    }, [setValue, clearErrors]);

    return {
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors,
        setValue,
        clearErrors,
        handleChangeDateRange,
        handleClickUpdateAndLeave,
    }
}