import { IAppUser } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import AppUserMapper from '../mappers/AppUserMapper';
import { urlHttpGov } from '../../urls';

class AppUsersService {
    private baseURL: string = `${urlHttpGov}/panel/app_user`;

    async listAppUsers(cityId: number, signal: AbortSignal) {

        const { data } = await privateHttpClient.get(`${this.baseURL}/county/${cityId}`, { signal });

        return data.map((user: any) => AppUserMapper.toDomain(user));
    }

    createAppUser(appUser: IAppUser) {
        const persistenceAppUser = AppUserMapper.toPersistence(appUser);

        return privateHttpClient.post(this.baseURL, persistenceAppUser);
    }

    async getAppUserById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return AppUserMapper.toDomain(data);
    }

    updateAppUser(id: number, appUser: IAppUser) {
        const persistenceAppUser = AppUserMapper.toPersistence(appUser);

        return privateHttpClient.patch(`${this.baseURL}/${id}`, persistenceAppUser);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AppUsersService();
