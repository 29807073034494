import { Link } from 'react-router-dom';
import { Alert, Breadcrumb, Col, Divider, Row, Steps } from 'antd';
import Title from 'antd/es/typography/Title';
import { BulbOutlined } from '@ant-design/icons';

import { IGiveaway } from '../../../../interfaces';

import useGiveawayForm from './useGiveawayForm';
import GiveawayDataStep from './Steps/GiveawayDataStep';
import AwardsStep from './Steps/AwardsStep';

interface IProps {
    action: 'Adicionar' | 'Editar';
    giveaway?: IGiveaway;
    createdGiveaway?: IGiveaway,
    onSubmit: (user: IGiveaway) => Promise<void>;
}

export default function GiveawayForm({ action, giveaway, onSubmit, createdGiveaway }: IProps) {
    const {
        currentStep,
        handleClickGiveawayStep,
        handleClickGiveawayStepWhenCreating,
        handleClickAwardsStep,
        handleSubmitGiveawayDataStepFinished,
        isInvalid,
        handleAwardsListLengthChange,
        refIsEditing,
        canEdit,
    } = useGiveawayForm({ giveaway, createdGiveaway });

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><BulbOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Sorteio</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/sorteios'>Sorteios</Link>,
                            },
                            {
                                title: `${action} Sorteio`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            
            <Row
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 16,
                }}
            >
                <div
                    style={{
                        width: '65%'
                    }}
                >

                    <Steps
                        current={currentStep}
                        onChange={() => { }} // função vazia apenas para habilitar click
                        items={[
                            {
                                title: "Dados do sorteio",
                                onClick: createdGiveaway ? () => handleClickGiveawayStepWhenCreating(createdGiveaway.id) : giveaway ? handleClickGiveawayStep : undefined,
                            },
                            {
                                title: "Prêmios",
                                onClick: giveaway ? handleClickAwardsStep : undefined,
                                disabled: !giveaway,
                                style: {
                                    cursor: giveaway ? 'pointer' : 'default',
                                }
                            },
                        ]}
                    />
                </div>
            </Row>

            <Row
                style={{
                    marginBottom: 16
                }}
            >
                {
                    currentStep === 0 && isInvalid && (
                        <Alert
                            type={'error'}
                            message="Sorteio inválido! Cadastre pelo menos um prêmio para validar o sorteio."
                            style={{
                                width: '100%',
                            }}
                        />
                    )
                }
            </Row>

            {
                currentStep === 0 && (
                    <GiveawayDataStep
                        onSubmit={onSubmit}
                        giveaway={giveaway}
                        onSubmitGiveawayDataStepFinished={handleSubmitGiveawayDataStepFinished}
                        refIsEditing={refIsEditing}
                        canEdit={canEdit}
                    />
                )
            }

            {
                currentStep === 1 && (
                    <AwardsStep
                        giveaway={giveaway || createdGiveaway}
                        onAwardsListLengthChange={handleAwardsListLengthChange}
                        refIsEditing={refIsEditing}
                        canEdit={canEdit}
                    />
                )
            }
        </>
    )
}
