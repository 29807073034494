import privateHttpClient from '../privateHttpClient';
import AwardTypeMapper from '../mappers/AwardTypeMapper';
import { urlHttpGov } from '../../urls';
import { IAwardType } from '../../../interfaces';

class AwardTypesService {
    private baseURL: string = `${urlHttpGov}/panel/award/type`;

    async listAwardTypes(signal: AbortSignal): Promise<Array<IAwardType>> {
        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        return data.map((awardType: any) => AwardTypeMapper.toDomain(awardType));
    }

    createAwardType(awardType: FormData) {
        return privateHttpClient.post(this.baseURL, awardType, { headers: { 
            "Content-Type": 'multipart/form-data',
         } });
    }

    async getAwardTypeById(id: number, signal: AbortSignal): Promise<IAwardType> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return AwardTypeMapper.toDomain(data);
    }

    async updateAwardType(id: number,awardType: FormData): Promise<IAwardType> {
        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, awardType, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        });

        return AwardTypeMapper.toDomain(data);
    }

    deleteAwardType(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AwardTypesService();
