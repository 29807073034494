import { IRole } from '../../../interfaces';

class RoleMapper {
    toPersistence(domainRole: IRole): any {
        return {
            id: domainRole.id,
            name: domainRole.name,
            description: domainRole.description,
            permissions: domainRole.permissions,
            permission_ids: domainRole.permission_ids
        }
    }

    toDomain(persistenceRole: any): IRole {
        return {
            id: persistenceRole.id,
            name: persistenceRole.name,
            description: persistenceRole.description,
            permissions: persistenceRole.permissions
        }
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RoleMapper();
