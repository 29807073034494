import { ReactNode, useCallback, useMemo, useRef } from "react";
import { Button, Carousel, Col, Row } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import { CarouselRef } from "antd/es/carousel";

import { colCarousel as colCarouselMin, colCarouselControls, colCarouselFull } from "../GridStyle";

interface IProps {
    dataLength: number;
    children: ReactNode;
}

export default function UpcomingConfigCarousel({ dataLength, children }: IProps) {
    const carouselRef = useRef<CarouselRef>(null);

    const hasMoreThanOneItem = useMemo(() => {
        return dataLength > 1;
    }, [dataLength]);

    const colCarousel = useMemo(() => {
        return hasMoreThanOneItem ? colCarouselMin : colCarouselFull;
    }, [hasMoreThanOneItem]);

    const handleClickNextConfig = useCallback(() => {
        carouselRef.current?.next();
    }, []);

    const handleClickPrevConfig = useCallback(() => {
        carouselRef.current?.prev();
    }, []);

    return (
        <Row
            style={{
                alignItems: 'center',
                marginBottom: 16,
            }}
        >
            {
                hasMoreThanOneItem && (
                    <Col
                        {...colCarouselControls}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start"
                        }}
                    >
                        <Button
                            type="text"
                            htmlType="button"
                            onClick={handleClickPrevConfig}
                        >
                            <ArrowLeftOutlined />
                        </Button>
                    </Col>
                )
            }

            <Col
                {...colCarousel}
            >
                <Carousel
                    ref={carouselRef}
                    style={{
                        width: '100%'
                    }}
                    dotPosition="top"
                >
                    {children}
                </Carousel>
            </Col>

            {
                dataLength > 1 && (
                    <Col
                        {...colCarouselControls}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end"
                        }}
                    >
                        <Button
                            type="text"
                            htmlType="button"
                            onClick={handleClickNextConfig}
                        >
                            <ArrowRightOutlined />
                        </Button>
                    </Col>
                )
            }

        </Row>
    )
}