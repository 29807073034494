import AwardTypeForm from '../components/AwardTypeForm';
import useNewAwardType from './useNewAwardType';

export default function NewAwardType() {
    const { handleSubmit } = useNewAwardType();

    return (
        <AwardTypeForm action='Adicionar' onSubmit={handleSubmit} />
    )
}
