import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Breadcrumb, Col, Divider, Form, Input, Row, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { UserOutlined } from '@ant-design/icons';

import { IAppUser } from '../../../../interfaces';

import useAppUserForm from './useAppUserForm';
import { colInputs } from './GridStyle';

interface IProps {
    action: 'Adicionar' | 'Editar' | 'Visualizar';
    user?: IAppUser;
    onSubmit: (user: IAppUser) => Promise<void>;
}

export default function AppUserForm({ action, user, onSubmit }: IProps) {
    const {
        isLoadingCities,
        handleSubmit,
        handleSubmitForm,
        cities,
        control,
    } = useAppUserForm(onSubmit, user);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><UserOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Usuário Aplicativo</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/usuarios-aplicativo'>Usuários Aplicativo</Link>,
                            },
                            {
                                title: `${action} Usuário Aplicativo`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row>
                    <Col
                        span={24}
                    >
                        <Form.Item
                            label="Nome"
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, digite o nome do Usuário!' },  }}
                                render={({ field }) => <Input {...field} disabled placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="CPF"
                        >
                            <Controller
                                name="cpf"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o CPF!' },
                                }}
                                render={({ field }) => <Input {...field} disabled placeholder='CPF' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="Telefone"
                        >
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o telefone!' },
                                }}
                                render={({ field }) => <Input {...field} disabled placeholder='Telefone' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        xs={{span: 24, offset: 0}}
                        sm={{span: 24, offset: 0}}
                        md= {{span: 24, offset: 0}}
                        lg= {{span: !user ? 12 : 24, offset: 0}}
                        xl= {{span: !user ? 12 : 24, offset: 0}}
                        xxl= {{span: !user ? 12 : 24, offset: 0}}
                    >
                        <Form.Item
                            label="Email"
                        >
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o email!' },
                                    pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Email inválido' }
                                }}
                                render={({ field }) => <Input {...field} disabled placeholder='Email' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="Logradouro"
                        >
                            <Controller
                                name="street"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o logradouro!' },
                                }}
                                render={({ field }) => <Input {...field} disabled placeholder='Logradouro' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="Bairro"
                        >
                            <Controller
                                name="neighborhood"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o bairro!' },
                                }}
                                render={({ field }) => <Input {...field} disabled placeholder='Bairro' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="Número"
                        >
                            <Controller
                                name="number"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o número da residência!' },
                                }}
                                render={({ field }) => <Input {...field} disabled placeholder='Número' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="CEP"
                        >
                            <Controller
                                name="cep"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o CEP!' },
                                }}
                                render={({ field }) => <Input {...field} disabled placeholder='CEP' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="Municípios"
                        >
                            <Controller
                                name="city_ids"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, selecione ao menos um município!' },
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        mode='multiple'
                                        allowClear
                                        disabled
                                        loading={isLoadingCities}
                                        options={cities.map((city) => ({ value: city.id, label: `${city.city} - ${city.uf}`, key: city.id }))}
                                        placeholder='Selecione os municípios'
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    {/* <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmiting || isLoadingCities}
                    >
                        Salvar
                    </Button> */}
                </Row>
            </Form>
        </>
    )
}
