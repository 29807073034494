import { IMessage } from '../components/Toast/interfaces';
import EventManager from '../lib/EventManager';

export const toastEventManager = new EventManager();

export default function toast({ key, type, text, description, duration }: IMessage) {
  toastEventManager.emit('addtoast', { key, type, text, description, duration });
}

export function clearToasts() {
  toastEventManager.emit('cleartoasts', undefined);
}
