import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import useAuth from "../../hooks/useAuth";

import { EmitError } from "../../utils/EmitError";
import { ISignInData } from "../../interfaces";

export default function useLogin() {
    const {
        loading: isLoadingUser, 
        user,
        signIn,
    } = useAuth();

    const [params] = useSearchParams();
    const isParamEmail = params.get("email");

    const navigate = useNavigate();
    const formRef = useRef<any>(null);

    const [isSubmiting, setIsSubmiting] = useState(false);
    const [msgEmail, setMsgEmail] = useState(false);

    const { 
        control, 
        handleSubmit, 
        formState: { errors,  }, 
        reset 
    } = useForm<ISignInData>({
        defaultValues: {
            email: '',
            password: '',
        }
    });

    useEffect(() => {
        if (user) {
            navigate('/dashboard');
        }
    }, [user, navigate]);

    useEffect(() => {
        if (isParamEmail) {

            const emailParam = String(isParamEmail);

            if (emailParam === 'true') {

                setMsgEmail(true)
            } else {
                setMsgEmail(false)
            }

        }

    }, [isParamEmail])

    const onSubmit: SubmitHandler<ISignInData> = useCallback(async (data) => {
        try {
            setIsSubmiting(true);
            const user = await signIn(data);

            setIsSubmiting(false);

            if (!user) {
                return;
            }
            
            reset();
            
            if (user?.first_access) {
                navigate('/primeiro-acesso');
                return;
            }

            navigate('/dashboard');
        } catch (error) {
            setIsSubmiting(false);

            EmitError(error, true, 'login_dkwdfaiweyi')
        }
    }, [navigate, reset, signIn]);

    const onClickForgotPassword = useCallback(() => {
        navigate('/recuperar-senha')
    }, [navigate]);

    return {
        isLoadingUser,
        user,
        isSubmiting,
        formRef,
        control,
        msgEmail,
        handleSubmit,
        errors,
        onSubmit,
        onClickForgotPassword,
    }
}