import type { ThemeConfig } from 'antd';
import { global } from './global';

export const configLight: ThemeConfig = {
    token: {
        ...global.token,
        colorBgLayout: '#f0f2f5',
    },
    components: {
        Input: {
            colorTextDisabled: '#3F3F3F'
        },
        Select: {
            colorTextDisabled: '#3F3F3F'
        },
        DatePicker: {
            colorTextDisabled: '#3F3F3F'
        }
    }
}
