import { Button } from "antd";

import { LeftOutlined } from '@ant-design/icons';

import Title from 'antd/es/typography/Title';

import Table from "../../../../components/Table";
import useInvoices from "./useInvoices";

const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
]

export default function Invoices() {
    const {
        columns,
        appUser,
        isLoading,
        tableData,
        onClickTableRow,
        rowSelection,
        period,
        handleClickGoBack,
    } = useInvoices();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                    alignItems: 'center',
                }}
            >
                <Button
                    type="link"
                    onClick={handleClickGoBack}
                    style={{
                        marginRight: 8,
                    }}
                >
                    <LeftOutlined />
                </Button>

                {
                    period && appUser && (

                        <Title
                        level={3}
                        style={{
                            margin: 0,
                        }}
                    >
                        {MONTHS[period.month]} {period.year} - {appUser?.name}
                    </Title>
                    )
                }

            </div>

            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
            />
        </>
    )
}