import { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UploadFile } from "antd";
import { AxiosError } from "axios";

import { IGiveaway } from "../../../../../interfaces";

import { EmitError } from "../../../../../utils/EmitError";
import GiveawaysService from "../../../../../services/http/services/GiveawaysService";
import { EmitSuccessAlert } from "../../../../../utils/EmitSuccessAlert";

const ACCEPTED_FILE_TYPES = [
    'application/json',
]

export interface IChangeFileProps {
    onChangeHookForm: (event: any) => void,
    file: UploadFile,
}

interface IInputs {
    winners_file: UploadFile;
    hash: string;
}

interface IProps {
    giveaway: IGiveaway | undefined;
    onWinnersFileImported: (hash: string, file: string) => void;
}

export default function useImportData({ giveaway, onWinnersFileImported }: IProps) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
    const [hash, setHash] = useState<string | undefined | null>(giveaway?.hash_with_winners);
    const [file, setFile] = useState<string | undefined | null>(giveaway?.file_with_winners);
    const [beingUploadedWinnersFileName, setBeingUploadedWinnersFileName] = useState<string | undefined>();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm<IInputs>({
        shouldFocusError: false,
    });

    const handleSubmitForm: SubmitHandler<IInputs> = useCallback(async (data) => {
        try {
            setIsSubmiting(true);
            const formData = new FormData();

            if (data.winners_file?.originFileObj) {
                formData.append('file', data.winners_file.originFileObj);
            }
    
            formData.append('hash', data.hash);

            const { hash, file } = await GiveawaysService.uploadGiveawayResult(formData);

            setHash(hash);
            setFile(file);
            onWinnersFileImported(hash, file);
            setIsSubmiting(false);
            EmitSuccessAlert('Resultado importado com sucesso!');
        } catch (err) {
            EmitError(err);
            setIsSubmiting(false);
        }
    }, [onWinnersFileImported]);

    const dummyRequest = useCallback(({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const handleChangeWinnersFile = useCallback(({ onChangeHookForm, file }: IChangeFileProps) => {
        if (file?.type && !ACCEPTED_FILE_TYPES.includes(file.type.toLowerCase())) {
            setError('winners_file', { message: 'Formato de arquivo inválido! O formato aceito é apenas JSON' });
            return;
        }

        clearErrors('winners_file');
        onChangeHookForm(file);
        setBeingUploadedWinnersFileName(file.name);
    }, [setError, clearErrors]);

    const handleClickDownloadFile = useCallback(async () => {
        if (!file) {
            return;
        }

        const controller = new AbortController();

        try {
            setIsDownloadingFile(true);
            const responseFile = await GiveawaysService.downloadGiveawayTicketsFile(file, controller.signal);

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(responseFile);
            link.download = file;
            link.click();
            window.URL.revokeObjectURL(link.href);
            setIsDownloadingFile(false);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }

            setIsDownloadingFile(false);
            EmitError(err, true, 'holdGiveawayExportData_ajksdkasdjasjdasd')
        }

        return () => {
            controller.abort();
        }
    }, [file]);

    return {
        control,
        handleSubmit,
        handleSubmitForm,
        errors,
        dummyRequest,
        handleChangeWinnersFile,
        beingUploadedWinnersFileName,
        handleClickDownloadFile,
        file,
        hash,
        isDownloadingFile,
        isSubmiting,
        ACCEPTED_FILE_TYPES,
    }
}