import { useNavigate } from "react-router-dom";

import CitiesService from "../../../services/http/services/CitiesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useNewCity() {
    const navigate = useNavigate();

    async function handleSubmit(city: FormData) {
        try {
            await CitiesService.createCity(city);

            EmitSuccessAlert('Município cadastrado com sucesso!');

            navigate('/municipios');
        } catch (err) {
            EmitError(err, true, 'newCity_auisdhiasdhuad');
        }
    }

    return {
        handleSubmit
    }

}