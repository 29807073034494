import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { IRecoverRequest } from "../../../interfaces";

import { formatCpf, formatPhone } from "../../../utils/Masks";
import { urlHttpGov } from "../../../services/urls";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoverRequestsContext } from "../../../contexts/RecoverRequestsContext";
import { EmitError } from "../../../utils/EmitError";
import privateHttpClient from '../../../services/http/privateHttpClient';
import { Buffer } from "buffer";
import { AxiosError } from "axios";
import AppAuthService from "../../../services/http/services/AppAuthService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";

interface IInputs {
    name: string,
    cpf: string,
    existing_phone: string,
    new_phone: string,
    created_at: string,
    observation: string,
}

export default function useViewRecoverRequest() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [recoverRequest, setRecoverRequest] = useState<IRecoverRequest | undefined>();
    const [images, setImages] = useState<Array<string>>([]);
    const [isConfirmApproveRequestModalVisible, setIsConfirmApproveRequestModalVisible] = useState<boolean>(false);
    const [isConfirmRefuseRequestModalVisible, setIsConfirmRefuseRequestModalVisible] = useState<boolean>(false);

    const { recoverRequests, loadRecoverRequests } = useRecoverRequestsContext();

    const { id } = useParams();
    const navigate = useNavigate();

    const {
        formState: { errors },
        control,
        setValue,
        setError,
        watch,
        clearErrors,
    } = useForm<IInputs>();

    const observation = watch('observation');

    const handleApproveRequest = useCallback(() => {
        setIsConfirmApproveRequestModalVisible(true);
    }, []);

    const handleRefuseRequest = useCallback(() => {
        setIsConfirmRefuseRequestModalVisible(true);
    }, []);

    const handleConfirmApproveRequest = useCallback(async () => {
        try {
            setIsSubmiting(true);
            if (!recoverRequest) {
                return;
            }

            await AppAuthService.approveRecoverRequest(recoverRequest, observation);

            EmitSuccessAlert('Solicitação aprovada com sucesso!');

            navigate('/recuperacao-acesso');
        } catch (err) {

            EmitError(err, true, 'viewRecoverRequest_akmdkoasdasd');
        } finally {
            setIsSubmiting(false);
        }
    }, [recoverRequest, observation, navigate]);

    const handleConfirmRefuseRequest = useCallback(async () => {
        try {
            setIsSubmiting(true);

            if (!recoverRequest) {
                return;
            }

            if (!observation) {
                setError('observation', { message: 'Ao recusar uma solicitação, a observação é obrigatória!' });
                setIsConfirmRefuseRequestModalVisible(false);
                return;
            }

            await AppAuthService.refuseRecoverRequest(recoverRequest, observation);

            EmitSuccessAlert('Solicitação recusada com sucesso!');

            navigate('/recuperacao-acesso');
        } catch (err) {

            EmitError(err, true, 'viewRecoverRequest_akmdkoasdasd');
        } finally {
            setIsSubmiting(false);
        }
    }, [recoverRequest, observation, setError, navigate]);

    const handleCancel = useCallback(() => {
        setIsConfirmApproveRequestModalVisible(false);
        setIsConfirmRefuseRequestModalVisible(false);
    }, []);

    const loadBase64Image = useCallback(async (url: string, signal: AbortSignal) => {
        try {
            if (!window.Buffer) {
                window.Buffer = Buffer;
            }

            const response = await privateHttpClient.get(url, { responseType: 'arraybuffer', signal });

            let data = `data:${response.headers["content-type"]};base64,${Buffer.from(response.data, "binary").toString("base64")}`;

            setImages((prevState) => [
                ...prevState,
                data,
            ]);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }
            EmitError(err, true, 'viewRecoverAccess_ajksdkasdjasjdasd')
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController();

        setIsLoading(true);

        if (recoverRequests.length === 0) {
            return;
        }

        const request = recoverRequests.find((request) => request.id === Number(id));

        if (!request) {
            EmitError("Solicitação não encontrada", true, 'viewRecoverRequest_asdashiasdas');

            navigate('/recuperacao-acesso');
            return;
        }

        const imagesBaseUrl = `${urlHttpGov}/panel/auth_app/recover_documents`;

        const imagesUrls = request.images.map((element) => `${imagesBaseUrl}/${element.img}`);

        setImages([]);

        imagesUrls.map(async (url) => await loadBase64Image(url, controller.signal));

        setRecoverRequest(request);
        setValue('name', request.app_user.name);
        setValue('cpf', formatCpf(request.app_user.cpf));
        setValue('existing_phone', formatPhone(request.existing_phone));
        setValue('new_phone', formatPhone(request.new_phone));
        setValue('observation', request?.observation || '');
        setValue('created_at', request?.created_at || '');

        setIsLoading(false);

        return () => {
            controller.abort();
        }

    }, [id, recoverRequests, loadBase64Image, setValue, navigate]);

    useEffect(() => {
        if (recoverRequests.length === 0) {
            loadRecoverRequests();
        }
    }, [recoverRequests, loadRecoverRequests]);

    useEffect(() => {
        if (observation) {
            clearErrors('observation');
        }
    }, [observation, clearErrors]);

    return {
        isLoading,
        isSubmiting,
        control,
        errors,
        recoverRequest,
        images,
        handleApproveRequest,
        handleRefuseRequest,
        isConfirmApproveRequestModalVisible,
        isConfirmRefuseRequestModalVisible,
        handleConfirmApproveRequest,
        handleConfirmRefuseRequest,
        handleCancel,
    }
}