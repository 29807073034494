import privateHttpClient from '../privateHttpClient';
import RewardMapper from '../mappers/RewardMapper';
import { urlHttpGov } from '../../urls';

class RewardsService {
    private baseURL: string = `${urlHttpGov}/panel/reward`;

    async listRewards(cityId: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/county/${cityId}`, { signal });

        return data.map((reward: any) => RewardMapper.toDomain(reward));
    }

    createReward(reward: FormData) {
        return privateHttpClient.post(this.baseURL, reward, { headers: { 'content-type': 'multipart/form-data' } });
    }

    async getRewardById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return RewardMapper.toDomain(data);
    }

    async updateReward(id: number, reward: FormData) {
        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, reward, { headers: { 'content-type': 'multipart/form-data' } });

        return RewardMapper.toDomain(data);
    }

    deleteReward(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RewardsService();
