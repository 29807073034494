import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ICityTax } from "../../../interfaces";

import { formatCpf, formatPhone } from "../../../utils/Masks";
import { urlHttpGov } from "../../../services/urls";
import { useNavigate, useParams } from "react-router-dom";
import { useCityTaxesContext } from "../../../contexts/CityTaxesContext";
import { EmitError } from "../../../utils/EmitError";
import privateHttpClient from '../../../services/http/privateHttpClient';
import { Buffer } from "buffer";
import { AxiosError } from "axios";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import CityTaxesService from "../../../services/http/services/CityTaxesService";
import { payment_modalities } from "../useCityTaxes";
import { convertMoedaBRDoJSComExtamente2CasasDecimais } from "../../../utils/Conversores";

export interface ICityTaxFile {
    url: string,
    type: string,
}

interface IInputs {
    name: string,
    cpf: string,
    phone: string,
    created_at: string,
    due_date: string,
    type: string,
    payment_modality: string,
    installment?: number,
    value: string,
    observation: string,
}

export default function useViewCityTax() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [cityTax, setCityTax] = useState<ICityTax | undefined>();
    const [files, setFiles] = useState<Array<ICityTaxFile>>([]);
    const [isConfirmApproveTaxModalVisible, setIsConfirmApproveTaxModalVisible] = useState<boolean>(false);
    const [isConfirmRefuseTaxModalVisible, setIsConfirmRefuseTaxModalVisible] = useState<boolean>(false);

    const { cityTaxes, loadCityTaxes } = useCityTaxesContext();

    const { id } = useParams();
    const navigate = useNavigate();

    const {
        formState: { errors },
        control,
        setValue,
        setError,
        watch,
        clearErrors,
    } = useForm<IInputs>();

    const observation = watch('observation');

    const handleApproveCityTax = useCallback(() => {
        setIsConfirmApproveTaxModalVisible(true);
    }, []);

    const handleRefuseCityTax = useCallback(() => {
        setIsConfirmRefuseTaxModalVisible(true);
    }, []);

    const handleConfirmApproveCityTax = useCallback(async () => {
        try {
            setIsSubmiting(true);
            if (!cityTax) {
                return;
            }

            await CityTaxesService.approveCityTax(cityTax, observation);

            EmitSuccessAlert('Imposto aprovado com sucesso!');

            navigate('/impostos');
        } catch (err) {

            EmitError(err, true, 'viewCityTax_akmdkoasdasd');
        } finally {
            setIsSubmiting(false);
        }
    }, [cityTax, observation, navigate]);

    const handleConfirmRefuseCityTax = useCallback(async () => {
        try {
            setIsSubmiting(true);

            if (!cityTax) {
                return;
            }

            if (!observation) {
                setError('observation', { message: 'Ao recusar um imposto, a observação é obrigatória!' });
                setIsConfirmRefuseTaxModalVisible(false);
                return;
            }

            await CityTaxesService.refuseCityTax(cityTax, observation);

            EmitSuccessAlert('Imposto recusado com sucesso!');

            navigate('/impostos');
        } catch (err) {

            EmitError(err, true, 'viewCityTax_akmdkoasdasd');
        } finally {
            setIsSubmiting(false);
        }
    }, [cityTax, observation, setError, navigate]);

    const handleCancel = useCallback(() => {
        setIsConfirmApproveTaxModalVisible(false);
        setIsConfirmRefuseTaxModalVisible(false);
    }, []);

    const loadBase64Image = useCallback(async (url: string, signal: AbortSignal) => {
        try {
            if (!window.Buffer) {
                window.Buffer = Buffer;
            }

            const response = await privateHttpClient.get(url, { responseType: 'arraybuffer', signal });

            let data = `data:${response.headers["content-type"]};base64,${Buffer.from(response.data, "binary").toString("base64")}`;

            setFiles((prevState) => [
                ...prevState,
                {
                    url: data,
                    type: response.headers["content-type"],
                },
            ]);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }
            EmitError(err, true, 'viewRecoverAccess_ajksdkasdjasjdasd')
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController();

        setIsLoading(true);

        if (cityTaxes.length === 0) {
            return;
        }

        const tax = cityTaxes.find((request) => request.id === Number(id));

        if (!tax) {
            EmitError("Solicitação não encontrada", true, 'viewRecoverRequest_asdashiasdas');

            navigate('/recuperacao-acesso');
            return;
        }

        const filesBaseUrl = `${urlHttpGov}/panel/taxes/file`;

        const filesUrls = tax.files.map((element) => `${filesBaseUrl}/${element.filename}`);

        setFiles([]);

        filesUrls.forEach(async (url) => {
            if (url.match(/.pdf/)) {
                setFiles((prevState) => [
                    ...prevState,
                    {
                        url: url,
                        type: 'application/pdf',
                    },
                ]);
                return;
            }

            await loadBase64Image(url, controller.signal)
        });

        setCityTax(tax);
        setValue('name', tax.app_user.name);
        setValue('cpf', formatCpf(tax.app_user.cpf));
        setValue('phone', formatPhone(tax.app_user.phone));
        setValue('type', tax?.type || '');
        setValue('payment_modality', tax?.payment_modality ? payment_modalities[tax.payment_modality] : '');
        setValue('installment', tax?.installment);
        setValue('value', `R$ ${convertMoedaBRDoJSComExtamente2CasasDecimais(tax.value)}`);
        setValue('observation', tax?.observation || '');
        setValue('due_date', tax?.due_date || '');
        setValue('created_at', tax?.created_at || '');

        setIsLoading(false);

        return () => {
            controller.abort();
        }

    }, [id, cityTaxes, loadBase64Image, setValue, navigate]);

    useEffect(() => {
        if (cityTaxes.length === 0) {
            loadCityTaxes();
        }
    }, [cityTaxes, loadCityTaxes]);

    useEffect(() => {
        if (observation) {
            clearErrors('observation');
        }
    }, [observation, clearErrors]);

    return {
        isLoading,
        isSubmiting,
        control,
        errors,
        cityTax,
        files,
        handleApproveCityTax,
        handleRefuseCityTax,
        isConfirmApproveTaxModalVisible,
        isConfirmRefuseTaxModalVisible,
        handleConfirmApproveCityTax,
        handleConfirmRefuseCityTax,
        handleCancel,
    }
}