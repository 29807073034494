import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Alert, Breadcrumb, Button, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { GiftOutlined, InboxOutlined } from '@ant-design/icons';

import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';

import { IReward } from '../../../../interfaces';

import { colInputs } from './GridStyle';
import useRewardForm from './useRewardForm';
import Dragger from 'antd/es/upload/Dragger';
import RadioIsInactive from '../RadioIsInactive';

interface IProps {
    action: 'Adicionar' | 'Editar';
    reward?: IReward;
    onSubmit: (city: FormData) => Promise<void>;
}

export default function RewardForm({ action, reward, onSubmit }: IProps) {
    const {
        handleSubmit,
        handleSubmitForm,
        control,
        isSubmiting,
        errors,
        handleChangeImage,
        imageUrl,
        dummyRequest,
        categories,
        isLoadingCategories,
        ACCEPTED_IMAGE_TYPES,
    } = useRewardForm(onSubmit, reward);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><GiftOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Recompensa</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/recompensas'>Recompensas</Link>,
                            },
                            {
                                title: `${action} Recompensa`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            
            {
                reward?.is_inactive && (
                    <Row
                        style={{
                            marginBottom: 12,
                        }}
                    >
                        <Alert
                            type={'error'}
                            message={"Essa recompensa está inativa!"}
                            description={"Recompensas inativas não aparecerão para os usuários no aplicativo."}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Row>
                )
            }

            {
                reward?.vouchers_issued && reward.vouchers_issued !== 0 ? (
                    <Row
                        style={{
                            marginBottom: 12,
                        }}
                    >
                        <Alert
                            type={'warning'}
                            message={
                                reward.vouchers_issued === 1 ? `Existe ${reward.vouchers_issued} unidade dessa recompensa pendente de retirada!`
                                    : `Existem ${reward.vouchers_issued} unidades dessa recompensa pendentes de retirada!`
                            }
                            style={{
                                width: '100%',
                            }}
                        />
                    </Row>
                ) : null
            }

            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Nome"
                            required
                            validateStatus={errors.name ? 'error' : 'success'}
                            help={errors.name?.message}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, digite o nome da recompensa!' }, }}
                                render={({ field }) => <Input {...field} placeholder='Nome da recompensa' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Descrição"
                            required
                            validateStatus={errors.description ? 'error' : 'success'}
                            help={errors.description?.message}
                        >
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite a descrição!' },
                                }}
                                render={({ field }) => <Input {...field} placeholder='Descrição' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                    >
                        <Form.Item
                            label="Pontos"
                            required
                            validateStatus={errors.points ? 'error' : 'success'}
                            help={errors.points?.message}
                        >
                            <Controller
                                name="points"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, informe o valor dos pontos!' }, }}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        type='number'
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder='Valor em pontos'
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                    >
                        <Form.Item
                            label="Estoque"
                            required
                            validateStatus={errors.stock ? 'error' : 'success'}
                            help={errors.stock?.message}
                        >
                            <Controller
                                name="stock"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, informe a quantidade em estoque!' }, }}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        type='number'
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder='Quantidade em estoque'
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                    >
                        <Form.Item
                            label="Categoria"
                            required
                            validateStatus={errors.category_id ? 'error' : 'success'}
                            help={errors.category_id?.message}
                        >
                            <Controller
                                name="category_id"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, informe a categoria da Recompensa!' },
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={categories.map((category) => ({ value: category.id, label: category.name }))}
                                        placeholder='Selecione a categoria'
                                        disabled={isLoadingCategories}
                                        loading={isLoadingCategories}
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                    >
                        <Form.Item
                            label="Status"
                            required
                            validateStatus={errors.is_inactive ? 'error' : 'success'}
                            help={errors.is_inactive?.message}
                        >
                            <Controller
                                name="is_inactive"
                                control={control}
                                render={({ field }) => (
                                    <RadioIsInactive
                                        {...field}
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Imagem"
                            validateStatus={errors.image ? 'error' : 'success'}
                            help={errors.image?.message}
                        >
                            <Controller
                                name="image"
                                control={control}
                                render={({ field: { value, onChange, ...field } }) => (
                                    <Dragger
                                        {...field}
                                        showUploadList={false}
                                        customRequest={dummyRequest}
                                        onChange={(e: UploadChangeParam<UploadFile>) => handleChangeImage({ onChangeHookForm: onChange, file: e.file })}
                                        accept={ACCEPTED_IMAGE_TYPES.toString()}
                                    >
                                        {
                                            imageUrl && typeof imageUrl === 'string' ? (
                                                <img
                                                    src={imageUrl}
                                                    alt="imagem da recompensa"
                                                    style={{
                                                        width: '50%',
                                                    }}
                                                />
                                            )
                                                : (
                                                    <>
                                                        <p
                                                            className="ant-upload-drag-icon"
                                                        >
                                                            <InboxOutlined />
                                                        </p>
                                                        <Text
                                                            style={{
                                                                margin: '0 8px',
                                                            }}
                                                            className="ant-upload-text"
                                                        >
                                                            Clique ou arraste o arquivo para fazer o upload
                                                        </Text>
                                                    </>
                                                )
                                        }
                                    </Dragger>
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    justify="end"
                >
                    <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmiting}
                        loading={isSubmiting}
                    >
                        Salvar
                    </Button>
                </Row>
            </Form>
        </>
    )
}
