import { Outlet, Navigate, useLocation } from "react-router-dom";

import { EModule } from "../../enums";
import useModules from "../../hooks/useModules";

type Props = {
    allowedModules: EModule[];
}

export default function RequireModules({ allowedModules }: Props) {
    const { cityModules } = useModules();
    const location = useLocation();

    return (
        cityModules.find((m) => allowedModules.includes(m))
            ? <Outlet />
            : <Navigate to="/modulo-nao-encontrado" state={{ from: location }} replace />
    )
}