import { ICityUpcomingConfigAndTerms, ICity } from '../../../interfaces';

class CityMapper {
    toPersistence(domainCity: ICity): any {
        return {
            id: domainCity.id,
            name: domainCity.name,
            cnpj: domainCity.cnpj,
            city: domainCity.city,
            uf: domainCity.uf,
            system_id: domainCity.system_id,
            modules: domainCity.modules ? JSON.stringify(domainCity.modules) : undefined,
            time_expire_points: domainCity.time_expire_points,
            time_expire_voucher: domainCity.time_expire_vouchers,
            value_divider_ticket_nfs: domainCity.nfs_tickets_divider,
            value_max_divider_ticket_nfs: domainCity.nfs_max_amount_tickets,
            max_amount_cnpj_divider_ticket_nfs: domainCity.nfs_max_month_quantity_by_cnpj_tickets,
            value_divider_ticket_nfc: domainCity.nfc_tickets_divider,
            value_max_divider_ticket_nfc: domainCity.nfc_max_amount_tickets,
            max_amount_cnpj_divider_ticket_nfc: domainCity.nfc_max_month_quantity_by_cnpj_tickets,
            value_divider_ticket_taxe: domainCity.taxe_tickets_divider,
            value_max_divider_ticket_taxe: domainCity.taxe_max_amount_tickets,
            value_divider_points_nfs: domainCity.nfs_points_divider,
            value_max_divider_points_nfs: domainCity.nfs_max_amount_points,
            max_amount_cnpj_divider_points_nfs: domainCity.nfs_max_month_quantity_by_cnpj_points,
            value_divider_points_nfc: domainCity.nfc_points_divider,
            value_max_divider_points_nfc: domainCity.nfc_max_amount_points,
            max_amount_cnpj_divider_points_nfc: domainCity.nfc_max_month_quantity_by_cnpj_points,
            value_divider_points_taxe: domainCity.taxe_points_divider,
            value_max_divider_points_taxe: domainCity.taxe_max_amount_points,
            description_divider_points: domainCity.points_config_description,
            description_divider_ticket: domainCity.tickets_config_description,
            start_date_points: domainCity.points_config_effective_date,
            start_date_ticket: domainCity.tickets_config_effective_date,
            logo_light: domainCity.logo_light,
            logo_dark: domainCity.logo_dark,
            terms_of_use: domainCity.terms_of_use,
            start_date_terms_of_use: domainCity.terms_of_use_effective_date,
        }
    }

    toDomain(persistenceCity: any): ICity {
        return {
            id: persistenceCity.id,
            name: persistenceCity.name,
            cnpj: persistenceCity.cnpj,
            city: persistenceCity.city,
            uf: persistenceCity.uf,
            nfs_tickets_divider: persistenceCity.value_divider_ticket_nfs || undefined,
            nfs_max_amount_tickets: persistenceCity.value_max_divider_ticket_nfs || undefined,
            nfs_max_month_quantity_by_cnpj_tickets: persistenceCity.max_amount_cnpj_divider_ticket_nfs || undefined,
            nfc_tickets_divider: persistenceCity.value_divider_ticket_nfc || undefined,
            nfc_max_amount_tickets: persistenceCity.value_max_divider_ticket_nfc || undefined,
            nfc_max_month_quantity_by_cnpj_tickets: persistenceCity.max_amount_cnpj_divider_ticket_nfc || undefined,
            taxe_tickets_divider: persistenceCity.value_divider_ticket_taxe || undefined,
            taxe_max_amount_tickets: persistenceCity.value_max_divider_ticket_taxe || undefined,
            nfs_points_divider: persistenceCity.value_divider_points_nfs || undefined,
            nfs_max_amount_points: persistenceCity.value_max_divider_points_nfs || undefined,
            nfs_max_month_quantity_by_cnpj_points: persistenceCity.max_amount_cnpj_divider_points_nfs || undefined,
            nfc_points_divider: persistenceCity.value_divider_points_nfc || undefined,
            nfc_max_amount_points: persistenceCity.value_max_divider_points_nfc || undefined,
            nfc_max_month_quantity_by_cnpj_points: persistenceCity.max_amount_cnpj_divider_points_nfc || undefined,
            taxe_points_divider: persistenceCity.value_divider_points_taxe || undefined,
            taxe_max_amount_points: persistenceCity.value_max_divider_points_taxe || undefined,
            points_config_description: persistenceCity.description_divider_points || undefined,
            tickets_config_description: persistenceCity.description_divider_ticket || undefined,
            points_config_effective_date: persistenceCity.start_date_points,
            tickets_config_effective_date: persistenceCity.start_date_ticket,
            time_expire_points: persistenceCity.time_expire_points || undefined,
            time_expire_vouchers: persistenceCity.time_expire_voucher || undefined,
            system_id: persistenceCity.system_id || undefined,
            modules: persistenceCity.modules,
            logo_light: persistenceCity.logo_light,
            logo_dark: persistenceCity.logo_dark,
            terms_of_use: persistenceCity.terms_of_use,
            terms_of_use_effective_date: persistenceCity.start_date_terms_of_use,
            created_at: persistenceCity.created_at,
        }
    }

    upcomingConfigToDomain(persistenceUpcomingDividers: any): ICityUpcomingConfigAndTerms {
        return {
            points_config: persistenceUpcomingDividers?.divider_points ? persistenceUpcomingDividers.divider_points.map((cfg: any) => (
                {
                    id: cfg.id,
                    nfc_divider: cfg.value_divider_points_nfc,
                    nfc_max_amount: cfg.value_max_divider_points_nfc,
                    nfc_max_month_quantity_by_cnpj: cfg.max_amount_cnpj_divider_points_nfc,
                    nfs_divider: cfg.value_divider_points_nfs,
                    nfs_max_amount: cfg.value_max_divider_points_nfs,
                    nfs_max_month_quantity_by_cnpj: cfg.max_amount_cnpj_divider_points_nfs,
                    taxe_divider: cfg.value_divider_points_taxe,
                    taxe_max_amount: cfg.value_max_divider_points_taxe,
                    description: cfg.description_divider_points,
                    effective_date: cfg.start_date_points,
                    created_at: cfg.created_at,
                    panel_user: cfg.panel_user,
                }
            ))  : [],
            tickets_config: persistenceUpcomingDividers?.divider_ticket ? persistenceUpcomingDividers.divider_ticket.map((cfg: any) => (
                {
                    id: cfg.id,
                    nfc_divider: cfg.value_divider_ticket_nfc,
                    nfc_max_amount: cfg.value_max_divider_ticket_nfc,
                    nfc_max_month_quantity_by_cnpj: cfg.max_amount_cnpj_divider_ticket_nfc,
                    nfs_divider: cfg.value_divider_ticket_nfs,
                    nfs_max_amount: cfg.value_max_divider_ticket_nfs,
                    nfs_max_month_quantity_by_cnpj: cfg.max_amount_cnpj_divider_ticket_nfs,
                    taxe_divider: cfg.value_divider_ticket_taxe,
                    taxe_max_amount: cfg.value_max_divider_ticket_taxe,
                    description: cfg.description_divider_ticket,
                    effective_date: cfg.start_date_ticket,
                    created_at: cfg.created_at,
                    panel_user: cfg.panel_user,
                }
            )) : [],
            terms_of_use: persistenceUpcomingDividers?.terms_of_use ? {
                id: persistenceUpcomingDividers?.terms_of_use?.id,
                file: persistenceUpcomingDividers?.terms_of_use?.file,
                panel_user: persistenceUpcomingDividers?.terms_of_use?.panel_user,
                effective_date: persistenceUpcomingDividers?.terms_of_use?.start_date,
                created_at: persistenceUpcomingDividers?.terms_of_use?.created_at,
                updated_at: persistenceUpcomingDividers?.terms_of_use?.updated_at,
            } : null,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CityMapper();
