import privateHttpClient from '../privateHttpClient';
import PermissionMapper from '../mappers/PermissionMapper';
import { urlHttpGov } from '../../urls';

class PermissionsService {
    private baseURL: string = `${urlHttpGov}/panel/user/permissions`;

    async listPermissions(signal: AbortSignal) {
        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        return data.map((permission: any) => PermissionMapper.toDomain(permission));
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PermissionsService();
