import { createContext, useCallback, useContext, useState } from "react";
import { IRecoverRequest } from "../interfaces";
import { AxiosError } from "axios";
import { EmitError } from "../utils/EmitError";
import AppAuthService from "../services/http/services/AppAuthService";
import useAuth from "../hooks/useAuth";
import { Outlet } from "react-router-dom";

interface IRecoverRequestsContext {
    recoverRequests: Array<IRecoverRequest>;
    loadRecoverRequests: (controller?: AbortController) => Promise<any>;
}
export const RecoverRequestsContext = createContext({} as IRecoverRequestsContext);

export function RecoverRequestsProvider() {
    const [recoverRequests, setRecoverRequests] = useState<Array<IRecoverRequest>>([]);

    const { activeCity } = useAuth();

    const loadRecoverRequests = useCallback(async (abortController?: AbortController) => {
        const controller = abortController || new AbortController();

        try {
            const recoverRequestsList = await AppAuthService.listRecoverRequests(activeCity?.id!, controller.signal);

            setRecoverRequests(recoverRequestsList);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }

            EmitError(err, true, 'recoverRequests_asdhashuidd');
        }

        return () => {
            controller.abort();
        }
    }, [activeCity]);

    return (
        <RecoverRequestsContext.Provider value={{
            recoverRequests, loadRecoverRequests
        }}>
            <Outlet />
        </RecoverRequestsContext.Provider>
    )
}

export function useRecoverRequestsContext() {
    return useContext(RecoverRequestsContext);
}
