import { Outlet, Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

type Props = {
    allowedPermissions: string[];
}

export default function RequireAuthorization({ allowedPermissions }: Props) {
    const { user } = useAuth();
    const location = useLocation();

    return (
        user && user?.permissions?.find((p) => allowedPermissions.includes(p.name))
            ? <Outlet />
            : <Navigate to="/inautorizado" state={{ from: location }} replace />
    )
}