import { Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { IAward } from "../../../../../../../interfaces";

interface IProps {
    award: IAward,
    onClickEditAward: (award: IAward) => void,
    onClickDeleteAward: () => void,
    disableDelete?: boolean,
}

export default function AwardsTableRowActions({
    award,
    onClickEditAward,
    onClickDeleteAward,
    disableDelete = false,
}: IProps) {
    return (
        <>
            <Button
                onClick={() => onClickEditAward(award)}
                style={{
                    marginRight: 8
                }}
            >
                <EditOutlined />
            </Button>
            <Button
                type="primary"
                danger
                onClick={onClickDeleteAward}
                disabled={disableDelete}
            >
                <DeleteOutlined />
            </Button>
        </>
    )
}