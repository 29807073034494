import { Tag } from "antd";

import { EGiveawayStatus } from "../../../../enums";

interface IProps {
    status: EGiveawayStatus;
    isValid: boolean;
}

export default function GiveawayStatusTag({ status, isValid }: IProps) {
    const type = !isValid ? "error" :
        status === "Finalizado" ? "success"
            : status === "Em verificação" ? "warning"
                : "default";

    const statusText = isValid ? status : 'Inválido';

    return (
        <Tag
            color={type}
        >
            {statusText}
        </Tag>
    )
}