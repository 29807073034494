import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Row, Tag } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";

import { ITicket } from "../../interfaces";

import EmptyListMessage from "../EmptyListMessage";
import TicketCard from "./components/TicketCard";
import LoadingMessage from "./components/LoadingMessage";
import EndMessage from "./components/EndMessage";

interface IProps {
    tickets: Array<ITicket>;
    paginated: boolean;
    isLoading: boolean;
    emptyListMessage: string;
    handleClickGoBack: () => void;
    title?: string;
    loadMore?: () => void;
    hasMore?: boolean;
    giveawayId?: number | string;
    totalTickets?: number;
}

export default function TicketsList({
    tickets,
    paginated,
    isLoading,
    emptyListMessage,
    handleClickGoBack,
    title,
    loadMore,
    hasMore,
    giveawayId,
    totalTickets,
}: IProps) {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        type="link"
                        onClick={handleClickGoBack}
                    >
                        <LeftOutlined />
                    </Button>
                    <Title
                        level={3}
                        style={{
                            margin: 0,
                        }}
                    >
                        {title}
                    </Title>
                </div>

                {
                    tickets.length > 0 && (
                        <Tag
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '4px 8px',
                            }}
                        >
                            <Text>
                                Total de bilhetes:
                            </Text>
                            <Title
                                level={5}
                                style={{
                                    margin: 0,
                                    marginLeft: 4,
                                }}
                            >
                                {totalTickets?.toLocaleString('pt-BR') || tickets.length.toLocaleString('pt-BR')}
                            </Title>
                        </Tag>
                    )
                }
            </div>

            {
                paginated && loadMore && hasMore ? (
                    <InfiniteScroll
                        dataLength={tickets.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<LoadingMessage />}
                        endMessage={tickets.length > 0 ? <EndMessage /> : undefined}
                        style={{
                            overflowX: 'hidden',
                        }}
                    >
                        <Row
                            gutter={[16, 16]}
                            style={{
                                marginTop: 50,
                            }}
                        >
                            {
                                tickets.map((ticket) => (
                                    <TicketCard
                                        ticket={ticket}
                                        key={ticket.id}
                                    />
                                ))
                            }
                        </Row>
                    </InfiniteScroll>
                ) : (
                    <Row
                        gutter={[16, 16]}
                        style={{
                            marginTop: 50
                        }}
                    >
                        {
                            tickets.map((ticket) => (
                                <TicketCard
                                    ticket={ticket}
                                    giveawayId={giveawayId}
                                    key={ticket.id}
                                />
                            ))
                        }

                    </Row>
                )
            }

            {
                tickets.length === 0 && (
                    <EmptyListMessage isLoading={isLoading} message={emptyListMessage} />
                )
            }
        </>
    )
}