import { Button } from 'antd';

import useCities from './useCities';
import Table from '../../components/Table';
import SimpleModal from '../../components/SimpleModal';

export default function Cities() {
    const {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewCity,
        handleClickEditCity,
        handleDeleteCity,
        handleConfirmDeleteCity,
        handleCancelDeleteCity,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        cityBeingDeleted,
    } = useCities();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={handleClickCreateNewCity}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickEditCity}
                    disabled={selectedIds.length !== 1}
                >
                    Editar
                </Button>
                <Button
                    type="primary"
                    danger
                    style={{ margin: 2 }}
                    onClick={() => {
                        handleDeleteCity(selectedIds[0])
                    }}
                    disabled={selectedIds.length !== 1}
                >
                    Deletar
                </Button>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
            >

            </Table>
            <SimpleModal
                open={isDeleteModalVisible}
                onOk={handleConfirmDeleteCity}
                okButtonProps={{
                    disabled: isLoadingDelete,
                    loading: isLoadingDelete
                }}
                cancelButtonProps={{
                    disabled: isLoadingDelete
                }}
                okText='Deletar'
                onCancel={handleCancelDeleteCity}
                title={`Deseja realmente deletar "${cityBeingDeleted?.city_uf}"?`}
            />
        </>
    )
}
