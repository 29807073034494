import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Breadcrumb, Button, Col, Divider, Form, Input, Radio, Row, Select, UploadFile } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { MessageOutlined, InboxOutlined, LeftOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { UploadChangeParam } from 'antd/es/upload';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ImgCrop from 'antd-img-crop';

import { IMessage } from '../../../../interfaces';

import useMessageForm from './useMessageForm';
import { removeAcento } from '../../../../utils/RemoveAcentos';
import { formatCpf, formatMessageDate } from '../../../../utils/Masks';
import { ThemeContext } from '../../../../contexts/ThemeContext';
import AppUsersModal from './AppUsersModal';
import { colInputs } from './GridStyle';

interface IProps {
    action: 'Adicionar' | 'Editar';
    message?: IMessage;
    onSubmit: (formData: FormData) => Promise<void>;
}

export default function MessageForm({ action, message, onSubmit }: IProps) {
    const {
        isSubmiting,
        isLoadingAppUsers,
        handleSubmit,
        handleSubmitForm,
        dummyRequest,
        appUsers,
        control,
        errors,
        defaultEditorState,
        editorState,
        handleChangeImage,
        imageUrl,
        onChangeEditor,
        onChangeIsDirectMessage,
        isGeneral,
        isDirectMessage,
        isEditing,
        handleCloseAppUsersModal,
        handleOpenAppUsersModal,
        isViewAppUsersModalVisible,
        acceptedImageTypes,
        body,
        title,
        loggedInUserName,
    } = useMessageForm(onSubmit, message);

    const { theme } = useContext(ThemeContext);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><MessageOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Mensagem {isGeneral ? 'Geral' : ''}</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to={`/mensagens${isGeneral ? '?type=general' : ''}`}>Mensagens {isGeneral ? 'Gerais' : ''}</Link>,
                            },
                            {
                                title: `${action} Mensagem ${isGeneral ? 'Geral' : ''}`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col {...colInputs}>
                        <Row>
                            <Col
                                span={24}
                            >
                                <Form.Item
                                    label="Título"
                                    required
                                    validateStatus={errors.title ? 'error' : 'success'}
                                    help={errors.title?.message}
                                >
                                    <Controller
                                        name="title"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Por favor, digite o título da Mensagem!'
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "O título precisa ter no máximo 100 caracteres"
                                            }
                                        }}
                                        render={({ field }) => <Input {...field} placeholder='Título' showCount />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row
                            gutter={16}
                        >
                            <Col
                                span={24}
                            >
                                <Form.Item
                                    label="Conteúdo"
                                    required
                                    validateStatus={errors.content ? 'error' : 'success'}
                                    help={errors.content?.message}
                                    tooltip="Conteúdo que aparecerá somente na notificação."
                                >
                                    <Controller
                                        name="content"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Por favor, digite o conteúdo da Mensagem!'
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "O conteúdo precisa ter no máximo 255 caracteres"
                                            }
                                        }}
                                        render={({ field }) => <Input {...field} placeholder='Conteúdo' showCount />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {
                            isGeneral && isEditing.current && (
                                <Row
                                    gutter={16}
                                >
                                    <Col
                                        span={24}
                                    >
                                        <Form.Item
                                            label="Usuários"
                                        >
                                            <Button
                                                onClick={handleOpenAppUsersModal}
                                                type='primary'
                                            >
                                                Visualizar usuários
                                            </Button>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }

                        {
                            !isGeneral && (
                                <Row
                                    gutter={16}
                                >
                                    <Col
                                        span={24}
                                    >
                                        <Form.Item
                                            label="Tipo"
                                            required
                                        >
                                            <Radio.Group
                                                value={isDirectMessage}
                                                onChange={(e) => onChangeIsDirectMessage(e.target.value)}
                                            >
                                                <Radio.Button
                                                    value={false}
                                                    disabled={isEditing.current && isDirectMessage}
                                                >
                                                    Mensagem Geral
                                                </Radio.Button>
                                                <Radio.Button
                                                    value={true}
                                                    disabled={isEditing.current && !isDirectMessage}
                                                >
                                                    Mensagem Direta
                                                </Radio.Button>
                                            </Radio.Group>

                                            {
                                                isEditing.current && (
                                                    <>
                                                        <Divider type='vertical' style={{ borderWidth: 2 }} />
                                                        <Button
                                                            onClick={handleOpenAppUsersModal}
                                                            type='primary'
                                                        >
                                                            Visualizar usuários
                                                        </Button>
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    {
                                        isDirectMessage && !isEditing.current && (
                                            <Col
                                                span={24}
                                            >
                                                <Form.Item
                                                    label="Usuários"
                                                    validateStatus={errors.app_user_ids ? 'error' : 'success'}
                                                    help={errors.app_user_ids?.message}
                                                    required
                                                >
                                                    <Controller
                                                        name="app_user_ids"
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Por favor, selecione pelo menos um usuário!"
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                mode='multiple'
                                                                allowClear
                                                                filterOption={(search, option) => removeAcento(option?.label || '').includes(removeAcento(search))}
                                                                options={appUsers.map((appUser) => ({ value: appUser.id, label: `${formatCpf(appUser.cpf)} • ${appUser.name}` }))}
                                                                placeholder='Selecione os usuários'
                                                                disabled={isLoadingAppUsers}
                                                                loading={isLoadingAppUsers}
                                                            />
                                                        )}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )
                                    }
                                </Row>
                            )
                        }

                        <Row
                            gutter={16}
                        >
                            <Col
                                {...colInputs}
                            >
                                <Form.Item
                                    label="Imagem"
                                    validateStatus={errors.image ? 'error' : 'success'}
                                    help={errors.image?.message}
                                >
                                    <Controller
                                        name="image"
                                        control={control}
                                        render={({ field: { value, onChange, ...field } }) => (
                                            <ImgCrop
                                                modalOk="Concluído"
                                                modalCancel="Cancelar"
                                                modalTitle="Editar"
                                                aspect={1.91 / 1}
                                            >
                                                <Dragger
                                                    {...field}
                                                    showUploadList={false}
                                                    customRequest={dummyRequest}
                                                    onChange={(e: UploadChangeParam<UploadFile>) => handleChangeImage({ onChangeHookForm: onChange, file: e.file })}
                                                    accept={acceptedImageTypes.toString()}
                                                >
                                                    {
                                                        imageUrl && typeof imageUrl === 'string' ? (
                                                            <img
                                                                src={imageUrl}
                                                                alt="Imagem"
                                                                style={{
                                                                    width: '50%',
                                                                }}
                                                            />
                                                        )
                                                            : (
                                                                <>
                                                                    <p
                                                                        className="ant-upload-drag-icon"
                                                                    >
                                                                        <InboxOutlined />
                                                                    </p>
                                                                    <Text
                                                                        style={{
                                                                            margin: '0 8px',
                                                                        }}
                                                                        className="ant-upload-text"
                                                                    >
                                                                        Clique ou arraste o arquivo para fazer o upload
                                                                    </Text>
                                                                </>
                                                            )
                                                    }
                                                </Dragger>
                                            </ImgCrop>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                span={24}
                            >
                                <Form.Item
                                    label="Mensagem"
                                    required
                                    validateStatus={errors.body ? 'error' : 'success'}
                                    help={errors.body?.message}
                                >
                                    <Editor
                                        localization={{
                                            translations: {
                                                // Generic
                                                'generic.add': 'Adicionar',
                                                'generic.cancel': 'Cancelar',

                                                // Color Picker
                                                'components.controls.colorpicker.colorpicker': 'Color Picker',
                                                'components.controls.colorpicker.text': 'Texto',
                                                'components.controls.colorpicker.background': 'Destacar',

                                                // Emoji
                                                'components.controls.emoji.emoji': 'Emoji',

                                                // FontFamily
                                                'components.controls.fontfamily.fontfamily': 'Fonte',

                                                // FontSize
                                                'components.controls.fontsize.fontsize': 'Tamanho da fonte',

                                                // History
                                                'components.controls.history.history': 'Histórico',
                                                'components.controls.history.undo': 'Desfazer',
                                                'components.controls.history.redo': 'Refazer',

                                                // Inline
                                                'components.controls.inline.bold': 'Negrito',
                                                'components.controls.inline.italic': 'Italico',

                                                // Link
                                                'components.controls.link.linkTitle': 'Título',
                                                'components.controls.link.linkTarget': 'Link',
                                                'components.controls.link.linkTargetOption': 'Abrir link em nova janela',
                                                'components.controls.link.link': 'Link',
                                                'components.controls.link.unlink': 'Remover Link',

                                                // List
                                                'components.controls.list.list': 'Lista',
                                                'components.controls.list.unordered': 'Não Ordenada',
                                                'components.controls.list.ordered': 'Ordenada',

                                                // TextAlign
                                                'components.controls.textalign.textalign': 'Alinhar Texto',
                                                'components.controls.textalign.left': 'Esquerda',
                                                'components.controls.textalign.center': 'Centro',
                                                'components.controls.textalign.right': 'Direita',
                                                'components.controls.textalign.justify': 'Justificar',
                                            }
                                        }}
                                        editorState={editorState}
                                        defaultEditorState={
                                            defaultEditorState
                                        }
                                        toolbarStyle={{
                                            backgroundColor: theme === "dark" ? "#212121" : "#FFFFFF",
                                            borderColor: theme === "dark" ? "#333333" : "#dddddd",
                                            color: '#254da8'
                                        }}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        editorStyle={{
                                            maxHeight: 250,
                                            border: `1px solid ${errors.body?.message ? "#FC5050" : theme === "dark" ? "#333333" : "#dddddd"}`,
                                            padding: 5,
                                            paddingBottom: 10,
                                            borderRadius: 2,
                                        }}
                                        onEditorStateChange={onChangeEditor}
                                        toolbar={{
                                            options: [
                                                "inline",
                                                "fontSize",
                                                "fontFamily",
                                                "list",
                                                "textAlign",
                                                "colorPicker",
                                                "history",
                                                "emoji",
                                                "link"
                                            ],
                                            inline: {
                                                options: ["bold", "italic"],
                                            },
                                            blockType: {
                                                options: [
                                                    "Normal",
                                                    "H1",
                                                    "H2",
                                                    "H3",
                                                    "H4",
                                                    "H5",
                                                    "H6",
                                                    "Blockquote",
                                                    "Code",
                                                ],
                                            },
                                            fontFamily: {
                                                options: [
                                                    "Montserrat-Bold",
                                                    "Montserrat-SemiBold",
                                                    "Montserrat-Medium",
                                                    "Montserrat-Regular",
                                                ],
                                            },
                                            colorPicker: {
                                                colors: [
                                                    "rgb(97,189,109)",
                                                    "rgb(26,188,156)",
                                                    "rgb(84,172,210)",
                                                    "rgb(44,130,201)",
                                                    "rgb(147,101,184)",
                                                    "rgb(71,85,119)",
                                                    "rgb(204,204,204)",
                                                    "rgb(65,168,95)",
                                                    "rgb(0,168,133)",
                                                    "rgb(61,142,185)",
                                                    "rgb(41,105,176)",
                                                    "rgb(85,57,130)",
                                                    "rgb(40,50,78)",
                                                    "rgb(0,0,0)",
                                                    "rgb(247,218,100)",
                                                    "rgb(251,160,38)",
                                                    "rgb(235,107,86)",
                                                    "rgb(226,80,65)",
                                                    "rgb(163,143,132)",
                                                    "rgb(239,239,239)",
                                                    "rgb(255,255,255)",
                                                    "rgb(250,197,28)",
                                                    "rgb(243,121,52)",
                                                    "rgb(209,72,65)",
                                                    "rgb(184,49,47)",
                                                    "rgb(124,112,107)",
                                                    "rgb(209,213,216)",
                                                ],
                                            },
                                            list: {
                                                options: [
                                                    "unordered",
                                                    "ordered",
                                                ],
                                            },
                                            textAlign: {
                                                options: [
                                                    "left",
                                                    "center",
                                                    "right",
                                                    "justify",
                                                ],
                                                left: "left",
                                                center: "center",
                                                right: "right",
                                                link: "justify",
                                            },
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>


                    <Col
                        {...colInputs}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div className="mockup-phone">
                            <div className="camera"></div>
                            <div className="display">
                                <div className="artboard artboard-demo phone-1 font-montserrat">
                                    <div className="flex w-full items-center justify-start pl-2 pt-8 pb-2 bg-app-blue">
                                        <LeftOutlined style={{ color: '#FFFFFF' }} />
                                        <span className="text-white font-bold ml-3">Mensagem</span>
                                    </div>
                                    <div className="flex flex-col flex-1 w-full bg-app-blue overflow-y-scroll no-scrollbar">
                                        <div className={`flex flex-col ${theme === "light" ? 'text-black' : 'text-app-dark-text'} ${theme === "light" ? 'bg-app-white-bg' : 'bg-app-dark-bg'} px-3 py-2`}>
                                            <span className='font-bold'>{title || 'Título'}</span>
                                            <span className={`text-xs ${theme === "light" ? 'text-app-hint-light' : 'text-app-hint-dark'}`}>Por {message?.panel_user?.name || loggedInUserName} • {formatMessageDate(message?.created_at ? new Date(message.created_at) : new Date())}</span>
                                        </div>
                                        <div className={`w-full ${theme === "light" ? 'bg-app-muted-200-light' : 'bg-app-muted-200-dark'} h-px`}></div>
                                        {
                                            imageUrl && typeof imageUrl === "string" && (
                                                <div className={`pt-2 ${theme === "light" ? 'bg-app-white-bg' : 'bg-app-dark-bg'}`}>
                                                    <img
                                                        src={imageUrl}
                                                        alt="app-preview"
                                                        className='w-full'
                                                    />
                                                </div>
                                            )
                                        }
                                        <div
                                            className={`flex-1 ${theme === "light" ? 'bg-app-white-bg' : 'bg-app-dark-bg'} pt-2 px-2 ${theme === "light" ? 'text-black' : 'text-app-dark-text'}`}
                                            dangerouslySetInnerHTML={{
                                                __html: body
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row
                    style={{
                        marginTop: 16
                    }}
                >
                    <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmiting || isLoadingAppUsers}
                    >
                        Salvar
                    </Button>
                </Row>
            </Form>

            {
                isViewAppUsersModalVisible && (
                    <AppUsersModal
                        onClose={handleCloseAppUsersModal}
                    />
                )
            }
        </>
    )
}
