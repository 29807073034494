import { Row } from "antd";
import Text from "antd/es/typography/Text";

export default function EndMessage() {
    return (
        <Row
            key="0"
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 8,
            }}
        >
            <Text>Fim da lista</Text>
        </Row>
    )
}