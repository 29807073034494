import { Button, Tag } from 'antd';

import usePanelUsers from './usePanelUsers';
import Table from '../../components/Table';
import { IPanelUser } from '../../interfaces';
import SimpleModal from '../../components/SimpleModal';

export default function PanelUsers() {
    const {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewPanelUser,
        handleClickEditPanelUser,
        handleDeletePanelUser,
        handleConfirmDeletePanelUser,
        handleCancelDeletePanelUser,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        panelUserBeingDeleted,
    } = usePanelUsers();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={handleClickCreateNewPanelUser}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickEditPanelUser}
                    disabled={selectedIds.length !== 1}
                >
                    Editar
                </Button>
                <Button
                    type="primary"
                    danger
                    style={{ margin: 2 }}
                    onClick={() => {
                        handleDeletePanelUser(selectedIds[0])
                    }}
                    disabled={selectedIds.length !== 1}
                >
                    Deletar
                </Button>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
                expandable={{
                    expandedRowRender: (user: IPanelUser) => user.cities.map((city) => (
                        <Tag key={city.id}>{city.city} - {city.uf}</Tag>
                    )),
                }}
            >

            </Table>
            <SimpleModal
                open={isDeleteModalVisible}
                onOk={handleConfirmDeletePanelUser}
                okButtonProps={{ 
                    disabled: isLoadingDelete,
                    loading: isLoadingDelete
                }}
                cancelButtonProps={{
                    disabled: isLoadingDelete
                }}
                okText='Deletar'
                onCancel={handleCancelDeletePanelUser}
                title={`Deseja realmente deletar "${panelUserBeingDeleted?.name}"?`}
            />
        </>
    )
}
