import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UploadFile } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { AxiosError } from "axios";

import { IAward, IAwardType, IGiveaway } from "../../../../../../interfaces";

import { EmitError } from "../../../../../../utils/EmitError";
import { EmitSuccessAlert } from "../../../../../../utils/EmitSuccessAlert";
import AwardsService, { IAwardsOrderObject } from "../../../../../../services/http/services/AwardsService";
import AwardMapper from "../../../../../../services/http/mappers/AwardMapper";
import { formatCurrencyInput } from "../../../../../../utils/Masks";
import { formatCurrencyForPersistence } from "../../../../../../utils/Conversores";
import AwardTypesService from "../../../../../../services/http/services/AwardTypesService";
import { awardImagesUrl } from "../../../../../../services/urls";

const ACCEPTED_IMAGE_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
]

export default function useAwardsStep(
    giveaway: IGiveaway | undefined, 
    onAwardsListLengthChange?: (awardsListLength: number) => void
) {
    const [awards, setAwards] = useState<Array<IAward>>([]);
    const [awardTypes, setAwardTypes] = useState<Array<IAwardType>>([]);
    const [fileList, setFileList] = useState<Array<UploadFile>>();
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [awardBeingEdited, setAwardBeingEdited] = useState<IAward | undefined>();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [isConfirmOverwriteFieldsToEditModalVisible, setIsConfirmOverwriteFieldsToEditModalVisible] = useState<boolean>(false);
    const [isConfirmFinishModalVisible, setIsConfirmFinishModalVisible] = useState<boolean>(false);
    const [awardBeingDeleted, setAwardBeingDeleted] = useState<IAward | undefined>();
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isOrderUnsaved, setIsOrderUnsaved] = useState<boolean>(false);
    const [isLoadingAwardTypes, setIsLoadingAwardTypes] = useState<boolean>(false);
    const [selectedTypeDefaultImageUrl, setSelectedTypeDefaultImageUrl] = useState<string | undefined>();

    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        setValue,
        clearErrors,
        setError,
        watch,
    } = useForm<IAward>({
        defaultValues: {
            giveaway_id: giveaway?.id,
            description: "",
            type_id: undefined,
            value: undefined,
            id: undefined,
            images: []
        }
    });

    const typeId = watch('type_id');

    const orderedAwardTypes = useMemo(() => {
        const ordered = awardTypes.sort((a, b) => a.name.localeCompare(b.name));

        // Coloca a opção "Outro" sempre no final da lista
        const otherTypeIndex = ordered.findIndex((type) => type.name.trim().toLowerCase() === "outro");
        if (otherTypeIndex >= 0) {
            ordered.push(ordered.splice(otherTypeIndex, 1)[0]);
        }

        return ordered;
    }, [awardTypes]);

    const handleSubmitForm: SubmitHandler<IAward> = useCallback(async (data) => {
        if (awardBeingEdited) {
            try {
                setIsSubmiting(true);

                const formData = new FormData();

                if (fileList && fileList[0]?.originFileObj) {
                    formData.append('image', fileList[0].originFileObj);
                }

                if (typeof data.value === 'string') {
                    data.value = formatCurrencyForPersistence(data.value);
                }

                const persistenceAward = AwardMapper.toPersistence(data);

                Object.keys(persistenceAward).forEach((fieldName: string) => {
                    if (persistenceAward[fieldName as keyof IAward]) {
                        formData.append(fieldName, String(persistenceAward[fieldName as keyof IAward]))
                    }
                });

                const updatedAward = await AwardsService.updateAward(awardBeingEdited.id, formData);

                setAwards((prevState) => [
                    ...prevState.filter((award) => award.id !== awardBeingEdited.id),
                    updatedAward,
                ])

                reset();
                setFileList([]);
                setAwardBeingEdited(undefined);

                EmitSuccessAlert('Prêmio atualizado com sucesso!');
            } catch (err) {
                EmitError(err, true, 'editAward_ijapsdjasdp');
            } finally {
                setIsSubmiting(false);
                return;
            }
        }

        try {
            setIsSubmiting(true);

            const formData = new FormData();

            if (fileList && fileList[0]?.originFileObj) {
                formData.append('image', fileList[0].originFileObj);
            }

            if (typeof data.value === 'string') {
                data.value = formatCurrencyForPersistence(data.value);
            }

            const persistenceAward = AwardMapper.toPersistence(data);

            Object.keys(persistenceAward).forEach((fieldName: string) => {
                if (persistenceAward[fieldName as keyof IAward]) {
                    formData.append(fieldName, String(persistenceAward[fieldName as keyof IAward]))
                }
            });

            const newAward = await AwardsService.createAward(formData);

            setAwards((prevState) => [
                ...prevState,
                newAward
            ])

            reset();
            setFileList([]);

            EmitSuccessAlert('Prêmio adicionado com sucesso!');
        } catch (err) {
            EmitError(err, true, 'newAward_ijapsdjasdp');
        } finally {
            setIsSubmiting(false);
        }

    }, [fileList, awardBeingEdited, reset]);

    const dummyRequest = useCallback(({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const onFinish = useCallback(() => {
        navigate('/sorteios');
    }, [navigate]);

    const handleClickFinish = useCallback(() => {
        const hasAddedFiles = fileList && fileList?.length > 0;

        if (isDirty || hasAddedFiles) {
            setIsConfirmFinishModalVisible(true);
            return;
        }

        onFinish();
    }, [fileList, onFinish, isDirty]);

    const handleConfirmFinish = useCallback(() => {
        setIsConfirmFinishModalVisible(false);
        onFinish();
    }, [onFinish]);

    const handleCancelFinish = useCallback(() => {
        setIsConfirmFinishModalVisible(false);
    }, []);

    const handleFileListChange = useCallback(({ fileList: newFileList }: UploadChangeParam<UploadFile>) => {
        if (newFileList.length > 0 && newFileList[0].type && !ACCEPTED_IMAGE_TYPES.includes(newFileList[0].type.toLowerCase())) {
            setError('images', { message: 'Formato de imagem inválido! Os formatos aceitos são: PNG, JPG, JPEG e WEBP.' });
            setFileList([]);
            return;
        }

        clearErrors('images');
        setFileList(newFileList);
    }, [clearErrors, setError]);

    const loadGiveawayAwards = useCallback(async (signal?: AbortSignal) => {
        try {
            const awardsList = await AwardsService.listAwardsByGiveawayId(giveaway?.id!, signal);

            setAwards(awardsList);
        } catch (err) {
            EmitError(err);
        }
    }, [giveaway]);

    useEffect(() => {
        if (!giveaway) {
            return;
        }

        const controller = new AbortController();

        loadGiveawayAwards(controller.signal);

        return () => {
            controller.abort();
        }
    }, [giveaway, loadGiveawayAwards]);

    const setBeingEditedAwardOnFields = useCallback((award: IAward) => {
        if (!award) {
            return;
        }

        setValue("description", award.description);
        setValue("type_id", award.type_id);
        setValue("value", typeof award.value === 'number' ? formatCurrencyInput(award.value.toFixed(2)) : 0);
        setFileList([]);
        clearErrors();
    }, [setValue, clearErrors]);

    const handleConfirmOverwriteFields = useCallback(() => {
        if (!awardBeingEdited) {
            return;
        }

        setBeingEditedAwardOnFields(awardBeingEdited);
        setIsConfirmOverwriteFieldsToEditModalVisible(false);
    }, [awardBeingEdited, setBeingEditedAwardOnFields]);

    const handleCancelOverwriteFields = useCallback(() => {
        setIsConfirmOverwriteFieldsToEditModalVisible(false);
        setAwardBeingEdited(undefined);
    }, []);

    const handleClickEditAward = useCallback((award: IAward) => {
        setAwardBeingEdited(award);

        const hasAddedFiles = fileList && fileList?.length > 0;

        if (isDirty || hasAddedFiles) {
            setIsConfirmOverwriteFieldsToEditModalVisible(true);
            return;
        }

        setBeingEditedAwardOnFields(award);
    }, [isDirty, fileList, setBeingEditedAwardOnFields]);

    const handleCancelEditAward = useCallback(() => {
        setAwardBeingEdited(undefined);
        setFileList([]);
        reset();
    }, [reset]);

    const handleClickDeleteAward = useCallback((award: IAward) => {
        setAwardBeingDeleted(award);
        setIsDeleteModalVisible(true);
    }, []);

    const handleConfirmDeleteAward = useCallback(async () => {
        if (!awardBeingDeleted) {
            return;
        }

        try {
            setIsLoadingDelete(true);

            await AwardsService.deleteAward(awardBeingDeleted?.id);

            setAwards((prevState) => prevState.filter((award) => award.id !== awardBeingDeleted.id));

            if (awardBeingEdited) {
                if (awardBeingDeleted.id === awardBeingEdited.id) {
                    handleCancelEditAward();
                }
            }
            EmitSuccessAlert("Prêmio deletado com sucesso!")
            setAwardBeingDeleted(undefined);
            setIsDeleteModalVisible(false);
        } catch (err) {
            EmitError(err, true, 'editAward_ijapsdjasdp');
        } finally {
            setIsLoadingDelete(false);
        }
    }, [awardBeingDeleted, awardBeingEdited, handleCancelEditAward]);

    const handleCancelDeleteAward = useCallback(() => {
        setAwardBeingDeleted(undefined);
        setIsDeleteModalVisible(false);
    }, []);

    const handleUpdateAwardsOrder = useCallback(async (awardsOrder: Array<IAwardsOrderObject>) => {
        try {
            await AwardsService.updateAwardsOrder(awardsOrder);

            await loadGiveawayAwards();
        } catch (err) {
            EmitError(err);
        }
    }, [loadGiveawayAwards]);

    const handleOrderChanged = useCallback((isEqual: boolean) => {
        setIsOrderUnsaved(!isEqual);
    }, []);

    const awardBeingEditedImageIsDefault = useMemo(() => {
        return Boolean(awardTypes.find((a) => a.default_image === awardBeingEdited?.images[0].img));
    }, [awardTypes, awardBeingEdited]);

    useEffect(() => {
        if (!onAwardsListLengthChange) {
            return;
        }

        onAwardsListLengthChange(awards.length);
    }, [onAwardsListLengthChange, awards]);

    useEffect(() => {
        const controller = new AbortController();

        async function loadAwardTypes() {
            try {
                setIsLoadingAwardTypes(true);

                const awardTypesList = await AwardTypesService.listAwardTypes(controller.signal);

                setAwardTypes(awardTypesList);
                setIsLoadingAwardTypes(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                EmitError(err);
                setIsLoadingAwardTypes(false);
            }
        }

        loadAwardTypes();

        return () => controller.abort();
    }, []);

    useEffect(() => {
        if (!typeId) {
            setSelectedTypeDefaultImageUrl(undefined);
            return;
        }

        const selectedType = awardTypes.find((type) => type.id === typeId);

        setSelectedTypeDefaultImageUrl(`${awardImagesUrl}/${selectedType?.default_image}`)
    }, [typeId, awardTypes]);

    return {
        isSubmiting,
        handleSubmit,
        handleSubmitForm,
        control,
        errors,
        dummyRequest,
        fileList,
        handleFileListChange,
        awards,
        orderedAwardTypes,
        isLoadingAwardTypes,
        selectedTypeDefaultImageUrl,
        awardBeingEditedImageIsDefault,
        handleClickEditAward,
        awardBeingEdited,
        handleCancelEditAward,
        handleClickDeleteAward,
        handleConfirmDeleteAward,
        isDeleteModalVisible,
        handleCancelDeleteAward,
        awardBeingDeleted,
        isLoadingDelete,
        isConfirmOverwriteFieldsToEditModalVisible,
        isConfirmFinishModalVisible,
        handleConfirmOverwriteFields,
        handleCancelOverwriteFields,
        handleClickFinish,
        handleConfirmFinish,
        handleCancelFinish,
        handleUpdateAwardsOrder,
        isOrderUnsaved,
        handleOrderChanged,
        ACCEPTED_IMAGE_TYPES,
    }
}