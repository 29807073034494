import { Modal, Row } from "antd";
import { IReward } from "../../../../../interfaces";
import { urlHttpGov } from "../../../../../services/urls";

interface IProps {
    isVisible: boolean,
    onOk: () => void,
    reward?: IReward,
}

export default function RewardImageModal({ isVisible, onOk, reward }: IProps) {
    if (!reward) {
        return null;
    }

    return (
        <Modal
            open={isVisible}
            onOk={onOk}
            okType='default'
            okText='Fechar'
            cancelButtonProps={{
                style: {
                    display: 'none',
                }
            }}
            onCancel={onOk}
        >
            <Row>
                <img
                    src={`${urlHttpGov}/image_reward/${reward.image}`}
                    alt="Imagem da recompensa"
                    style={{
                        width: '100%', 
                    }}
                />
            </Row>
        </Modal>
    )
}