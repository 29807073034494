import { ChangeEvent, useCallback, useState } from "react";
import { Col, Form, Input, ModalProps, Row } from "antd";

import SimpleModal from "../../../../../components/SimpleModal";
import AppUsersList from "../AppUsersList";
import { formatCpf } from "../../../../../utils/Masks";

interface IProps extends ModalProps {
    onClose: () => void;
}

export default function AppUsersModal({ onClose }: IProps) {
    const [name, setName] = useState<string>('');
    const [cpf, setCpf] = useState<string>('');

    const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const onChangeCpf = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCpf(formatCpf(e.target.value));
    }, []);

    return (
        <SimpleModal
            open={true}
            title="Usuários"
            onOk={onClose}
            onCancel={onClose}
            okButtonProps={{
                type: 'default'
            }}
            cancelButtonProps={{
                hidden: true
            }}
            okText="Fechar"
            okType="primary"
            closable
        >
            <div id="scrollableDiv">
                <Form>
                    <Row
                        gutter={8}
                        style={{
                            marginTop: 24
                        }}
                    >
                        <Col
                            span={12}
                        >
                            <Form.Item
                                label="Nome"
                            >
                                <Input
                                    value={name}
                                    onChange={onChangeName}
                                />

                            </Form.Item>
                        </Col>
                        <Col
                            span={12}
                        >
                            <Form.Item
                                label="CPF"
                            >
                                <Input
                                    value={cpf}
                                    onChange={onChangeCpf}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <AppUsersList
                    filters={{
                        name,
                        cpf
                    }}
                />
            </div>
        </SimpleModal>
    )
}