import { Button } from 'antd';

import useRecoverRequests from './useRecoverRequests';
import Table from '../../components/Table';

export default function RecoverRequests() {
    const {
        isLoading,
        handleClickViewRequest,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
    } = useRecoverRequests();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={handleClickViewRequest}
                    disabled={selectedIds.length !== 1}
                >
                    Visualizar
                </Button>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
            />
        </>
    )
}
