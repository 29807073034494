import GiveawayForm from '../components/GiveawayForm';
import useNewGiveaway from './useNewGiveaway';

export default function NewGiveaway() {
    const {
        handleSubmit,
        createdGiveaway
    } = useNewGiveaway();

    return (
        <GiveawayForm action='Adicionar' onSubmit={handleSubmit} createdGiveaway={createdGiveaway} />
    )
}
