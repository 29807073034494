import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputRef, Space, Tag } from "antd";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from '@ant-design/icons';

import { IRecoverRequest } from "../../interfaces";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";

import { formatCpf } from "../../utils/Masks";
import { useRecoverRequestsContext } from "../../contexts/RecoverRequestsContext";
import { removeAcento } from "../../utils/RemoveAcentos";
import { formatDateStringToDateAndTime } from "../../utils/Conversores";

interface ITableData extends IRecoverRequest {
    key: number | undefined;
    app_user_name: string;
    app_user_cpf: string;
}

export default function useRecoverRequests() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { recoverRequests, loadRecoverRequests } = useRecoverRequestsContext();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ITableData, inputColumnName: string, formatter?: (text: string) => string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) =>
            removeAcento(record[dataIndex]!.toString()).toLowerCase().includes(removeAcento(value).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'app_user_name',
            key: 'app_user_name',
            sorter: (a: ITableData, b: ITableData) => a.app_user_name.localeCompare(b.app_user_name),
            showSorterTooltip: false,
            ...getColumnSearchProps('app_user_name', 'Nome')
        },
        {
            title: 'CPF',
            dataIndex: 'app_user_cpf',
            key: 'app_user_cpf',
            ...getColumnSearchProps('app_user_cpf', 'CPF', formatCpf),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Em Análise',
                    value: 'Em Análise',
                },
                {
                    text: 'Recusado',
                    value: 'Recusado!',
                },
                {
                    text: 'Aprovado',
                    value: 'Aprovado!',
                },
            ],
            onFilter: (value: any, record: IRecoverRequest) => {
                return record.status === value;
            },
            render: (_: any, request: any) => (
                <Tag
                    key={request.id}
                    color={request.status === "Aprovado!" ? "success" : request.status === "Recusado!" ? "error" : "default"}
                >
                    {request.status}
                </Tag>
            ),
            sorter: (a: any, b: any) => a.status.localeCompare(b.status),
            showSorterTooltip: false,
        },
        {
            title: 'Data de resposta',
            dataIndex: 'date_response',
            key: 'date_response',
            render: (record: any) => record ? formatDateStringToDateAndTime(new Date(record).toString()) : '',
            defaultSortOrder: 'ascend' as keyof SortOrder,
            sorter: {
                compare: (a: any, b: any) => new Date(a.date_response).getTime() - new Date(b.date_response).getTime(),
                multiple: 2,
            },
            showSorterTooltip: false,
        },
        {
            title: 'Data da solicitação',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (record: any) => formatDateStringToDateAndTime(new Date(record).toString()),
            defaultSortOrder: 'ascend' as keyof SortOrder,
            sorter: {
                compare: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
                multiple: 1
            },
            showSorterTooltip: false,
        },
    ]

    const setTableDataFromRequest = useCallback((data: Array<IRecoverRequest>) => {
        const newData = data.map((request) => ({
            ...request,
            key: request.id,
            app_user_name: request.app_user.name,
            app_user_cpf: request.app_user.cpf,
        }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        setTableDataFromRequest(recoverRequests);
    }, [recoverRequests, setTableDataFromRequest]);

    useEffect(() => {
        const controller = new AbortController();

        async function loadData() {
            setIsLoading(true);

            await loadRecoverRequests(controller);

            setIsLoading(false);
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [loadRecoverRequests]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if(newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleClickViewRequest = useCallback(() => {
        navigate(`/recuperacao-acesso/${selectedIds[0]}`);
    }, [navigate, selectedIds]);

    return {
        isLoading,
        handleClickViewRequest,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
    }
}