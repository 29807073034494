import { Alert, Button, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";

import { ICityTerms } from "../../../../interfaces";

import { formatDateStringToDate, formatDateStringToDateAndTime } from "../../../../utils/Conversores";
import { urlTermsOfUseByFileName } from "../../../../services/urls";

interface IProps {
    title: string;
    terms: ICityTerms;
}

export default function UpcomingTermsAlert({ terms, title }: IProps) {
    return (
        <Row
            style={{
                marginBottom: 16,
            }}
        >
            <Alert
                type='warning'
                message={<Title level={5}>{title}</Title>}
                description={(
                    <Row
                        gutter={[8, 8]}
                    >
                        <Col span={24} >
                            <Button
                                type="dashed"
                                href={`${urlTermsOfUseByFileName}/${terms.file}`}
                                target="_blank"
                            >
                                Visualizar arquivo
                            </Button>
                        </Col>
                        <Col span="24">
                            <Text>
                                <strong>Data de vigência: </strong>{formatDateStringToDate(terms.effective_date)}
                            </Text>
                        </Col>
                        <Col>
                            <Text>
                                <strong>Usuário: </strong>{terms.panel_user.name}
                            </Text>
                        </Col>
                        <Col>
                            <Text>
                                <strong>Data da alteração: </strong>{formatDateStringToDateAndTime(terms.created_at)}
                            </Text>
                        </Col>
                    </Row>
                )}
                style={{
                    width: '100%',
                }}
            />
        </Row>
    )
}