import { useState } from "react";

import { IGiveaway } from "../../../interfaces";

import GiveawaysService from "../../../services/http/services/GiveawaysService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";

export default function useNewGiveaway() {
    const [createdGiveaway, setCreatedGiveaway] = useState<IGiveaway | undefined>();

    async function handleSubmit(giveaway: IGiveaway) {
        const newGiveaway = await GiveawaysService.createGiveaway(giveaway);

        EmitSuccessAlert('Sorteio cadastrado com sucesso!');
        setCreatedGiveaway(newGiveaway);
    }

    return {
        handleSubmit,
        createdGiveaway,
    }
}