import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { ICategory, IReward } from "../../../../interfaces";

import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import { urlHttpGov } from "../../../../services/urls";
import RewardMapper from "../../../../services/http/mappers/RewardMapper";
import useAuth from "../../../../hooks/useAuth";
import CategoriesService from "../../../../services/http/services/CategoriesService";

const ACCEPTED_IMAGE_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
]

interface IRewardInputs {
    name: string,
    description: string,
    points: number,
    stock: number,
    category_id: number,
    city_id: number,
    is_inactive: boolean,
    image?: UploadFile,
}

interface IHandleChangeImageProps {
    onChangeHookForm: (event: any) => void,
    file: UploadFile,
}

export default function useRewardForm(
    onSubmit: (reward: FormData) => Promise<void>, 
    reward: IReward | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [categories, setCategories] = useState<Array<ICategory>>([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>();

    const { activeCity } = useAuth();

    useEffect(() => {
        const imageBaseUrl = `${urlHttpGov}/image_reward`;

        if (reward?.image) {
            setImageUrl(`${imageBaseUrl}/${reward.image}`);
        }

    }, [reward]);

    const handleSubmitForm: SubmitHandler<IRewardInputs> = async (data) => {
        setIsSubmiting(true);

        const formData = new FormData();

        if(data?.image?.originFileObj) {
            formData.append('image', data.image.originFileObj);
        }

        const formatedData: IReward = {
            ...data,
            image: undefined,
        }

        const persistenceReward = RewardMapper.toPersistence(formatedData);

        Object.keys(persistenceReward).forEach((fieldName: string) => {
            if (fieldName === 'image') {
                return;
            }

            formData.append(fieldName, String(persistenceReward[fieldName as keyof IReward]));
        })

        await onSubmit(formData);

        setIsSubmiting(false);
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        setError,
        clearErrors,
    } = useForm<IRewardInputs>({
        defaultValues: {
            name: reward?.name || '',
            description: reward?.description || '',
            points: reward?.points || reward?.points === 0 ? reward.points : undefined,
            stock: reward?.stock || reward?.stock === 0 ? reward.stock : undefined,
            city_id: reward?.city_id || activeCity?.id || undefined,
            category_id: reward?.category_id || undefined,
            is_inactive: reward?.is_inactive || false,
        }
    });

    const dummyRequest = useCallback(({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const getBase64 = useCallback((img: RcFile | undefined, callback: (result: string | ArrayBuffer | null) => void) => {
        if (!img) {
            return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }, []);

    const handleChangeImage = useCallback(({ onChangeHookForm, file }: IHandleChangeImageProps) => {
        if (file?.type && !ACCEPTED_IMAGE_TYPES.includes(file.type.toLowerCase())) {
            setError('image', { message: 'Formato de imagem inválido! Os formatos aceitos são: PNG, JPG, JPEG e WEBP.' });
            return;
        }

        clearErrors('image');
        onChangeHookForm(file);
        getBase64(file.originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
        });
    }, [getBase64, clearErrors, setError]);




    useEffect(() => {
        const controller = new AbortController();

        async function loadCategories() {
            try {
                setIsLoadingCategories(true);

                const categoriesList = await CategoriesService.listCategories(activeCity?.id!, controller.signal);

                setCategories(categoriesList);
            } catch (err) {

            } finally {
                setIsLoadingCategories(false);
            }
        }

        loadCategories();

        return () => {
            controller.abort();
        }
    }, [activeCity]);

    return {
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors,
        handleChangeImage,
        imageUrl,
        dummyRequest,
        categories,
        isLoadingCategories,
        ACCEPTED_IMAGE_TYPES,
    }
}