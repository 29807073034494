import AppUserForm from '../components/AppUserForm';
import Loading from '../../../components/Loading';
import useViewAppUser from './useViewAppUser';

export default function ViewAppUser() {
    const {
        isLoading,
        user,
        handleSubmit
    } = useViewAppUser();

    return (
        <Loading loading={isLoading} >
            <AppUserForm action='Visualizar' user={user} key={user?.id} onSubmit={handleSubmit} />
        </Loading>
    )
}
