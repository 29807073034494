import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Col, Divider, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { EnvironmentOutlined } from '@ant-design/icons';

import { ICity } from '../../../../interfaces';

import { colInputs } from './GridStyle';
import useCityForm, { MODULES } from './useCityForm';
import FormStepsController from '../FormStepsController';
import RegisterStep from './Steps/RegisterStep';
import ConfigStep from './Steps/ConfigStep';
import FilesStep from './Steps/FilesStep';
import SimpleModal from '../../../../components/SimpleModal';

interface IProps {
    action: 'Adicionar' | 'Editar';
    city?: ICity;
    onSubmit: (city: FormData) => Promise<void>;
    isLoadingCity?: boolean;
}

export default function CityForm({ action, city, onSubmit, isLoadingCity }: IProps) {
    const {
        handleSubmit,
        handleSubmitForm,
        control,
        isSubmiting,
        errors,
        clearErrors,
        uf,
        cityName,
        cities,
        isLoadingCities,
        setValue,
        handleChangeLightLogoImage,
        handleChangeDarkLogoImage,
        handleChangeTermsOfUseFile,
        lightLogoImageUrl,
        darkLogoImageUrl,
        termsOfUseFileName,
        hasTermsOfUse,
        needsToInformPointsConfigEffectiveDate,
        needsToInformTicketsConfigEffectiveDate,
        needsToInformTermsOfUseEffectiveDate,
        upcomingConfig,
        systems,
        currentStep,
        refIsEditing,
        refSteps,
        alreadyIncludedModules,
        hasNewModules,
        newModules,
        trigger,
        formHasInvoiceNFCModule,
        formHasInvoiceNFSModule,
        formHasRewardModule,
        formHasTaxesModule,
        isConfirmAddNewModulesModalOpen,
        refForm,
        errorsLength,
        handleCloseConfirmAddNewModulesModal,
        handleOpenConfirmAddNewModulesModal,
        handleConfirmAddNewModules,
        handleClickRegisterStep,
        handleClickConfigStep,
        handleClickFilesStep,
        handleClickNextStep,
        handleClickPreviousStep,
        ACCEPTED_IMAGE_TYPES,
        ACCEPTED_FILE_TYPES,
    } = useCityForm(onSubmit, city, isLoadingCity);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><EnvironmentOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Município {city && ` - ${city.city} - ${city.uf}`}</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/municipios'>Municípios</Link>,
                            },
                            {
                                title: `${action} Município`,
                            }
                        ]}
                    />
                </Col>
                <Divider />
            </Row>

            <FormStepsController
                refSteps={refSteps}
                currentStep={currentStep}
                errors={errors}
                items={[
                    {
                        title: "Cadastro",
                        onClick: handleClickRegisterStep,
                        fields: ["name", "cnpj", "city", "uf"]
                    },
                    {
                        title: "Configurações",
                        onClick: handleClickConfigStep,
                        fields: [
                            'modules',
                            "nfc_points_divider",
                            "nfc_max_amount_points",
                            "nfc_max_month_quantity_by_cnpj_points",
                            "nfs_points_divider",
                            "nfs_max_amount_points",
                            "nfs_max_month_quantity_by_cnpj_points",
                            "taxe_points_divider",
                            "taxe_max_amount_points",
                            "nfc_tickets_divider",
                            "nfc_max_amount_tickets",
                            "nfc_max_month_quantity_by_cnpj_tickets",
                            "nfs_tickets_divider",
                            "nfs_max_amount_tickets",
                            "nfs_max_month_quantity_by_cnpj_tickets",
                            "taxe_tickets_divider",
                            "taxe_max_amount_tickets",
                            "points_config_description",
                            "points_config_effective_date",
                            "tickets_config_description",
                            "tickets_config_effective_date",
                            "system_id",
                            "time_expire_points",
                            "time_expire_vouchers",
                        ]
                    },
                    {
                        title: "Arquivos",
                        onClick: handleClickFilesStep,
                        fields: ["logo_dark", "logo_light", "terms_of_use", "terms_of_use_effective_date"]
                    },
                ]}
            />

            <Form
                ref={refForm}
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                {
                    currentStep === 0 && (
                        <RegisterStep
                            control={control}
                            errors={errors}
                            colInputs={colInputs}
                            cities={cities}
                            setValue={setValue}
                            selectedCity={{ uf, city: cityName }}
                            isLoadingCities={isLoadingCities}
                            clearErrors={clearErrors}
                            isEditing={refIsEditing.current}
                        />
                    )
                }

                {
                    currentStep === 1 && (
                        <ConfigStep
                            control={control}
                            errors={errors}
                            formHasInvoiceNFCModule={formHasInvoiceNFCModule}
                            formHasInvoiceNFSModule={formHasInvoiceNFSModule}
                            formHasRewardModule={formHasRewardModule}
                            formHasTaxesModule={formHasTaxesModule}
                            alreadyIncludedModules={alreadyIncludedModules}
                            needsToInformPointsConfigEffectiveDate={needsToInformPointsConfigEffectiveDate}
                            needsToInformTicketsConfigEffectiveDate={needsToInformTicketsConfigEffectiveDate}
                            upcomingConfig={upcomingConfig}
                            systems={systems}
                            city={city}
                        />
                    )
                }

                {
                    currentStep === 2 && (
                        <FilesStep
                            control={control}
                            errors={errors}
                            city={city}
                            colInputs={colInputs}
                            lightLogoImageUrl={lightLogoImageUrl}
                            darkLogoImageUrl={darkLogoImageUrl}
                            termsOfUseFileName={termsOfUseFileName}
                            hasTermsOfUse={hasTermsOfUse}
                            onChangeLightLogoImage={handleChangeLightLogoImage}
                            onChangeDarkLogoImage={handleChangeDarkLogoImage}
                            onChangeTermsOfUseFile={handleChangeTermsOfUseFile}
                            acceptedImageTypes={ACCEPTED_IMAGE_TYPES}
                            acceptedFileTypes={ACCEPTED_FILE_TYPES}
                            needsToInformTermsOfUseEffectiveDate={needsToInformTermsOfUseEffectiveDate}
                            upcomingTerms={upcomingConfig?.terms_of_use}
                        />
                    )
                }

                <Row
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        type='default'
                        htmlType='button'
                        disabled={isSubmiting || currentStep === 0}
                        loading={isSubmiting}
                        onClick={handleClickPreviousStep}
                    >
                        Anterior
                    </Button>

                    {
                        hasNewModules && (
                            <Button
                                type='primary'
                                htmlType='button'
                                disabled={isSubmiting || errorsLength > 0}
                                loading={isSubmiting}
                                onClick={async () => {
                                    const passed = await trigger(undefined, { shouldFocus: false });

                                    if (passed) {
                                        handleOpenConfirmAddNewModulesModal();
                                    }
                                }}
                                style={{
                                    marginRight: 8
                                }}
                            >
                                Salvar
                            </Button>
                        )
                    }

                    {
                        !hasNewModules && (
                            <Button
                                type='primary'
                                htmlType='submit'
                                disabled={isSubmiting || errorsLength > 0}
                                loading={isSubmiting}
                                style={{
                                    marginRight: 8
                                }}
                            >
                                Salvar
                            </Button>

                        )
                    }

                    <Button
                        type='default'
                        htmlType='button'
                        disabled={isSubmiting || currentStep === 3}
                        loading={isSubmiting}
                        onClick={handleClickNextStep}
                    >
                        Próximo
                    </Button>
                </Row>
            </Form>

            <SimpleModal
                open={isConfirmAddNewModulesModalOpen}
                onOk={handleConfirmAddNewModules}
                okButtonProps={{
                    disabled: isSubmiting,
                    loading: isSubmiting
                }}
                cancelButtonProps={{
                    disabled: isSubmiting
                }}
                okText='Confirmar'
                onCancel={handleCloseConfirmAddNewModulesModal}
                title={`Deseja realmente adicionar os seguintes módulos ao Município?`}
            >
                <ul>
                    {
                        MODULES.filter((module) => newModules.includes(module.name)).map((module) => (
                            <li key={`modal-confirm-${module.name}`}>{module.label}</li>
                        ))
                    }
                </ul>
                <Text style={{ color: '#FC5050' }}>Uma vez adicionado, o módulo <strong>NÃO</strong> poderá ser removido!</Text>
            </SimpleModal>
        </>
    )
}
