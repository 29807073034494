import { IAppUser } from '../../../interfaces';

class AppUserMapper {
    toPersistence(domainAppUser: IAppUser): any {
        return {
            id: domainAppUser.id,
            name: domainAppUser.name,
            phone: domainAppUser.phone,
            cpf: domainAppUser.cpf,
            email: domainAppUser.email,
            street: domainAppUser.street,
            neighborhood: domainAppUser.neighborhood,
            number: domainAppUser.number,
            cep: domainAppUser.cep,
            county_ids: domainAppUser.city_ids,
        }
    }

    toDomain(persistenceAppUser: any): IAppUser {
        return {
            id: persistenceAppUser.id,
            name: persistenceAppUser.name,
            phone: persistenceAppUser.phone,
            cpf: persistenceAppUser.cpf,
            email: persistenceAppUser.email,
            street: persistenceAppUser.street,
            neighborhood: persistenceAppUser.neighborhood,
            number: persistenceAppUser.number,
            cep: persistenceAppUser.cep,
            cities: persistenceAppUser.counties,
            last_access: persistenceAppUser.lastAccess,
            created_at: persistenceAppUser.createdAt,
            updated_at: persistenceAppUser.updatedAt,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AppUserMapper();
