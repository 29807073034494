import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IPanelUser } from "../../../interfaces";

import PanelUsersService from "../../../services/http/services/PanelUsersService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";
import useAuth from "../../../hooks/useAuth";

export default function useEditPanelUser() {
    const { id } = useParams();
    const [user, setUser] = useState<IPanelUser>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const {
        user: activeUser,
        activeCity,
        updateUserData,
        clearUserActiveCity,
    } = useAuth();

    useEffect(() => {
        const controller = new AbortController();

        async function loadPanelUser() {
            try {
                setIsLoading(true);
                const userData = await PanelUsersService.getPanelUserById(Number(id), controller.signal);

                setUser(userData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editPanelUser_opasdasjpdasd');

                navigate('/usuarios-painel');
            }
        }

        loadPanelUser();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(userData: IPanelUser) {
        try {
            const idNumber = Number(id);
            const updatedPanelUser = await PanelUsersService.updatePanelUser(idNumber, userData);

            if (idNumber === activeUser?.id && updatedPanelUser) {
                const unlinkedActiveCity = !updatedPanelUser.cities.find((city) => city.id === activeCity?.id);

                await updateUserData(updatedPanelUser, unlinkedActiveCity);

                if (unlinkedActiveCity) {
                    clearUserActiveCity();
                }
            }

            EmitSuccessAlert('Usuário atualizado com sucesso!');

            navigate('/usuarios-painel');
        } catch (err) {
            EmitError(err, true, 'editPanelUser_asiopdasodasd');
        }
    }

    return {
        isLoading,
        user,
        handleSubmit
    }
}