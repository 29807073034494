import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { IPermission, IRole } from "../../../../interfaces";

import PermissionsService from "../../../../services/http/services/PermissionsService";

export default function useRoleForm(
    onSubmit: (role: IRole) => Promise<void>,
    role: IRole | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(true);
    const [permissions, setPermissions] = useState<Array<IPermission>>([]);

    const handleSubmitForm: SubmitHandler<IRole> = async (data) => {
        setIsSubmiting(true);

        await onSubmit(data);

        setIsSubmiting(false);
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm<IRole>({
        defaultValues: {
            name: role?.name || '',
            description: role?.description || '',
        }
    });

    useEffect(() => {
        const controller = new AbortController();

        async function loadPermissions() {
            try {
                setIsLoadingPermissions(true);
                const permissionsList = await PermissionsService.listPermissions(controller.signal);

                setPermissions(permissionsList);
                
                setValue('permission_ids', role?.permissions.map((permission) => permission.id).filter((id) => typeof id === "number") as number[]);
                setIsLoadingPermissions(false);
            } catch (err) {}
        }

        loadPermissions();

        return () => {
            controller.abort();
        }
    }, [role, setValue]);

    return {
        isLoadingPermissions,
        permissions,
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors
    }
}