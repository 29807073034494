import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Button, Input, InputRef, Space } from "antd";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from '@ant-design/icons';

import { IAwardType } from "../../interfaces";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";

import AwardTypesService from "../../services/http/services/AwardTypesService";
import { EmitSuccessAlert } from "../../utils/EmitSuccessAlert";
import { EmitError } from "../../utils/EmitError";
import useAuth from "../../hooks/useAuth";
import { removeAcento } from "../../utils/RemoveAcentos";
import { formatDateStringToDateAndTime } from "../../utils/Conversores";

interface ITableData extends IAwardType {
    key: number | undefined;
}

export default function useAwardTypes() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [awardTypeBeingDeleted, setAwardTypeBeingDeleted] = useState<ITableData | null>(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { activeCity } = useAuth();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ITableData, inputColumnName: string, formatter?: (text: string) => string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) =>
            removeAcento(record[dataIndex]!.toString()).toLowerCase().includes(removeAcento(value).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: IAwardType, b: IAwardType) => a.name.localeCompare(b.name),
            showSorterTooltip: false,
            ...getColumnSearchProps('name', 'Nome'),
        },
        {
            title: 'Data de Cadastro',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_: any, { created_at }: IAwardType) => formatDateStringToDateAndTime(created_at),
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: IAwardType, b: IAwardType) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            showSorterTooltip: false,
        },
    ]
    
    const setTableDataFromRequest = useCallback((data: Array<IAwardType>) => {
        const newData = data.map((awardType) => ({ ...awardType, key: awardType.id }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        async function loadAwardTypes() {
            try {
                setIsLoading(true);

                const awardTypes = await AwardTypesService.listAwardTypes(controller.signal);
                
                setTableDataFromRequest(awardTypes);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'awardTypes_asdiopasijep');
                setIsLoading(false);
            }
        }

        loadAwardTypes();

        return () => {
            controller.abort();
        }
    }, [activeCity, setTableDataFromRequest]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if(newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleDeleteAwardType = useCallback((id: number) => {
        setIsDeleteModalVisible(true);
        setAwardTypeBeingDeleted(tableData.find((element) => element.id === id) || null);
    }, [tableData]);

    const handleConfirmDeleteAwardType = useCallback(async () => {
        try {
            setIsLoadingDelete(true);
            await AwardTypesService.deleteAwardType(awardTypeBeingDeleted?.id!);
            EmitSuccessAlert('Tipo de Prêmio deletado com sucesso!');
            setTableData((prevState) => prevState.filter((element) => element.id !== awardTypeBeingDeleted?.id));
            setIsDeleteModalVisible(false);
            setAwardTypeBeingDeleted(null);
            setSelectedIds([]);
            setIsLoadingDelete(false);
        } catch (err) {
            EmitError(err, true, 'awardTypes_poasdapenmasd');
            setIsLoadingDelete(false);
        }
    }, [awardTypeBeingDeleted]);

    const handleCancelDeleteAwardType = useCallback(async () => {
        setIsDeleteModalVisible(false); 
        setAwardTypeBeingDeleted(null);
    }, []);

    const handleClickCreateNewAwardType = useCallback(() => {
        navigate('/tipos-de-premios/criar')
    }, [navigate]);

    const handleClickEditAwardType = useCallback(() => {
        navigate(`/tipos-de-premios/editar/${selectedIds[0]}`)
    }, [selectedIds, navigate]);

    return {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewAwardType,
        handleClickEditAwardType,
        handleDeleteAwardType,
        handleConfirmDeleteAwardType,
        handleCancelDeleteAwardType,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        awardTypeBeingDeleted,
        tableData,
    }
}