import CityForm from '../components/CityForm';
import Loading from '../../../components/Loading';
import useEditCity from './useEditCity';

export default function EditCity() {
    const {
        isLoading,
        city,
        handleSubmit
    } = useEditCity();

    return (
        <Loading loading={isLoading} >
            <CityForm
                key={city?.id}
                action='Editar'
                city={city}
                onSubmit={handleSubmit}
                isLoadingCity={isLoading}
            />
        </Loading>
    )
}
