import Loading from "../../../../components/Loading";
import useTickets from "./useTickets";
import TicketsList from "../../../../components/TicketsList";

export default function Tickets() {
    const {
        tickets,
        appUser,
        isLoading,
        handleClickGoBack,
        giveawayId,
    } = useTickets();

    return (
        <Loading loading={isLoading} >
            <TicketsList
                paginated={false}
                title={appUser?.name}
                handleClickGoBack={handleClickGoBack}
                tickets={tickets}
                isLoading={isLoading}
                giveawayId={giveawayId}
                emptyListMessage="Usuário não possui bilhetes neste sorteio"
            />
        </Loading>
    )
}