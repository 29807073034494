import { IAward } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import AwardMapper from '../mappers/AwardMapper';
import { urlHttpGov } from '../../urls';

export interface IAwardsOrderObject {
    id: number,
    order: number,
}

class AwardsService {
    private baseURL: string = `${urlHttpGov}/panel/award`;

    async listAwards(signal: AbortSignal) {

        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        return data.map((award: any) => AwardMapper.toDomain(award));
    }

    async createAward(award: FormData) {
        const { data } = await privateHttpClient.post(this.baseURL, award, { headers: { "content-type": 'multipart/form-data' } });

        const domainAward = AwardMapper.toDomain(data);

        return domainAward;
    }

    async getAwardById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return AwardMapper.toDomain(data);
    }

    async updateAward(id: number, award: FormData) {
        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, award, { headers: { "content-type": "multipart/form-data" } });

        return AwardMapper.toDomain(data);
    }

    deleteAward(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }

    async listAwardsByGiveawayId(giveawayId: number, signal?: AbortSignal): Promise<Array<IAward>> {

        const { data } = await privateHttpClient.get(`${this.baseURL}/raffle/${giveawayId}`, { signal });

        return data.map((award: any) => AwardMapper.toDomain(award));
    }

    updateAwardsOrder(award_order: Array<IAwardsOrderObject>) {
        return privateHttpClient.put(`${this.baseURL}/ordering`, { award_order });
    }

    async validateAward(id: number, isValid: boolean, observation: string | undefined): Promise<IAward> {
        const { data } = await privateHttpClient.put(`${this.baseURL}/status/${id}`, {
            is_approve: isValid,
            observation,
        });

        return AwardMapper.toDomain(data);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AwardsService();
