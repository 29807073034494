import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputRef, Space } from "antd";
import Highlighter from "react-highlight-words";
import { AxiosError } from "axios";

import { SearchOutlined } from '@ant-design/icons';

import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { IAppUser, IInvoice } from "../../../../interfaces";

import { removeAcento } from "../../../../utils/RemoveAcentos";
import InvoicesService from "../../../../services/http/services/InvoicesService";
import AppUsersService from "../../../../services/http/services/AppUsersService";
import useAuth from "../../../../hooks/useAuth";
import { EmitError } from "../../../../utils/EmitError";
import { formatDateStringToDateAndTime } from "../../../../utils/Conversores";
import { formatCurrency, formatPoints } from "../../../../utils/Masks";
import StatusTag from "../../components/StatusTag";
import useModules from "../../../../hooks/useModules";

interface IPeriod {
    month: number,
    year: number,
}

interface ITableData extends IInvoice {
    key: number;
}

export default function useInvoices() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [appUser, setAppUser] = useState<IAppUser>();
    const [period, setPeriod] = useState<IPeriod | undefined>(undefined);
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const { activeCity } = useAuth();
    const { hasRewardModule } = useModules();
    const navigate = useNavigate();
    const { id, period: paramsPeriod } = useParams();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents).toLowerCase() : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ?
                            formatter ? formatter(text.toString())
                                : text.toString()
                            : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Empresa',
            dataIndex: 'company',
            key: 'company',
            sorter: (a: ITableData, b: ITableData) => a?.company?.localeCompare(b?.company || '')!,
            showSorterTooltip: false,
            ...getColumnSearchProps('company', 'Nome'),
        },
        {
            title: 'Valor Total',
            dataIndex: 'value',
            key: 'value',
            render: (record: number) => record ? `R$ ${formatCurrency(record)}` : 'R$ 0,00',
            sorter: (a: ITableData, b: ITableData) => (a?.value || 0) - (b?.value || 0),
            showSorterTooltip: false,
        },
        {
            title: 'Total de Pontos',
            dataIndex: 'reward_points',
            key: 'reward_points',
            render: (record: number) => formatPoints(record),
            sorter: (a: ITableData, b: ITableData) => (a?.reward_points || 0) - (b?.reward_points || 0),
            showSorterTooltip: false,
            hidden: !hasRewardModule,
        },
        {
            title: 'Status',
            dataIndex: 'is_valid',
            key: 'is_valid',
            sorter: (a: ITableData, b: ITableData) => a.status.localeCompare(b.status),
            showSorterTooltip: false,
            filters: [
                {
                    text: 'Concluído',
                    value: 'Concluído',
                },
                {
                    text: 'Com erro',
                    value: 'Erro',
                },
                {
                    text: 'Alerta',
                    value: 'Alerta',
                },
            ],
            onFilter: (value: any, record: ITableData) => {
                return record.status === value;
            },
            render: (_: any, { id, status, message }: ITableData) => (
                <StatusTag
                    status={status}
                    message={message}
                    key={`tag-${id}`}
                />
            ),
        },
        {
            title: 'Data de Emissão',
            dataIndex: 'date_issue',
            key: 'date_issue',
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: ITableData, b: ITableData) => new Date(a?.date_issue!).getTime() - new Date(b?.date_issue!).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('date_issue', 'Data do sorteio', true, formatDateStringToDateAndTime),
        },
    ].filter((column) => !column?.hidden);

    const setTableDataFromRequest = useCallback((data: Array<IInvoice>) => {
        const newData = data.map((invoice) => ({
            ...invoice,
            key: invoice.id,
        }));

        setTableData(newData);
    }, []);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newKey = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newKey]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);


    const handleClickGoBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (!paramsPeriod) {
            return;
        }

        const controller = new AbortController();

        async function loadInvoices() {
            try {
                setIsLoading(true);

                const [month, year] = paramsPeriod?.split('-')!;
                setPeriod({ month: Number(month), year: Number(year) });

                const [invoicesList, appUserData] = await Promise.all([
                    InvoicesService.listInvoicesByMonth(
                        Number(id),
                        activeCity?.id!,
                        Number(month),
                        Number(year),
                        controller.signal,
                    ),
                    AppUsersService.getAppUserById(Number(id), controller.signal),
                ])

                setTableDataFromRequest(invoicesList);
                setAppUser(appUserData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'viewAppUserInvoices_asodkaskldasd');
                setIsLoading(false);
            }
        }

        loadInvoices();

        return () => {
            controller.abort();
        }
    }, [id, paramsPeriod, activeCity, setTableDataFromRequest]);

    return {
        columns,
        isLoading,
        tableData,
        appUser,
        onClickTableRow,
        rowSelection,
        selectedIds,
        period,
        handleClickGoBack,
    }
}