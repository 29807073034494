import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { RcFile } from "antd/es/upload";
import { FormInstance } from "antd";
import * as yup from "yup";

import { ICitySystem, ICityUpcomingConfigAndTerms, ICity, ICityData } from "../../../../interfaces";
import { ICityInputs, IChangeFileProps, IModule } from "../../interfaces";

import { formatCurrencyInput, normalizeCnpjNumber, onlyNumbers } from "../../../../utils/Masks";
import { urlHttpGov, urlTermsOfUse } from "../../../../services/urls";
import CityMapper from "../../../../services/http/mappers/CityMapper";
import { DateFns } from "../../../../utils/dateFns";
import CitiesService from "../../../../services/http/services/CitiesService";
import { EmitError } from "../../../../utils/EmitError";
import { wrapperSchema } from "./schema";
import httpClient from "../../../../services/http/httpClient";
import { formatCurrencyForPersistence } from "../../../../utils/Conversores";
import { EModule } from "../../../../enums";

const ACCEPTED_FILE_TYPES = [
    'application/pdf',
]

const ACCEPTED_IMAGE_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
]

export const MODULES: IModule[] = [
    {
        name: EModule.INVOICE_NFC,
        label: "Nota Fiscal de Consumo",
    },
    {
        name: EModule.INVOICE_NFS,
        label: "Nota Fiscal de Serviço",
    },
    {
        name: EModule.REWARD,
        label: "Recompensas",
    },
    {
        name: EModule.TAXES,
        label: "Impostos Municipais",
    },
]

export default function useCityForm(
    onSubmit: (city: FormData) => Promise<void>,
    city: ICity | undefined,
    isLoadingCity: boolean = false,
) {
    const [currentStep, setCurrentStep] = useState(0);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [darkLogoImageUrl, setDarkLogoImageUrl] = useState<string | ArrayBuffer | null | undefined>();
    const [lightLogoImageUrl, setLightLogoImageUrl] = useState<string | ArrayBuffer | null | undefined>();
    const [termsOfUseFileName, setTermsOfUseFileName] = useState<string | undefined>();
    const [hasTermsOfUse, setHasTermsOfUse] = useState<boolean>(false);
    const [upcomingConfig, setUpcomingConfig] = useState<ICityUpcomingConfigAndTerms | undefined>(undefined);
    const [systems, setSystems] = useState<Array<ICitySystem>>([]);
    const [cities, setCities] = useState<Array<ICityData>>([]);
    const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
    const [isConfirmAddNewModulesModalOpen, setIsConfirmAddNewModulesModalOpen] = useState<boolean>(false);

    const refIsEditing = useRef<boolean>(Boolean(city));
    const refForm = useRef<FormInstance>(null);
    const refSteps = useRef<HTMLDivElement>(null);

    const useYupValidationResolver = (validationSchema: yup.ObjectSchema<ICityInputs>) =>
        useCallback(
            async (data: any) => {
                try {
                    const values = await validationSchema.validate(data, {
                        abortEarly: false,
                    })

                    return {
                        values,
                        errors: {},
                    }
                } catch (errors: any) {
                    return {
                        values: {},
                        errors: errors.inner.reduce(
                            (allErrors: any, currentError: any) => ({
                                ...allErrors,
                                [currentError.path]: {
                                    type: currentError.type ?? "validation",
                                    message: currentError.message,
                                },
                            }),
                            {}
                        ),
                    }
                }
            },
            [validationSchema]
        )

    const validationSchema = useMemo(() => wrapperSchema({
        type: city ? "EDIT" : "CREATE",
        points: {
            nfc_divider: city?.nfc_points_divider,
            nfs_divider: city?.nfs_points_divider,
            taxe_divider: city?.taxe_points_divider,
            nfs_max_amount: city?.nfs_max_amount_points,
            nfs_max_month_quantity_by_cnpj: city?.nfs_max_month_quantity_by_cnpj_points,
            nfc_max_amount: city?.nfc_max_amount_points,
            nfc_max_month_quantity_by_cnpj: city?.nfc_max_month_quantity_by_cnpj_points,
            taxe_max_amount: city?.taxe_max_amount_points,
        },
        tickets: {
            nfc_divider: city?.nfc_tickets_divider,
            nfs_divider: city?.nfs_tickets_divider,
            taxe_divider: city?.taxe_tickets_divider,
            nfs_max_amount: city?.nfs_max_amount_tickets,
            nfs_max_month_quantity_by_cnpj: city?.nfs_max_month_quantity_by_cnpj_tickets,
            nfc_max_amount: city?.nfc_max_amount_tickets,
            nfc_max_month_quantity_by_cnpj: city?.nfc_max_month_quantity_by_cnpj_tickets,
            taxe_max_amount: city?.taxe_max_amount_tickets,
        },
        cityModules: city?.modules || [],
        upcomingConfig
    }), [city, upcomingConfig]);

    const resolver = useYupValidationResolver(validationSchema);

    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        watch,
        setValue,
        clearErrors,
        setError,
        resetField,
        trigger,
    } = useForm<ICityInputs>({
        defaultValues: {
            name: city?.name || '',
            cnpj: city?.cnpj || '',
            city: city?.city,
            uf: city?.uf,
            nfc_points_divider: city?.nfc_points_divider,
            nfc_tickets_divider: city?.nfc_tickets_divider,
            nfs_points_divider: city?.nfs_points_divider,
            nfs_tickets_divider: city?.nfs_tickets_divider,
            taxe_points_divider: city?.taxe_points_divider,
            taxe_tickets_divider: city?.taxe_tickets_divider,
            points_config_description: city?.points_config_description,
            tickets_config_description: city?.tickets_config_description,
            time_expire_points: city?.time_expire_points,
            time_expire_vouchers: city?.time_expire_vouchers,
            nfc_max_amount_points: city?.nfc_max_amount_points ? formatCurrencyInput(city.nfc_max_amount_points.toFixed(2)) : undefined,
            nfc_max_amount_tickets: city?.nfc_max_amount_tickets ? formatCurrencyInput(city.nfc_max_amount_tickets.toFixed(2)) : undefined,
            nfc_max_month_quantity_by_cnpj_points: city?.nfc_max_month_quantity_by_cnpj_points,
            nfc_max_month_quantity_by_cnpj_tickets: city?.nfc_max_month_quantity_by_cnpj_tickets,
            nfs_max_amount_points: city?.nfs_max_amount_points ? formatCurrencyInput(city.nfs_max_amount_points.toFixed(2)) : undefined,
            nfs_max_amount_tickets: city?.nfs_max_amount_tickets ? formatCurrencyInput(city.nfs_max_amount_tickets.toFixed(2)) : undefined,
            nfs_max_month_quantity_by_cnpj_points: city?.nfs_max_month_quantity_by_cnpj_points,
            nfs_max_month_quantity_by_cnpj_tickets: city?.nfs_max_month_quantity_by_cnpj_tickets,
            taxe_max_amount_points: city?.taxe_max_amount_points ? formatCurrencyInput(city.taxe_max_amount_points.toFixed(2)) : undefined,
            taxe_max_amount_tickets: city?.taxe_max_amount_tickets ? formatCurrencyInput(city.taxe_max_amount_tickets.toFixed(2)) : undefined,
            modules: city?.modules && typeof city?.modules !== "string" ? city?.modules : [],
        },
        // defaultValues: {
        //     name: city?.name || '',
        //     cnpj: city?.cnpj || '',
        //     city: city?.city,
        //     uf: city?.uf,
        //     nfc_points_divider: city?.nfc_points_divider || 10,
        //     nfc_tickets_divider: city?.nfc_tickets_divider || 50,
        //     nfs_points_divider: city?.nfs_points_divider || 8,
        //     nfs_tickets_divider: city?.nfs_tickets_divider || 40,
        //     taxe_points_divider: city?.taxe_points_divider || 5,
        //     taxe_tickets_divider: city?.taxe_tickets_divider || 20,
        //     points_config_description: city?.points_config_description || '',
        //     tickets_config_description: city?.tickets_config_description || '',
        //     time_expire_points: city?.time_expire_points || 365,
        //     time_expire_vouchers: city?.time_expire_vouchers || 30,
        //     nfc_max_amount_points: formatCurrencyInput(city?.nfc_max_amount_points?.toFixed(2) || Number(3000).toFixed(2)),
        //     nfc_max_amount_tickets: formatCurrencyInput(city?.nfc_max_amount_tickets?.toFixed(2) || Number(3000).toFixed(2)),
        //     nfc_max_month_quantity_by_cnpj_points: city?.nfc_max_month_quantity_by_cnpj_points || 30,
        //     nfc_max_month_quantity_by_cnpj_tickets: city?.nfc_max_month_quantity_by_cnpj_tickets || 30,
        //     nfs_max_amount_points: formatCurrencyInput(city?.nfs_max_amount_points?.toFixed(2) || Number(4500).toFixed(2)),
        //     nfs_max_amount_tickets: formatCurrencyInput(city?.nfs_max_amount_tickets?.toFixed(2) || Number(4500).toFixed(2)),
        //     nfs_max_month_quantity_by_cnpj_points: city?.nfs_max_month_quantity_by_cnpj_points || 30,
        //     nfs_max_month_quantity_by_cnpj_tickets: city?.nfs_max_month_quantity_by_cnpj_tickets || 30,
        //     taxe_max_amount_points: formatCurrencyInput(city?.taxe_max_amount_points?.toFixed(2) || Number(1000).toFixed(2)),
        //     taxe_max_amount_tickets: formatCurrencyInput(city?.taxe_max_amount_tickets?.toFixed(2) || Number(1000).toFixed(2)),
        //     modules: city?.modules && typeof city?.modules !== "string" ? city?.modules : [],
        // },
        resolver,
        shouldFocusError: false,
        mode: 'onChange'
    });

    const modules = watch("modules");

    const formHasRewardModule = useMemo(() => {
        if (!modules) {
            return false;
        }

        return modules.includes(EModule.REWARD);
    }, [modules]);

    const formHasInvoiceNFCModule = useMemo(() => {
        if (!modules) {
            return false;
        }

        return modules.includes(EModule.INVOICE_NFC);
    }, [modules]);

    const formHasInvoiceNFSModule = useMemo(() => {
        if (!modules) {
            return false;
        }

        return modules.includes(EModule.INVOICE_NFS);
    }, [modules]);

    const formHasTaxesModule = useMemo(() => {
        if (!modules) {
            return false;
        }

        return modules.includes(EModule.TAXES);
    }, [modules]);

    const alreadyIncludedModules: EModule[] = useMemo(() => {
        return !!city?.modules && typeof city?.modules !== "string" ? city.modules : [];
    }, [city]);

    const newModules = useMemo(() => {
        return modules?.filter((m) => !alreadyIncludedModules.includes(m)) || [];
    }, [alreadyIncludedModules, modules]);

    const hasNewModules = useMemo(() => {
        return newModules.length > 0;
    }, [newModules]);

    const errorsLength = Object.keys(errors).length;

    const handleSubmitForm: SubmitHandler<ICityInputs> = async (data) => {
        setIsSubmiting(true);

        let hasDateValidationError = false;

        if (data.points_config_effective_date) {
            const pointsDividerEffectiveDate = DateFns.dateStringToDateTime(DateFns.setTimeToMidnight(new Date(data.points_config_effective_date)).toISOString());

            if (!DateFns.dateIsAfter(pointsDividerEffectiveDate, DateFns.dateStringToDateTime(new Date().toISOString()))) {
                setError("points_config_effective_date", { message: "Data de vigência precisa ser ao dia da alteração!" });
                hasDateValidationError = true;
            }

            const hasUpcomingPointsConfig = !!upcomingConfig?.points_config && upcomingConfig?.points_config.length > 0;

            if (hasUpcomingPointsConfig) {
                const lastDate = upcomingConfig?.points_config.slice(-1)[0].effective_date;

                if (!DateFns.dateIsAfter(pointsDividerEffectiveDate, DateFns.dateStringToDateTime(lastDate))) {
                    setError("points_config_effective_date", { message: `Data de vigência precisa ser posterior a ${new Date(lastDate).toLocaleDateString('pt-BR')}!` });
                    hasDateValidationError = true;
                }
            }
        }

        if (data.tickets_config_effective_date) {
            const ticketsDividerEffectiveDate = DateFns.dateStringToDateTime(DateFns.setTimeToMidnight(new Date(data.tickets_config_effective_date)).toISOString());

            if (!DateFns.dateIsAfter(ticketsDividerEffectiveDate, DateFns.dateStringToDateTime(new Date().toISOString()))) {
                setError("tickets_config_effective_date", { message: "Data de vigência precisa ser posterior ao dia da alteração!" });
                hasDateValidationError = true;
            }

            const hasUpcomingTicketsConfig = !!upcomingConfig?.tickets_config && upcomingConfig?.tickets_config.length > 0;

            if (hasUpcomingTicketsConfig) {
                const lastDate = upcomingConfig?.tickets_config.slice(-1)[0].effective_date;

                if (!DateFns.dateIsAfter(ticketsDividerEffectiveDate, DateFns.dateStringToDateTime(lastDate))) {
                    setError("tickets_config_effective_date", { message: `Data de vigência precisa ser posterior a ${new Date(lastDate).toLocaleDateString('pt-BR')}!` });
                    hasDateValidationError = true;
                }
            }
        }

        if (data.terms_of_use_effective_date) {
            const termsOfUseEffectiveDate = DateFns.dateStringToDateTime(DateFns.setTimeToMidnight(new Date(data.terms_of_use_effective_date)).toISOString());

            if (!DateFns.dateDayIsAfterOrEqual(termsOfUseEffectiveDate, DateFns.dateStringToDateTime(new Date().toISOString()))) {
                setError("terms_of_use_effective_date", { message: "Data de vigência precisa ser igual ou posterior ao dia da alteração!" });
                hasDateValidationError = true;
            }
        }

        if (hasDateValidationError) {
            setIsSubmiting(false);
            refSteps.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }

        const formData = new FormData();

        if (data?.logo_dark?.originFileObj) {
            formData.append('logo_dark', data.logo_dark.originFileObj);
        }

        if (data?.logo_light?.originFileObj) {
            formData.append('logo_light', data.logo_light.originFileObj);
        }

        if (data?.terms_of_use?.originFileObj) {
            formData.append('terms_of_use', data.terms_of_use.originFileObj);
        }

        const formatedData: ICity = {
            ...data,
            cnpj: onlyNumbers(data.cnpj),
            uf: data.uf.toUpperCase(),
            points_config_effective_date: data?.points_config_effective_date && DateFns.setTimeToMidnight(new Date(data?.points_config_effective_date)).toISOString(),
            tickets_config_effective_date: data?.tickets_config_effective_date && DateFns.setTimeToMidnight(new Date(data?.tickets_config_effective_date)).toISOString(),
            terms_of_use_effective_date: data?.terms_of_use_effective_date && DateFns.setTimeToMidnight(new Date(data?.terms_of_use_effective_date)).toISOString(),
            logo_dark: undefined,
            logo_light: undefined,
            terms_of_use: undefined,
            modules: hasNewModules ? newModules : undefined,
            nfc_max_amount_points: data.nfc_max_amount_points ? formatCurrencyForPersistence(data.nfc_max_amount_points.toString()) : undefined,
            nfc_max_amount_tickets: data.nfc_max_amount_tickets ? formatCurrencyForPersistence(data.nfc_max_amount_tickets.toString()) : undefined,
            nfs_max_amount_points: data.nfs_max_amount_points ? formatCurrencyForPersistence(data.nfs_max_amount_points.toString()) : undefined,
            nfs_max_amount_tickets: data.nfs_max_amount_tickets ? formatCurrencyForPersistence(data.nfs_max_amount_tickets.toString()) : undefined,
            taxe_max_amount_points: data.taxe_max_amount_points ? formatCurrencyForPersistence(data.taxe_max_amount_points.toString()) : undefined,
            taxe_max_amount_tickets: data.taxe_max_amount_tickets ? formatCurrencyForPersistence(data.taxe_max_amount_tickets.toString()) : undefined,
            points_config_description: data.points_config_description || '',
            tickets_config_description: data.tickets_config_description || ''
        }

        // RETIRAR DADOS NÃO EDITADOS DO OBJETO
        Object.keys(formatedData).forEach((fieldName) => {
            if (city && formatedData[fieldName as keyof ICity] === city[fieldName as keyof ICity]) {
                delete formatedData[fieldName as keyof ICity];
            }
        });

        const persistenceCity = CityMapper.toPersistence(formatedData);

        if (!persistenceCity?.start_date_points) {
            delete persistenceCity.start_date_points;
        }

        if (!persistenceCity?.start_date_ticket) {
            delete persistenceCity.start_date_ticket;
        }

        if (!persistenceCity?.system_id) {
            delete persistenceCity.system_id;
        }

        if (!persistenceCity?.start_date_terms_of_use) {
            delete persistenceCity.start_date_terms_of_use;
        }

        if (!persistenceCity?.modules) {
            delete persistenceCity.modules;
        }

        Object.keys(persistenceCity).forEach((fieldName: string) => {
            if (['logo_dark', 'logo_light', 'terms_of_use'].includes(fieldName)) {
                return;
            }

            if (!persistenceCity[fieldName]) {
                return;
            }

            formData.append(fieldName, String(persistenceCity[fieldName as keyof ICity]));
        })

        await onSubmit(formData);

        setIsSubmiting(false);
    };

    const cnpjValue = watch('cnpj');
    const uf = watch('uf');
    const cityName = watch('city');

    const needsToInformPointsConfigEffectiveDate: boolean = !!city && Boolean(dirtyFields?.nfc_points_divider || dirtyFields?.nfs_points_divider || dirtyFields?.taxe_points_divider || dirtyFields?.nfs_max_amount_points || dirtyFields?.nfs_max_month_quantity_by_cnpj_points || dirtyFields?.nfc_max_amount_points || dirtyFields?.nfc_max_month_quantity_by_cnpj_points || dirtyFields?.taxe_max_amount_points);
    const needsToInformTicketsConfigEffectiveDate: boolean = !!city && Boolean(dirtyFields?.nfc_tickets_divider || dirtyFields?.nfs_tickets_divider || dirtyFields?.taxe_tickets_divider || dirtyFields?.nfs_max_amount_tickets || dirtyFields?.nfs_max_month_quantity_by_cnpj_tickets || dirtyFields?.nfc_max_amount_tickets || dirtyFields?.nfc_max_month_quantity_by_cnpj_tickets || dirtyFields?.taxe_max_amount_tickets);
    const needsToInformTermsOfUseEffectiveDate: boolean = !!city && Boolean(dirtyFields?.terms_of_use);

    const getBase64 = useCallback((img: RcFile | undefined, callback: (result: string | ArrayBuffer | null) => void) => {
        if (!img) {
            return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }, []);

    const handleChangeDarkLogoImage = useCallback(({ onChangeHookForm, file }: IChangeFileProps) => {
        if (file?.type && !ACCEPTED_IMAGE_TYPES.includes(file.type.toLowerCase())) {
            setError('logo_dark', { message: 'Formato de imagem inválido! Os formatos aceitos são: PNG, JPG, JPEG e WEBP.' });
            return;
        }

        clearErrors('logo_dark');
        onChangeHookForm(file);
        getBase64(file.originFileObj, (imageUrl) => {
            setDarkLogoImageUrl(imageUrl);
        });
    }, [getBase64, setError, clearErrors]);

    const handleChangeLightLogoImage = useCallback(({ onChangeHookForm, file }: IChangeFileProps) => {
        if (file?.type && !ACCEPTED_IMAGE_TYPES.includes(file.type.toLowerCase())) {
            setError('logo_light', { message: 'Formato de imagem inválido! Os formatos aceitos são: PNG, JPG, JPEG e WEBP.' });
            return;
        }

        clearErrors('logo_light');
        onChangeHookForm(file);
        getBase64(file.originFileObj, (imageUrl) => {
            setLightLogoImageUrl(imageUrl);
        });
    }, [getBase64, setError, clearErrors]);

    const handleChangeTermsOfUseFile = useCallback(({ onChangeHookForm, file }: IChangeFileProps) => {
        if (file?.type && file.type.toLowerCase() !== 'application/pdf') {
            setError('terms_of_use', { message: 'Formato de arquivo inválido! O formato aceito é apenas PDF' });
            return;
        }

        clearErrors('terms_of_use');
        onChangeHookForm(file);
        setTermsOfUseFileName(file.name);
    }, [setError, clearErrors]);

    const handleClickRegisterStep = useCallback(() => {
        setCurrentStep(0);
    }, []);

    const handleClickConfigStep = useCallback(() => {
        setCurrentStep(1);
    }, []);

    const handleClickFilesStep = useCallback(() => {
        setCurrentStep(2);
    }, []);

    const handleClickNextStep = useCallback(() => {
        setCurrentStep((prevState) => prevState < 3 ? prevState + 1 : prevState);
    }, []);

    const handleClickPreviousStep = useCallback(() => {
        setCurrentStep((prevState) => prevState > 0 ? prevState - 1 : prevState);
    }, []);

    const handleOpenConfirmAddNewModulesModal = useCallback(() => {
        setIsConfirmAddNewModulesModalOpen(true);
    }, []);

    const handleCloseConfirmAddNewModulesModal = useCallback(() => {
        setIsConfirmAddNewModulesModalOpen(false);
    }, []);

    const handleConfirmAddNewModules = useCallback(() => {
        setIsConfirmAddNewModulesModalOpen(false);
        refForm.current?.submit();
    }, []);

    useEffect(() => {
        setValue("cnpj", normalizeCnpjNumber(cnpjValue))
    }, [cnpjValue, setValue]);

    useEffect(() => {
        if (!modules?.includes(EModule.INVOICE_NFC)) {
            clearErrors([
                'nfc_max_amount_points',
                'nfc_max_amount_tickets',
                'nfc_max_month_quantity_by_cnpj_points',
                'nfc_max_month_quantity_by_cnpj_tickets',
                'nfc_points_divider',
                'nfc_tickets_divider',
                'tickets_config_effective_date',
                'points_config_effective_date',
            ]);

            setValue('nfc_max_amount_points', undefined);
            setValue('nfc_max_amount_tickets', undefined);
            setValue('nfc_max_month_quantity_by_cnpj_points', undefined);
            setValue('nfc_max_month_quantity_by_cnpj_tickets', undefined);
            setValue('nfc_points_divider', undefined);
            setValue('nfc_tickets_divider', undefined);
            setValue('tickets_config_effective_date', undefined);
            setValue('points_config_effective_date', undefined);
        }

        if (!modules?.includes(EModule.INVOICE_NFS)) {
            clearErrors([
                'nfs_max_amount_points',
                'nfs_max_amount_tickets',
                'nfs_max_month_quantity_by_cnpj_points',
                'nfs_max_month_quantity_by_cnpj_tickets',
                'nfs_points_divider',
                'nfs_tickets_divider',
                'system_id',
                'tickets_config_effective_date',
                'points_config_effective_date',
            ]);

            setValue('nfs_max_amount_points', undefined);
            setValue('nfs_max_amount_tickets', undefined);
            setValue('nfs_max_month_quantity_by_cnpj_points', undefined);
            setValue('nfs_max_month_quantity_by_cnpj_tickets', undefined);
            setValue('nfs_points_divider', undefined);
            setValue('nfs_tickets_divider', undefined);
            setValue('system_id', undefined);
            setValue('tickets_config_effective_date', undefined);
            setValue('points_config_effective_date', undefined);
        }

        if (!modules?.includes(EModule.TAXES)) {
            clearErrors([
                'taxe_max_amount_points',
                'taxe_max_amount_tickets',
                'taxe_points_divider',
                'taxe_tickets_divider',
                'tickets_config_effective_date',
                'points_config_effective_date',
            ]);

            setValue('taxe_max_amount_points', undefined);
            setValue('taxe_max_amount_tickets', undefined);
            setValue('taxe_points_divider', undefined);
            setValue('taxe_tickets_divider', undefined);
            setValue('tickets_config_effective_date', undefined);
            setValue('points_config_effective_date', undefined);
        }

        if (!modules?.includes(EModule.REWARD)) {
            clearErrors([
                'points_config_description',
                'points_config_effective_date',
                'nfc_points_divider',
                'nfs_points_divider',
                'taxe_points_divider',
                'time_expire_points',
                'time_expire_vouchers',
                'nfc_max_amount_points',
                'nfs_max_amount_points',
                'taxe_max_amount_points',
                'nfc_max_month_quantity_by_cnpj_points',
                'nfs_max_month_quantity_by_cnpj_points',
            ]);

            setValue('points_config_description', undefined);
            setValue('points_config_effective_date', undefined);
            setValue('nfc_points_divider', undefined);
            setValue('nfs_points_divider', undefined);
            setValue('taxe_points_divider', undefined);
            setValue('time_expire_points', undefined);
            setValue('nfc_max_amount_points', undefined);
            setValue('nfs_max_amount_points', undefined);
            setValue('taxe_max_amount_points', undefined);
            setValue('nfc_max_month_quantity_by_cnpj_points', undefined);
            setValue('nfs_max_month_quantity_by_cnpj_points', undefined);
            setValue('time_expire_vouchers', undefined);
        }
    }, [modules, clearErrors, setValue]);

    useEffect(() => {
        const imagesBaseUrl = `${urlHttpGov}/logo_county`;

        if (city?.logo_dark) {
            setDarkLogoImageUrl(`${imagesBaseUrl}/${city.logo_dark}`);
        }

        if (city?.logo_light) {
            setLightLogoImageUrl(`${imagesBaseUrl}/${city.logo_light}`);
        }

    }, [city]);

    useEffect(() => {
        if (!city || !city?.id) {
            return;
        }

        const controller = new AbortController();

        async function loadUpcomingConfig() {
            try {
                const config = await CitiesService.findCityUpcomingConfig(city?.id!, controller.signal);

                setUpcomingConfig(config);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                EmitError(err);
            }
        }

        loadUpcomingConfig();

        return () => {
            controller.abort();
        }
    }, [city]);

    useEffect(() => {
        if (
            (city && city.modules?.find(m => m === EModule.INVOICE_NFS)) ||
            isLoadingCity ||
            !formHasInvoiceNFSModule
        ) {
            return;
        }

        const controller = new AbortController();

        async function loadSystems() {
            try {
                const systemsList = await CitiesService.getSystems(controller.signal);

                setSystems(systemsList);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                EmitError(err);
            }
        }

        loadSystems();

        return () => {
            controller.abort();
        }
    }, [city, isLoadingCity, formHasInvoiceNFSModule]);

    useEffect(() => {
        if (!city) {
            return;
        }

        const controller = new AbortController();

        async function verifyIfHasTermsOfUse() {
            try {
                const response = await httpClient.get(`${urlTermsOfUse}/${city?.id}`, { signal: controller.signal });

                if (response.status === 200) {
                    setHasTermsOfUse(true);
                    return;
                }

                setHasTermsOfUse(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                setHasTermsOfUse(false);
            }
        }

        verifyIfHasTermsOfUse();

        return () => {
            controller.abort();
        }
    }, [city]);

    useEffect(() => {
        const controller = new AbortController();

        async function loadCities() {
            if (!uf) {
                resetField('city');
                return;
            }

            try {
                setIsLoadingCities(true);
                const citiesList = await CitiesService.findAllBrazilCitiesByUf(uf, controller.signal);

                setCities(citiesList);
                setIsLoadingCities(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                setIsLoadingCities(false);
            }
        }

        loadCities();

        return () => controller.abort()
    }, [uf, resetField]);

    return {
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors,
        clearErrors,
        setValue,
        uf,
        cityName,
        cities,
        isLoadingCities,
        handleChangeDarkLogoImage,
        handleChangeLightLogoImage,
        handleChangeTermsOfUseFile,
        darkLogoImageUrl,
        lightLogoImageUrl,
        termsOfUseFileName,
        hasTermsOfUse,
        needsToInformPointsConfigEffectiveDate,
        needsToInformTicketsConfigEffectiveDate,
        needsToInformTermsOfUseEffectiveDate,
        upcomingConfig,
        systems,
        currentStep,
        refIsEditing,
        alreadyIncludedModules,
        hasNewModules,
        newModules,
        trigger,
        formHasInvoiceNFCModule,
        formHasInvoiceNFSModule,
        formHasRewardModule,
        formHasTaxesModule,
        isConfirmAddNewModulesModalOpen,
        refForm,
        refSteps,
        errorsLength,
        handleOpenConfirmAddNewModulesModal,
        handleCloseConfirmAddNewModulesModal,
        handleConfirmAddNewModules,
        handleClickRegisterStep,
        handleClickConfigStep,
        handleClickFilesStep,
        handleClickNextStep,
        handleClickPreviousStep,
        ACCEPTED_IMAGE_TYPES,
        ACCEPTED_FILE_TYPES,
    }
}