import CategoryForm from '../components/CategoryForm';
import useNewCategory from './useNewCategory';

export default function NewCategory() {
    const { handleSubmit } = useNewCategory();

    return (
        <CategoryForm action='Adicionar' onSubmit={handleSubmit} />
    )
}
