import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Breadcrumb, Button, Col, Divider, Form, Input, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { BarsOutlined, InboxOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';

import { IAwardType } from '../../../../interfaces';

import { colInputs } from './GridStyle';
import useAwardTypeForm from './useAwardTypeForm';

interface IProps {
    action: 'Adicionar' | 'Editar';
    awardType?: IAwardType;
    onSubmit: (data: FormData) => Promise<void>;
}

export default function AwardTypeForm({ action, awardType, onSubmit }: IProps) {
    const {
        handleSubmitForm,
        handleSubmit,
        isSubmiting,
        errors,
        control,
        dummyRequest,
        handleChangeDefaultImage,
        defaultImageUrl,
    } = useAwardTypeForm(onSubmit, awardType);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><BarsOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Tipo de Prêmio</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/tipos-de-premios'>Tipos de Prêmios</Link>,
                            },
                            {
                                title: `${action} Tipo de Prêmio`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Nome"
                            required
                            validateStatus={errors.name ? 'error' : 'success'}
                            help={errors.name?.message}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, digite o nome do Tipo de Prêmio!' }, }}
                                render={({ field }) => <Input {...field} placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Imagem Padrão"
                            validateStatus={errors.default_image ? 'error' : 'success'}
                            help={errors.default_image?.message}
                            required
                        >
                            <Controller
                                name="default_image"
                                control={control}
                                rules={{ required: { value: !awardType, message: 'Por favor, insira a imagem padrão do Tipo de Prêmio!' }, }}
                                render={({ field: { value, onChange, ...field } }) => (
                                    <Dragger
                                        {...field}
                                        showUploadList={false}
                                        customRequest={dummyRequest}
                                        onChange={(e: UploadChangeParam<UploadFile>) => handleChangeDefaultImage({ onChangeHookForm: onChange, file: e.file })}
                                        accept="image/png, image/jpg, image/jpeg, image/webp"
                                    >
                                        {
                                            defaultImageUrl && typeof defaultImageUrl === 'string' ? (
                                                <img
                                                    src={defaultImageUrl}
                                                    alt="Imagem padrão do tipo de prêmio"
                                                    style={{
                                                        width: '50%',
                                                    }}
                                                />
                                            )
                                                : (
                                                    <>
                                                        <p
                                                            className="ant-upload-drag-icon"
                                                        >
                                                            <InboxOutlined />
                                                        </p>
                                                        <Text
                                                            style={{
                                                                margin: '0 8px',
                                                            }}
                                                            className="ant-upload-text"
                                                        >
                                                            Clique ou arraste o arquivo para fazer o upload
                                                        </Text>
                                                    </>
                                                )
                                        }

                                    </Dragger>
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isSubmiting}
                    loading={isSubmiting}
                >
                    Salvar
                </Button>
            </Form>
        </>

    )
}
