import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IGiveaway, ITicket } from "../../../interfaces";

import TicketsService from "../../../services/http/services/TicketsService";
import { EmitError } from "../../../utils/EmitError";
import Loading from "../../../components/Loading";
import GiveawaysService from "../../../services/http/services/GiveawaysService";
import { isSameMonth } from "date-fns";
import TicketsList from "../../../components/TicketsList";

const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
]

export default function Tickets() {
    const [giveaway, setGiveaway] = useState<IGiveaway>();
    const [tickets, setTickets] = useState<Array<ITicket>>([]);
    const [totalTickets, setTotalTickets] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingTickets, setIsLoadingTickets] = useState<boolean>(false);

    const { id } = useParams();

    const navigate = useNavigate();

    const giveawayBeginAndEndAtSameMonth = giveaway && isSameMonth(new Date(giveaway.period_beginning), new Date(giveaway.period_ending));

    const listTitle: string | undefined = useMemo(() => {
        if (!giveaway) {
            return undefined;
        }

        const dateBeginning = new Date(giveaway.period_beginning);
        
        if (giveawayBeginAndEndAtSameMonth) {
            return `${giveaway.description} - ${MONTHS[dateBeginning.getMonth()]}/${dateBeginning.getFullYear()}`;
        }

        const dateEnding = new Date(giveaway.period_ending);

        return `${giveaway.description} - ${MONTHS[dateBeginning.getMonth()]}/${dateBeginning.getFullYear()} à ${MONTHS[dateEnding.getMonth()]}/${dateEnding.getFullYear()}`;
    }, [giveaway, giveawayBeginAndEndAtSameMonth]);

    const loadMore = useCallback(async () => {
        if (isLoadingTickets) {
            return;
        }

        const controller = new AbortController();

        try {
            setIsLoadingTickets(true);

            const ticketsData = await TicketsService.listTicketsByGiveawayId(Number(id), page, controller.signal);

            if (ticketsData.records.length === 0) {
                setHasMore(false);
            }

            setTickets((prevState) => [...prevState, ...ticketsData.records]);
            setPage((prevState) => prevState + 1);
            setIsLoadingTickets(false);

        } catch (err) {
            if (err instanceof AxiosError && err.name === "CanceledError") {
                return;
            }

            setIsLoadingTickets(false);
            EmitError(err);
        }

        return () => {
            controller.abort();
        }
    }, [id, page, isLoadingTickets]);

    useEffect(() => {
        if (!id) {
            return;
        }

        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoading(true);

                const [giveawayData, ticketsData] = await Promise.all([
                    GiveawaysService.getGiveawayById(Number(id), controller.signal),
                    TicketsService.listTicketsByGiveawayId(Number(id), 1, controller.signal)
                ]);

                setGiveaway(giveawayData);
                setTickets(ticketsData.records);
                if (ticketsData.records.length < 100) {
                    setHasMore(false);
                }
                setTotalTickets(ticketsData?.count || 0);
                setPage((prevState) => prevState + 1);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'giveawayTickets_asodkaskldasd');
                setIsLoading(false);
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    const handleClickGoBack = useCallback(() => {
        navigate('/sorteios');
    }, [navigate]);

    return (
        <Loading loading={isLoading} >
            <TicketsList
                paginated
                tickets={tickets}
                handleClickGoBack={handleClickGoBack}
                title={listTitle}
                loadMore={loadMore}
                hasMore={hasMore}
                isLoading={isLoading}
                emptyListMessage="Não há bilhetes concorrendo neste sorteio"
                totalTickets={totalTickets}
            />
        </Loading >
    )
}