import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { TeamOutlined } from '@ant-design/icons';

import { IPanelUser } from '../../../../interfaces';

import UpdatePasswordFormModal from './UpdatePasswordFormModal';
import usePanelUserForm from './usePanelUserForm';
import { colInputs } from './GridStyle';
import { removeAcento } from '../../../../utils/RemoveAcentos';

interface IProps {
    action: 'Adicionar' | 'Editar';
    user?: IPanelUser;
    onSubmit: (user: IPanelUser) => Promise<void>;
}

export default function PanelUserForm({ action, user, onSubmit }: IProps) {
    const {
        isSubmiting,
        isLoadingCities,
        isLoadingRolePermissions,
        isLoadingRoles,
        handleOpenUpdatePasswordModal,
        handleCloseUpdatePasswordModal,
        handleSubmit,
        handleSubmitForm,
        cities,
        roles,
        rolePermissions,
        control,
        errors,
        isUpdatePasswordModalVisible
    } = usePanelUserForm(onSubmit, user);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><TeamOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Usuário Painel</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/usuarios-painel'>Usuários Painel</Link>,
                            },
                            {
                                title: `${action} Usuário Painel`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row>
                    <Col
                        span={24}
                    >
                        <Form.Item
                            label="Nome"
                            required
                            validateStatus={errors.name ? 'error' : 'success'}
                            help={errors.name?.message}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, digite o nome do Usuário!' }, }}
                                render={({ field }) => <Input {...field} placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: !user ? 12 : 24, offset: 0 }}
                        xl={{ span: !user ? 12 : 24, offset: 0 }}
                        xxl={{ span: !user ? 12 : 24, offset: 0 }}
                    >
                        <Form.Item
                            label="Email"
                            required
                            validateStatus={errors.email ? 'error' : 'success'}
                            help={errors.email?.message}
                        >
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite o email!' },
                                    pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Email inválido' }
                                }}
                                render={({ field }) => <Input {...field} placeholder='Email' />}
                            />
                        </Form.Item>
                    </Col>
                    {
                        !user && (
                            <Col
                                {...colInputs}
                            >
                                <Form.Item
                                    label="Senha"
                                    required
                                    validateStatus={errors.password ? 'error' : 'success'}
                                    help={errors.password?.message}
                                >
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{
                                            required: { value: true, message: 'Por favor, digite a senha!' },
                                            minLength: { value: 8, message: 'A senha precisa ter no mínimo 8 caracteres!' }
                                        }}
                                        render={({ field }) => <Input.Password {...field} placeholder='Senha' />}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Perfil"
                            required
                            validateStatus={errors.role_id ? 'error' : 'success'}
                            help={errors.role_id?.message}
                        >
                            <Controller
                                name="role_id"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, informe o Perfil do Usuário!' },
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={roles.map((role) => ({ value: role.id, label: role.name }))}
                                        placeholder='Selecione o perfil'
                                        disabled={isLoadingRoles}
                                        loading={isLoadingRoles}
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Permissões"
                        >
                            <Select
                                mode='multiple'
                                disabled
                                loading={isLoadingRolePermissions}
                                value={rolePermissions.map((permission) => permission.id)}
                                options={rolePermissions.map((permission) => ({ value: permission.id, label: permission.description, key: permission.id }))}
                                placeholder='Permissões do perfil'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Municípios"
                            required
                            validateStatus={errors.city_ids ? 'error' : 'success'}
                            help={errors.city_ids?.message}
                        >
                            <Controller
                                name="city_ids"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, selecione ao menos um município!' },
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        mode='multiple'
                                        allowClear
                                        filterOption={(search, option) => removeAcento(option?.label || '').includes(removeAcento(search))}
                                        disabled={isLoadingCities}
                                        loading={isLoadingCities}
                                        options={cities.map((city) => ({ value: city.id, label: `${city.city} - ${city.uf}`, key: city.id }))}
                                        placeholder='Selecione os municípios'
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmiting || isLoadingRoles || isLoadingCities}
                    >
                        Salvar
                    </Button>
                    {
                        user && (
                            <Button
                                type='default'
                                htmlType='button'
                                disabled={isSubmiting}
                                onClick={handleOpenUpdatePasswordModal}
                                style={{
                                    marginLeft: '8px'
                                }}
                            >
                                Alterar senha
                            </Button>
                        )
                    }
                </Row>
            </Form>
            {
                user && isUpdatePasswordModalVisible && (
                    <UpdatePasswordFormModal
                        userId={user.id!}
                        onClose={handleCloseUpdatePasswordModal}
                        onFormSubmited={handleCloseUpdatePasswordModal}
                    />
                )
            }
        </>
    )
}
