import CityForm from '../components/CityForm';
import useNewCity from './useNewCity';

export default function NewCity() {
    const { handleSubmit } = useNewCity();
    
    return (
        <CityForm action='Adicionar' onSubmit={handleSubmit} />
    )
}
