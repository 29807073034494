import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { ICity, IPanelUser, IPermission, IRole } from "../../../../interfaces";

import RolesService from "../../../../services/http/services/RolesService";
import CitiesService from "../../../../services/http/services/CitiesService";

export default function usePanelUserForm(
    onSubmit: (user: IPanelUser) => Promise<void>,
    user: IPanelUser | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(true);
    const [isLoadingCities, setIsLoadingCities] = useState<boolean>(true);
    const [isLoadingRolePermissions, setIsLoadingRolePermissions] = useState<boolean>(false);
    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [rolePermissions, setRolePermissions] = useState<Array<IPermission>>([]);
    const [isUpdatePasswordModalVisible, setIsUpdatePasswordModalVisible] = useState<boolean>(false);

    const handleSubmitForm: SubmitHandler<IPanelUser> = async (data) => {
        setIsSubmiting(true);

        await onSubmit(data);

        setIsSubmiting(false);
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm<IPanelUser>({
        defaultValues: {
            name: user?.name || '',
            email: user?.email || '',
            role_id: user?.role.id,
            cities: user?.cities,
            city_ids: user?.cities?.map((permission) => permission.id) || [],
        }
    });

    useEffect(() => {
        const controller = new AbortController();

        async function loadRoles() {
            try {
                setIsLoadingRoles(true);
                const rolesData = await RolesService.listRoles(controller.signal);

                setRoles(rolesData);
                setIsLoadingRoles(false);
            } catch (err) { }
        }

        async function loadCities() {
            try {
                setIsLoadingCities(true);
                const citiesList = await CitiesService.listCities(controller.signal);

                setCities(citiesList.sort((a, b) => `${a.city} - ${a.uf}`.localeCompare(`${b.city} - ${b.uf}`)));
                setIsLoadingCities(false);
            } catch (err) { }
        }

        loadRoles();
        loadCities();

        return () => {
            controller.abort();
        }
    }, []);

    const handleOpenUpdatePasswordModal = useCallback(() => {
        setIsUpdatePasswordModalVisible(true);
    }, []);

    const handleCloseUpdatePasswordModal = useCallback(() => {
        setIsUpdatePasswordModalVisible(false);
    }, []);

    const roleId = watch('role_id');

    const loadRolePermissions = useCallback(async (roleId: number, signal: AbortSignal) => {
        try {
            setIsLoadingRolePermissions(true);
            const role = await RolesService.getRoleById(roleId, signal);

            setRolePermissions(role.permissions);
            setIsLoadingRolePermissions(false);
        } catch (err) { }
    }, []);

    useEffect(() => {
        if (!roleId) {
            return;
        }

        const controller = new AbortController();

        loadRolePermissions(Number(roleId), controller.signal);

        return () => {
            controller.abort();
        }
    }, [user, roleId, loadRolePermissions]);

    return {
        isLoadingRoles,
        isLoadingCities,
        isLoadingRolePermissions,
        roles,
        cities,
        rolePermissions,
        handleSubmitForm,
        handleSubmit,
        control,
        isSubmiting,
        errors,
        isUpdatePasswordModalVisible,
        handleOpenUpdatePasswordModal,
        handleCloseUpdatePasswordModal
    }
}