import MessageForm from '../components/MessageForm';
import Loading from '../../../components/Loading';
import useEditMessage from './useEditMessage';

export default function EditMessage() {
    const {
        isLoading,
        message,
        handleSubmit
    } = useEditMessage();

    return (
        <Loading loading={isLoading} >
            <MessageForm action='Editar' message={message} key={message?.id} onSubmit={handleSubmit} />
        </Loading>
    )
}
