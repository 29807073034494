import { Modal, ModalProps } from "antd";

interface IProps extends ModalProps {

}

export default function SimpleModal({
    open,
    onOk,
    okButtonProps,
    okType = "danger",
    okText = "Confirmar",
    onCancel,
    cancelButtonProps,
    cancelText = "Cancelar",
    title,
    children,
    closable = false,
    ...props
}: IProps) {
    return (
        <Modal
            open={open}
            onOk={onOk}
            closable={closable}
            okButtonProps={{
                type: "primary",
                ...okButtonProps,
            }}
            cancelButtonProps={{
                ...cancelButtonProps,
            }}
            okType={okType}
            okText={okText}
            cancelText={cancelText}
            onCancel={onCancel}
            title={title}
            { ...props }
        >
            {children}
        </Modal>
    )
}