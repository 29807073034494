import { ITicket } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import TicketMapper from '../mappers/TicketMapper';
import { urlHttpGov } from '../../urls';

interface IListTicketsByGiveawayIdAndAppUserId {
    records: Array<ITicket>;
    count?: number;
}

class TicketsService {
    private baseURL: string = `${urlHttpGov}/panel/ticket`;

    async listTickets(cityId: number, signal: AbortSignal) {

        const { data } = await privateHttpClient.get(`${this.baseURL}/${cityId}`, { signal });

        return data.map((ticket: any) => TicketMapper.toDomain(ticket));
    }

    async listTicketsByGiveawayId(giveawayId: number, page: number, signal: AbortSignal): Promise<IListTicketsByGiveawayIdAndAppUserId> {

        const { data } = await privateHttpClient.get(`${this.baseURL}/raffle/${giveawayId}?page=${page}`, { signal });

        const domainData = {
            ...data,
            records: data.records.map((ticket: any) => TicketMapper.toDomain(ticket)),
        }

        return domainData;
    }

    async listTicketsByGiveawayIdAndAppUserId(giveawayId: number, appUserId: number, signal: AbortSignal): Promise<Array<ITicket>> {

        const { data } = await privateHttpClient.get(`${this.baseURL}/raffle/${giveawayId}/app_user/${appUserId}`, { signal });

        return data.map((ticket: any) => TicketMapper.toDomain(ticket));
    }

    async getTicketById(id: number, cityId: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}/${cityId}`, { signal });

        return TicketMapper.toDomain(data);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TicketsService();
