import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Button, Input, InputRef, Space, Tag } from "antd";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from '@ant-design/icons';

import { ICategory, IReward } from "../../interfaces";

import RewardsService from "../../services/http/services/RewardsService";
import { EmitSuccessAlert } from "../../utils/EmitSuccessAlert";
import { EmitError } from "../../utils/EmitError";
import useAuth from "../../hooks/useAuth";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { formatDateStringToDateAndTime } from "../../utils/Conversores";
import CategoriesService from "../../services/http/services/CategoriesService";
import { removeAcento } from "../../utils/RemoveAcentos";

interface ITableData extends IReward {
    key: number | undefined;
}

export default function useRewards() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [rewardBeingDeleted, setRewardBeingDeleted] = useState<ITableData | null>(null);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [categories, setCategories] = useState<Array<ICategory>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const { activeCity } = useAuth();
    const navigate = useNavigate();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ITableData, inputColumnName: string, formatter?: (text: string) => string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) =>
            removeAcento(record[dataIndex]!.toString()).toLowerCase().includes(removeAcento(value).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });
    
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: IReward, b: IReward) => a.name.localeCompare(b.name),
            showSorterTooltip: false,
            ...getColumnSearchProps('name', 'Nome')
        },
        {
            title: 'Pontos',
            dataIndex: 'points',
            key: 'points',
            render: (record: number) => record.toLocaleString('pt-BR'),
            sorter: (a: IReward, b: IReward) => a.points - b.points,
        },
        {
            title: 'Estoque',
            dataIndex: 'stock',
            key: 'stock',
            render: (record: number) => record.toLocaleString('pt-BR'),
            sorter: (a: IReward, b: IReward) => a.stock - b.stock,
        },
        {
            title: 'Pendentes de retirada',
            dataIndex: 'vouchers_issued',
            key: 'vouchers_issued',
            render: (record: number) => record.toLocaleString('pt-BR'),
            sorter: (a: IReward, b: IReward) => (a?.vouchers_issued || 0) - (b?.vouchers_issued || 0),
        },
        {
            title: 'Categoria',
            dataIndex: 'category_id',
            key: 'category_id',
            filters: categories.map((category) => ({ text: category.name, value: category.id })),
            onFilter: (value: any, record: IReward) => {
                return record.category_id === value;
            },
            render: (_: any, { category_id }: IReward) => categories.find((category) => category.id === category_id)?.name
        },
        {
            title: 'Data de Cadastro',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_: any, { created_at }: IReward) => formatDateStringToDateAndTime(created_at!),
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: IReward, b: IReward) => new Date(a.created_at!).getTime() - new Date(b.created_at!).getTime(),
            showSorterTooltip: false,
        },
        {
            title: 'Status',
            dataIndex: 'is_inactive',
            key: 'is_inactive',
            
            sorter: (a: IReward, b: IReward) => Number(a.is_inactive) - Number(b.is_inactive),
            showSorterTooltip: false,
            filters: [
                {
                    text: 'Ativa',
                    value: false,
                },
                {
                    text: 'Inativa',
                    value: true,
                },
            ],
            onFilter: (value: any, record: IReward) => {
                return record.is_inactive === value;
            },
            render: (_: any, { id, is_inactive }: IReward) => (
                <Tag
                    key={`tag-${id}`}
                    color={!is_inactive ? "success" : "error"}
                >
                    {!is_inactive ? "Ativa" : "Inativa"}
                </Tag>
            ),
        },
    ]

    const setTableDataFromRequest = useCallback((data: Array<IReward>) => {
        const newData = data.map((reward) => ({ ...reward, key: reward.id }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoading(true);
                
                const [rewards, categoriesList] = await Promise.all([
                    RewardsService.listRewards(activeCity?.id!, controller.signal),
                    CategoriesService.listCategories(activeCity?.id!, controller.signal),
                ]);

                setTableDataFromRequest(rewards);
                setCategories(categoriesList);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'rewards_asdhashuidd');
                setIsLoading(false);
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [activeCity, setTableDataFromRequest]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if(newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleDeleteReward = useCallback((id: number) => {
        setIsDeleteModalVisible(true);
        setRewardBeingDeleted(tableData.find((element) => element.id === id) || null);
    }, [tableData]);

    const handleConfirmDeleteReward = useCallback(async () => {
        try {
            setIsLoadingDelete(true);
            await RewardsService.deleteReward(rewardBeingDeleted?.id!);
            EmitSuccessAlert("Recompensa deletada com sucesso!");
            setTableData((prevState) => prevState.filter((element) => element.id !== rewardBeingDeleted?.id));
            setIsDeleteModalVisible(false);
            setRewardBeingDeleted(null);
            setSelectedIds([]);
            setIsLoadingDelete(false);
        } catch (err) {
            EmitError(err, true, 'rewards_pasdojasdjoasdjn');
            setIsLoadingDelete(false);
        }
    }, [rewardBeingDeleted]);

    const handleCancelDeleteReward = useCallback(async () => {
        setIsDeleteModalVisible(false); 
        setRewardBeingDeleted(null);
    }, []);

    const handleClickCreateNewReward = useCallback(() => {
        navigate('/recompensas/criar');
    }, [navigate]);

    const handleClickEditReward = useCallback(() => {
        navigate(`/recompensas/editar/${selectedIds[0]}`)
    }, [navigate, selectedIds]);

    return {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewReward,
        handleClickEditReward,
        handleDeleteReward,
        handleConfirmDeleteReward,
        handleCancelDeleteReward,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        rewardBeingDeleted,
        tableData,
    }
}