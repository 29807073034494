import { useCallback, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormInstance } from "antd";

import { ICity } from "../../interfaces";

import useAuth from "../../hooks/useAuth";
import { EmitSuccessAlert } from "../../utils/EmitSuccessAlert";
import { EmitError } from "../../utils/EmitError";
import { removeAcento } from "../../utils/RemoveAcentos";

interface IInputs {
    cityId: number | undefined,
}

interface IOption {
    value: any;
    label: any;
}

export default function useSelectCityModal() {
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const refForm = useRef<FormInstance>(null);
    const { user, activeCity, setUserActiveCity, handleCloseSelectCityModal } = useAuth();

    const handleSubmitForm: SubmitHandler<IInputs> = async (data) => {
        try {
            setIsSubmiting(true);

            const selectedCity = cities.find((city) => city.id === data.cityId);

            if (!selectedCity) {
                return;
            }

            await setUserActiveCity(selectedCity);

            setIsSubmiting(false);

            EmitSuccessAlert('Município selecionado com sucesso!');
            handleCloseSelectCityModal();
        } catch (err) {
            EmitError(err, true, 'selectCityModal_asdkasidaskd');
            setIsSubmiting(false);
        }
    };

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: {
            errors,
            isValid,
        }
    } = useForm<IInputs>({
        defaultValues: {
            cityId: activeCity?.id
        }
    });

    const selectedId = watch("cityId");

    useEffect(() => {
        if (!cities.find((city) => city.id === selectedId)) {
            setValue("cityId", undefined);
        }
    }, [activeCity, selectedId, cities, setValue]);

    const filterOption = useCallback((input: string, option: IOption | undefined) => (removeAcento(option?.label ?? '')).includes(removeAcento(input)), [])

    useEffect(() => {
        if (!user) {
            return;
        }

        setCities(user.cities.sort((a, b) => `${a.city} - ${a.uf}`.localeCompare(`${b.city} - ${b.uf}`)));
    }, [user]);

    const handleConfirmSelectCity = useCallback(() => {
        refForm.current?.submit();
    }, []);

    return {
        cities,
        isSubmiting,
        handleSubmit,
        handleSubmitForm,
        control,
        errors,
        refForm,
        handleConfirmSelectCity,
        isValid,
        filterOption,
    }
}