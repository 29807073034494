import { IAward } from '../../../interfaces';

class AwardMapper {
    toPersistence(domainAward: IAward): any {
        return {
            id: domainAward.id,
            description: domainAward.description,
            type_id: domainAward.type_id,
            value: domainAward.value,
            images: domainAward.images,
            raffleId: domainAward.giveaway_id,
            order: domainAward.order,
        }
    }

    toDomain(persistenceAward: any): IAward {
        return {
            id: persistenceAward.id,
            description: persistenceAward.description,
            type_id: persistenceAward.type_id,
            value: persistenceAward.value,
            images: persistenceAward.photos,
            giveaway_id: persistenceAward.raffleId,
            app_user: persistenceAward.app_user,
            winning_ticket_id: persistenceAward.winning_ticket_id,
            order: persistenceAward.order,
            status: persistenceAward.status,
            observation: persistenceAward.observation,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AwardMapper();
