export const colInputs = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 12, offset: 0 },
};

export const colInputsThreePerRow = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 8, offset: 0 },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
}

export const inputName = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 12, offset: 0 },
    xl: { span: 12, offset: 0 },
    xxl: { span: 12, offset: 0 },
}

export const inputCpfAndPhone = {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 6, offset: 0 },
    xl: { span: 6, offset: 0 },
    xxl: { span: 6, offset: 0 },
}
