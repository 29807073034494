import { Controller } from 'react-hook-form';
import { Col, Form, Input, Modal } from 'antd';

import useUpdatePasswordFormModal from './useUpdatePasswordFormModal';

interface IProps {
    userId: number;
    onClose: () => void;
    onFormSubmited: () => void;
}

export default function UpdatePasswordFormModal({ userId, onClose, onFormSubmited }: IProps) {
    const {
        isSubmiting,
        handleSubmit,
        handleSubmitForm,
        handleConfirmUpdatePassword,
        control,
        errors,
        refForm
    } = useUpdatePasswordFormModal(onFormSubmited, userId);

    return (
        <Modal
            open
            cancelText="Cancelar"
            okText="Alterar"
            onCancel={onClose}
            onOk={handleConfirmUpdatePassword}
            closable={false}
            okButtonProps={{
                disabled: isSubmiting,
                loading: isSubmiting
            }}
            cancelButtonProps={{
                disabled: isSubmiting,
            }}
        >
            <Form
                layout='vertical'
                onFinish={handleSubmit(handleSubmitForm)}
                ref={refForm}
            >
                <Col
                    xs={24}
                >
                    <Form.Item
                        label="Senha"
                        required
                        validateStatus={errors.password ? 'error' : 'success'}
                        help={errors.password?.message}
                    >
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Por favor, digite a senha!' },
                                minLength: { value: 8, message: 'A senha precisa ter no mínimo 8 caracteres!' }
                            }}
                            render={({ field }) => <Input.Password {...field} placeholder='Senha' />}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                >
                    <Form.Item
                        label="Confirme a senha"
                        required
                        validateStatus={errors.confirmPassword ? 'error' : 'success'}
                        help={errors.confirmPassword?.message}
                    >
                        <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Por favor, confirme a senha!' },
                                minLength: { value: 8, message: 'A senha precisa ter no mínimo 8 caracteres!' }
                            }}
                            render={({ field }) => <Input.Password {...field} placeholder='Confirme a senha' />}
                        />
                    </Form.Item>
                </Col>
            </Form>
        </Modal>
    )
}
