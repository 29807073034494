import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputRef, Space } from "antd";
import Highlighter from "react-highlight-words";
import { AxiosError } from "axios";

import { SearchOutlined } from '@ant-design/icons';

import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { IGiveaway } from "../../interfaces";

import { EmitSuccessAlert } from "../../utils/EmitSuccessAlert";
import { EmitError } from "../../utils/EmitError";
import GiveawaysService from "../../services/http/services/GiveawaysService";
import useAuth from "../../hooks/useAuth";
import { removeAcento } from "../../utils/RemoveAcentos";
import { formatDateStringToDate, formatDateStringToDateAndTime } from "../../utils/Conversores";
import GiveawayStatusTag from "./components/GiveawayStatusTag";

interface ITableData extends IGiveaway {
    key: number | undefined;
}

export default function useGiveaways() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [giveawayBeingDeleted, setGiveawayBeingDeleted] = useState<ITableData | null>(null);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { activeCity } = useAuth();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents) : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
            sorter: (a: IGiveaway, b: IGiveaway) => a.description.localeCompare(b.description),
            showSorterTooltip: false,
            ...getColumnSearchProps('description', 'Descrição'),
        },
        {
            title: 'Início',
            dataIndex: 'period_beginning',
            key: 'period_beginning',
            sorter: (a: IGiveaway, b: IGiveaway) => new Date(a.period_beginning).getTime() - new Date(b.period_beginning).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('period_beginning', 'Início', true, formatDateStringToDate),
        },
        {
            title: 'Fim',
            dataIndex: 'period_ending',
            key: 'period_ending',
            sorter: (a: IGiveaway, b: IGiveaway) => new Date(a.period_ending).getTime() - new Date(b.period_ending).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('period_ending', 'Fim', true, formatDateStringToDate),
        },
        {
            title: 'Data do sorteio',
            dataIndex: 'date',
            key: 'date',
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: IGiveaway, b: IGiveaway) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('date', 'Data do sorteio', true, formatDateStringToDateAndTime),
        },
        {
            title: 'Data limite importação',
            dataIndex: 'import_deadline',
            key: 'import_deadline',
            sorter: (a: IGiveaway, b: IGiveaway) => new Date(a.import_deadline).getTime() - new Date(b.import_deadline).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('import_deadline', 'Data limite importação', true, formatDateStringToDateAndTime),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: IGiveaway, b: IGiveaway) => a.status.localeCompare(b.status),
            showSorterTooltip: false,
            filters: [
                {
                    text: 'Inválido',
                    value: 'Inválido',
                },
                {
                    text: 'Em espera',
                    value: 'Em espera',
                },
                {
                    text: 'Vigente',
                    value: 'Vigente',
                },
                {
                    text: 'Periodo de importação',
                    value: 'Periodo de importação',
                },

                {
                    text: 'Aguardando sorteio',
                    value: 'Aguardando sorteio',
                },
                {
                    text: 'Em verificação',
                    value: 'Em verificação',
                },
                {
                    text: 'Finalizado',
                    value: 'Finalizado',
                },
                
            ],
            onFilter: (value: any, record: IGiveaway) => {
                if (value === "Inválido") {
                    return !record.is_valid;
                }

                return record.status === value && record.is_valid;
            },
            render: (_: any, { id, is_valid, status }: IGiveaway) => (
                <GiveawayStatusTag
                    status={status}
                    isValid={is_valid}
                    key={`tag-${id}`}
                />
            ),
        },
    ]

    const setTableDataFromRequest = useCallback((data: Array<IGiveaway>) => {
        const newData = data.map((giveaway) => ({ ...giveaway, key: giveaway.id }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        async function loadGiveaways() {
            try {
                setIsLoading(true);

                const giveawaysList = await GiveawaysService.listGiveaways(activeCity?.id!, controller.signal);
                
                setTableDataFromRequest(giveawaysList);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'giveaways_aosdpasdpasd');
                setIsLoading(false);
            }
        }

        loadGiveaways();

        return () => {
            controller.abort();
        }
    }, [setTableDataFromRequest, activeCity]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if(newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleDeleteGiveaway = useCallback((id: number) => {
        setIsDeleteModalVisible(true);
        setGiveawayBeingDeleted(tableData.find((element) => element.id === id) || null);
    }, [tableData]);

    const handleConfirmDeleteGiveaway = useCallback(async () => {
        try {
            setIsLoadingDelete(true);

            await GiveawaysService.deleteGiveaway(giveawayBeingDeleted?.id!);
            
            setTableData((prevState) => prevState.filter((element) => element.id !== giveawayBeingDeleted?.id));
            setIsDeleteModalVisible(false);
            setGiveawayBeingDeleted(null);
            setSelectedIds([]);
            setIsLoadingDelete(false);
            EmitSuccessAlert('Sorteio deletado com sucesso!');
        } catch (err) {
            EmitError(err, true, 'giveaways_oasdajsdosp');
            setIsLoadingDelete(false);
        }
    }, [giveawayBeingDeleted]);

    const handleCancelDeleteGiveaway = useCallback(async () => {
        setIsDeleteModalVisible(false); 
        setGiveawayBeingDeleted(null);
    }, []);

    const handleClickCreateNewGiveaway = useCallback(() => {
        navigate('/sorteios/criar');
    }, [navigate]);

    const handleClickEditGiveaway = useCallback(() => {
        navigate(`/sorteios/editar/${selectedIds[0]}`);
    }, [navigate, selectedIds]);

    const handleClickViewGiveawayTickets = useCallback(() => {
        navigate(`/sorteios/${selectedIds[0]}/bilhetes`);
    }, [navigate, selectedIds]);

    const handleClickHoldGiveaway = useCallback(() => {
        navigate(`/sorteios/${selectedIds[0]}/realizar-sorteio`);
    }, [navigate, selectedIds]);

    const handleClickViewGiveawayWinners = useCallback(() => {
        navigate(`/sorteios/${selectedIds[0]}/ganhadores`);
    }, [navigate, selectedIds]);

    const canViewWinners = useMemo(() => {
        if (selectedIds.length !== 1) {
            return false;
        }

        const giveaway = tableData.find((g) => g.id === selectedIds[0]);

        const statusThatCanAccess = ["Em verificação", "Finalizado"];

        if (!giveaway || !statusThatCanAccess.includes(giveaway.status) || !giveaway.is_valid) {
            return false;
        }

        return true;
    }, [selectedIds, tableData]);

    const canHoldGiveaway = useMemo(() => {
        if (selectedIds.length !== 1) {
            return false;
        }

        const giveaway = tableData.find((g) => g.id === selectedIds[0]);

        const statusThatCanAccess = ["Aguardando sorteio", "Em verificação", "Finalizado"];

        if (!giveaway || !statusThatCanAccess.includes(giveaway.status) || !giveaway.is_valid) {
            return false;
        }

        return true;
    }, [selectedIds, tableData]);

    return {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewGiveaway,
        handleClickEditGiveaway,
        handleClickViewGiveawayTickets,
        handleDeleteGiveaway,
        handleConfirmDeleteGiveaway,
        handleCancelDeleteGiveaway,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        giveawayBeingDeleted,
        tableData,
        canViewWinners,
        canHoldGiveaway,
        handleClickHoldGiveaway,
        handleClickViewGiveawayWinners,
    }
}