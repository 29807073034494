import { Button, Row } from "antd";

interface IProps {
    show?: boolean,
    onClickSaveOrder: () => void,
    onClickCancel: () => void,
    isSubmiting: boolean,
}

export default function AwardsListTableFooter({
    show = false,
    onClickSaveOrder,
    onClickCancel,
    isSubmiting,
}: IProps) {
    if (!show) {
        return null;
    }

    return (
        <Row
            style={{
                justifyContent: 'flex-end'
            }}
        >
            <Button
                onClick={onClickCancel}
                disabled={isSubmiting}
                style={{
                    marginRight: 8,
                }}
            >
                Cancelar
            </Button>
            <Button
                onClick={onClickSaveOrder}
                disabled={isSubmiting}
                loading={isSubmiting}
                type="primary"
            >
                Salvar ordenação
            </Button>
        </Row>
    )
}