import { BrowserRouter, Routes, Route } from 'react-router-dom';

import RequireAuthentication from './RequireAuthentication';
import RequireAuthorization from './RequireAuthoziration';
import RequireModules from './RequireModules';
import { EModule } from '../enums';

import { RecoverRequestsProvider } from '../contexts/RecoverRequestsContext';
import { CityTaxesProvider } from '../contexts/CityTaxesContext';

import LayoutAdmin from '../components/LayoutAdmin';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Cities from '../pages/Cities';
import NewCity from '../pages/Cities/NewCity';
import EditCity from '../pages/Cities/EditCity';
import Roles from '../pages/Roles';
import NewRole from '../pages/Roles/NewRole';
import EditRole from '../pages/Roles/EditRole';
import PanelUsers from '../pages/PanelUsers';
import EditPanelUser from '../pages/PanelUsers/EditPanelUser';
import NewPanelUser from '../pages/PanelUsers/NewPanelUser';
import FirstAccess from '../pages/FirstAccess';
import AppUsers from '../pages/AppUsers';
import ViewAppUser from '../pages/AppUsers/ViewAppUser';
import InvoicesGroupedByMonth from '../pages/AppUsers/ViewAppUserInvoices/InvoicesGroupedByMonth';
import Giveaways from '../pages/Giveaways';
import NewGiveaway from '../pages/Giveaways/NewGiveaway';
import EditGiveaway from '../pages/Giveaways/EditGiveaway';
import AppUserTickets from '../pages/AppUsers/ViewAppUserTickets/Tickets';
import Tickets from '../pages/Giveaways/Tickets';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Rewards from '../pages/Rewards';
import NewReward from '../pages/Rewards/NewReward';
import EditReward from '../pages/Rewards/EditReward';
import Categories from '../pages/Categories';
import NewCategory from '../pages/Categories/NewCategory';
import EditCategory from '../pages/Categories/EditCategory';
import RecoverRequests from '../pages/RecoverRequests';
import ViewRecoverRequest from '../pages/RecoverRequests/ViewRecoverRequest';
import ViewAppUserVouchers from '../pages/AppUsers/ViewAppUserVouchers';
import Vouchers from '../pages/Vouchers';
import ViewVoucher from '../pages/Vouchers/ViewVoucher';
import TermsOfUse from '../pages/TermsOfUse';
import GiveawayWinners from '../pages/Giveaways/GiveawayWinners';
import Invoices from '../pages/AppUsers/ViewAppUserInvoices/Invoices';
import TicketsGroupedByGiveaway from '../pages/AppUsers/ViewAppUserTickets/TicketsGroupedByGiveaway';
import CityTaxes from '../pages/CityTaxes';
import ViewCityTax from '../pages/CityTaxes/ViewCityTax';
import HoldGiveaway from '../pages/Giveaways/HoldGiveaway';
import AwardTypes from '../pages/AwardTypes';
import NewAwardType from '../pages/AwardTypes/NewAwardType';
import EditAwardType from '../pages/AwardTypes/EditAwardType';
import RegainAccessDocuments from '../pages/RegainAccessDocuments';
import Unauthorized from '../pages/Unauthorized';
import Messages from '../pages/Messages';
import NewMessage from '../pages/Messages/NewMessage';
import EditMessage from '../pages/Messages/EditMessage';
import CityHasNoModule from '../pages/CityHasNoModule';

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
                <Route path="/recuperacao-de-acesso" element={<RegainAccessDocuments />} />
                <Route path="/termos-de-uso" element={<TermsOfUse />} />

                <Route element={<RequireAuthentication />}>
                    <Route path="/primeiro-acesso" element={<FirstAccess />} />

                    <Route element={<LayoutAdmin />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/inautorizado" element={<Unauthorized />} />
                        <Route path="/modulo-nao-encontrado" element={<CityHasNoModule />} />

                        <Route element={<RequireAuthorization allowedPermissions={['manage_county']} />}>
                            <Route path="/municipios" element={<Cities />} />
                            <Route path="/municipios/criar" element={<NewCity />} />
                            <Route path="/municipios/editar/:id" element={<EditCity />} />
                        </Route>

                        <Route element={<RequireAuthorization allowedPermissions={['manage_config']} />}>
                            <Route path="/perfis" element={<Roles />} />
                            <Route path="/perfis/criar" element={<NewRole />} />
                            <Route path="/perfis/editar/:id" element={<EditRole />} />

                            <Route path="/usuarios-painel" element={<PanelUsers />} />
                            <Route path="/usuarios-painel/criar" element={<NewPanelUser />} />
                            <Route path="/usuarios-painel/editar/:id" element={<EditPanelUser />} />

                            <Route path="/tipos-de-premios" element={<AwardTypes />} />
                            <Route path="/tipos-de-premios/criar" element={<NewAwardType />} />
                            <Route path="/tipos-de-premios/editar/:id" element={<EditAwardType />} />
                        </Route>

                        <Route element={<RequireAuthorization allowedPermissions={['manage_user_app']} />}>
                            <Route path="/usuarios-aplicativo" element={<AppUsers />} />
                            <Route path="/usuarios-aplicativo/visualizar/:id" element={<ViewAppUser />} />
                            <Route path="/usuarios-aplicativo/:id/bilhetes" element={<TicketsGroupedByGiveaway />} />
                            <Route path="/usuarios-aplicativo/:id/bilhetes/sorteio/:giveawayId" element={<AppUserTickets />} />

                            <Route element={<RequireModules allowedModules={[EModule.INVOICE_NFC, EModule.INVOICE_NFS]} />}>
                                <Route path="/usuarios-aplicativo/:id/notas" element={<InvoicesGroupedByMonth />} />
                                <Route path="/usuarios-aplicativo/:id/notas/:period" element={<Invoices />} />
                            </Route>

                            <Route element={<RequireModules allowedModules={[EModule.REWARD]} />}>
                                <Route path="/usuarios-aplicativo/:id/vouchers" element={<ViewAppUserVouchers />} />
                            </Route>

                            <Route element={<RecoverRequestsProvider />}>
                                <Route path="/recuperacao-acesso/" element={<RecoverRequests />} />
                                <Route path="/recuperacao-acesso/:id" element={<ViewRecoverRequest />} />
                            </Route>


                            <Route element={<RequireModules allowedModules={[EModule.TAXES]} />}>
                                <Route element={<CityTaxesProvider />}>
                                    <Route path="/impostos" element={<CityTaxes />} />
                                    <Route path="/impostos/:id" element={<ViewCityTax />} />
                                </Route>
                            </Route>

                            <Route path="/mensagens" element={<Messages />} />
                            <Route path="/mensagens/criar" element={<NewMessage />} />
                            <Route path="/mensagens/editar/:id" element={<EditMessage />} />
                        </Route>

                        <Route element={<RequireAuthorization allowedPermissions={['manage_raffle']} />}>
                            <Route path="/sorteios" element={<Giveaways />} />
                            <Route path="/sorteios/criar" element={<NewGiveaway />} />
                            <Route path="/sorteios/editar/:id" element={<EditGiveaway />} />
                            <Route path="/sorteios/:id/bilhetes" element={<Tickets />} />
                            <Route path="/sorteios/:id/ganhadores" element={<GiveawayWinners />} />
                            <Route path="/sorteios/:id/realizar-sorteio" element={<HoldGiveaway />} />
                        </Route>

                        <Route element={<RequireAuthorization allowedPermissions={['manage_reward']} />}>
                            <Route element={<RequireModules allowedModules={[EModule.REWARD]} />}>
                                <Route path="/recompensas" element={<Rewards />} />
                                <Route path="/recompensas/criar" element={<NewReward />} />
                                <Route path="/recompensas/editar/:id" element={<EditReward />} />

                                <Route path="/categorias" element={<Categories />} />
                                <Route path="/categorias/criar" element={<NewCategory />} />
                                <Route path="/categorias/editar/:id" element={<EditCategory />} />

                                <Route path="/vouchers" element={<Vouchers />} />
                                <Route path="/vouchers/:code" element={<ViewVoucher />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
