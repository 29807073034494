import { Alert } from 'antd';

import { IMessage } from '../interfaces';

import useToastMessage from './useToastMessage';

interface IProps {
    message: IMessage,
    onRemoveMessage: (id: number) => void,
}

export default function ToastMessage({ message, onRemoveMessage }: IProps) {
    const { handleRemoveToast } = useToastMessage(message, onRemoveMessage);

    return (
        <Alert
            message={message.text}
            description={message.description}
            onClose={handleRemoveToast}
            type={message.type}
            showIcon
            closable
            style={{
                marginBottom: '8px',
            }}
        />
    )
}
