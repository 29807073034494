import { ReactNode } from "react";
import { Col, Row, theme as themeAnt } from "antd";
import Title from "antd/es/typography/Title";

import { colCardsConfig } from "../CityForm/GridStyle";

interface IProps {
    title: string,
    children: ReactNode,
}

export default function FieldsCard({ title, children }: IProps) {
    const { token: {
        colorPrimaryBg
    } } = themeAnt.useToken();

    return (
        <Col
            {...colCardsConfig}
            style={{
                padding: '8px 8px',
            }}
        >
            <div
                style={{
                    background: colorPrimaryBg,
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    paddingRight: 12,
                    borderRadius: 8,
                    height: '100%',
                }}
            >
                <Row>
                    <Title
                        level={5}
                    >
                        {title}
                    </Title>
                </Row>
                <Row
                    gutter={16}
                >
                    {children}
                </Row>
            </div>
        </Col>
    )
}