import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { SearchOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import { IVoucher } from "../../interfaces";
import { Button, Input, InputRef, Space, Tag } from "antd";
import useAuth from "../../hooks/useAuth";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { removeAcento } from "../../utils/RemoveAcentos";
import Highlighter from "react-highlight-words";
import VouchersService from "../../services/http/services/VouchersService";
import { AxiosError } from "axios";
import { EmitError } from "../../utils/EmitError";
import { formatCpf } from "../../utils/Masks";
import { formatDateStringToDateAndTime } from "../../utils/Conversores";

interface IInputs {
    redemption_code: string,
}

interface ITableData extends IVoucher {
    key: number | undefined;
    reward_name: string;
    app_user_cpf: string;
    app_user_name: string;
}

export default function useVouchers() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { activeCity } = useAuth();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents) : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'CPF',
            dataIndex: 'app_user_cpf',
            key: 'app_user_cpf',
            ...getColumnSearchProps('app_user_cpf', 'CPF', false, formatCpf),
        },
        {
            title: 'Recompensa',
            dataIndex: 'reward_name',
            key: 'reward_name',
            sorter: (a: ITableData, b: ITableData) => a?.reward?.name.localeCompare(b?.reward?.name!)!,
            showSorterTooltip: false,
            ...getColumnSearchProps('reward_name', 'Recompensa'),
            width: '30%',
            ellipsis: true,
        },
        {
            title: 'Código',
            dataIndex: 'redemption_code',
            key: 'redemption_code',
        },
        {
            title: 'Data de entrega',
            dataIndex: 'date_redeemed',
            key: 'date_redeemed',
            sorter: (a: ITableData, b: ITableData) => (a.date_redeemed ? new Date(a.date_redeemed).getTime() : 0) - (b.date_redeemed ? new Date(b.date_redeemed).getTime() : 0),
            showSorterTooltip: false,
            ...getColumnSearchProps('date_redeemed', 'Data de entrega', true, formatDateStringToDateAndTime),
        },
        {
            title: 'Expira',
            dataIndex: 'validity',
            key: 'validity',
            defaultSortOrder: 'ascend' as keyof SortOrder,
            sorter: {
                compare: (a: ITableData, b: ITableData) => new Date(a.validity).getTime() - new Date(b.validity).getTime(),
                multiple: 1,
            },
            showSorterTooltip: false,
            ...getColumnSearchProps('validity', 'Data de expiração', true, formatDateStringToDateAndTime),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: (a: ITableData, b: ITableData) => a.status.localeCompare(b.status)!,
                multiple: 2,
            },
            filters: [
                {
                    text: 'Emitido',
                    value: 'Emitido',
                },
                {
                    text: 'Resgatado',
                    value: 'Resgatado',
                },
                {
                    text: 'Expirado',
                    value: 'Expirado',
                },
            ],
            onFilter: (value: any, record: IVoucher) => {
                return record.status === value;
            },
            render: (_: any, request: IVoucher) => (
                <Tag
                    key={request.id}
                    color={request.status === "Resgatado" ? "success" : request.status === "Expirado" ? "error" : "default"}
                >
                    {request.status}
                </Tag>
            ),
            defaultSortOrder: 'ascend' as keyof SortOrder,
        }
    ]

    const setTableDataFromRequest = useCallback((data: Array<IVoucher>) => {
        const newData = data.map((voucher) => ({
            ...voucher,
            key: voucher.id,
            app_user_name: voucher.app_user?.name!,
            app_user_cpf: voucher.app_user?.cpf!,
            reward_name: voucher?.reward?.name!,
        }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        if (!activeCity) {
            return;
        }

        const controller = new AbortController();

        async function loadVouchers() {
            try {
                setIsLoading(true);

                const appUsersList = await VouchersService.listVouchers(activeCity?.id!, controller.signal);

                setTableDataFromRequest(appUsersList);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'appUsers_aosdpasdpasd');
                setIsLoading(false);
            }
        }

        loadVouchers();

        return () => {
            controller.abort();
        }
    }, [activeCity, setTableDataFromRequest]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleSubmitForm: SubmitHandler<IInputs> = (data) => {
        navigate(`/vouchers/${data.redemption_code}`)
    };

    const handleClickViewVoucher = useCallback(() => {
        const voucher = tableData.find((v) => v.id === selectedIds[0]);

        if (!voucher) {
            return;
        }

        navigate(`/vouchers/${voucher.redemption_code}`);
    }, [navigate, selectedIds, tableData]);

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<IInputs>({
        defaultValues: {
            redemption_code: '',
        }
    });

    return {
        handleSubmitForm,
        handleSubmit,
        control,
        errors,
        isLoading,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        handleClickViewVoucher,
    }
}