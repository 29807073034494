import { Button } from 'antd';

import useGiveaways from './useGiveaways';
import Table from '../../components/Table';
import SimpleModal from '../../components/SimpleModal';

export default function Giveaways() {
    const {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewGiveaway,
        handleClickEditGiveaway,
        handleClickViewGiveawayTickets,
        handleDeleteGiveaway,
        handleConfirmDeleteGiveaway,
        handleCancelDeleteGiveaway,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        giveawayBeingDeleted,
        canHoldGiveaway,
        canViewWinners,
        handleClickHoldGiveaway,
        handleClickViewGiveawayWinners,
    } = useGiveaways();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={handleClickCreateNewGiveaway}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickEditGiveaway}
                    disabled={selectedIds.length !== 1}
                >
                    Editar
                </Button>
                <Button
                    type="primary"
                    danger
                    style={{ margin: 2 }}
                    onClick={() => {
                        handleDeleteGiveaway(selectedIds[0])
                    }}
                    disabled={selectedIds.length !== 1}
                >
                    Deletar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickViewGiveawayTickets}
                    disabled={selectedIds.length !== 1}
                >
                    Ver bilhetes
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickHoldGiveaway}
                    disabled={!canHoldGiveaway}
                >
                    Realização
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickViewGiveawayWinners}
                    disabled={!canViewWinners}
                >
                    Ver ganhadores
                </Button>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                loading={isLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
            >

            </Table>
            <SimpleModal
                open={isDeleteModalVisible}
                onOk={handleConfirmDeleteGiveaway}
                okButtonProps={{ 
                    disabled: isLoadingDelete,
                    loading: isLoadingDelete,
                }}
                cancelButtonProps={{
                    disabled: isLoadingDelete
                }}
                okText='Deletar'
                onCancel={handleCancelDeleteGiveaway}
                title={`Deseja realmente deletar "${giveawayBeingDeleted?.description}"?`}
            />
        </>
    )
}
