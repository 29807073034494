import { Button, Form, Row } from "antd";

import { IGiveaway, IPanelUser } from "../../../../../interfaces";

import useExportData from "./useExportData";
import FileAndHashContainer from "../../components/FileAndHashContainer";
import { formatDateStringToDateAndTime } from "../../../../../utils/Conversores";

interface IProps {
    giveaway: IGiveaway | undefined;
    panelUserGeneratedParticipantsFile?: IPanelUser;
    onTicketsFileGenerated: (hash: string, file: string) => void;
}

export default function ExportData({ giveaway, panelUserGeneratedParticipantsFile, onTicketsFileGenerated }: IProps) {
    const {
        isExportingTickets,
        isDownloadingFile,
        hash,
        file,
        handleClickExportTickets,
        handleClickDownloadFile,
    } = useExportData({ giveaway, onTicketsFileGenerated });

    if (!giveaway) {
        return null;
    }

    return (
        <Form
            autoComplete='off'
            layout='vertical'
        >
            {
                !hash && (
                    <Row
                        justify="center"
                        style={{
                            marginTop: 32,
                        }}
                    >
                        <Button
                            onClick={handleClickExportTickets}
                            type="primary"
                            htmlType="button"
                            disabled={isExportingTickets}
                            loading={isExportingTickets}
                        >
                            Gerar arquivo com os bilhetes
                        </Button>
                    </Row>
                )
            }

            {
                file && hash && (
                    <FileAndHashContainer
                        hash={hash}
                        file={file}
                        downloadFileButtonLabel="Fazer download do arquivo de bilhetes"
                        fileInfoLabel={panelUserGeneratedParticipantsFile && `Arquivo gerado em ${formatDateStringToDateAndTime(giveaway?.participants_file_generated_at)} por ${panelUserGeneratedParticipantsFile.name}`}
                        isDownloadingFile={isDownloadingFile}
                        onClickDownloadFile={handleClickDownloadFile}
                    />
                )
            }
        </Form>
    )
}