import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IReward } from "../../../interfaces";

import RewardsService from "../../../services/http/services/RewardsService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useEditReward() {
    const { id } = useParams();
    const [reward, setReward] = useState<IReward>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadReward() {
            try {
                setIsLoading(true);
                const rewardData = await RewardsService.getRewardById(Number(id), controller.signal);

                setReward(rewardData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editReward_asdashiasdas');

                navigate('/recompensas');
            }
        }

        loadReward();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(reward: FormData) {
        try {
            await RewardsService.updateReward(Number(id), reward);

            EmitSuccessAlert('Recompensa atualizada com sucesso!');

            navigate('/recompensas');
        } catch (err) {
            EmitError(err, true, 'editReward_iashdausda');
        }
    }

    return {
        isLoading,
        reward,
        handleSubmit
    }
}