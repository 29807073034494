import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import AwardTypesService from "../../../services/http/services/AwardTypesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useNewAwardType() {
    const navigate = useNavigate();

    const handleSubmit = useCallback(async (awardType: FormData) => {
        try {
            await AwardTypesService.createAwardType(awardType);

            EmitSuccessAlert('Tipo de Prêmio cadastrado com sucesso!');

            navigate('/tipos-de-premios');
        } catch (err) {
            EmitError(err, true, 'newAwardType_asdpasmdas');
        }
    }, [navigate]);

    return {
        handleSubmit
    }
}