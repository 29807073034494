import { useNavigate } from "react-router-dom";

import RewardsService from "../../../services/http/services/RewardsService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useNewReward() {
    const navigate = useNavigate();

    async function handleSubmit(reward: FormData) {
        try {
            await RewardsService.createReward(reward);

            EmitSuccessAlert('Recompensa cadastrada com sucesso!');

            navigate('/recompensas');
        } catch (err) {
            EmitError(err, true, 'newReward_auisdhiasdhuad');
        }
    }

    return {
        handleSubmit
    }

}