import { ReactNode, createContext, useCallback, useEffect, useState } from "react";
import jwt_decode from 'jwt-decode';
import { addMinutes } from "date-fns";
import { AxiosError } from "axios";

import { ICity, IPanelUser, ISignInData } from "../interfaces";

import privateHttpClient from "../services/http/privateHttpClient";
import useLocalStorage from "../hooks/useLocalStorage";
import { EmitError } from "../utils/EmitError";
import { clearToasts } from "../utils/toast";
import AuthService from "../services/http/services/AuthService";
import { EModule } from "../enums";

interface ITokenCityData {
    id: number,
    modules: EModule[],
}

interface IAuthContext {
    loading: boolean,
    user: IPanelUser | undefined,
    signIn: (data: any) => Promise<IPanelUser | undefined>,
    signOut: () => void,
    activeCity: ICity | undefined,
    setUserActiveCity: (city: ICity) => Promise<void>,
    clearUserActiveCity: () => void,
    updateUserData: (updatedUser: IPanelUser, ignoreRefresh?: boolean) => Promise<void>,
    updateUserCityOnStorage: (city: ICity) => void,
    isSelectCityModalOpen: boolean,
    handleOpenSelectCityModal: () => void,
    handleCloseSelectCityModal: () => void,
}

export const AuthContext = createContext({} as IAuthContext);

interface IProps {
    children: ReactNode,
}

export function AuthProvider({ children }: IProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<IPanelUser>();
    const [activeCity, setActiveCity] = useState<ICity | undefined>();
    const [isSelectCityModalOpen, setIsSelectCityModalOpen] = useState<boolean>(false);

    const {
        getToken,
        getRefreshToken,
        getUser,
        removeTokenFromLocalStorage,
        removeRefreshTokenFromLocalStorage,
        removeUserFromLocalStorage,
        getSelectedCityFromStorage,
        removeSelectedCityFromLocalStorage,
    } = useLocalStorage();

    const refreshToken = useCallback(async (cityId: number): Promise<string | undefined> => {
        try {
            const refreshToken = getRefreshToken();

            const controller = new AbortController();

            let { data: { token, refresh_token } } = await AuthService.refreshToken(refreshToken, cityId, controller.signal);

            if (typeof token !== "string") {
                return undefined;
            }

            localStorage.setItem('@NotaMunicipio:token', JSON.stringify(
                { token: token }
            ));

            privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            if (refresh_token && typeof refresh_token !== 'undefined') {
                localStorage.setItem('@NotaMunicipio:rftoken', JSON.stringify(
                    { refresh_token }
                ));
            }

            return token;
        } catch (err) {
            if (err instanceof AxiosError && err.name === "CanceledError") {
                return;
            }

            EmitError(err, true, 'andoandoasd_refreshToken');

            return;
        }
    }, [getRefreshToken])

    const refreshTokenAndGetCityModules = useCallback(async (cityId: number): Promise<EModule[] | undefined> => {
        const token = await refreshToken(cityId);

        if (!token) {
            return;
        }

        const decodedToken: any = jwt_decode(token);

        if (decodedToken && decodedToken.county.modules) {
            return decodedToken.county.modules;
        }

        return undefined;
    }, [refreshToken]);

    const updateUserData = useCallback(async (updatedPanelUserData: IPanelUser, ignoreRefresh = false) => {
        if (ignoreRefresh) {
            const storedToken = getToken();

            if (!storedToken) {
                return;
            }

            const decodedToken: any = jwt_decode(storedToken);

            const updatedUserWithPermissions = {
                ...updatedPanelUserData,
                permissions: decodedToken.permissions,
            }

            setUser(updatedUserWithPermissions);

            localStorage.setItem('@NotaMunicipio:user', JSON.stringify(
                { user: updatedUserWithPermissions }
            ));

            return;
        }

        if (!activeCity || !activeCity.id) {
            return;
        }

        const token = await refreshToken(activeCity.id);

        if (!token) {
            return;
        }

        const decodedToken: any = jwt_decode(token);

        const updatedUserWithPermissions = {
            ...updatedPanelUserData,
            permissions: decodedToken.permissions,
        }

        setUser(updatedUserWithPermissions);

        localStorage.setItem('@NotaMunicipio:user', JSON.stringify(
            { user: updatedUserWithPermissions }
        ));
    }, [activeCity, getToken, refreshToken]);

    const updateUserCityOnStorage = useCallback((city: ICity) => {
        const user = getUser();

        const userCities = user?.cities;

        if (!userCities) {
            return;
        }

        const cityToBeUpdatedIndex = userCities.findIndex((c) => c.id === city.id);

        if (cityToBeUpdatedIndex < 0) {
            return;
        }

        userCities[cityToBeUpdatedIndex] = city;

        setUser({
            ...user,
            cities: userCities,
        });

        localStorage.setItem('@NotaMunicipio:user', JSON.stringify(
            {
                user: {
                    ...user,
                    cities: userCities,
                }
            }
        ));
    }, [getUser]);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (user && !activeCity) {
            setIsSelectCityModalOpen(true);
            return;
        }

        setIsSelectCityModalOpen(false);
    }, [activeCity, loading, user]);

    const handleOpenSelectCityModal = useCallback(() => {
        setIsSelectCityModalOpen(true);
    }, []);

    const handleCloseSelectCityModal = useCallback(() => {
        setIsSelectCityModalOpen(false);
    }, []);

    const signOut = useCallback(() => {
        removeTokenFromLocalStorage();
        removeRefreshTokenFromLocalStorage();
        removeUserFromLocalStorage();
        removeSelectedCityFromLocalStorage();
        privateHttpClient.defaults.headers.common['Authorization'] = undefined;
        setUser(undefined);
        setActiveCity(undefined);
        clearToasts();
    }, [removeTokenFromLocalStorage, removeRefreshTokenFromLocalStorage, removeUserFromLocalStorage, removeSelectedCityFromLocalStorage]);

    const setUserActiveCity = useCallback(async (city: ICity, ignoreRefresh: boolean = false) => {
        if (!city || !city.id) {
            return;
        }

        if (ignoreRefresh) {
            setActiveCity(city);

            localStorage.setItem('@NotaMunicipio:selectedCity', JSON.stringify(
                { city }
            ));

            return;
        }

        const modules = await refreshTokenAndGetCityModules(city.id);

        if (!modules) {
            signOut();
            throw new Error('Usuário não autorizado.');
        }

        setActiveCity({ ...city, modules });

        localStorage.setItem('@NotaMunicipio:selectedCity', JSON.stringify(
            { city: { ...city, modules } }
        ));

        return;
    }, [refreshTokenAndGetCityModules, signOut]);

    const clearUserActiveCity = useCallback(() => {
        removeSelectedCityFromLocalStorage();
        setActiveCity(undefined);
    }, [removeSelectedCityFromLocalStorage]);

    const signIn = useCallback(async (data: ISignInData): Promise<IPanelUser | undefined> => {
        try {
            let response = await AuthService.signIn(data);

            localStorage.setItem('@NotaMunicipio:token', JSON.stringify(
                { token: response.token }
            ));

            localStorage.setItem('@NotaMunicipio:rftoken', JSON.stringify(
                { refresh_token: response.refresh_token }
            ));

            const decodedToken: any = jwt_decode(response.token);

            const panelUser = { ...response.panel_user, permissions: decodedToken.permissions };

            localStorage.setItem('@NotaMunicipio:user', JSON.stringify(
                { user: panelUser }
            ));

            privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;

            setUser(panelUser);

            const tokenCity: ITokenCityData = decodedToken.county;

            if (tokenCity) {
                const cityToBeSelected = panelUser.cities.find((city) => city.id === tokenCity.id);

                if (cityToBeSelected) {
                    setUserActiveCity({
                        ...cityToBeSelected,
                        modules: tokenCity.modules,
                    }, true);
                }
            }

            clearToasts();
            return panelUser;
        } catch (err) {
            EmitError(err);
            return;
        }
    }, [setUserActiveCity]);

    const checkExpiredToken = useCallback((token: string | undefined): boolean => {
        if (token) {
            let decodedToken: any = jwt_decode(token);

            if (decodedToken != null) {
                // addMinutes(new Date(), 5).getTime() / 1000
                // if (decodedToken.exp < new Date().getTime() / 1000) {
                if (decodedToken.exp < addMinutes(new Date(), 3).getTime() / 1000) {
                    return true;
                }
            }
        } else if (token === undefined) {
            return true;
        }
        return false;
    }, []);

    useEffect(() => {
        setLoading(true);

        const storedUser = getUser();
        const storedToken = getToken();
        const storedSelectedCity = getSelectedCityFromStorage();

        if (storedUser) {
            setUser(storedUser);
            privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        }

        if (storedSelectedCity) {
            setActiveCity(storedSelectedCity);
        }

        setLoading(false);
    }, [getUser, getToken, getSelectedCityFromStorage]);

    useEffect(() => {
        const requestIntercept = privateHttpClient.interceptors.request.use(async (config) => {
            if (config.url?.includes('refresh_token')) {
                return config;
            }

            if (getToken() !== undefined && checkExpiredToken(getToken())) {
                try {

                    const refreshToken = getRefreshToken();
                    const selectedCity = getSelectedCityFromStorage();

                    let result = await AuthService.refreshToken(refreshToken, selectedCity?.id!, config.signal);

                    localStorage.setItem('@NotaMunicipio:token', JSON.stringify(
                        { token: result.data.token }
                    ));

                    privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;

                    if (result.data?.refresh_token &&
                        typeof result.data?.refresh_token !== 'undefined') {

                        localStorage.setItem('@NotaMunicipio:rftoken', JSON.stringify(
                            { refresh_token: result.data.refresh_token }
                        ));
                    }

                    if (config?.headers?.Authorization) {
                        config.headers.set('Authorization', 'Bearer ' + result.data.token);
                    }

                    return config;

                } catch (error) {
                    if (error instanceof AxiosError && error.name === 'CanceledError') {
                        return config;
                    }

                    signOut();
                    throw new Error('Usuário não autorizado.');
                }
            } else {
                return config;
            }
        }, function (error) {
            return Promise.reject(error);
        });

        return () => {
            privateHttpClient.interceptors.request.eject(requestIntercept);
        }
    }, [checkExpiredToken, getRefreshToken, getToken, signOut, getSelectedCityFromStorage]);

    return (
        <AuthContext.Provider
            value={{
                loading,
                user,
                signIn,
                signOut,
                activeCity,
                setUserActiveCity,
                clearUserActiveCity,
                updateUserData,
                updateUserCityOnStorage,
                isSelectCityModalOpen,
                handleOpenSelectCityModal,
                handleCloseSelectCityModal,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}