import { useCallback, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import Text from "antd/es/typography/Text";

import { colInputs } from "../../GridStyle";
import { EmitError } from "../../../../../utils/EmitError";

interface IProps {
    hash: string;
    file: string;
    downloadFileButtonLabel: string,
    fileInfoLabel?: string,
    isDownloadingFile: boolean;
    onClickDownloadFile: () => void;
}

export default function FileAndHashContainer({
    hash,
    file,
    downloadFileButtonLabel,
    fileInfoLabel,
    isDownloadingFile,
    onClickDownloadFile,
}: IProps) {
    const [copiedHash, setCopiedHash] = useState<boolean>(false);

    const handleClickCopyHash = useCallback(() => {
        if (!hash || copiedHash) {
            return;
        }

        try {
            navigator.clipboard.writeText(hash);
            setCopiedHash(true);
        } catch (err) {
            EmitError(err);
        }

        const timeoutId = setTimeout(() => {
            setCopiedHash(false);
        }, 3500);

        return () => {
            clearTimeout(timeoutId);
        }
    }, [hash, copiedHash]);

    return (
        <>
            {
                file && (
                    <>
                        <Row
                            justify="center"
                            style={{
                                marginTop: 32,
                            }}
                        >
                            <Button
                                onClick={onClickDownloadFile}
                                type="primary"
                                htmlType="button"
                                disabled={isDownloadingFile}
                                loading={isDownloadingFile}
                            >
                                {downloadFileButtonLabel}
                            </Button>
                        </Row>
                        <Row
                            justify="center"
                            style={{
                                marginBottom: 16,
                                marginTop: 8,
                                textAlign: 'center'
                            }}
                        >
                            <Text>{fileInfoLabel}</Text>
                        </Row>
                    </>
                )
            }

            {
                hash && (
                    <Row
                        justify="center"
                    >
                        <Col
                            {...colInputs}
                        >
                            <Form.Item
                                label="Hash"

                            >
                                <Input
                                    disabled
                                    name="hash"
                                    placeholder="Hash"
                                    value={hash}
                                />
                            </Form.Item>
                        </Col>

                        <Button
                            type="default"
                            htmlType="button"
                            onClick={handleClickCopyHash}
                            style={{
                                cursor: !copiedHash ? 'pointer' : 'default',
                                alignSelf: 'center',
                            }}
                        >
                            {
                                !copiedHash ? 'Copiar Hash' : (
                                    <>
                                        <CheckCircleOutlined style={{ marginRight: 8 }} />
                                        Copiado!
                                    </>
                                )
                            }
                        </Button>
                    </Row>
                )
            }
        </>
    )
}