import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IGiveaway } from "../../../interfaces";

import { EmitError } from "../../../utils/EmitError";
import GiveawaysService from "../../../services/http/services/GiveawaysService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";

export default function useEditGiveaway() {
    const [giveaway, setGiveaway] = useState<IGiveaway>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadGiveaway() {
            try {
                setIsLoading(true);
                const userData = await GiveawaysService.getGiveawayById(Number(id), controller.signal);

                setGiveaway(userData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editGiveaway_opasdasjpdasd');

                navigate('/sorteios');
            }
        }

        loadGiveaway();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(giveawayData: IGiveaway) {
        const newGiveawayData = await GiveawaysService.updateGiveaway(Number(id), giveawayData);

        EmitSuccessAlert('Sorteio atualizado com sucesso!');
        setGiveaway(newGiveawayData);
    }

    return {
        isLoading,
        giveaway,
        handleSubmit,
    }
}