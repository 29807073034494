import { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { IAward } from "../../../../../../../interfaces";

import AwardsTableRowActions from "./AwardsTableRowActions";
import { formatCurrency } from "../../../../../../../utils/Masks";
import AwardsListTableFooter from "./AwardsListTableFooter";
import { IAwardsOrderObject } from "../../../../../../../services/http/services/AwardsService";
import AwardsListTableRow from "./AwardsListTableRow";

interface IProps {
    awards: Array<IAward>,
    onClickEditAward: (award: IAward) => void,
    onClickDeleteAward: (award: IAward) => void,
    onUpdateAwardsOrder: (awardsOrder: Array<IAwardsOrderObject>) => Promise<void>,
    onAwardsOrderChanged: (isEqual: boolean) => void,
    giveawayEnded: boolean,
}

interface ITableData extends IAward {
}

export default function AwardsList({
    awards,
    onClickEditAward,
    onClickDeleteAward,
    onUpdateAwardsOrder,
    onAwardsOrderChanged,
    giveawayEnded,
}: IProps) {
    const [isUpdatingOrder, setIsUpdatingOrder] = useState<boolean>(false);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [orderedTableData, setOrderedTableData] = useState<Array<ITableData>>([]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
    );

    const onDragEnd = ({ active, over }: any) => {
        if (active.id !== over?.id) {
            setOrderedTableData((prev) => {
                const activeIndex = prev.findIndex((i) => i.id === active.id);
                const overIndex = prev.findIndex((i) => i.id === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const isEqual = orderedTableData.every((row, index) => {
        const initialIndex = tableData.findIndex(r => r.id === row.id);

        return initialIndex === index;
    });

    const handleClickSaveOrder = useCallback(async () => {
        setIsUpdatingOrder(true);

        await onUpdateAwardsOrder(orderedTableData.map((data, index) => ({ id: data.id, order: index + 1})));

        setIsUpdatingOrder(false);
    }, [onUpdateAwardsOrder, orderedTableData]);

    const handleCancelOrder = useCallback(() => {
        setOrderedTableData(tableData);
    }, [tableData]);

    const columns = [
        {
            title: "N°",
            dataIndex: "order",
            key: "order",
            render: (_: any, __: any, index: number) => index + 1,
        },
        {
            title: "Descrição",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Valor",
            dataIndex: "value",
            key: "value",
            render: (_: any, { value }: ITableData) => `R$ ${formatCurrency(value.toString())}`
        },
        {
            title: "Ações",
            dataIndex: "actions",
            key: "actions",
            render: (_: any, award: ITableData) => (
                <AwardsTableRowActions
                    award={award}
                    onClickEditAward={onClickEditAward}
                    onClickDeleteAward={() => onClickDeleteAward(award)}
                    disableDelete={!isEqual || giveawayEnded}
                />
            )
        },
    ]

    useEffect(() => {
        onAwardsOrderChanged(isEqual);
    }, [isEqual, onAwardsOrderChanged]);

    useEffect(() => {
        setTableData(awards);
        setOrderedTableData(awards.sort((a, b) => a.order - b.order));
    }, [awards]);

    return (
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                items={orderedTableData.map((i) => i.id)}
                strategy={verticalListSortingStrategy}
                disabled={giveawayEnded}
            >
                <Table
                    bordered
                    components={{
                        body: {
                          row: AwardsListTableRow,
                        },
                      }}
                    columns={columns}
                    dataSource={orderedTableData}
                    rowKey={(award) => award.id}
                    footer={() =>(
                        <AwardsListTableFooter
                            show={!isEqual}
                            onClickSaveOrder={handleClickSaveOrder}
                            onClickCancel={handleCancelOrder}
                            isSubmiting={isUpdatingOrder}
                        />
                    )}
                    pagination={false}
                />
            </SortableContext>
        </DndContext>
    )
}