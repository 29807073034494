import { IPanelUser } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import PanelUserMapper from '../mappers/PanelUserMapper';
import { urlHttpGov } from '../../urls';

class PanelUsersService {
    private baseURL: string = `${urlHttpGov}/panel/user`;

    async listPanelUsers(signal: AbortSignal) {

        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        return data.map((user: any) => PanelUserMapper.toDomain(user));
    }

    createPanelUser(panelUser: IPanelUser) {
        const persistencePanelUser = PanelUserMapper.toPersistence(panelUser);

        return privateHttpClient.post(this.baseURL, persistencePanelUser);
    }

    async getPanelUserById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return PanelUserMapper.toDomain(data);
    }

    async updatePanelUser(id: number, panelUser: IPanelUser): Promise<IPanelUser> {
        const persistencePanelUser = PanelUserMapper.toPersistence(panelUser);

        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, persistencePanelUser);

        return PanelUserMapper.toDomain(data);
    }

    deletePanelUser(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }

    updatePanelUserPassword(id: number, password: string) {
        return privateHttpClient.patch(`${this.baseURL}/${id}`, { password });
    }

    async updatePanelUserPasswordOnFirstAccess(password: string): Promise<IPanelUser> {
        const { data } = await privateHttpClient.post(`${this.baseURL}/first_access`, { new_password: password });

        return PanelUserMapper.toDomain(data);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PanelUsersService();
