import { IGiveaway, IGiveawayWithDetails } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import GiveawayMapper from '../mappers/GiveawayMapper';
import { urlHttpGov } from '../../urls';

class GiveawaysService {
    private baseURL: string = `${urlHttpGov}/panel/raffle`;

    async listGiveaways(cityId: number, signal: AbortSignal): Promise<Array<IGiveaway>> {

        const { data } = await privateHttpClient.get(`${this.baseURL}/county/${cityId}`, { signal });

        return data.map((giveaway: any) => GiveawayMapper.toDomain(giveaway));
    }

    async createGiveaway(giveaway: IGiveaway): Promise<IGiveaway> {
        const persistenceGiveaway = GiveawayMapper.toPersistence(giveaway);

        const { data } = await privateHttpClient.post(this.baseURL, persistenceGiveaway);

        const domainGiveaway = GiveawayMapper.toDomain(data);

        return domainGiveaway;
    }

    async getGiveawayById(id: number, signal: AbortSignal): Promise<IGiveaway> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return GiveawayMapper.toDomain(data);
    }

    async updateGiveaway(id: number, giveaway: IGiveaway): Promise<IGiveaway> {
        const persistenceGiveaway = GiveawayMapper.toPersistence(giveaway);

        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, persistenceGiveaway);

        const domainGiveaway = GiveawayMapper.toDomain(data);

        return domainGiveaway;
    }

    deleteGiveaway(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }

    async listGiveawaysWithDetails(cityId: number, appUserId: number, signal: AbortSignal): Promise<Array<IGiveawayWithDetails>> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/details/county/${cityId}/app_user/${appUserId}`, { signal });

        return data.map((element: any): IGiveawayWithDetails => ({
            ...GiveawayMapper.toDomain(element.raffle),
            total_tickets: element.count_tickets,
            total_invoices_balance: element.balance_of_invoice,
            total_taxes_balance: element.balance_of_taxe,
        }));
    }

    async exportGiveawayTickets(id: number): Promise<{hash: string, fileName: string}> {
        const { data } = await privateHttpClient.post(`${this.baseURL}/file`, { raffleId: id });

        return {
            hash: data.hash,
            fileName: data.fileName,
        };
    }

    async downloadGiveawayTicketsFile(fileName: string, signal: AbortSignal): Promise<Blob> {
        const response = await privateHttpClient.get(`${this.baseURL}/files/${fileName}`, {
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/json',
            },
            signal: signal,
        });

        return new Blob([response.data], { type: 'application/json' });
    }

    async uploadGiveawayResult(formData: FormData): Promise<{hash: string, file: string}> {
        const { data } = await privateHttpClient.post(`${this.baseURL}/generate_winners`, formData, {
            headers: { "Content-Type": 'multipart-formdata' }
        });
        
        return {
            hash: data.hash_with_winners,
            file: data.file_with_winners,
        };
    }

    async downloadGiveawayWinnersFile(fileName: string, signal: AbortSignal): Promise<Blob> {
        const response = await privateHttpClient.get(`${this.baseURL}/files/${fileName}`, {
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/json',
            },
            signal: signal,
        });

        return new Blob([response.data], { type: 'application/json' });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GiveawaysService();
