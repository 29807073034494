import { ICategory } from '../../../interfaces';

class CategoryMapper {
    toPersistence(domainCategory: ICategory): any {
        return {
            id: domainCategory.id,
            name: domainCategory.name,
            description: domainCategory.description,
            county_id: domainCategory.city_id,
            created_at: domainCategory.created_at,
        }
    }

    toDomain(persistenceCategory: any): ICategory {
        return {
            id: persistenceCategory.id,
            name: persistenceCategory.name,
            description: persistenceCategory.description,
            city_id: persistenceCategory.county_id,
            created_at: persistenceCategory.created_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CategoryMapper();
