import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Button, Input, InputRef, Space, Table, Tag } from "antd";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from '@ant-design/icons';

import { ICity, IPanelUser, IRole } from "../../interfaces";
import { FilterDropdownProps } from "antd/es/table/interface";

import PanelUsersService from "../../services/http/services/PanelUsersService";
import { EmitSuccessAlert } from "../../utils/EmitSuccessAlert";
import { EmitError } from "../../utils/EmitError";
import { removeAcento } from "../../utils/RemoveAcentos";
import RolesService from "../../services/http/services/RolesService";
import CitiesService from "../../services/http/services/CitiesService";

interface ITableData extends IPanelUser {
    key: number | undefined;
}

export default function usePanelUsers() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [panelUserBeingDeleted, setPanelUserBeingDeleted] = useState<ITableData | null>(null);
    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ITableData, inputColumnName: string, formatter?: (text: string) => string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) =>
            removeAcento(record[dataIndex]!.toString()).toLowerCase().includes(removeAcento(value).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: ITableData, b: ITableData) => a.name.localeCompare(b.name),
            showSorterTooltip: false,
            ...getColumnSearchProps('name', 'Nome'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email', 'Email'),
        },
        {
            title: 'Perfil',
            dataIndex: 'role',
            key: 'role',
            filters: roles.map((role) => ({ text: role.name, value: role.id })),
            onFilter: (value: any, record: IPanelUser) => {
                return record.role.id === value;
            },
            render: (_: any, { role }: any) => role.name
        },
        {
            title: 'Municípios',
            dataIndex: 'cities',
            key: 'cities',
            filters: cities.map((city) => ({ text: `${city.city} - ${city.uf}`, value: city.id })),
            onFilter: (value: any, record: IPanelUser) => {
                return record.cities.find((city) => city.id === value);
            },
            render: (_: any, { cities, ...rest }: any) => {
                if (cities.length > 1) {
                    return '...';
                }

                return cities.map((city: ICity) => <Tag key={city.id}>{city.city} - {city.uf}</Tag>)
            },
            width: '25%',
            ellipsis: true,
        },
        Table.EXPAND_COLUMN,
    ]

    const setTableDataFromRequest = useCallback((data: Array<IPanelUser>) => {
        const newData = data.map((user) => ({ ...user, key: user.id }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoading(true);

                const [
                    panelUsersList,
                    rolesList,
                    citiesList,
                ] = await Promise.all([
                    PanelUsersService.listPanelUsers(controller.signal),
                    RolesService.listRoles(controller.signal),
                    CitiesService.listCities(controller.signal),
                ]);

                setTableDataFromRequest(panelUsersList);
                setRoles(rolesList);
                setCities(citiesList);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'panelUsers_aosdpasdpasd');
                setIsLoading(false);
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [setTableDataFromRequest]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if(newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleDeletePanelUser = useCallback((id: number) => {
        setIsDeleteModalVisible(true);
        setPanelUserBeingDeleted(tableData.find((element) => element.id === id) || null);
    }, [tableData]);

    const handleConfirmDeletePanelUser = useCallback(async () => {
        try {
            setIsLoadingDelete(true);
            await PanelUsersService.deletePanelUser(panelUserBeingDeleted?.id!);
            EmitSuccessAlert('Usuário deletado com sucesso!');
            setTableData((prevState) => prevState.filter((element) => element.id !== panelUserBeingDeleted?.id));
            setIsDeleteModalVisible(false);
            setPanelUserBeingDeleted(null);
            setSelectedIds([]);
            setIsLoadingDelete(false);
        } catch (err) {
            EmitError(err, true, 'panelUsers_oasdajsdosp');
            setIsLoadingDelete(false);
        }
    }, [panelUserBeingDeleted]);

    const handleCancelDeletePanelUser = useCallback(async () => {
        setIsDeleteModalVisible(false); 
        setPanelUserBeingDeleted(null);
    }, []);

    const handleClickCreateNewPanelUser = useCallback(() => {
        navigate('/usuarios-painel/criar');
    }, [navigate]);

    const handleClickEditPanelUser = useCallback(() => {
        navigate(`/usuarios-painel/editar/${selectedIds[0]}`);
    }, [navigate, selectedIds]);

    return {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewPanelUser,
        handleClickEditPanelUser,
        handleDeletePanelUser,
        handleConfirmDeletePanelUser,
        handleCancelDeletePanelUser,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        panelUserBeingDeleted,
        tableData,
    }
}