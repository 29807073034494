import { useEffect } from "react";

import { IMessage } from "../interfaces";

export default function useToastMessage(
    message: IMessage,
    onRemoveMessage: (id: number) => void,
) {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onRemoveMessage(message.id!)
        }, message.duration || 5000)

        return () => {
            clearTimeout(timeoutId);
        }
    }, [message, onRemoveMessage]);

    function handleRemoveToast() {
        onRemoveMessage(message.id!);
    }

    return {
        handleRemoveToast,
    }
}