import RoleForm from '../components/RoleForm';
import useNewRole from './useNewRole';

export default function NewRole() {
    const { handleSubmit } = useNewRole();

    return (
        <RoleForm action='Adicionar' onSubmit={handleSubmit} />
    )
}
