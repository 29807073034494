import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Alert, Breadcrumb, Button, Col, Divider, Form, Image, Input, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { UnlockOutlined } from '@ant-design/icons';

import { colInputs } from './GridStyle';
import useViewRecoverRequest from './useViewRecoverRequest';
import Loading from '../../../components/Loading';
import SimpleModal from '../../../components/SimpleModal';

export default function ViewRecoverRequest() {
    const {
        isLoading,
        isSubmiting,
        control,
        images,
        handleApproveRequest,
        handleRefuseRequest,
        errors,
        recoverRequest,
        isConfirmApproveRequestModalVisible,
        isConfirmRefuseRequestModalVisible,
        handleConfirmApproveRequest,
        handleConfirmRefuseRequest,
        handleCancel,
    } = useViewRecoverRequest();

    return (
        <Loading loading={isLoading}>
            <Row>
                <Col>
                    <Title level={3}><UnlockOutlined style={{ fontSize: 35, marginRight: 10 }} />Visualizar Solicitação de Recuperação de Acesso</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/recuperacao-acesso'>Solicitações de Recuperação de Acesso</Link>,
                            },
                            {
                                title: 'Visualizar Solicitação de Recuperação de Acesso',
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            {
                recoverRequest?.status && recoverRequest.status !== "Em Análise" && (
                    <Row
                        style={{
                            marginBottom: 12,
                        }}
                    >
                        <Alert
                            type={recoverRequest.status === "Aprovado!" ? 'success' : 'error'}
                            message={`${recoverRequest.status} ${recoverRequest?.date_response ? new Date(recoverRequest.date_response).toLocaleDateString('pt-BR', { hour: '2-digit', minute: '2-digit' }) : ''} Usuário: ${recoverRequest?.panel_user?.name}`}
                            description={`Observação: ${recoverRequest?.observation ? recoverRequest.observation : 'Não há observação cadastrada.'}`}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Row>
                )
            }

            <Form
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Nome do Usuário"
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="CPF"
                        >
                            <Controller
                                name="cpf"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='CPF' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Telefone cadastrado atualmente"
                        >
                            <Controller
                                name="existing_phone"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Telefone cadastrado atualmente' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Novo telefone"
                        >
                            <Controller
                                name="new_phone"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Novo telefone' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Data da solicitação"
                        >
                            <Controller
                                name="created_at"
                                control={control}
                                render={({ field }) => <Input {...field} value={new Date(field.value).toLocaleDateString('pt-BR')} disabled placeholder='Data da solicitação' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Title level={5}>Fotos dos documentos</Title>

                <Row>
                    {
                        isLoading || images.length > 0 ? (
                            <Image.PreviewGroup>
                                {
                                    images?.map((url, index) => (
                                        <Image width={200} src={url} key={index} />
                                    ))
                                }
                            </Image.PreviewGroup>
                        )
                            : (
                                <Text>
                                    Não há fotos disponíveis.
                                </Text>
                            )
                    }
                </Row>

                {
                    recoverRequest?.status === "Em Análise" && (
                        <Row
                            style={{
                                marginTop: 16
                            }}
                        >
                            <Col
                                xs={24}
                            >
                                <Form.Item
                                    label="Observação"
                                    validateStatus={errors.observation ? 'error' : 'success'}
                                    help={errors.observation?.message}
                                >
                                    <Controller
                                        name="observation"
                                        control={control}
                                        render={({ field }) => <Input {...field} placeholder='Observação' />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }


                {
                    recoverRequest?.status === "Em Análise" && (
                        <Row
                            style={{
                                marginTop: 16
                            }}
                            justify="end"
                        >
                            <Button
                                type='primary'
                                danger
                                htmlType='button'
                                disabled={isSubmiting || isConfirmRefuseRequestModalVisible || isConfirmApproveRequestModalVisible}
                                loading={isSubmiting}
                                style={{
                                    marginRight: 8,
                                }}
                                onClick={handleRefuseRequest}
                            >
                                Recusar
                            </Button>

                            <Button
                                type='primary'
                                htmlType='button'
                                disabled={isSubmiting || isConfirmRefuseRequestModalVisible || isConfirmApproveRequestModalVisible}
                                loading={isSubmiting}
                                onClick={handleApproveRequest}
                            >
                                Aprovar
                            </Button>
                        </Row>
                    )
                }
            </Form>

            <SimpleModal
                open={isConfirmApproveRequestModalVisible}
                onOk={handleConfirmApproveRequest}
                okButtonProps={{
                    disabled: isSubmiting,
                    loading: isSubmiting
                }}
                cancelButtonProps={{
                    disabled: isSubmiting
                }}
                okType='primary'
                okText='Aprovar'
                onCancel={handleCancel}
                title={`Deseja realmente aprovar a solicitação de recuperação de acesso do usuário "${recoverRequest?.app_user.name}"?`}
            />

            <SimpleModal
                open={isConfirmRefuseRequestModalVisible}
                onOk={handleConfirmRefuseRequest}
                okButtonProps={{
                    disabled: isSubmiting,
                    loading: isSubmiting
                }}
                cancelButtonProps={{
                    disabled: isSubmiting
                }}
                okText='Recusar'
                onCancel={handleCancel}
                title={`Deseja realmente recusar a solicitação de recuperação de acesso do usuário "${recoverRequest?.app_user.name}"?`}
            />
        </Loading>
    )
}
