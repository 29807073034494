import { IMessage } from '../../../interfaces';
import { IMessageUser } from '../../../pages/Messages/interfaces';

import privateHttpClient from '../privateHttpClient';
import MessageMapper from '../mappers/MessageMapper';
import { urlHttpGov } from '../../urls';

class MessagesService {
    private baseURL: string = `${urlHttpGov}/panel/message`;

    async listCityMessages(cityId: number, signal: AbortSignal): Promise<IMessage[]> {

        const { data } = await privateHttpClient.get(`${this.baseURL}/county/${cityId}`, { signal });

        return data.map((message: any) => MessageMapper.toDomain(message));
    }

    async listGeneralMessages(signal: AbortSignal): Promise<Array<IMessage>> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/general`, { signal });

        return data.map((message: any) => MessageMapper.toDomain(message));
    }

    async createMessage(message: FormData) {

        const { data } = await privateHttpClient.post(this.baseURL, message, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });

        return MessageMapper.toDomain(data);
    }

    async getMessageById(id: number, signal: AbortSignal): Promise<IMessage> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return MessageMapper.toDomain(data);
    }

    async updateMessage(id: number, message: FormData) {
        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, message, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });

        return MessageMapper.toDomain(data);
    }

    async listMessageAppUsers(
        id: number,
        page: number,
        signal: AbortSignal,
        filters?: {
            name: string,
            cpf: string,
        },
    ): Promise<Array<IMessageUser>> {
        const { data } = await privateHttpClient.get(
            `${this.baseURL}/${id}/app_users?page=${page}&name=${filters?.name || ''}&cpf=${filters?.cpf || ''}`,
            { signal }
        );

        return data;
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MessagesService();
