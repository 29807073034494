import {
    colInputsConfigDescription,
    colInputsConfigDescriptionExpanded,
} from "../../GridStyle";

export default function useConfigStep(
    needsToInformPointsConfigEffectiveDate: boolean,
    needsToInformTicketsConfigEffectiveDate: boolean
) {
    const colInputsPointsConfigDescription = needsToInformPointsConfigEffectiveDate ? colInputsConfigDescription : colInputsConfigDescriptionExpanded;
    const colInputsTicketsConfigDescription = needsToInformTicketsConfigEffectiveDate ? colInputsConfigDescription : colInputsConfigDescriptionExpanded;

    return {
        colInputsPointsConfigDescription,
        colInputsTicketsConfigDescription,
    }
}