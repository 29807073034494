import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { SolutionOutlined } from '@ant-design/icons';

import { IRole } from '../../../../interfaces';

import { colInputs } from './GridStyle';
import useRoleForm from './useRoleForm';

interface IProps {
    action: 'Adicionar' | 'Editar';
    role?: IRole;
    onSubmit: (role: IRole) => Promise<void>;
}

export default function RoleForm({ action, role, onSubmit }: IProps) {
    const {
        isLoadingPermissions,
        permissions,
        handleSubmitForm,
        handleSubmit,
        isSubmiting,
        errors,
        control,
    } = useRoleForm(onSubmit, role);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><SolutionOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Perfil</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/perfis'>Perfis</Link>,
                            },
                            {
                                title: `${action} Perfil`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Nome"
                            required
                            validateStatus={errors.name ? 'error' : 'success'}
                            help={errors.name?.message}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, digite o nome do Perfil!' }, }}
                                render={({ field }) => <Input {...field} placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Descrição"
                            required
                            validateStatus={errors.description ? 'error' : 'success'}
                            help={errors.description?.message}
                        >
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite a descrição do Perfil!' },
                                }}
                                render={({ field }) => <Input {...field} placeholder='Descrição' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Permissões"
                            required
                            validateStatus={errors.permission_ids ? 'error' : 'success'}
                            help={errors.permission_ids?.message}
                        >
                            <Controller
                                name="permission_ids"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, selecione ao menos uma permisssão!' },
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        mode='multiple'
                                        allowClear
                                        options={permissions.map((permission) => ({ value: permission.id, label: permission.description }))}
                                        placeholder='Selecione as permissões'
                                        disabled={isLoadingPermissions}
                                        loading={isLoadingPermissions}
                                    />
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isSubmiting || isLoadingPermissions}
                    loading={isSubmiting}
                >
                    Salvar
                </Button>
            </Form>
        </>

    )
}
