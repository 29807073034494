import { Controller } from "react-hook-form";
import { Button, Col, Form, Input, Row } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import Text from "antd/es/typography/Text";

import { InboxOutlined } from '@ant-design/icons';

import { IGiveaway, IPanelUser } from "../../../../../interfaces";

import { colInputs } from "../../GridStyle";
import useImportData from "./useImportData";
import FileAndHashContainer from "../../components/FileAndHashContainer";
import { formatDateStringToDateAndTime } from "../../../../../utils/Conversores";

interface IProps {
    giveaway: IGiveaway | undefined;
    panelUserUploadedWinnersFile?: IPanelUser;
    onWinnersFileImported: (hash: string, file: string) => void;
}

export default function ImportData({ giveaway, panelUserUploadedWinnersFile, onWinnersFileImported }: IProps) {
    const {
        isSubmiting,
        control,
        handleSubmit,
        handleSubmitForm,
        errors,
        dummyRequest,
        handleChangeWinnersFile,
        beingUploadedWinnersFileName,
        file,
        handleClickDownloadFile,
        hash,
        isDownloadingFile,
        ACCEPTED_FILE_TYPES,
    } = useImportData({ giveaway, onWinnersFileImported});
    return (
        <Form
            onFinish={handleSubmit(handleSubmitForm)}
            autoComplete='off'
            layout='vertical'
        >
            {
                !file && !hash && (
                    <>
                        <Col
                            {...colInputs}
                        >
                            <Form.Item
                                label="Arquivo dos ganhadores"
                                validateStatus={errors.winners_file ? 'error' : 'success'}
                                help={errors.winners_file?.message}
                                required
                            >
                                <Controller
                                    name="winners_file"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "Por favor, selecione o arquivo gerado pelo sistema de sorteio!" }
                                    }}
                                    render={({ field: { value, onChange, ...field } }) => (
                                        <Dragger
                                            {...field}
                                            showUploadList={false}
                                            customRequest={dummyRequest}
                                            onChange={(e: UploadChangeParam<UploadFile>) => handleChangeWinnersFile({ onChangeHookForm: onChange, file: e.file })}
                                            accept={ACCEPTED_FILE_TYPES.toString()}
                                        >
                                            {
                                                beingUploadedWinnersFileName && typeof beingUploadedWinnersFileName === 'string' ? (
                                                    <Text
                                                        style={{
                                                            margin: '0 8px',
                                                        }}
                                                        className="ant-upload-text"
                                                    >
                                                        {beingUploadedWinnersFileName}
                                                    </Text>
                                                )
                                                    : (
                                                        <>
                                                            <p
                                                                className="ant-upload-drag-icon"
                                                            >
                                                                <InboxOutlined />
                                                            </p>
                                                            <Text
                                                                style={{
                                                                    margin: '0 8px',
                                                                }}
                                                                className="ant-upload-text"
                                                            >
                                                                Clique ou arraste o arquivo para fazer o upload
                                                            </Text>
                                                        </>
                                                    )
                                            }
                                        </Dragger>
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            span={24}
                        >
                            <Form.Item
                                label="Hash gerado pelo sistema de sorteio"
                                required
                                validateStatus={errors.hash ? 'error' : 'success'}
                                help={errors.hash?.message}
                            >
                                <Controller
                                    name="hash"
                                    control={control}
                                    rules={{ required: { value: true, message: 'Por favor, informe o Hash!' }, }}
                                    render={({ field }) =>
                                        <Input
                                            {...field}
                                            placeholder='Hash'
                                        />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Row
                            justify="center"
                            style={{
                                marginTop: 32,
                            }}
                        >
                            <Button
                                onClick={() => { }}
                                type="primary"
                                htmlType="submit"
                                disabled={isSubmiting}
                            >
                                Importar Resultado
                            </Button>
                        </Row>

                    </>
                )
            }

{
                file && hash && (
                    <FileAndHashContainer
                        hash={hash}
                        file={file}
                        downloadFileButtonLabel="Fazer download do arquivo de vencedores"
                        fileInfoLabel={panelUserUploadedWinnersFile && `Arquivo importado em ${formatDateStringToDateAndTime(giveaway?.winners_file_uploaded_at)} por ${panelUserUploadedWinnersFile.name}`}
                        isDownloadingFile={isDownloadingFile}
                        onClickDownloadFile={handleClickDownloadFile}
                    />
                )
            }
        </Form>
    )
}