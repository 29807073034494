import RewardForm from '../components/RewardForm';
import useNewReward from './useNewReward';

export default function NewReward() {
    const { handleSubmit } = useNewReward();

    return (
        <RewardForm action='Adicionar' onSubmit={handleSubmit} />
    )
}
