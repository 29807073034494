import {
    DownOutlined,
} from '@ant-design/icons';
import Text from "antd/es/typography/Text";

import useAuth from "../../../../hooks/useAuth";

interface IProps {
    hidden?: boolean;
}

export default function CityName({ hidden = false }: IProps) {
    const { activeCity, user, handleOpenSelectCityModal } = useAuth();

    if (hidden) {
        return null;
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 8,
            }}
        >
            {
                activeCity && (
                    <Text
                        style={{
                            fontWeight: "bold",
                            color: '#FFF',
                            cursor: user?.cities && user.cities.length > 1 ? 'pointer' : 'default',
                            textAlign: 'center',
                        }}
                        onClick={user?.cities && user.cities.length > 1 ? handleOpenSelectCityModal : undefined}
                    >
                        {activeCity?.city} - {activeCity?.uf}
                        {
                            user?.cities && user.cities.length > 1 && (
                                <DownOutlined
                                    style={{
                                        fontSize: 10,
                                        marginLeft: 8,
                                    }}
                                />
                            )
                        }

                    </Text>
                )
            }

        </div>
    )
}