import { Button } from 'antd';

import useAwardTypes from './useAwardTypes';
import Table from '../../components/Table';
import SimpleModal from '../../components/SimpleModal';

export default function AwardTypes() {
    const {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewAwardType,
        handleClickEditAwardType,
        handleDeleteAwardType,
        handleConfirmDeleteAwardType,
        handleCancelDeleteAwardType,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        awardTypeBeingDeleted
    } = useAwardTypes();

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    zIndex: 99,
                    paddingTop: 24,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 10,
                    margin: -24
                }}
            >
                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={handleClickCreateNewAwardType}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    onClick={handleClickEditAwardType}
                    disabled={selectedIds.length !== 1}
                >
                    Editar
                </Button>
                <Button
                    type="primary"
                    danger
                    style={{ margin: 2 }}
                    onClick={() => {
                        handleDeleteAwardType(selectedIds[0])
                    }}
                    disabled={selectedIds.length !== 1}
                >
                    Deletar
                </Button>
            </div>
            <Table
                columns={columns}
                tableData={tableData}
                rowSelection={rowSelection}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onClickTableRow(record, rowIndex) }, // click row
                        onDoubleClick: event => { }, // double click row
                        onContextMenu: event => { }, // right button click row
                        onMouseEnter: event => { }, // mouse enter row
                        onMouseLeave: event => { },
                    }
                }}
                loading={isLoading}
            >

            </Table>
            <SimpleModal
                open={isDeleteModalVisible}
                onOk={handleConfirmDeleteAwardType}
                okButtonProps={{ 
                    disabled: isLoadingDelete,
                    loading: isLoadingDelete
                }}
                cancelButtonProps={{
                    disabled: isLoadingDelete
                }}
                okText='Deletar'
                onCancel={handleCancelDeleteAwardType}
                title={`Deseja realmente deletar "${awardTypeBeingDeleted?.name}"?`}
            />
        </>
    )
}
