import { IAwardType } from '../../../interfaces';

class AwardTypeMapper {
    toPersistence(domainAwardType: IAwardType): any {
        return {
            id: domainAwardType.id,
            name: domainAwardType.name,
            image: domainAwardType.default_image,
        }
    }

    toDomain(persistenceAwardType: any): IAwardType {
        return {
            id: persistenceAwardType.id,
            name: persistenceAwardType.name,
            default_image: persistenceAwardType.photo[0]?.img,
            created_at: persistenceAwardType.created_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AwardTypeMapper();
