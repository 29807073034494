import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import Highlighter from "react-highlight-words";
import { Button, Input, InputRef, Space } from "antd";

import { SearchOutlined } from '@ant-design/icons';

import { ICity } from "../../interfaces";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";

import CitiesService from "../../services/http/services/CitiesService";
import { EmitSuccessAlert } from "../../utils/EmitSuccessAlert";
import { EmitError } from "../../utils/EmitError";
import { normalizeCnpjNumber } from "../../utils/Masks";
import { removeAcento } from "../../utils/RemoveAcentos";
import { formatDateStringToDateAndTime } from "../../utils/Conversores";

interface ITableData extends ICity {
    key: number | undefined;
    city_uf: string;
}

export default function useCities() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [cityBeingDeleted, setCityBeingDeleted] = useState<ITableData | null>(null);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ITableData, inputColumnName: string, formatter?: (text: string) => string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) =>
            removeAcento(record[dataIndex]!.toString()).toLowerCase().includes(removeAcento(value).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ?
                            formatter ? formatter(text.toString())
                                : text.toString()
                            : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Município',
            dataIndex: ['city_uf'],
            key: 'city_uf',
            sorter: (a: ITableData, b: ITableData) => a.city_uf.localeCompare(b.city_uf),
            showSorterTooltip: false,
            ...getColumnSearchProps('city_uf', 'Município'),
        },
        {
            title: 'CNPJ',
            dataIndex: 'cnpj',
            key: 'cnpj',
            ...getColumnSearchProps('cnpj', 'CNPJ', normalizeCnpjNumber),
        },
        {
            title: 'Data de cadastro',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (record: string) => formatDateStringToDateAndTime(record),
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: ITableData, b: ITableData) => new Date(a.created_at!).getTime() - new Date(b.created_at!).getTime(),
            showSorterTooltip: false,
        },
    ]

    const setTableDataFromRequest = useCallback((data: Array<ICity>) => {
        const newData = data.map((city) => ({ ...city, key: city.id, city_uf: `${city.city} - ${city.uf}` }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        async function loadCities() {
            try {
                setIsLoading(true);
                const cities = await CitiesService.listCities(controller.signal);

                setTableDataFromRequest(cities);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'cities_asdhashuidd');
                setIsLoading(false);
            }
        }

        loadCities();

        return () => {
            controller.abort();
        }
    }, [setTableDataFromRequest]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleDeleteCity = useCallback((id: number) => {
        setIsDeleteModalVisible(true);
        setCityBeingDeleted(tableData.find((element) => element.id === id) || null);
    }, [tableData]);

    const handleConfirmDeleteCity = useCallback(async () => {
        try {
            setIsLoadingDelete(true);
            await CitiesService.deleteCity(cityBeingDeleted?.id!);
            EmitSuccessAlert("Município deletado com sucesso!");
            setTableData((prevState) => prevState.filter((element) => element.id !== cityBeingDeleted?.id));
            setIsDeleteModalVisible(false);
            setCityBeingDeleted(null);
            setSelectedIds([]);
            setIsLoadingDelete(false);
        } catch (err) {
            EmitError(err);
            setIsLoadingDelete(false);
        }
    }, [cityBeingDeleted]);

    const handleCancelDeleteCity = useCallback(async () => {
        setIsDeleteModalVisible(false);
        setCityBeingDeleted(null);
    }, []);

    const handleClickCreateNewCity = useCallback(() => {
        navigate('/municipios/criar');
    }, [navigate]);

    const handleClickEditCity = useCallback(() => {
        navigate(`/municipios/editar/${selectedIds[0]}`)
    }, [navigate, selectedIds]);

    return {
        isLoading,
        isDeleteModalVisible,
        isLoadingDelete,
        handleClickCreateNewCity,
        handleClickEditCity,
        handleDeleteCity,
        handleConfirmDeleteCity,
        handleCancelDeleteCity,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        cityBeingDeleted,
        tableData,
    }
}