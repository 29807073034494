import Title from "antd/es/typography/Title";
import { IAppUser, IReward, IVoucher } from "../../interfaces";
import { formatCpf } from "../../utils/Masks";

import Text from 'antd/es/typography/Text';
import { Col } from "antd";
import { useCallback, useContext, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface IProps {
    voucher?: IVoucher,
    reward?: IReward,
    user?: IAppUser,
    onClick?: (voucher: IVoucher) => void,
    clickable?: boolean,
    fullWidth?: boolean,
}

export default function Voucher({
    voucher,
    reward,
    user,
    onClick = () => { },
    clickable = true,
    fullWidth = false,
}: IProps) {
    const [isMouseIn, setIsMouseIn] = useState<boolean>(false);

    const { theme } = useContext(ThemeContext);

    const handleMouseEnter = useCallback(() => {
        if (!clickable) {
            return
        }

        setIsMouseIn(true);
    }, [clickable]);

    const handleMouseLeave = useCallback(() => {
        if (!clickable) {
            return
        }

        setIsMouseIn(false);
    }, [clickable]);

    if (!voucher || !reward || !user) {
        return null;
    }

    return (
        <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: fullWidth ? 24 : 12, offset: 0 }}
            xxl={{ span: fullWidth ? 24 : 12, offset: 0 }}

        >
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => onClick(voucher)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 150,
                    backgroundColor: theme === 'light' ? '#FFF' : '#212121',
                    boxShadow:
                        isMouseIn ?
                            theme === 'light' ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                                : 'rgba(255, 255, 255, 0.07) 0px 4px 12px'
                            : 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    padding: 0,
                    transition: 'ease-in .3s',
                    cursor: clickable ? 'pointer' : 'default',
                }}
            >

                <div
                    style={{
                        width: '70%',
                        height: 150,
                        paddingLeft: 6,
                        paddingTop: 6,
                        paddingBottom: 6,
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            borderBottom: '2px solid #3BE200',
                            borderLeft: '2px solid #3BE200',
                            borderTop: '2px solid #3BE200',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-around',
                            padding: 8,
                        }}
                    >
                        <Text
                            style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: 16
                            }}
                        >
                            {'Produto: '}

                            <Text
                                style={{
                                    fontWeight: 'normal',
                                    fontSize: 16
                                }}
                            >
                                {reward.name}
                            </Text>
                        </Text>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                style={{
                                    textAlign: 'left',
                                    borderBottom: '1px dashed #4E4E4E',
                                    fontWeight: 'bold',
                                    fontSize: 16
                                }}
                            >
                                {'CPF: '}

                                <Text
                                    style={{
                                        fontWeight: 'normal',
                                        fontSize: 16
                                    }}
                                >
                                    {user && formatCpf(user.cpf)}
                                </Text>
                            </Text>

                            <Text
                                style={{
                                    textAlign: 'left',
                                    borderBottom: '1px dashed #4E4E4E',
                                    fontWeight: 'bold',
                                    fontSize: 16
                                }}
                            >
                                {'Valor: '}

                                <Text
                                    style={{
                                        fontWeight: 'normal',
                                        fontSize: 16
                                    }}
                                >
                                    {voucher.points_used.toLocaleString('pt-BR')} P
                                </Text>
                            </Text>

                        </div>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                style={{
                                    textAlign: 'left',
                                    borderBottom: '1px dashed #4E4E4E',
                                    fontWeight: 'bold',
                                    fontSize: 16
                                }}
                            >
                                {'Data de Resgate: '}

                                <Text
                                    style={{
                                        fontWeight: 'normal',
                                        fontSize: 16
                                    }}
                                >
                                    {new Date(voucher.created_at).toLocaleDateString('pt-BR')}
                                </Text>
                            </Text>

                            <Text
                                style={{
                                    textAlign: 'left',
                                    borderBottom: '1px dashed #4E4E4E',
                                    fontWeight: 'bold',
                                    fontSize: 16
                                }}
                            >
                                {'Expira: '}

                                <Text
                                    style={{
                                        fontWeight: 'normal',
                                        fontSize: 16
                                    }}
                                >
                                    {new Date(voucher.validity).toLocaleDateString('pt-BR')}
                                </Text>
                            </Text>
                        </div>
                    </div>

                </div>

                <div
                    style={{
                        width: '30%',
                        backgroundColor: '#3BE200',
                        height: 150,
                        paddingRight: 6,
                        paddingTop: 6,
                        paddingBottom: 6,
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            borderBottom: `2px solid ${theme === 'light' ? '#FFF' : '#212121'}`,
                            borderRight: `2px solid ${theme === 'light' ? '#FFF' : '#212121'}`,
                            borderTop: `2px solid ${theme === 'light' ? '#FFF' : '#212121'}`,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Text
                            style={{
                                color: theme === 'light' ? '#FFF' : '#212121',
                            }}
                        >
                            CÓDIGO
                        </Text>

                        <Title
                            level={3}
                            style={{
                                color: theme === 'light' ? '#FFF' : '#212121',
                                margin: 0,
                            }}
                        >
                            {voucher.redemption_code}
                        </Title>
                    </div>
                </div>
            </div>
        </Col>
    )
}