import PanelUserForm from '../components/PanelUserForm';
import useNewPanelUser from './useNewPanelUser';

export default function NewPanelUser() {
    const { handleSubmit } = useNewPanelUser();

    return (
        <PanelUserForm action='Adicionar' onSubmit={handleSubmit} />
    )
}
