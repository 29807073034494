import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Row } from "antd";
import Text from "antd/es/typography/Text";
import { AxiosError } from "axios";

import './styles.css';

import { IMessageUser } from "../../../interfaces";

import AppUserRow from "../AppUserRow";
import MessagesService from "../../../../../services/http/services/MessagesService";
import { EmitError } from "../../../../../utils/EmitError";
import { onlyNumbers } from "../../../../../utils/Masks";

interface IProps {
    filters: {
        name: string,
        cpf: string,
    }
}

export default function AppUsersList({
    filters,
}: IProps) {
    const [appUsers, setAppUsers] = useState<Array<IMessageUser>>([]);
    const [filteredAppUsers, setFilteredAppUsers] = useState<Array<IMessageUser>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFiltered, setIsLoadingFiltered] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState(true);
    const [pageFiltered, setPageFiltered] = useState<number>(1);
    const [hasMoreFiltered, setHasMoreFiltered] = useState(true);
    const { id } = useParams();

    const isSearching = useMemo(() => {
        return filters.name.length >= 3 || filters.cpf.length >= 3;
    }, [filters]);

    const loadMore = useCallback(async () => {
        if (isLoading) {
            return;
        }

        const controller = new AbortController();

        try {
            setIsLoading(true);

            const appUsersList = await MessagesService.listMessageAppUsers(Number(id), page, controller.signal);

            if (appUsersList.length === 0) {
                setHasMore(false);
            }

            setAppUsers((prevState) => [...prevState, ...appUsersList]);
            setPage((prevState) => prevState + 1);
            setIsLoading(false);

        } catch (err) {
            if (err instanceof AxiosError && err.name === "CanceledError") {
                return;
            }

            setIsLoading(false);
            EmitError(err);
        }

        return () => {
            controller.abort();
        }
    }, [id, page, isLoading]);

    const loadMoreFiltered = useCallback(async () => {
        if (isLoadingFiltered) {
            return;
        }

        const controller = new AbortController();

        try {
            setIsLoadingFiltered(true);

            const appUsersList = await MessagesService.listMessageAppUsers(
                Number(id),
                pageFiltered,
                controller.signal,
                {
                    ...filters,
                    cpf: onlyNumbers(filters.cpf),
                }
            );

            if (appUsersList.length === 0) {
                setHasMoreFiltered(false);
            }

            setFilteredAppUsers((prevState) => [...prevState, ...appUsersList]);
            setPageFiltered((prevState) => prevState + 1);
            setIsLoadingFiltered(false);

        } catch (err) {
            if (err instanceof AxiosError && err.name === "CanceledError") {
                return;
            }

            setIsLoadingFiltered(false);
            EmitError(err);
        }

        return () => {
            controller.abort();
        }
    }, [id, pageFiltered, isLoadingFiltered, filters]);

    useEffect(() => {
        if (!id) {
            return;
        }

        const controller = new AbortController();

        async function loadAppUsers() {
            try {
                setIsLoading(true);

                const appUsersList = await MessagesService.listMessageAppUsers(Number(id), 1, controller.signal);

                setAppUsers(appUsersList);
                if (appUsersList.length < 100) {
                    setHasMore(false);
                }
                setPage((prevState) => prevState + 1);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                setIsLoading(false);
                EmitError(err);
            }
        }

        loadAppUsers();

        return () => {
            controller.abort();
        }
    }, [id]);

    useEffect(() => {
        if (!id || (!filters.name && !filters.cpf)) {
            setFilteredAppUsers([]);
            return;
        }

        if (!isSearching) {
            return;
        }

        const controller = new AbortController();

        async function loadFilteredAppUsers() {
            try {
                setIsLoadingFiltered(true);

                const appUsersList = await MessagesService.listMessageAppUsers(
                    Number(id),
                    1,
                    controller.signal,
                    {
                        ...filters,
                        cpf: onlyNumbers(filters.cpf),
                    }
                );

                setFilteredAppUsers(appUsersList);

                if (appUsersList.length < 100) {
                    setHasMoreFiltered(false);
                } else {
                    setHasMoreFiltered(true);
                }

                setPageFiltered(2);

                setIsLoadingFiltered(false);
            } catch (err) {
                setIsLoadingFiltered(false);

                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                EmitError(err);
            }
        }

        loadFilteredAppUsers();

        return () => {
            controller.abort();
        }
    }, [id, filters, isSearching]);

    return (
        <>
            {
                isSearching ? (
                    <InfiniteScroll
                        key="infinity-scroll-filter"
                        height={300}
                        dataLength={filteredAppUsers.length}
                        next={loadMoreFiltered}
                        hasMore={hasMoreFiltered}
                        loader={(
                            <Row
                                key="0"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: 8,
                                }}
                            >
                                <Text>Carregando...</Text>
                            </Row>
                        )}
                        endMessage={filteredAppUsers.length > 0 ? (
                            <Row
                                key="0"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: 8,
                                }}
                            >
                                <Text>•</Text>
                            </Row>
                        ) : undefined}
                        style={{
                            overflowX: 'hidden',
                        }}
                    >
                        <Row
                            gutter={[0, 16]}
                            style={{
                                marginTop: 16,
                            }}
                        >
                            {
                                filteredAppUsers.map((appUser) => (
                                    <AppUserRow
                                        key={appUser.id}
                                        appUser={appUser}
                                        filters={filters}
                                    />
                                ))
                            }
                        </Row>
                    </InfiniteScroll>
                )
                    : (
                        <InfiniteScroll
                            key="infinity-scroll-all"
                            height={300}
                            dataLength={appUsers.length}
                            next={loadMore}
                            hasMore={hasMore}
                            loader={(
                                <Row
                                    key="0"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 8,
                                    }}
                                >
                                    <Text>Carregando...</Text>
                                </Row>
                            )}
                            endMessage={appUsers.length > 0 ? (
                                <Row
                                    key="0"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 8,
                                    }}
                                >
                                    <Text>•</Text>
                                </Row>
                            ) : undefined}
                            style={{
                                overflowX: 'hidden',
                            }}
                        >
                            <Row
                                gutter={[0, 16]}
                                style={{
                                    marginTop: 16,
                                }}
                            >
                                {
                                    appUsers.map((appUser) => (
                                        <AppUserRow
                                            key={appUser.id}
                                            appUser={appUser}
                                            filters={filters}
                                        />
                                    ))
                                }
                            </Row>
                        </InfiniteScroll>
                    )
            }
        </>
    )
}