import { useNavigate } from "react-router-dom";

import { ICategory } from "../../../interfaces";

import CategoriesService from "../../../services/http/services/CategoriesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";
import { useCallback } from "react";
import useAuth from "../../../hooks/useAuth";

export default function useNewCategory() {
    const navigate = useNavigate();
    const { activeCity } = useAuth();

    const handleSubmit = useCallback(async (category: ICategory) => {
        try {
            await CategoriesService.createCategory({
                ...category,
                city_id: activeCity?.id!,
            });

            EmitSuccessAlert('Categoria cadastrada com sucesso!');

            navigate('/categorias');
        } catch (err) {
            EmitError(err, true, 'newCategory_asdpasmdas');
        }
    }, [activeCity, navigate]);

    return {
        handleSubmit
    }
}