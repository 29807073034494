import GiveawayForm from '../components/GiveawayForm';
import Loading from '../../../components/Loading';
import useEditGiveaway from './useEditGiveaway';

export default function EditGiveaway() {
    const {
        isLoading,
        giveaway,
        handleSubmit,
    } = useEditGiveaway();

    return (
        <Loading loading={isLoading} >
            <GiveawayForm
                action='Editar'
                giveaway={giveaway}
                key={giveaway?.id}
                onSubmit={handleSubmit}
            />
        </Loading>
    )
}
