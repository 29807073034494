import * as yup from "yup";

import { formatCurrencyForPersistence } from "../../../../utils/Conversores";
import { EModule } from "../../../../enums";
import { ICityUpcomingConfigAndTerms } from "../../../../interfaces";

const COMMON_SCHEMA = {
    name: yup.string().required("Por favor, informe o nome do Orgão!"),
    // eslint-disable-next-line
    cnpj: yup.string().matches(/^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/, { message: "CNPJ inválido!" }).min(18, "CNPJ inválido!").max(18, "CNPJ inválido!").required("Por favor, informe o CNPJ do Município!"),
    city: yup.string().required("Por favor, informe o Município!"),
    uf: yup.string().required("Por favor, informe o Estado!"),
    points_config_description: yup.string(),
    tickets_config_description: yup.string(),
    logo_dark: yup.object().shape({
        uid: yup.string().required(),
        name: yup.string().required(),
    }).notRequired().default(undefined),
    logo_light: yup.object().shape({
        uid: yup.string().required(),
        name: yup.string().required(),
    }).notRequired().default(undefined),
    terms_of_use: yup.object().shape({
        uid: yup.string().required(),
        name: yup.string().required(),
    }).notRequired().default(undefined),
    created_at: yup.string(),
};

interface IWrapperSchemaProps {
    points: {
        nfc_divider: number | undefined,
        nfs_divider: number | undefined,
        taxe_divider: number | undefined,
        nfs_max_amount: number | undefined,
        nfs_max_month_quantity_by_cnpj: number | undefined,
        nfc_max_amount: number | undefined,
        nfc_max_month_quantity_by_cnpj: number | undefined,
        taxe_max_amount: number | undefined,
    },
    tickets: {
        nfc_divider: number | undefined,
        nfs_divider: number | undefined,
        taxe_divider: number | undefined,
        nfs_max_amount: number | undefined,
        nfs_max_month_quantity_by_cnpj: number | undefined,
        nfc_max_amount: number | undefined,
        nfc_max_month_quantity_by_cnpj: number | undefined,
        taxe_max_amount: number | undefined,
    },
    type: "CREATE" | "EDIT",
    cityModules: EModule[],
    upcomingConfig: ICityUpcomingConfigAndTerms | undefined,
}

export const wrapperSchema = ({
    type,
    points,
    tickets,
    cityModules,
    upcomingConfig,
}: IWrapperSchemaProps) =>
    yup.object().shape({
        ...COMMON_SCHEMA,
        nfc_points_divider: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (!!upcomingConfig?.points_config?.find((cfg) => cfg.nfc_divider) && !points.nfc_divider) {
                return schema;
            }

            return [EModule.INVOICE_NFC, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor do divisor dos pontos da NFC!") : schema;
        }),
        nfc_tickets_divider: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.nfc_divider) && !tickets.nfc_divider) {
                return schema;
            }

            return [EModule.INVOICE_NFC].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor do divisor dos bilhetes da NFC!") : schema;
        }),
        nfs_points_divider: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (!!upcomingConfig?.points_config?.find((cfg) => cfg.nfs_divider) && !points.nfs_divider) {
                return schema;
            }

            return [EModule.INVOICE_NFS, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor do divisor dos pontos da NFS!") : schema;
        }),
        nfs_tickets_divider: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.nfs_divider) && !tickets.nfs_divider) {
                return schema;
            }

            return [EModule.INVOICE_NFS].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor do divisor dos bilhetes da NFS!") : schema;
        }),
        taxe_points_divider: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.points_config?.find((cfg) => cfg.taxe_divider) && !points.taxe_divider) {
                return schema;
            }

            return [EModule.TAXES, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor do divisor dos pontos do Imposto!") : schema;
        }),
        taxe_tickets_divider: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.taxe_divider) && !tickets.taxe_divider) {
                return schema;
            }

            return [EModule.TAXES].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor do divisor dos bilhetes do Imposto!") : schema;
        }),
        nfs_max_amount_tickets: yup.string().notOneOf(['0,00'], "Valor inválido!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.nfs_max_amount) && !tickets.nfs_max_amount) {
                return schema;
            }

            return [EModule.INVOICE_NFS].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor máximo para geração de bilhetes a partir de uma NFS!") : schema;
        }),
        nfs_max_month_quantity_by_cnpj_tickets: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.nfs_max_month_quantity_by_cnpj) && !tickets.nfs_max_month_quantity_by_cnpj) {
                return schema;
            }

            return [EModule.INVOICE_NFS].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o número máximo mensal de notas em um mesmo estabelecimento para geração de bilhetes a partir de uma NFS!") : schema;
        }),
        nfc_max_amount_tickets: yup.string().notOneOf(['0,00'], "Valor inválido!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.nfc_max_amount) && !tickets.nfc_max_amount) {
                return schema;
            }

            return [EModule.INVOICE_NFC].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor máximo para geração de bilhetes a partir de uma NFC!") : schema;
        }),
        nfc_max_month_quantity_by_cnpj_tickets: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.nfc_max_month_quantity_by_cnpj) && !tickets.nfc_max_month_quantity_by_cnpj) {
                return schema;
            }

            return [EModule.INVOICE_NFC].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o número máximo mensal de notas em um mesmo estabelecimento para geração de bilhetes a partir de uma NFC!") : schema;
        }),
        taxe_max_amount_tickets: yup.string().notOneOf(['0,00'], "Valor inválido!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.tickets_config?.find((cfg) => cfg.taxe_max_amount) && !tickets.taxe_max_amount) {
                return schema;
            }

            return [EModule.TAXES].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor máximo para geração de bilhetes a partir de um Imposto!") : schema;
        }),
        nfs_max_amount_points: yup.string().notOneOf(['0,00'], "Valor inválido!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.points_config?.find((cfg) => cfg.nfs_max_amount) && !points.nfs_max_amount) {
                return schema;
            }

            return [EModule.INVOICE_NFS, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor máximo para geração de pontos a partir de uma NFS!") : schema;
        }),
        nfs_max_month_quantity_by_cnpj_points: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.points_config?.find((cfg) => cfg.nfs_max_month_quantity_by_cnpj) && !points.nfs_max_month_quantity_by_cnpj) {
                return schema;
            }

            return [EModule.INVOICE_NFS, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o número máximo mensal de notas em um mesmo estabelecimento para geração de pontos a partir de uma NFS!") : schema;
        }),
        nfc_max_amount_points: yup.string().notOneOf(['0,00'], "Valor inválido!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.points_config?.find((cfg) => cfg.nfc_max_amount) && !points.nfc_max_amount) {
                return schema;
            }

            return [EModule.INVOICE_NFC, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor máximo para geração de pontos a partir de uma NFC!") : schema;
        }),
        nfc_max_month_quantity_by_cnpj_points: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.points_config?.find((cfg) => cfg.nfc_max_month_quantity_by_cnpj) && !points.nfc_max_month_quantity_by_cnpj) {
                return schema;
            }

            return [EModule.INVOICE_NFC, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o número máximo mensal de notas em um mesmo estabelecimento para geração de pontos a partir de uma NFC!") : schema;
        }),
        taxe_max_amount_points: yup.string().notOneOf(['0,00'], "Valor inválido!").when(['modules'], ([modules], schema: any) => {
            if (upcomingConfig?.points_config?.find((cfg) => cfg.taxe_max_amount) && !points.taxe_max_amount) {
                return schema;
            }

            return [EModule.TAXES, EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o valor máximo para geração de pontos a partir de um Imposto!") : schema;
        }),
        time_expire_points: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            return [EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o tempo de validade dos pontos!") : schema;
        }),
        time_expire_vouchers: yup.number().positive("Número precisa ser positivo!").when(['modules'], ([modules], schema: any) => {
            return [EModule.REWARD].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o tempo de validade dos Vouchers!") : schema;
        }),
        points_config_effective_date: yup.string().when([
            'nfc_points_divider', 'nfs_points_divider', 'taxe_points_divider', 'nfs_max_amount_points', 'nfs_max_month_quantity_by_cnpj_points', 'nfc_max_amount_points', 'nfc_max_month_quantity_by_cnpj_points', 'taxe_max_amount_points', 'modules',
        ], ([
            nfc, nfs, taxe, nfs_max_amount, nfs_max_month_quantity, nfc_max_amount, nfc_max_month_quantity, taxe_max_amount, modules,
        ], schema: any) => {
            const isCreate = type === "CREATE";

            const currencyValues = {
                nfs_max_amount: nfs_max_amount ? formatCurrencyForPersistence(nfs_max_amount || '0') : undefined,
                nfc_max_amount: nfc_max_amount ? formatCurrencyForPersistence(nfc_max_amount || '0') : undefined,
                taxe_max_amount: taxe_max_amount ? formatCurrencyForPersistence(taxe_max_amount || '0') :  undefined,
            }

            const hasInvoiceNFCModule = !!modules.find((module: EModule) => module === EModule.INVOICE_NFC);
            const hasInvoiceNFSModule = !!modules.find((module: EModule) => module === EModule.INVOICE_NFS);
            const hasTaxesModule = !!modules.find((module: EModule) => module === EModule.TAXES);

            const haveChanged =
                !isCreate &&
                (
                    (points.nfc_divider !== nfc && hasInvoiceNFCModule) ||
                    (points.nfs_divider !== nfs && hasInvoiceNFSModule) ||
                    (points.taxe_divider !== taxe && hasTaxesModule) ||
                    (points.nfs_max_amount !== currencyValues.nfs_max_amount && hasInvoiceNFSModule) ||
                    (points.nfs_max_month_quantity_by_cnpj !== nfs_max_month_quantity && hasInvoiceNFSModule) ||
                    (points.nfc_max_amount !== currencyValues.nfc_max_amount && hasInvoiceNFCModule) ||
                    (points.nfc_max_month_quantity_by_cnpj !== nfc_max_month_quantity && hasInvoiceNFCModule) ||
                    (points.taxe_max_amount !== currencyValues.taxe_max_amount && hasTaxesModule)
                );

            return haveChanged ? schema.required("Por favor, informe a data de vigência dos novos divisores dos pontos!") : schema;
        }),
        tickets_config_effective_date: yup.string().when([
            'nfc_tickets_divider', 'nfs_tickets_divider', 'taxe_tickets_divider', 'nfs_max_amount_tickets', 'nfs_max_month_quantity_by_cnpj_tickets', 'nfc_max_amount_tickets', 'nfc_max_month_quantity_by_cnpj_tickets', 'taxe_max_amount_tickets', 'modules',
        ], ([
            nfc, nfs, taxe, nfs_max_amount, nfs_max_month_quantity, nfc_max_amount, nfc_max_month_quantity, taxe_max_amount, modules,
        ], schema: any) => {
            const isCreate = type === "CREATE";

            const currencyValues = {
                nfs_max_amount: nfs_max_amount ? formatCurrencyForPersistence(nfs_max_amount || '0') : undefined,
                nfc_max_amount: nfc_max_amount ? formatCurrencyForPersistence(nfc_max_amount || '0') : undefined,
                taxe_max_amount: taxe_max_amount ? formatCurrencyForPersistence(taxe_max_amount || '0') : undefined,
            }

            const hasInvoiceNFCModule = !!modules.find((module: EModule) => module === EModule.INVOICE_NFC);
            const hasInvoiceNFSModule = !!modules.find((module: EModule) => module === EModule.INVOICE_NFS);
            const hasTaxesModule = !!modules.find((module: EModule) => module === EModule.TAXES);

            const haveChanged =
                !isCreate &&
                (
                    (tickets.nfc_divider !== nfc && hasInvoiceNFCModule) ||
                    (tickets.nfs_divider !== nfs && hasInvoiceNFSModule) ||
                    (tickets.taxe_divider !== taxe && hasTaxesModule) ||
                    (tickets.nfs_max_amount !== currencyValues.nfs_max_amount && hasInvoiceNFSModule) ||
                    (tickets.nfs_max_month_quantity_by_cnpj !== nfs_max_month_quantity && hasInvoiceNFSModule) ||
                    (tickets.nfc_max_amount !== currencyValues.nfc_max_amount && hasInvoiceNFCModule) ||
                    (tickets.nfc_max_month_quantity_by_cnpj !== nfc_max_month_quantity && hasInvoiceNFCModule) ||
                    (tickets.taxe_max_amount !== currencyValues.taxe_max_amount && hasTaxesModule)
                );

            return haveChanged ? schema.required("Por favor, informe a data de vigência dos novos divisores dos bilhetes!") : schema;
        }),
        terms_of_use_effective_date: yup.string().when(['terms_of_use'], ([terms], schema: any) => {
            const isCreate = type === "CREATE";
            const hasNew =  !isCreate && Boolean(terms);

            return hasNew ? schema.required("Por favor, informe a data de vigência dos novos Termos de Uso!") : schema;
        }),
        ...(type === "CREATE" ? {
            modules: yup.array().of(yup.mixed<EModule>().required()).test('atLestOne', 'Por favor, selecione ao menos um módulo de arrecadação.', (modules) => modules?.some((module) => [EModule.INVOICE_NFC, EModule.INVOICE_NFS, EModule.TAXES].includes(module))),
            system_id: yup.number().when(['modules'], ([modules], schema: any) => {
                return [EModule.INVOICE_NFS].every((module) => modules.includes(module)) ? schema.required("Por favor, informe o sistema de Nota Fiscal de Serviço") : schema.nullable();
            }),
        } : {
            modules: yup.array().of(yup.mixed<EModule>().required()),
            system_id: yup.number().when(['modules'], ([modules], schema: any) => {
                return [EModule.INVOICE_NFS].every((module) => modules.includes(module)) && !cityModules.includes(EModule.INVOICE_NFS) ? schema.required("Por favor, informe o sistema de Nota Fiscal de Serviço") : schema.nullable();
            }),
        }),
    });