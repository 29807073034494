import privateHttpClient from '../privateHttpClient';
import CategoryMapper from '../mappers/CategoryMapper';
import { urlHttpGov } from '../../urls';
import { ICategory } from '../../../interfaces';

class CategoriesService {
    private baseURL: string = `${urlHttpGov}/panel/category`;

    async listCategories(cityId: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/county/${cityId}`, { signal });

        return data.map((category: any) => CategoryMapper.toDomain(category));
    }

    createCategory(category: ICategory) {
        const persistenceCategory = CategoryMapper.toPersistence(category);

        return privateHttpClient.post(this.baseURL, persistenceCategory);
    }

    async getCategoryById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return CategoryMapper.toDomain(data);
    }

    async updateCategory(id: number, category: ICategory) {
        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, category);

        return CategoryMapper.toDomain(data);
    }

    deleteCategory(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CategoriesService();
