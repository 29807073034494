import { ITicket } from '../../../interfaces';

class TicketMapper {
    toPersistence(domainTicket: ITicket): any {
        return {
            id: domainTicket.id,
            app_user_id: domainTicket.app_user_id,
            raffle_id: domainTicket.giveaway_id,
            app_user: domainTicket.app_user,
            raffle: domainTicket.giveaway,
        }
    }

    toDomain(persistenceTicket: any): ITicket {
        return {
            id: persistenceTicket.id,
            app_user_id: persistenceTicket.app_user_id,
            giveaway_id: persistenceTicket.raffle_id,
            winner: persistenceTicket.winner,
            app_user: persistenceTicket.app_user,
            giveaway: persistenceTicket.raffle,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TicketMapper();
