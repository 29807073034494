import { useCallback, useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ThemeContext } from './../../contexts/ThemeContext';
import { EmitError } from './../../utils/EmitError';
import { VerificaRota } from '../../services/VerificaSePodeAcessar';
import './style.css';
import Loading from '../../components/Loading';
import useLocalStorage from '../../hooks/useLocalStorage';
import useAuth from '../../hooks/useAuth';
import toast, { clearToasts } from '../../utils/toast';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import PanelUsersService from '../../services/http/services/PanelUsersService';
import { EmitSuccessAlert } from '../../utils/EmitSuccessAlert';

interface IInputs {
    password: string,
    confirmPassword: string,
}

export default function FirstAccess() {
    const navigate = useNavigate();

    const {
        getUser,
    } = useLocalStorage();

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
    } = useForm<IInputs>({
        defaultValues: {
            password: '',
            confirmPassword: '',
        }
    })

    const { signOut } = useAuth();

    const { theme } = useContext(ThemeContext);

    const [isSubmiting, setIsSubmiting] = useState(false);

    useEffect(() => {
        let arrayUrl = window.location.pathname.split("/");
        if (VerificaRota(arrayUrl, navigate)) {

            if (!getUser()?.first_access) {
                navigate('/dashboard');
                return;
            }

            toast({
                type: 'info',
                text: 'Bem-vindo!',
                description: 'Por favor, redefina a sua senha!',
                duration: 1000 * 60 * 10,
                key: 'primeiroAcesso_aiosdashdhloas'
            })
        }
    }, [navigate, getUser])

    const onSubmit: SubmitHandler<IInputs> = useCallback(async (data): Promise<void> => {
        if (data.password !== data.confirmPassword) {
            setError('confirmPassword', { message: 'Senhas não coincidem' });
            return;
        }

        try {
            setIsSubmiting(true);

            const userId = getUser()?.id;

            if (!userId) {
                EmitError('Error');
                return;
            }

            const panelUser = await PanelUsersService.updatePanelUserPasswordOnFirstAccess(data.password);

            localStorage.setItem('@NotaMunicipio:user', JSON.stringify(
                { user: panelUser }
            ));

            clearToasts();
            reset();
            setIsSubmiting(false);

            let username = panelUser.name.split(" ")[0];

            EmitSuccessAlert('Senha atualizada com sucesso!', `Bem-vindo, ${username}!`);
            navigate('/dashboard');
        } catch (err) {
            setIsSubmiting(false);

            let msgErro: any = (err as Error);

            if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                EmitError('Sessão expirada');
                navigate('/');
            } else {
                EmitError(err, true, 'primeiroacesso_dkwdfaiweyi');
            }
        }
    }, [getUser, navigate, reset, setError]);

    return (
        <Row
            gutter={24}
            style={{
                maxHeight: "100vh",
                margin: 0
            }}
            align="middle"
        >
            <Col
                className='fotoFundoPrimeiroAcesso'
                flex={3}
            >
            </Col>

            <Col
                flex={1}
                style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: '563px',
                    backgroundColor: theme === 'light' ? undefined : '#141414',
                }}
            >
                <Loading loading={isSubmiting} >
                    <Form
                        name="formPrimeiroAcesso"
                        style={{
                            width: '100%'
                        }}
                        layout="vertical"
                        onFinish={handleSubmit(onSubmit)}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {/* <img style={{
                                width: '290px',
                                // margin: '20px'
                            }} src={ImagemLogo}></img> */}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 30
                        }}>
                            <Form.Item
                                style={{
                                    marginBottom: 15,
                                    maxWidth: '100%',
                                }}
                                label="Nova senha"
                                required
                                validateStatus={errors.password ? 'error' : 'success'}
                                help={errors.password?.message}
                            >
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Por favor, digite sua nova senha!'
                                        },
                                        minLength: {
                                            value: 8,
                                            message: 'A senha precisa ter no mínimo 8 caracteres!'
                                        }
                                    }}
                                    render={({ field }) => <Input.Password {...field} autoComplete="off" placeholder="Nova senha" />}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    marginBottom: 15
                                }}
                                label="Confirme a nova senha"
                                required
                                validateStatus={errors.confirmPassword ? 'error' : 'success'}
                                help={errors.confirmPassword?.message}
                            >
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Por favor, confirme sua nova senha!'
                                        }
                                    }}
                                    render={({ field }) => <Input.Password {...field} autoComplete="off" placeholder="Confirme a nova senha" />}
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType='submit'
                            >
                                Continuar
                            </Button>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Button
                                    type="link"
                                    style={{ marginTop: 10 }}
                                    htmlType='button'
                                    onClick={() => {
                                        signOut();
                                        navigate('/')
                                    }}
                                >
                                    {`Sair`}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Loading>
            </Col>
        </Row>
    );
};