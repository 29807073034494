import AwardTypeForm from '../components/AwardTypeForm';
import Loading from '../../../components/Loading';
import useEditAwardType from './useEditAwardType';

export default function EditAwardType() {
    const {
        isLoading,
        handleSubmit,
        awardType
    } = useEditAwardType();

    return (
        <Loading loading={isLoading} >
            <AwardTypeForm action='Editar' awardType={awardType} key={awardType?.id} onSubmit={handleSubmit} />
        </Loading>
    )
}
