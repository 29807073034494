import { Divider } from "antd";

export default function TermsOfUse() {
    return (
        <div
            style={{
                padding: '32px 48px',
                color: '#212121',
                background: '#FFFFFF',
                minHeight: '100vh',
            }}
        >
            <h1
                style={{
                    fontWeight: 'bolder',
                }}
            >
                Nota Município - Termos de Uso
            </h1>

            <section>
                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        Este termo de uso ("Termo de Uso") regulamenta o programa de incentivo ao consumidor exigir o documento fiscal no momento de uma compra e, assim, poder escanear o QR Code ou digitar os dados do documento fiscal manualmente, para participar de sorteios de prêmios e também receber pontos para efetuar a troca por produtos ou benefícios em estabelecimentos parceiros. ("Nota Município"), disponibilizado pela GOVFÁCIL GESTÃO & TECNOLOGIA LTDA, empresa com sede na Cidade de Umuarama e Estado do Paraná, na Rua Adrian Gabriel de Oliveira, 3958 - Parque Interlagos - 87511-005 - Umuarama - PR, inscrita no CNPJ/MF sob o nº 41.886.613/0001-55, doravante denominada simplesmente "GOVFÁCIL", aos seus usuários da internet, através de aplicativo Android e iOS (doravante "Aplicativo"). No ato de acesso ao Aplicativo, o USUÁRIO (doravante "USUÁRIO" ou "Você") se obriga a aceitar e cumprir, plenamente e sem reservas, todos os termos e condições deste Termo de Uso.
                    </p>

                    <p>
                        SE VOCÊ NÃO CONCORDA COM ESTE TERMO DE USO, NÃO CONECTE, NÃO ACESSE, NÃO REALIZE O DOWNLOAD E NÃO UTILIZE O APLICATIVO DE QUALQUER MANEIRA.
                    </p>

                    <p>
                        Ao aceitar os Termos, você atesta que toda e qualquer informação que você nos providenciar por meio do Aplicativo é verdadeira e correta. O fornecimento de qualquer informação falsa ou fraudulenta é estritamente proibido e passível de responsabilização penal.
                    </p>
                </div>
            </section>

            <section>
                <h2
                    id="destinacao-do-aplicativo"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    1 - DESTINAÇÃO DO APLICATIVO
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        1.1 O Aplicativo destina-se a incentivar os adquirentes de mercadorias, bens e serviços de transporte interestadual a exigir do fornecedor a entrega de documento fiscal hábil.
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="cadastro"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    2 - CADASTRO
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        2.1. Para o cadastramento, Você precisa ter pelo menos 18 (dezoito) anos completos e ser plenamente capaz e deve fazer uma declaração nesse sentido. Se você for menor de 18 (dezoito) anos ou necessitar de representação na forma da lei, seus pais ou responsáveis deverão lhe representar ou assistir. Neste caso, eles deverão preencher o seu cadastro e se responsabilizarão integralmente por Você e por seus atos.
                    </p>

                    <p>
                        2.2. Você é responsável por seu login, sendo feito através do envio de um código de acesso, através de SMS ou WhatsApp, ao número de celular cadastrado. Em caso de perda ou roubo, e impossibilidade de recuperação do número do celular, pelo próprio Aplicativo você conseguirá iniciar o processo de recuperação da conta no sistema. Em caso de dúvidas, procure nossos canais de atendimento do Nota Município em <a href="https://www.notamunicipio.com.br/fale-conosco" target="_blank" rel="noreferrer">https://www.notamunicipio.com.br/fale-conosco</a>.
                    </p>

                    <p>
                        2.3. A GOVFÁCIL manterá os Dados Pessoais informados no cadastramento do USUÁRIO pois são necessários para o funcionamento do sistema, com a finalidade de controle dos acessos e possibilitar o uso das funções <strong>Minhas Notas</strong>, <strong>Meu Perfil</strong>, <strong>Minhas Reclamações</strong>, <strong>Meus Bilhetes</strong> e <strong>Meus Pontos</strong>. Ao fazer login no sistema Nota Município, Você aceita e concorda com o os termos de uso especificados nesse documento.
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="condicoes-de-uso"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    3 - CONDIÇÕES DE USO DO APLICATIVO
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        3.1. Ao utilizar o Aplicativo, você se compromete a observar o Termo de Uso, e além disso se compromete a NÃO utilizar comercialmente os dados obtidos pelo Aplicativo.
                    </p>

                    <p>
                        3.2. Você não deverá utilizar o Aplicativo para a prática de atos proibidos pela lei e pelo presente Termo de Uso, ou atos que possam danificar, inutilizar, sobrecarregar ou deteriorar o Aplicativo, os equipamentos informáticos de outros usuários ou de outros internautas (hardware e software), assim como os documentos, arquivos e toda classe de conteúdo armazenados nos seus equipamentos informáticos (cracking, scanning, bots) ou impedir a normal utilização da referida ferramenta, equipamentos informáticos e conteúdos por parte dos demais usuários e de outros internautas.
                    </p>

                    <p>
                        3.2.1. Você se compromete a não utilizar qualquer sistema automatizado, inclusive, mas sem se limitar a "robôs", "spiders" ou "agentes automatizados", que acessem o Aplicativo de maneira a efetuar diversas solicitações aos servidores da GOVFÁCIL em um dado período de tempo, que seja considerado humanamente impossível de ser realizado no mesmo período por meio de um navegador convencional ou de um aplicativo.
                    </p>
                    <p>
                        3.2.2. A qualquer momento a GOVFÁCIL poderá, por meios de mecanismos automatizados ou verificações em sua base, validar se o Usuário está utilizando o Aplicativo irregularmente e poderá bloquear, parcial ou definitivamente, a utilização do aplicativo pelo Usuário, seja impedindo o acesso por IP, ASN, CPF, conta do Google ou Facebook.
                    </p>
                    <p>
                        3.3. Caso Você utilize o Aplicativo contrariando as proibições da cláusula 3.3 acima ou contrariando qualquer outra disposição deste Termo de Uso, a GOVFÁCIL poderá, a seu exclusivo critério, bloquear seu IP de acesso, bloqueá-lo como Usuário e/ou bloquear o cadastro do Usuário, a qualquer tempo e sem qualquer aviso prévio.
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="exclusao-de-garantias-e-de-responsabilidade"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    4 - EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        4.1. A GOVFÁCIL não tem obrigação de controlar a utilização do Aplicativo pelos Usuários. A GOVFÁCIL também não tem a obrigação de verificar a identidade dos Usuários, nem a veracidade, vigência, e autenticidade dos dados que os Usuários informam sobre si mesmos à GOVFÁCIL, a outros usuários ou a terceiros.
                    </p>

                    <p>
                        4.2. A GOVFÁCIL NÃO SE RESPONSABILIZA PELOS DANOS E PREJUÍZOS DIRETOS OU INDIRETOS QUE POSSAM SER CAUSADOS PELO USUÁRIO EM VIRTUDE DA UTILIZAÇÃO DO APLICATIVO, OU DO CONTEÚDO DISPONIBILIZADO.
                    </p>

                    <p>
                        4.3. Você, Usuário, é o único responsável pelo modo de utilização do Aplicativo, de modo que responderá integralmente por qualquer ato que cause danos a direitos de terceiros, que viole o presente Termo de Uso, leis ou regulamentos locais, nacionais ou internacionais aplicáveis.
                    </p>

                    <p>
                        4.4. A GOVFÁCIL não garante, de forma expressa ou implícita, o desempenho e a adequação do Aplicativo para qualquer finalidade específica desejada por Você.
                    </p>

                    <p>
                        4.5. A GOVFÁCIL não é responsável pela prestação de quaisquer serviços ou bens, vendidos ou anunciados através do Aplicativo.
                    </p>
                </div>
            </section>

            <Divider style={{ background: '#F0F0F0', height: 2 }} />

            <section>
                <h2
                    id="licenca-de-uso"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    5 - DA LICENÇA DE USO DO APLICATIVO
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        5.1. A GOVFÁCIL autoriza o uso dos aplicativos de informática que compõem o Aplicativo Nota Município, desde que tal fato ocorra estritamente em conformidade com o estabelecido neste Termo de Uso. A GOVFÁCIL não concede nenhuma outra licença ou autorização de uso sobre os seus direitos de propriedade industrial e intelectual.
                    </p>

                    <p>
                        5.2. <strong>Você não deverá, seja por você ou por alguém sob seu consentimento:</strong>

                        <ol
                            type="i"
                        >
                            <li>
                                copiar, modificar, adaptar, traduzir, descompilar ou desconstruir, adaptar, sublicenciar o aplicativo (ou qualquer parte dele), distribuir, expor, executar publicamente, tornar disponível para o público, emular, reduzir a uma forma possível de leitura humana, realizar de qualquer forma de comercialização, tais como: vender, alugar, emprestar, processar, compilar, proceder a reengenharia reversa, combinando com outro software, traduzir, modificar ou criar trabalhos derivados de qualquer material que esteja sujeito a proteção por direitos de propriedade titularizados pela GOVFÁCIL;
                            </li>
                            <li>
                                utilizar o aplicativo e/ou em conexão para spam, envio de mensagem não solicitada, assédio, fraude eletrônica ou conduta similar;
                            </li>
                            <li>
                                interferir em ou violar o direito de privacidade de qualquer outro usuário ou terceiros, incluindo direito de propriedade intelectual, ou coletar informações pessoais identificáveis de outros usuários do aplicativo sem o seu consentimento, incluindo a utilização de robôs, spider, sites de busca ou de recuperação de dados, ou outro dispositivo manual ou automático de recuperação, indexação ou de data-mine;
                            </li>
                            <li>
                                difamar, abusar, assediar, perseguir, ameaçar, ou de qualquer forma, violar os direitos individuais de outros;
                            </li>
                            <li>
                                transmitir ou de qualquer forma manter disponível para contato com o aplicativo qualquer vírus eletrônico, "worm", "cavalo de troia", "time bomb", "web bug", "spyware" ou qualquer outro código de computador, arquivo ou programa que possa ou esteja destinado a causar dano ou sabotar a operação de qualquer hardware, software ou equipamento de telecomunicação, ou qualquer outro código ou componente de natureza invasiva, perturbadora ou prejudicial.
                            </li>
                            <li>
                                interferir com ou prejudicar a operação do aplicativo, ou dos servidores ou da rede que hospeda o aplicativo, ou desobedecer a qualquer requisito, procedimento, política ou regulação de tais servidores ou redes;
                            </li>
                            <li>
                                vender, licenciar ou explorar para qualquer propósito comercial a utilização ou acesso ao aplicativo;
                            </li>
                            <li>
                                realizar uma moldura (frame) ou espelhamento (mirror) de qualquer parte deste aplicativo sem a autorização expressa da GOVFÁCIL;
                            </li>
                            <li>
                                criar um banco de dados por meio de download sistemáticos ou armazenamento de qualquer conteúdo do aplicativo;
                            </li>
                            <li>
                                providenciar informação pessoal falsa ou enganosa;
                            </li>
                            <li>
                                utilizar do aplicativo para qualquer propósito imoral, ilegal ou não autorizado;
                            </li>
                            <li>
                                utilizar o aplicativo para utilização não particular ou comercial.
                            </li>
                        </ol>
                    </p>


                </div>
            </section>

            <section>
                <h2
                    id="vigencia"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    6 - VIGÊNCIA
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        6.1. A GOVFÁCIL se reserva no direito de modificar, corrigir, melhorar, alterar, otimizar, fazer qualquer outra mudança, ou descontinuar, temporária ou permanentemente o Aplicativo (ou qualquer parte dele, incluindo, mas não limitada somente ao conteúdo), independentemente de comunicado prévio, a qualquer tempo e na sua inteira discricionariedade. Além disso, você agora reconhece que o conteúdo providenciado pelo Aplicativo pode ser alterado, estendido em termos de conteúdo ou forma ou ainda removido a qualquer tempo e sem prévia notificação. Você concorda que a GOVFÁCIL não está legalmente obrigada perante você ou terceiros por qualquer modificação, suspensão ou descontinuidade do Aplicativo.
                    </p>

                    <p>
                        6.2. A disponibilidade do Aplicativo e sua funcionalidade depende de vários fatores, tais como o software para conexão em rede, questões de hardware, provedores de conexão da GOVFÁCIL e rede pública de telefonia celular. A GOVFÁCIL não garante ou assegura que o aplicativo operará e/ou estará disponível em todo o tempo sem interrupção ou ruptura, ou estar imune a acesso não autorizado.
                    </p>
                </div>
            </section>

            <section>
                <h2
                    id="disposicoes-gerais"
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    7. DISPOSIÇÕES GERAIS
                </h2>

                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >
                    <p>
                        7.1. O não exercício pela GOVFÁCIL ou pelo Usuário de quaisquer direitos ou faculdades que lhes sejam conferidos por este Termo de Uso ou pela legislação, bem como a eventual tolerância contra infrações a este Termo de Uso, não importará na renúncia aos seus direitos, novação ou alteração de itens e cláusulas deste Termo de Uso, podendo a GOVFÁCIL ou Usuário exercê-los a qualquer momento.
                    </p>

                    <p>
                        7.2. O presente termo de uso será interpretado e regido de acordo com as leis e regulamentos da República Federativa do Brasil e quaisquer disputas judiciais oriundas do presente termo de uso deverão ser submetidas ao foro central da Comarca da Região de Umuarama - Estado do Paraná.
                    </p>

                    <p>
                        7.3. Caso alguma condição ou restrição, termo ou disposição do presente termo de uso seja declarada nula por decisão judicial, transitada em julgado, tal nulidade não se estenderá as demais condições, restrições, termos ou disposições aqui previstas, as quais permanecerão em pleno vigor e efeito.
                    </p>

                    <p>
                        7.4. A marcação do "check-box" ao lado do texto "Concordo com os Termos", seguida pelo clique no botão "aceitar" e/ou a utilização do Aplicativo por Você são consideradas, para todos os fins de direito, como sua aceitação de todos os termos e condições do presente Termo de Uso, que terá valor de contrato, passando o mesmo a regular a relação entre a GOVFÁCIL e Você.
                    </p>

                    <p>
                        7.5. A GOVFÁCIL poderá divulgar notificações ou mensagens através do Aplicativo ou do site do Nota Município para informar ao usuário eventuais mudanças no Aplicativo ou no Termo de Uso e/ou outras informações que considerar relevantes.
                    </p>

                    <p>
                    7.6. Se você tiver qualquer questionamento (ou comentário) concernente ao presente termo de uso, nos contate no seguinte endereço e faremos o possível para responder dentre de um prazo razoável: <a href="https://www.notamunicipio.com.br/fale-conosco" target="_blank" rel="noreferrer">https://www.notamunicipio.com.br/fale-conosco</a>.
                    </p>
                </div>
            </section>

        </div>
    )
}