import { IVoucher } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import VoucherMapper from '../mappers/VoucherMapper';
import { urlHttpGov } from '../../urls';

class VouchersService {
    private baseURL: string = `${urlHttpGov}/panel/voucher`;

    async listVouchers(cityId: number, signal: AbortSignal) {

        const { data } = await privateHttpClient.get(`${this.baseURL}/county/${cityId}`, { signal });

        return data.map((voucher: any) => VoucherMapper.toDomain(voucher));
    }

    async listVouchersByAppUserAndCity(appUserId: number, cityId: number, signal: AbortSignal): Promise<Array<IVoucher>> {

        const { data } = await privateHttpClient.get(`${this.baseURL}/app_user/${appUserId}/county/${cityId}`, { signal });

        return data.map((voucher: any) => VoucherMapper.toDomain(voucher));
    }

    async listVouchersByGiveawayIdAndAppUserId(giveawayId: number, appUserId: number, signal: AbortSignal): Promise<Array<IVoucher>> {

        const { data } = await privateHttpClient.get(`${this.baseURL}/raffle/${giveawayId}/app_user/${appUserId}`, { signal });

        return data.map((voucher: any) => VoucherMapper.toDomain(voucher));
    }

    async getVoucherById(id: number, cityId: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}/${cityId}`, { signal });

        return VoucherMapper.toDomain(data);
    }

    async getVoucherByRedemptionCode(code: string, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/redemptionCode/${code}`, { signal });

        return VoucherMapper.toDomain(data);
    }

    async confirmVoucherRedemption(id: number) {
        return privateHttpClient.patch(`${this.baseURL}/${id}`);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new VouchersService();
