import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputRef, Space } from "antd";
import Highlighter from "react-highlight-words";
import { AxiosError } from "axios";

import { SearchOutlined } from '@ant-design/icons';

import { IAward, IGiveaway } from "../../../interfaces";

import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { removeAcento } from "../../../utils/RemoveAcentos";
import { EmitError } from "../../../utils/EmitError";
import { formatCpf, formatCurrency, formatPhone } from "../../../utils/Masks";
import GiveawaysService from "../../../services/http/services/GiveawaysService";
import AwardStatusTag from "../components/AwardStatusTag";

export interface ITableData extends IAward {
    key: number | undefined;
    app_user_cpf: string;
    app_user_name: string;
    app_user_phone: string;
}

export default function useGiveawayWinners() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isConfirmValidateAwardModalVisible, setIsConfirmValidateAwardModalVisible] = useState<boolean>(false);
    const [invalidating, setInvalidating] = useState<boolean>(false);
    const [selectedAward, setSelectedAward] = useState<ITableData | undefined>();

    const searchInput = useRef<InputRef>(null);

    const { id } = useParams();
    const navigate = useNavigate();

    const canValidadeOrInvalidateAward = useMemo(() => {
        return !!selectedAward && selectedAward.status === "Em análise";
    }, [selectedAward]);

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents) : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'app_user_name',
            key: 'app_user_name',
            sorter: (a: ITableData, b: ITableData) => a.app_user_name.localeCompare(b.app_user_name),
            showSorterTooltip: false,
            ...getColumnSearchProps('app_user_name', 'Nome'),
        },
        {
            title: 'CPF',
            dataIndex: 'app_user_cpf',
            key: 'app_user_cpf',
            ...getColumnSearchProps('app_user_cpf', 'CPF', false, formatCpf),
        },
        {
            title: 'Telefone',
            dataIndex: 'app_user_phone',
            key: 'app_user_phone',
            ...getColumnSearchProps('app_user_phone', 'Telefone', false, formatPhone),
        },
        {
            title: 'Prêmio',
            dataIndex: 'description',
            key: 'description',
            sorter: (a: ITableData, b: ITableData) => a.description.localeCompare(b.description),
            showSorterTooltip: false,
            ...getColumnSearchProps('description', 'Prêmio'),
            width: '20%',
            ellipsis: true,
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            render: (record: number) => `R$ ${formatCurrency(record.toString())}`,
            sorter: (a: ITableData, b: ITableData) => Number(a.value) - Number(b.value),
            showSorterTooltip: false,
        },
        {
            title: 'Bilhete',
            dataIndex: 'winning_ticket_id',
            key: 'winning_ticket_id',
            render: (record: number) => record?.toLocaleString('pt-BR'),
            sorter: (a: ITableData, b: ITableData) => a.winning_ticket_id! - b.winning_ticket_id!,
            showSorterTooltip: false,
        },
        {
            title: 'Ordem',
            dataIndex: 'order',
            key: 'order',
            render: (record: number) => record?.toLocaleString('pt-BR'),
            sorter: (a: ITableData, b: ITableData) => a.order! - b.order!,
            defaultSortOrder: 'ascend' as keyof SortOrder,
            showSorterTooltip: false,
        },
        {
            title: 'Situação',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: (a: ITableData, b: ITableData) => a.status.localeCompare(b.status)!,
            },
            filters: [
                {
                    text: 'Em análise',
                    value: 'Em análise',
                },
                {
                    text: 'Válido',
                    value: 'Válido',
                },
                {
                    text: 'Inválido',
                    value: 'Inválido',
                },
            ],
            onFilter: (value: any, record: ITableData) => {
                return record.status === value;
            },
            render: (_: any, award: ITableData) => (
                <AwardStatusTag
                    key={award.id}
                    status={award.status}
                    observation={award.observation}
                />
            ),
            showSorterTooltip: false,
        },
    ]

    const setTableDataFromRequest = useCallback((data: IGiveaway) => {
        if (!data.awards) {
            return;
        }

        const newData = data.awards.map((award) => ({
            ...award,
            key: award.id,
            app_user_name: award?.app_user?.name!,
            app_user_cpf: award.app_user?.cpf!,
            app_user_phone: award.app_user?.phone!,
        }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        async function loadWinners() {
            try {
                setIsLoading(true);

                const giveawayData = await GiveawaysService.getGiveawayById(Number(id), controller.signal);

                setTableDataFromRequest(giveawayData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'giveawayWinners_aosdpasdpasd');
                setIsLoading(false);
            }
        }

        loadWinners();

        return () => {
            controller.abort();
        }
    }, [setTableDataFromRequest, navigate, id]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleClickViewAppUser = useCallback(() => {
        const reward = tableData.find((r) => r.id === selectedIds[0]);

        if (!reward || !reward?.app_user) {
            return;
        }

        navigate(`/usuarios-aplicativo/visualizar/${reward.app_user?.id}`);
    }, [navigate, selectedIds, tableData]);

    const handleClickGoBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const handleClickValidateAward = useCallback(async (isValid: boolean) => {
        if (!selectedAward) {
            return;
        }

        if (!isValid) {
            setInvalidating(true);
        }

        setIsConfirmValidateAwardModalVisible(true);
    }, [selectedAward]);

    const handleValidateFinished = useCallback((award: IAward) => {
        setTableData((prevState) => {
            const awardIndex = prevState.findIndex((a) => a.id === award.id);

            if (awardIndex < 0) {
                return prevState;
            }

            const newTableData = [ ...prevState ];

            newTableData[awardIndex] = {
                ...newTableData[awardIndex],
                ...award,
            }

            return newTableData;
        });

        setSelectedIds([]);
        setIsConfirmValidateAwardModalVisible(false);
        setSelectedAward(undefined);
        setInvalidating(false);
    }, []);

    const handleCancelValidateAward = useCallback(() => {
        setIsConfirmValidateAwardModalVisible(false);
        setInvalidating(false);
    }, []);

    useEffect(() => {
        if (selectedIds.length !== 1) {
            setSelectedAward(undefined);
            return;
        }

        const selectedAwardData = tableData.find((award) => award.id === selectedIds[0]);

        setSelectedAward(selectedAwardData);
    }, [selectedIds, tableData]);

    return {
        isLoading,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        handleClickViewAppUser,
        handleClickGoBack,
        handleClickValidateAward,
        isConfirmValidateAwardModalVisible,
        invalidating,
        handleValidateFinished,
        handleCancelValidateAward,
        canValidadeOrInvalidateAward,
        selectedAward,
    }
}