import { useCallback, useEffect, useRef, useState } from "react";
import { IGiveaway } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import { DateFns } from "../../../../utils/dateFns";

interface IProps {
    giveaway?: IGiveaway,
    createdGiveaway?: IGiveaway,
}

export default function useGiveawayForm({ giveaway, createdGiveaway }: IProps) {
    const [currentStep, setCurrentStep] = useState(0);
    const [awardsListLength, setAwardsListLength] = useState<number | undefined>();

    const navigate = useNavigate();
    const refIsEditing = useRef<boolean>(Boolean(giveaway));

    const giveawayExistsAndIsInvalid = !!giveaway && !giveaway.is_valid;
    const isInvalid = awardsListLength === undefined ? giveawayExistsAndIsInvalid : awardsListLength === 0 ? true : false;

    const canEdit = Boolean(giveaway?.date && !DateFns.dateIsAfter(DateFns.dateStringToDateTime(new Date().toISOString()), DateFns.dateStringToDateTime(new Date(giveaway.date).toISOString())));

    const handleClickGiveawayStep = useCallback(() => {
        setCurrentStep(0);
    }, []);

    const handleClickAwardsStep = useCallback(() => {
        setCurrentStep(1);
    }, []);

    const handleClickGiveawayStepWhenCreating = useCallback((createdGiveawayId: number) => {
        if (!createdGiveawayId) {
            return;
        }

        navigate(`/sorteios/editar/${createdGiveawayId}`)
    }, [navigate]);

    const handleSubmitGiveawayDataStepFinished = useCallback(() => {
        if (!giveaway && !createdGiveaway) {
            return;
        }

        setCurrentStep(1);
    }, [giveaway, createdGiveaway]);

    const handleAwardsListLengthChange = useCallback((listLength: number) => {
        setAwardsListLength(listLength);
    }, []);

    useEffect(() => {
        if (!createdGiveaway) {
            return;
        }

        setCurrentStep(1);
    }, [createdGiveaway]);

    return {
        currentStep,
        handleClickGiveawayStep,
        handleClickGiveawayStepWhenCreating,
        handleClickAwardsStep,
        handleSubmitGiveawayDataStepFinished,
        handleAwardsListLengthChange,
        isInvalid,
        refIsEditing,
        canEdit,
    }
}