import { urlHttpGov } from "../../urls";
import privateHttpClient from "../privateHttpClient";


class InvoicesService {
    private baseURL = `${urlHttpGov}/panel/invoices`;

    async listMonthsData(userId: number, cityId: number, signal: any) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/group/${userId}/${cityId}`, { signal });

        return data;
    }

    async listInvoicesByMonth(userId: number, cityId: number, month: number, year: number, signal: any) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/details/${userId}/${cityId}?month=${month}&year=${year}`, { signal });

        return data;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvoicesService();