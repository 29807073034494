import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IRole } from "../../../interfaces";

import RolesService from "../../../services/http/services/RolesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useEditRole() {
    const { id } = useParams();
    const [role, setRole] = useState<IRole>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadRole() {
            try {
                setIsLoading(true);
                const roleData = await RolesService.getRoleById(Number(id), controller.signal);

                setRole(roleData);
                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {

                    return;
                }

                setIsLoading(false);

                EmitError(err, true, 'editRole_sdopasdasd');

                navigate('/perfis');
            }
        }

        loadRole();

        return () => {
            controller.abort();
        }
    }, [id, navigate]);

    async function handleSubmit(roleData: IRole) {
        try {
            await RolesService.updateRole(Number(id), roleData);

            EmitSuccessAlert('Perfil atualizado com sucesso!');

            navigate('/perfis');
        } catch (err) {
            EmitError(err, true, 'editRole_aspaiopsdhjaq');
        }
    }

    return {
        isLoading,
        handleSubmit,
        role,
    }
}