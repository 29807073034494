import { Tag, Tooltip } from "antd";

import { EInvoiceStatus } from "../../../../enums";

interface IProps {
    status: EInvoiceStatus;
    message?: string;
}

export default function StatusTag({ status, message }: IProps) {
    const type = status === "Concluído" ? "success" 
                    : status === "Erro" ? "error"
                        : status === "Alerta" ? "warning" 
                            : "default";

    const tooltipColor = type === "warning" ? "orange" : "red";
    const statusText = status === "Erro" ? "Com erro" : status;
    const tooltipText = status !== "Concluído" && status !== "Em processamento" ? message : '';

    return (
        <Tooltip
            title={tooltipText}
            color={tooltipColor}
        >
            <Tag
                color={type}
            >
                {statusText}
            </Tag>
        </Tooltip>
    )
}