import { useCallback, useState } from "react";
import { AxiosError } from "axios";

import { IGiveaway } from "../../../../../interfaces";

import GiveawaysService from "../../../../../services/http/services/GiveawaysService";
import { EmitError } from "../../../../../utils/EmitError";
import { EmitSuccessAlert } from "../../../../../utils/EmitSuccessAlert";

interface IProps {
    giveaway: IGiveaway | undefined;
    onTicketsFileGenerated: (hash: string, file: string) => void;
}

export default function useExportData({ giveaway, onTicketsFileGenerated }: IProps) {
    const [isExportingTickets, setIsExportingTickets] = useState<boolean>(false);
    const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
    const [hash, setHash] = useState<string | undefined | null>(giveaway?.hash_with_participants);
    const [file, setFile] = useState<string | undefined | null>(giveaway?.file_with_participants);

    const handleClickExportTickets = useCallback(async () => {
        try {
            if (!giveaway) {
                return;
            }

            setIsExportingTickets(true);

            const { hash, fileName } = await GiveawaysService.exportGiveawayTickets(giveaway.id);

            setHash(hash);
            setFile(fileName);
            onTicketsFileGenerated(hash, fileName);
            EmitSuccessAlert('Arquivo de bilhetes gerado com sucesso!', 'Clique em fazer download para baixar o arquivo.');
        } catch (err) {
            EmitError(err);
        } finally {
            setIsExportingTickets(false);
        }
    }, [giveaway, onTicketsFileGenerated]);

    const handleClickDownloadFile = useCallback(async () => {
        if (!file) {
            return;
        }

        const controller = new AbortController();

        try {
            setIsDownloadingFile(true);
            const responseFile = await GiveawaysService.downloadGiveawayTicketsFile(file, controller.signal);

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(responseFile);
            link.download = file;
            link.click();
            window.URL.revokeObjectURL(link.href);
            setIsDownloadingFile(false);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }

            setIsDownloadingFile(false);
            EmitError(err, true, 'holdGiveawayExportData_ajksdkasdjasjdasd')
        }

        return () => {
            controller.abort();
        }
    }, [file]);

    return {
        isExportingTickets,
        isDownloadingFile,
        hash,
        file,
        handleClickExportTickets,
        handleClickDownloadFile,
    }
}