import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { AxiosError } from "axios";
import { Buffer } from "buffer";

import { IAppUser, IMessage } from "../../../../interfaces";
import { IChangeFileProps, IMessageInputs } from "../../interfaces";

import useAuth from "../../../../hooks/useAuth";
import AppUsersService from "../../../../services/http/services/AppUsersService";
import { EmitError } from "../../../../utils/EmitError";
import { getBase64Image } from "../../../../utils/getBase64Image";
import MessageMapper from "../../../../services/http/mappers/MessageMapper";
import privateHttpClient from "../../../../services/http/privateHttpClient";
import { messageImageUrl } from "../../../../services/urls";
import useLocalStorage from "../../../../hooks/useLocalStorage";

const ACCEPTED_IMAGE_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
]

export default function useMessageForm(
    onSubmit: (message: FormData) => Promise<void>,
    message: IMessage | undefined,
) {
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [isLoadingAppUsers, setIsLoadingAppUsers] = useState<boolean>(false);
    const [appUsers, setAppUsers] = useState<Array<IAppUser>>([]);
    const [defaultEditorState,] =
        useState<any>(undefined);
    const [editorState, setEditorState] = useState<any>(
        EditorState.createEmpty()
    );
    const [isDirectMessage, setIsDirectMessage] = useState<boolean>(message?.type === "DIRECT");
    const [isViewAppUsersModalVisible, setIsViewAppUsersModalVisible] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null | undefined>();

    const alreadyLoadedAppUsersRef = useRef(false);
    const isEditing = useRef<boolean>(!!message);
    const [searchParams] = useSearchParams();

    const { activeCity } = useAuth();
    const { getUser } = useLocalStorage();

    const type = searchParams.get('type');

    const isGeneral = useMemo(() => {
        return type?.toLowerCase() === "general" || message?.type === "GENERAL";
    }, [type, message]);

    const loggedInUserName = useMemo(() => {
        return getUser()?.name || 'Usuário Painel';
    }, [getUser]);

    const loadBase64Image = useCallback(async (url: string, signal: AbortSignal) => {
        try {
            if (!window.Buffer) {
                window.Buffer = Buffer;
            }

            const response = await privateHttpClient.get(url, { responseType: 'arraybuffer', signal });

            let data = `data:${response.headers["content-type"]};base64,${Buffer.from(response.data, "binary").toString("base64")}`;

            setImageUrl(data);
        } catch (err) {
            if (err instanceof AxiosError && err.name === 'CanceledError') {
                return;
            }
            EmitError(err, true, 'viewRecoverAccess_ajksdkasdjasjdasd')
        }
    }, [])

    const body = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
    );

    const handleSubmitForm: SubmitHandler<IMessageInputs> = async (data) => {
        setIsSubmiting(true);

        const finalBody = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );

        if (finalBody.replace("<p></p>", "").trim().length === 0) {
            setError('body', { message: 'Por favor, preencha o corpo da mensagem.' });
            setIsSubmiting(false);
            return;
        }

        const formData = new FormData();

        if (data?.image?.originFileObj) {
            formData.append('image', data.image.originFileObj);
        }

        formData.append('body', finalBody);

        const formatedData: IMessage = {
            ...data,
            app_user_ids: isDirectMessage ? JSON.stringify(data.app_user_ids) : undefined,
            image: undefined,
            type: undefined,
        }

        const persistenceMessage = MessageMapper.toPersistence(formatedData);

        if (!persistenceMessage.app_user_ids) {
            delete persistenceMessage.app_user_ids;
        }

        Object.keys(persistenceMessage).forEach((fieldName: string) => {
            if (fieldName === 'image' || fieldName === 'body') {
                return;
            }

            formData.append(fieldName, String(persistenceMessage[fieldName as keyof IMessage]));
        })

        await onSubmit(formData);

        setIsSubmiting(false);
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        setError,
        clearErrors,
        watch,
    } = useForm<IMessageInputs>({
        defaultValues: {
            title: message?.title || '',
            content: message?.content || '',
            city_id: isGeneral ? null : activeCity?.id,
        }
    });

    const title = watch('title');

    const dummyRequest = useCallback(({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }, []);

    const onChangeIsDirectMessage = useCallback((value: boolean) => {
        setIsDirectMessage(value);
    }, []);

    const handleChangeImage = useCallback(({ onChangeHookForm, file }: IChangeFileProps) => {
        if (file?.type && !ACCEPTED_IMAGE_TYPES.includes(file.type.toLowerCase())) {
            setError('image', { message: 'Formato de imagem inválido! Os formatos aceitos são: PNG, JPG, JPEG e WEBP.' });
            return;
        }

        clearErrors('image');
        onChangeHookForm(file);
        getBase64Image(file.originFileObj, (imageUrl) => {
            setImageUrl(imageUrl);
        });
    }, [setError, clearErrors]);

    const onChangeEditor = useCallback((editorState: EditorState): void => {
        if (errors.body?.message) {
            clearErrors("body");
        }
        setEditorState(editorState);
    }, [errors, clearErrors]);

    const handleOpenAppUsersModal = useCallback(() => {
        setIsViewAppUsersModalVisible(true);
    }, []);

    const handleCloseAppUsersModal = useCallback(() => {
        setIsViewAppUsersModalVisible(false);
    }, []);

    useEffect(() => {
        if (!message) {
            return;
        }

        const controller = new AbortController();

        if (message.image) {
            loadBase64Image(`${messageImageUrl}/${message.image}`, controller.signal)
        }

        const contentBlocks = htmlToDraft(
            message.body
        );

        const sampleEditorContent =
            ContentState.createFromBlockArray(
                contentBlocks.contentBlocks,
                contentBlocks.entityMap
            );
        const editorState =
            EditorState.createWithContent(sampleEditorContent);

        setEditorState(editorState);

        return () => {
            controller.abort();
        }

    }, [loadBase64Image, message]);

    useEffect(() => {
        const controller = new AbortController();

        if (!activeCity || !isDirectMessage || alreadyLoadedAppUsersRef.current) {
            return;
        }

        async function loadAppUsers() {
            try {
                setIsLoadingAppUsers(true);
                const appUsersList = await AppUsersService.listAppUsers(activeCity?.id!, controller.signal);

                setAppUsers(appUsersList);
                alreadyLoadedAppUsersRef.current = true;
                setIsLoadingAppUsers(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                setIsLoadingAppUsers(false);
                EmitError(err);
            }
        }

        loadAppUsers();

        return () => {
            controller.abort();
        }
    }, [activeCity, isDirectMessage]);

    return {
        isLoadingAppUsers,
        appUsers,
        handleSubmitForm,
        handleSubmit,
        dummyRequest,
        control,
        isSubmiting,
        errors,
        defaultEditorState,
        editorState,
        handleChangeImage,
        imageUrl,
        onChangeEditor,
        onChangeIsDirectMessage,
        isGeneral,
        isDirectMessage,
        isEditing,
        handleOpenAppUsersModal,
        handleCloseAppUsersModal,
        isViewAppUsersModalVisible,
        acceptedImageTypes: ACCEPTED_IMAGE_TYPES,
        body,
        title,
        loggedInUserName,
    }
}