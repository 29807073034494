import { useMemo } from "react";

import useAuth from "./useAuth";
import { EModule } from "../enums";

export default function useModules() {
    const { activeCity } = useAuth();

    const cityModules = useMemo(() => {
        return activeCity?.modules || [];
    }, [activeCity]);

    const hasRewardModule = useMemo(() => {
        return !!activeCity?.modules?.includes(EModule.REWARD);
    }, [activeCity]);

    const hasAtLeastOneInvoiceModule = useMemo(() => {
        return !!activeCity?.modules?.includes(EModule.INVOICE_NFC) || !!activeCity?.modules?.includes(EModule.INVOICE_NFS);
    }, [activeCity]);

    const hasTaxesModule = useMemo(() => {
        return !!activeCity?.modules?.includes(EModule.TAXES);
    }, [activeCity]);

    return {
        cityModules,
        hasRewardModule,
        hasAtLeastOneInvoiceModule,
        hasTaxesModule,
    }
}