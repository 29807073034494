import { IRecoverRequest } from '../../../interfaces';

class RecoverRequestMapper {
    toPersistence(domainRecoverRequest: IRecoverRequest): any {
        return {
            id: domainRecoverRequest.id,
            photo: domainRecoverRequest.images,
            new_phone: domainRecoverRequest.new_phone,
            existing_phone: domainRecoverRequest.existing_phone,
            status: domainRecoverRequest.status,
            observation: domainRecoverRequest.observation,
            date_response: domainRecoverRequest.date_response,
            panel_user_id: domainRecoverRequest.panel_user_id,
            app_user: domainRecoverRequest.app_user,
            created_at: domainRecoverRequest.created_at,
            updated_at: domainRecoverRequest.updated_at,
        }
    }

    toDomain(persistenceRecoverRequest: any): IRecoverRequest {
        return {
            id: persistenceRecoverRequest.id,
            images: persistenceRecoverRequest.photo,
            new_phone: persistenceRecoverRequest.new_phone,
            existing_phone: persistenceRecoverRequest.existing_phone,
            status: persistenceRecoverRequest.status,
            observation: persistenceRecoverRequest.observation,
            date_response: persistenceRecoverRequest.date_response,
            panel_user_id: persistenceRecoverRequest.panel_user_id,
            app_user: persistenceRecoverRequest.app_user,
            panel_user: persistenceRecoverRequest.panel_user,
            created_at: persistenceRecoverRequest.created_at,
            updated_at: persistenceRecoverRequest.updated_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RecoverRequestMapper();
