import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SearchOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import { ICityTax } from "../../interfaces";
import { Button, Input, InputRef, Space } from "antd";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import { removeAcento } from "../../utils/RemoveAcentos";
import Highlighter from "react-highlight-words";
import { formatCpf, formatCurrency } from "../../utils/Masks";
import { formatDateStringToDate, formatDateStringToDateAndTime } from "../../utils/Conversores";
import { useCityTaxesContext } from "../../contexts/CityTaxesContext";
import StatusTag from "./components/StatusTag";

export const payment_modalities = {
    COTA_UNICA: "Cota Única",
    PARCELA: "Parcela",
}

interface ITableData extends ICityTax {
    key: number | undefined,
    app_user_cpf: string,
}

export default function useCityTaxes() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<ITableData>>([]);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef<InputRef>(null);

    const navigate = useNavigate();
    const { cityTaxes, loadCityTaxes } = useCityTaxesContext();

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (
        dataIndex: keyof ITableData,
        inputColumnName: string,
        useFormatterOnSearch: boolean = false,
        formatter?: (text: string) => string,
    ) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Pesquisar por ${inputColumnName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        Pesquisar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Resetar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fechar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value: any, record: ITableData) => {
            const recordWithoutAccents = removeAcento(record[dataIndex]!.toString()).toLowerCase();
            let compareRecord = useFormatterOnSearch && formatter ? formatter(recordWithoutAccents) : recordWithoutAccents;
            return compareRecord.includes(removeAcento(value).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                        text ? 
                            formatter ? formatter(text.toString()) 
                            : text.toString() 
                        : ''}
                />
            ) : (
                formatter ? formatter(text) : text
            ),
    });

    const columns = [
        {
            title: 'CPF',
            dataIndex: 'app_user_cpf',
            key: 'app_user_cpf',
            ...getColumnSearchProps('app_user_cpf', 'CPF', false, formatCpf),
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            render: (record: number) => record ? `R$ ${formatCurrency(record)}` : 'R$ 0,00',
            sorter: (a: ITableData, b: ITableData) => (a?.value || 0) - (b?.value || 0),
            showSorterTooltip: false,
        },
        {
            title: 'Data de vencimento',
            dataIndex: 'due_date',
            key: 'due_date',
            sorter: (a: ITableData, b: ITableData) => (a.due_date ? new Date(a.due_date).getTime() : 0) - (b.due_date ? new Date(b.due_date).getTime() : 0),
            showSorterTooltip: false,
            ...getColumnSearchProps('due_date', 'Data de vencimento', true, formatDateStringToDate),
        },
        {
            title: 'Imposto',
            dataIndex: 'type',
            key: 'type',
            sorter: {
                compare: (a: ITableData, b: ITableData) => a.type.localeCompare(b.type)!,
            },
            filters: [
                {
                    text: 'IPTU',
                    value: 'IPTU',
                },
                {
                    text: 'ITBI',
                    value: 'ITBI',
                },
            ],
            onFilter: (value: any, record: ITableData) => {
                return record.type === value;
            },
            render: (_: any, tax: ITableData) => tax.type,
        },
        {
            title: 'Modalidade',
            dataIndex: 'payment_modality',
            key: 'payment_modality',
            sorter: {
                compare: (a: ITableData, b: ITableData) => a.payment_modality.localeCompare(b.payment_modality)!,
            },
            filters: [
                {
                    text: 'Cota Única',
                    value: 'COTA_UNICA',
                },
                {
                    text: 'Parcela',
                    value: 'PARCELA',
                },
            ],
            onFilter: (value: any, record: ITableData) => {
                return record.payment_modality === value;
            },
            render: (_: any, tax: ITableData) => payment_modalities[tax.payment_modality],
        },
        {
            title: 'Parcela',
            dataIndex: 'installment',
            key: 'installment',
            sorter: (a: ITableData, b: ITableData) => (a?.installment || 0) - (b?.installment || 0),
            showSorterTooltip: false,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: (a: ITableData, b: ITableData) => a.status.localeCompare(b.status)!,
            },
            filters: [
                {
                    text: 'Aprovado',
                    value: 'Aprovado',
                },
                {
                    text: 'Alerta',
                    value: 'Alerta',
                },
                {
                    text: 'Recusado',
                    value: 'Erro',
                },
                {
                    text: 'Em análise',
                    value: 'Em análise',
                },
            ],
            onFilter: (value: any, record: ITableData) => {
                return record.status === value;
            },
            render: (_: any, request: ITableData) => (
                <StatusTag
                    key={request.id}
                    status={request.status}
                    message={request.message}
                />
            ),
        },
        {
            title: 'Data de cadastro',
            dataIndex: 'created_at',
            key: 'created_at',
            defaultSortOrder: 'descend' as keyof SortOrder,
            sorter: (a: ITableData, b: ITableData) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            showSorterTooltip: false,
            ...getColumnSearchProps('created_at', 'Data do sorteio', true, formatDateStringToDateAndTime),
        },
    ]

    const setTableDataFromRequest = useCallback((data: Array<ICityTax>) => {
        const newData = data.map((tax) => ({
            ...tax,
            key: tax.id,
            app_user_cpf: tax.app_user.cpf,
        }));
        setTableData(newData);
    }, []);

    useEffect(() => {
        setTableDataFromRequest(cityTaxes);
    }, [cityTaxes, setTableDataFromRequest]);

    useEffect(() => {
        const controller = new AbortController();

        async function loadData() {
            setIsLoading(true);

            await loadCityTaxes(controller);

            setIsLoading(false);
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [loadCityTaxes]);

    const onClickTableRow = useCallback((record: any, index: any) => {
        if (selectedIds.includes(record.id)) {
            setSelectedIds((prevState) => prevState.filter((id) => id !== record.id));
        } else {
            setSelectedIds((prevState) => [...prevState, record.id]);
        }
    }, [selectedIds]);

    const onSelectChange = useCallback((newSelectedRowKeys: Array<any>) => {
        if (newSelectedRowKeys.length > selectedIds.length) {
            if (newSelectedRowKeys.length - 1 === selectedIds.length) {
                const newId = newSelectedRowKeys.pop();
                return setSelectedIds((prevState) => [...prevState, newId]);
            }

            if (newSelectedRowKeys.length - 1 > selectedIds.length) {
                return setSelectedIds(newSelectedRowKeys);
            }
        }

        setSelectedIds(newSelectedRowKeys);
    }, [selectedIds]);

    const rowSelection = useMemo(() => ({
        type: 'checkbox' as const,
        selectedRowKeys: selectedIds,
        onChange: onSelectChange,
    }), [selectedIds, onSelectChange]);

    const handleClickViewCityTax = useCallback(() => {
        const tax = tableData.find((v) => v.id === selectedIds[0]);

        if (!tax) {
            return;
        }

        navigate(`/impostos/${tax.id}`);
    }, [navigate, selectedIds, tableData]);

    return {
        isLoading,
        columns,
        onClickTableRow,
        rowSelection,
        selectedIds,
        tableData,
        handleClickViewCityTax,
    }
}