import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Breadcrumb, Button, Col, Divider, Form, Input, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { BarsOutlined } from '@ant-design/icons';

import { ICategory } from '../../../../interfaces';

import { colInputs } from './GridStyle';
import useCategoryForm from './useCategoryForm';

interface IProps {
    action: 'Adicionar' | 'Editar';
    category?: ICategory;
    onSubmit: (category: ICategory) => Promise<void>;
}

export default function CategoryForm({ action, category, onSubmit }: IProps) {
    const {
        handleSubmitForm,
        handleSubmit,
        isSubmiting,
        errors,
        control,
    } = useCategoryForm(onSubmit, category);

    return (
        <>
            <Row>
                <Col>
                    <Title level={3}><BarsOutlined style={{ fontSize: 35, marginRight: 10 }} /> {action} Categoria</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/categorias'>Categorias</Link>,
                            },
                            {
                                title: `${action} Categoria`,
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            <Form
                onFinish={handleSubmit(handleSubmitForm)}
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="Nome"
                            required
                            validateStatus={errors.name ? 'error' : 'success'}
                            help={errors.name?.message}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: { value: true, message: 'Por favor, digite o nome da Categoria!' },  }}
                                render={({ field }) => <Input {...field} placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        { ...colInputs }
                    >
                        <Form.Item
                            label="Descrição"
                            required
                            validateStatus={errors.description ? 'error' : 'success'}
                            help={errors.description?.message}
                        >
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: { value: true, message: 'Por favor, digite a descrição da Categoria!' },
                                }}
                                render={({ field }) => <Input {...field} placeholder='Descrição' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isSubmiting}
                    loading={isSubmiting}
                >
                    Salvar
                </Button>
            </Form>
        </>

    )
}
