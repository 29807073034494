import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { IAppUser, ITicket } from "../../../../interfaces";

import { EmitError } from "../../../../utils/EmitError";
import useAuth from "../../../../hooks/useAuth";
import TicketsService from "../../../../services/http/services/TicketsService";
import AppUsersService from "../../../../services/http/services/AppUsersService";

export default function useTickets() {
    const [appUser, setAppUser] = useState<IAppUser >();
    const [tickets, setTickets] = useState<Array<ITicket>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id, giveawayId } = useParams();
    const { activeCity } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoading(true);

                const [ticketsList, appUserData] = await Promise.all([
                    TicketsService.listTicketsByGiveawayIdAndAppUserId(
                        Number(giveawayId),
                        Number(id),
                        controller.signal,
                    ),
                    AppUsersService.getAppUserById(Number(id), controller.signal),
                ]);

                setTickets(ticketsList);
                setAppUser(appUserData);

                setIsLoading(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === 'CanceledError') {
                    return;
                }

                EmitError(err, true, 'viewAppUserTickets_asodkaskldasd');
                setIsLoading(false);
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [id, navigate, giveawayId, activeCity]);

    const handleClickGoBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return {
        appUser,
        tickets,
        isLoading,
        handleClickGoBack,
        giveawayId,
    }
}