import { Empty } from "antd";

interface IProps {
    isLoading?: boolean,
    message?: string,
}

export default function EmptyListMessage({ isLoading = false, message }: IProps) {
    if (isLoading) {
        return null;
    }

    return (
        <Empty
            style={{
                width: '100%',
            }}
            description={message || undefined}
        />
    )
}