import { Breadcrumb, Button, Col, Divider, Row, Steps } from "antd";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";

import { EnvironmentOutlined } from '@ant-design/icons';

import Loading from "../../../components/Loading";
import ImportData from "./Steps/ImportData";
import ExportData from "./Steps/ExportData";
import useHoldGiveaway from "./useHoldGiveaway";

export default function HoldGiveaway() {
    const {
        giveaway,
        isLoadingGiveaway,
        currentStep,
        handleClickExportStep,
        handleClickImportStep,
        handleClickNextStep,
        handleClickPreviousStep,
        handleTicketsFileGenerated,
        handleWinnersFileImported,
        alreadyGeneratedTicketsFile,
        panelUserGeneratedParcitipantsFile,
        panelUserUploadedWinnersFile,
    } = useHoldGiveaway();

    return (
        <Loading loading={isLoadingGiveaway}>
            <Row>
                <Col>
                    <Title level={3}><EnvironmentOutlined style={{ fontSize: 35, marginRight: 10 }} /> Realizar Sorteio</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/sorteios'>Sorteios</Link>,
                            },
                            {
                                title: 'Realizar Sorteio',
                            }
                        ]}
                    />
                </Col>
                <Divider />
            </Row>

            <Row
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 16,
                }}
            >
                <div
                    style={{
                        width: '65%'
                    }}
                >

                    <Steps
                        current={currentStep}
                        onChange={() => { }} // função vazia apenas para habilitar click
                        items={[
                            {
                                title: "Bilhetes",
                                onClick: handleClickExportStep,
                            },
                            {
                                title: "Resultado",
                                onClick: alreadyGeneratedTicketsFile ? handleClickImportStep : undefined,
                                disabled: !alreadyGeneratedTicketsFile,
                            },
                        ]}
                    />
                </div>
            </Row>

            {
                currentStep === 0 && (
                    <ExportData
                        giveaway={giveaway}
                        panelUserGeneratedParticipantsFile={panelUserGeneratedParcitipantsFile}
                        onTicketsFileGenerated={handleTicketsFileGenerated}
                        key={giveaway?.id}
                    />
                )
            }

            {
                currentStep === 1 && (
                    <ImportData
                        giveaway={giveaway}
                        panelUserUploadedWinnersFile={panelUserUploadedWinnersFile}
                        onWinnersFileImported={handleWinnersFileImported}
                        key={giveaway?.id}
                    />
                )
            }

            <Row
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 16,
                }}
            >
                <Button
                    type='default'
                    htmlType='button'
                    disabled={currentStep === 0} // || isSubmiting
                    // loading={isSubmiting}
                    onClick={handleClickPreviousStep}
                >
                    Anterior
                </Button>

                <Button
                    type='default'
                    htmlType='button'
                    disabled={currentStep === 1 || !alreadyGeneratedTicketsFile}
                    onClick={handleClickNextStep}
                >
                    Próximo
                </Button>
            </Row>
        </Loading>
    )
}