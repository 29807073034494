import { IRecoverRequest } from "../../../interfaces";
import { urlHttpGov } from "../../urls";
import RecoverRequestMapper from "../mappers/RecoverRequestMapper";
import privateHttpClient from "../privateHttpClient";

class AuthService {
    private baseURL = `${urlHttpGov}/panel/auth_app/recover`;

    async listRecoverRequests(cityId: number, signal: AbortSignal): Promise<Array<IRecoverRequest>> {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${cityId}`, { signal });

        return data.map((request: any) => RecoverRequestMapper.toDomain(request));
    }

    approveRecoverRequest(request: IRecoverRequest, observation: string) {

        return privateHttpClient.patch(this.baseURL, {
            app_user_id: request.app_user.id,
            recover_session_id: request.id,
            is_approve: true,
            observation: observation || undefined,
        })
    }

    refuseRecoverRequest(request: IRecoverRequest, observation: string) {
        
        return privateHttpClient.patch(this.baseURL, {
            app_user_id: request.app_user.id,
            recover_session_id: request.id,
            is_approve: false,
            observation: observation,
        })
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();