import CategoryForm from '../components/CategoryForm';
import Loading from '../../../components/Loading';
import useEditCategory from './useEditCategory';

export default function EditCategory() {
    const {
        isLoading,
        handleSubmit,
        category
    } = useEditCategory();

    return (
        <Loading loading={isLoading} >
            <CategoryForm action='Editar' category={category} key={category?.id} onSubmit={handleSubmit} />
        </Loading>
    )
}
