import { GenericAbortSignal } from "axios";
import { urlHttpGov } from "../../urls";
import httpClient from "../httpClient";
import privateHttpClient from "../privateHttpClient";
import { ISignInData, ISignInReturn } from "../../../interfaces";
import PanelUserMapper from "../mappers/PanelUserMapper";

class AuthService {
    private baseURL = `${urlHttpGov}/panel/auth`;

    async signIn(data: ISignInData): Promise<ISignInReturn>  {
        const response = await httpClient.post(this.baseURL, data);
        const domainUser = PanelUserMapper.toDomain(response.data.panel_user);

        return {
            ...response.data,
            panel_user: domainUser,
        };
    }

    refreshToken(
        refresh_token: string | undefined,
        cityId: number,
        signal: GenericAbortSignal | undefined,
    ) {
        return privateHttpClient.post(`${this.baseURL}/refresh_token`, {
            refresh_token,
            county_id: cityId,
        }, { signal });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();