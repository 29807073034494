import { useMemo } from "react";
import { Alert, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";

import { ICityConfig } from "../../../../interfaces";

import { formatDateStringToDate, formatDateStringToDateAndTime } from "../../../../utils/Conversores";
import { formatCurrency } from "../../../../utils/Masks";

interface IProps {
    title: string;
    config: ICityConfig;
}

export default function UpcomingConfigAlert({ config, title }: IProps) {
    const hasRewardModule = useMemo(() => {
        return !!config?.nfs_max_amount || !!config?.nfc_max_amount || !!config?.taxe_max_amount;
    }, [config]);

    const hasAtLeastOneInvoiceModule = useMemo(() => {
        return !!config?.nfs_max_month_quantity_by_cnpj || !!config?.nfc_max_month_quantity_by_cnpj;
    }, [config]);

    return (
        <Row>
            <Alert
                type='warning'
                message={<Title level={5}>{title}</Title>}
                description={(
                    <Row
                        gutter={[8, 8]}
                    >
                        <Col span={24}>
                            <Text>
                                <strong>Divisores</strong>
                            </Text>
                        </Col>
                        {
                            config?.nfs_divider && (
                                <Col>
                                    <Text>
                                        NFS: {config.nfs_divider}
                                    </Text>
                                </Col>
                            )
                        }

                        {
                            config?.nfc_divider && (
                                <Col>
                                    <Text>
                                        NFC: {config.nfc_divider}
                                    </Text>
                                </Col>
                            )
                        }

                        {
                            config?.taxe_divider && (
                                <Col>
                                    <Text>
                                        Impostos: {config.taxe_divider}
                                    </Text>
                                </Col>
                            )
                        }

                        {
                            hasRewardModule && (
                                <>
                                    <Col span={24}>
                                        <Text>
                                            <strong>Valor máximo para gerar pontos</strong>
                                        </Text>
                                    </Col>

                                    {
                                        config?.nfs_max_amount && (
                                            <Col>
                                                <Text>
                                                    NFS: R$ {formatCurrency(config.nfs_max_amount.toFixed(2))}
                                                </Text>
                                            </Col>
                                        )
                                    }

                                    {
                                        config?.nfc_max_amount && (
                                            <Col>
                                                <Text>
                                                    NFC: R$ {formatCurrency(config.nfc_max_amount.toFixed(2))}
                                                </Text>
                                            </Col>
                                        )
                                    }

                                    {
                                        config?.taxe_max_amount && (
                                            <Col>
                                                <Text>
                                                    Impostos: R$ {formatCurrency(config.taxe_max_amount.toFixed(2))}
                                                </Text>
                                            </Col>
                                        )
                                    }
                                </>
                            )
                        }

                        {
                            hasAtLeastOneInvoiceModule && (
                                <>
                                    <Col span={24}>
                                        <Text>
                                            <strong>Quantidade mensal limite por estabelecimento</strong>
                                        </Text>
                                    </Col>

                                    {
                                        config?.nfs_max_month_quantity_by_cnpj && (
                                            <Col>
                                                <Text>
                                                    NFS: {config.nfs_max_month_quantity_by_cnpj}
                                                </Text>
                                            </Col>
                                        )
                                    }

                                    {
                                        config?.nfc_max_month_quantity_by_cnpj && (
                                            <Col>
                                                <Text>
                                                    NFC: {config.nfc_max_month_quantity_by_cnpj}
                                                </Text>
                                            </Col>
                                        )
                                    }

                                </>
                            )
                        }

                        <Col span={24}>
                            <Text>
                                <strong>Descrição: </strong>{config?.description || 'Não há descrição cadastrada'}
                            </Text>
                        </Col>
                        <Col>
                            <Text>
                                <strong>Data de vigência: </strong>{formatDateStringToDate(config?.effective_date)}
                            </Text>
                        </Col>
                        <Col>
                            <Text>
                                <strong>Usuário: </strong>{config?.panel_user?.name}
                            </Text>
                        </Col>
                        <Col>
                            <Text>
                                <strong>Data da alteração: </strong>{formatDateStringToDateAndTime(config?.created_at)}
                            </Text>
                        </Col>
                    </Row>
                )}
                style={{
                    width: '100%',
                }}
            />
        </Row>
    )
}