import { useNavigate } from "react-router-dom";

import { IRole } from "../../../interfaces";

import RolesService from "../../../services/http/services/RolesService";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import { EmitError } from "../../../utils/EmitError";

export default function useNewRole() {
    const navigate = useNavigate();

    async function handleSubmit(role: IRole) {
        try {
            await RolesService.createRole(role);

            EmitSuccessAlert('Perfil cadastrado com sucesso!');

            navigate('/perfis');
        } catch (err) {
            EmitError(err, true, 'newRole_asdpasmdas');
        }
    }

    return {
        handleSubmit
    }
}