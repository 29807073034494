import { IMessage } from '../../../interfaces';

class MessageMapper {
    toPersistence(domainMessage: IMessage): any {
        return {
            id: domainMessage.id,
            title: domainMessage.title,
            content: domainMessage.content,
            body: domainMessage.body,
            county_id: domainMessage.city_id,
            app_user_ids: domainMessage.app_user_ids,
            panel_user_id: domainMessage.panel_user_id,
            image: domainMessage.image,
            panel_user: domainMessage.panel_user,
            created_at: domainMessage.created_at,
            updated_at: domainMessage.created_at,
        }
    }

    toDomain(persistenceMessage: any): IMessage {
        return {
            id: persistenceMessage.id,
            title: persistenceMessage.title,
            content: persistenceMessage.content,
            body: persistenceMessage.body,
            city_id: persistenceMessage.county_id,
            panel_user_id: persistenceMessage.panel_user_id,
            panel_user: persistenceMessage.panel_user,
            app_user_ids: persistenceMessage.app_user_ids,
            image: persistenceMessage.image,
            type: persistenceMessage.type,
            created_at: persistenceMessage.created_at,
            updated_at: persistenceMessage.created_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MessageMapper();
