import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AxiosError } from "axios";

import { IAppUser, IReward, IVoucher } from "../../../interfaces";

import { EmitError } from "../../../utils/EmitError";
import { EmitSuccessAlert } from "../../../utils/EmitSuccessAlert";
import VouchersService from "../../../services/http/services/VouchersService";
import AppUsersService from "../../../services/http/services/AppUsersService";
import RewardsService from "../../../services/http/services/RewardsService";
import { formatCpf, formatPhone } from "../../../utils/Masks";

interface IInputs {
    name: string,
    cpf: string,
    phone: string,
    created_at: string,
}

export default function useViewVoucher() {
    const [isLoadingVoucher, setIsLoadingVoucher] = useState<boolean>(true);
    const [isLoadingAppUserAndRewardData, setIsLoadingAppUserAndRewardData] = useState<boolean>(false);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [voucher, setVoucher] = useState<IVoucher | undefined>();
    const [appUser, setAppUser] = useState<IAppUser | undefined>();
    const [reward, setReward] = useState<IReward | undefined>();
    const [isRewardImageModalVisible, setIsRewardImageModalVisible] = useState<boolean>(false);
    const [isConfirmRedemptionModalVisible, setIsConfirmRedemptionModalVisible] = useState<boolean>(false);

    const { code } = useParams();
    const navigate = useNavigate();

    const {
        formState: { errors },
        control,
        setValue,
    } = useForm<IInputs>();

    const handleConfirmRedemption = useCallback(() => {
        setIsConfirmRedemptionModalVisible(true);
    }, []);

    const handleCancel = useCallback(() => {
        setIsConfirmRedemptionModalVisible(false);
    }, []);

    const handleConfirm = useCallback(async () => {
        try {
            setIsSubmiting(true);
            if (!voucher) {
                return;
            }

            await VouchersService.confirmVoucherRedemption(voucher.id);

            EmitSuccessAlert('Entrega confirmada com sucesso!');

            navigate(-1);
        } catch (err) {

            EmitError(err, true, 'viewRecoverRequest_akmdkoasdasd');
        } finally {
            setIsSubmiting(false);
        }
    }, [voucher, navigate]);

    const handleClickViewReward = useCallback(() => {
        if (!reward) {
            return;
        }

        setIsRewardImageModalVisible(true);
    }, [reward]);

    const handleClickCloseRewardImageModal = useCallback(() => {
        setIsRewardImageModalVisible(false);
    }, []);

    useEffect(() => {
        if (!code) {
            navigate('/vouchers');
        }

        const controller = new AbortController();

        async function loadVoucher() {
            try {
                setIsLoadingVoucher(true);

                const voucherData = await VouchersService.getVoucherByRedemptionCode(code!, controller.signal);

                setVoucher(voucherData);

                setValue('created_at', voucherData?.created_at || '');
                setIsLoadingVoucher(false);
            } catch (error) {
                if (error instanceof AxiosError && error.name === "CanceledError") {
                    return;
                }

                EmitError(error, true, "viewVoucher_askdaslkdaskd");
                navigate(-1);
            }
        }

        loadVoucher();

        return () => {
            controller.abort();
        }
    }, [code, setValue, navigate]);

    useEffect(() => {
        if (!voucher) {
            return;
        }

        const controller = new AbortController();

        async function loadData() {
            try {
                setIsLoadingAppUserAndRewardData(true);

                const [appUserData, rewardData] = await Promise.all([
                    AppUsersService.getAppUserById(voucher?.app_user_id!, controller.signal),
                    RewardsService.getRewardById(voucher?.reward_id!, controller.signal),
                ]);


                setValue('name', appUserData.name);
                setValue('cpf', formatCpf(appUserData.cpf));
                setValue('phone', formatPhone(appUserData.phone));

                setAppUser(appUserData);
                setReward(rewardData);
                setIsLoadingAppUserAndRewardData(false);
            } catch (err) {
                if (err instanceof AxiosError && err.name === "CanceledError") {
                    return;
                }

                EmitError(err, true, "viewVoucher_aksldmasdkasl");
                setIsLoadingAppUserAndRewardData(false);
            }
        }

        loadData();

        return () => {
            controller.abort();
        }
    }, [voucher, setValue])

    return {
        isLoadingVoucher,
        isLoadingAppUserAndRewardData,
        isSubmiting,
        control,
        errors,
        voucher,
        appUser,
        reward,
        handleConfirmRedemption,
        handleClickViewReward,
        isRewardImageModalVisible,
        handleClickCloseRewardImageModal,
        handleConfirm,
        handleCancel,
        isConfirmRedemptionModalVisible,
    }
}