import { Card, Col, Row } from "antd";
import { ITicket } from "../../../../interfaces";
import { FaTicketAlt } from 'react-icons/fa';

import Text from "antd/es/typography/Text";
import { useCallback, useContext, useState } from "react";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { useNavigate } from "react-router-dom";

interface IProps {
    ticket: ITicket,
    giveawayId?: number | string;
}

export default function TicketCard({ ticket, giveawayId }: IProps) {
    const [isMouseIn, setIsMouseIn] = useState<boolean>(false);

    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();

    const handleMouseEnterCard = useCallback(() => {
        setIsMouseIn(true);
    }, []);

    const handleMouseLeaveCard = useCallback(() => {
        setIsMouseIn(false);
    }, []);

    const goToGiveawayWinnersPage = useCallback(() => {
        if (!giveawayId) {
            return;
        }

        navigate(`/sorteios/${giveawayId}/ganhadores`)
    }, [giveawayId, navigate]);

    return (
        <Col>
            <Card
                onClick={ticket.winner ? goToGiveawayWinnersPage : undefined}
                onMouseEnter={handleMouseEnterCard}
                onMouseLeave={handleMouseLeaveCard}
                style={{
                    height: '100%',
                    boxShadow:
                        isMouseIn ?
                            theme === 'light' ? 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                                : 'rgba(255, 255, 255, 0.07) 0px 4px 12px'
                            : undefined,
                    cursor: ticket.winner ? 'pointer' : 'default',
                }}
            >
                <Row
                    justify='center'
                >
                    <FaTicketAlt
                        color={ticket.winner ? '#DEA000' : undefined}
                        fontSize={36}
                    />
                </Row>
                <Row
                    justify='center'
                >
                    <Text
                        style={{
                            color: ticket.winner ? '#DEA000' : undefined,
                        }}
                    >
                        {ticket.id.toLocaleString('pt-BR')}
                    </Text>
                </Row>
            </Card>
        </Col>
    )
}