import { Tag, Tooltip } from "antd";

import { ECityTaxStatus } from "../../../../enums";

interface IProps {
    status: ECityTaxStatus,
    message?: string,
}

export default function StatusTag({ status, message }: IProps) {
    const type = status === "Aprovado" ? "success" 
                    : status === "Erro" ? "error"
                        : status === "Alerta" ? "warning" 
                            : "default";

    const tooltipColor = type === "warning" ? "orange" : "red";
    const statusText = status === "Erro" ? "Recusado" : status;
    const tooltipText = message !== "Recusado" && message !== "Alerta" && type !== "success" && type !== "default" ? message : ''; 

    return (
        <Tooltip
            title={tooltipText}
            color={tooltipColor}
        >
            <Tag
                color={type}
            >
                {statusText}
            </Tag>
        </Tooltip>
    )
}