import { Alert, Row } from "antd";

import { ICityTax } from "../../../../interfaces";

import { formatDateStringToDateAndTime } from "../../../../utils/Conversores";

interface IProps {
    cityTax: ICityTax,
}

export default function StatusOnView({ cityTax }: IProps) {
    const {
        status,
        message,
        panel_user,
        observation,
        date_response,
    } = cityTax;

    if (status === "Em análise") {
        return null;
    }

    const statusType = status !== "Erro" ? 'success' : 'error';
    const statusText = status !== "Alerta" ? message : "Aprovado";
    const dateResponse = date_response ? formatDateStringToDateAndTime(date_response) : '';
    const hasWarning = status === "Alerta";

    return (
        <>
            <Row
                style={{
                    marginBottom: 12,
                }}
            >
                <Alert
                    type={statusType}
                    message={`${statusText} - ${dateResponse} - Usuário: ${panel_user?.name}`}
                    description={`Observação: ${observation ? observation : 'Não há observação cadastrada.'}`}
                    style={{
                        width: '100%',
                    }}
                />
            </Row>

            {
                hasWarning && (
                    <Row
                        style={{
                            marginBottom: 12,
                        }}
                    >
                        <Alert
                            type="warning"
                            message="Alerta"
                            description={message}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Row>
                )
            }
        </>
    )
}