import { Tag, Tooltip } from "antd";

import { EAwardStatus } from "../../../../enums";

interface IProps {
    status: EAwardStatus;
    observation: string | undefined;
}

export default function AwardStatusTag({ status, observation }: IProps) {
    const type = status === "Válido" ? "success" 
                    : status === "Inválido" ? "error"
                        : "default";

    return (
        <Tooltip
            title={observation}
        >
            <Tag
                color={type}
            >
                {status}
            </Tag>
        </Tooltip>
    )
}