import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Alert, Breadcrumb, Button, Col, Divider, Form, Input, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { TagOutlined } from '@ant-design/icons';

import { colInputs } from './GridStyle';
import useViewVoucher from './useViewVoucher';
import Loading from '../../../components/Loading';
import Voucher from '../../../components/Voucher';
import RewardImageModal from './components/RewardImageModal';
import SimpleModal from '../../../components/SimpleModal';

export default function ViewVoucher() {
    const {
        isLoadingVoucher,
        isLoadingAppUserAndRewardData,
        isSubmiting,
        control,
        handleConfirmRedemption,
        voucher,
        appUser,
        reward,
        handleClickViewReward,
        isRewardImageModalVisible,
        handleClickCloseRewardImageModal,
        isConfirmRedemptionModalVisible,
        handleConfirm,
        handleCancel,
    } = useViewVoucher();

    if (isLoadingVoucher) {
        return null;
    }

    return (
        <Loading loading={isLoadingAppUserAndRewardData}>
            <Row>
                <Col>
                    <Title level={3}><TagOutlined style={{ fontSize: 35, marginRight: 10 }} />Visualizar Voucher</Title>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to='/vouchers'>Vouchers</Link>,
                            },
                            {
                                title: 'Visualizar Voucher',
                            }
                        ]}
                    />
                    <Divider />
                </Col>
            </Row>
            {
                voucher?.status && voucher.status !== "Emitido" && (
                    <Row
                        style={{
                            marginBottom: 12,
                        }}
                    >
                        <Alert
                            type={voucher.status === "Resgatado" ? 'success' : 'error'}
                            message={`${voucher.status} ${voucher?.date_redeemed ? new Date(voucher.date_redeemed).toLocaleDateString('pt-BR', { hour: '2-digit', minute: '2-digit' }) : ''} ${voucher?.panel_user?.name ? `- Usuário: ${voucher.panel_user.name}` : ''}`}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Row>
                )
            }
            <Row
                style={{
                    marginBottom: 24,
                }}
            >
                <Voucher
                    reward={reward}
                    user={appUser}
                    voucher={voucher}
                    clickable={false}
                    fullWidth
                />
            </Row>
            <Form
                autoComplete='off'
                layout='vertical'
            >
                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Nome do Usuário"
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Nome' />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        {...colInputs}
                    >
                        <Form.Item
                            label="Telefone"
                        >
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => <Input {...field} disabled placeholder='Telefone' />}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={16}
                >
                    <Col
                        {...colInputs}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <Button
                            type='default'
                            htmlType='button'
                            disabled={isSubmiting}
                            loading={isSubmiting}
                            onClick={handleClickViewReward}
                            style={{
                                width: '100%'
                            }}
                        >
                            Visualizar recompensa
                        </Button>
                    </Col>
                </Row>

                {
                    voucher?.status === "Emitido" && (
                        <Row
                            style={{
                                marginTop: 16
                            }}
                            justify="end"
                        >
                            <Button
                                type='primary'
                                htmlType='button'
                                disabled={isSubmiting}
                                loading={isSubmiting}
                                onClick={handleConfirmRedemption}
                            >
                                Confirmar entrega
                            </Button>
                        </Row>
                    )
                }
            </Form>

            <RewardImageModal
                isVisible={isRewardImageModalVisible}
                onOk={handleClickCloseRewardImageModal}
                reward={reward}
            />

            <SimpleModal
                open={isConfirmRedemptionModalVisible}
                onOk={handleConfirm}
                okButtonProps={{ 
                    disabled: isSubmiting,
                    loading: isSubmiting
                }}
                cancelButtonProps={{
                    disabled: isSubmiting
                }}
                okType='primary'
                onCancel={handleCancel}
                title={`Deseja realmente confirmar a entrega do Voucher com o código ${voucher?.redemption_code} para o usuário "${appUser?.name}"?`}
            />
        </Loading>
    )
}
